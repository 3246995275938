import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios'
import React, { useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import MiniDrawer from '../../../../../../components/drawer';
import { enqueueSnackbar } from 'notistack';
import { ceil } from 'lodash';



const CouponandOffersettings = () => {
  const auth = JSON.parse(localStorage.getItem("userData"))

  console.log(auth.bundleApproved)

  const params = useParams()
  const navigate = useNavigate()
  const [selectedOffer, setSelectedOffer] = useState("sel")
  const [selectedDiscount, setSelectedDiscount] = useState("sel")
  const [discountValue, setDiscountValue] = useState('')
  const [minimumAmount, setMinimumAmount] = useState('')
  const [selectedCommunity, setSelectedCommunity] = useState([])
  const [selectedYogaFans, setSelectedYogaFans] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [couponCode,setCouponCode] = useState('')
  const [selectedItemObject, setSelectedItemObject] = useState(null);

  const [notes, setNotes] = useState('')

  const [communities, setCommunities] = useState([])
  const [course, setCourses] = useState([])
  const [yogaFans, setYogaFans] = useState([])

  const [expiresAt, setExpiresAt] = useState(null);
  const [activateNow, setActivateNow] = useState(null);
  // Inside your component:
const [amountInYearAmount, setAmountInYearAmount] = useState("");  // default to checked
const [amountInMonthAmount, setAmountInMonthAmount] = useState("");


const [selectedCategory, setSelectedCategory] = useState('');
const [selectedItem, setSelectedItem] = useState('');



const [selectedItemsObjectFans, setSelectedItemsObjectFans] = useState(null);
const [selectedItemsObjectComm, setSelectedItemsObjectComm] = useState(null);
const [selectedItemsObjectCourse, setSelectedItemsObjectCourse] = useState(null);

const [isPrivate, setIsPrivate] = React.useState(false)


const headerlocal = {
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*",
  Authorization: localStorage.getItem("token")
}



  React.useEffect(() => {
    getCommunity();
  }, []);

  const getCommunity = async () => {
    await axios
      .get(
        process.env.REACT_APP_BASE_URL +
        `Community/GetCommunitybasedonteacherid?createdby=${params && params.id
        }`
        // `Community/getCommunity`, { headers: headerlocal }
      )
      .then((res) => {
        console.log(res.data, "community get");

        const discountOptions = res.data.communities.filter(community => 
          community.community_type !== 'public' && community.festiveOffer !== false
          );
          setCommunities(discountOptions)

        // const discountOptions = res.data.community.filter(community => community.community_type !== 'public');
        // setCommunities(discountOptions)


        localStorage.setItem('communityData', JSON.stringify(res.data.communities));

        // console.log(res.data, "community");
      })
      .catch((error) => {
        console.log(error, "error in community get");
      });
  };

  React.useEffect(() => {
    CourseTable()
  }, [])


  const CourseTable = () => {
    axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCoursebycreateId/${auth._id}`)
      .then((res) => {
        console.log(res.data)

        // setTotal(res.data.totalcount)
        const paidcourses = res.data.Courses.filter(course => course.course_paymenttype !== 'free' && course.festiveOffer !== false);
        setCourses(paidcourses)
        console.log(res.data.Courses)
      })
      .catch((err) => {
        console.log(err, "error in CourseTable");
      })
  }

  React.useEffect(() => {
    getFans()
  }, [])

  const getFans = async () => {
    await axios.get(process.env.REACT_APP_BASE_URL + `FanPage/GetFansbyTeacherId/${params.id}`).then((res) => {
      // await axios.get(process.env.REACT_APP_BASE_URL + `FanPage/GettingallfansPages`).then((res) => {
        console.log(res.data)
      const paidFanspage = res.data.fans.filter(fan => 
          fan.fanspage_paymentype !== 'free' && (fan.festiveOffer !== false && fan.festiveOffer !== undefined )
          );
      setYogaFans(paidFanspage)
      // const paidFanspage = res.data.fansPages.filter(fan => fan.fanspage_paymentype !== 'free');
      // setYogaFans(paidFanspage)
    }).catch((error) => {
      console.log(error, "error in service get");
    })
  }


  const handleChangeCouponType = (e) => {
    setSelectedOffer(e.target.value)
    if (e.target.value !== "sel") {
      setErrors(prevErrors => ({ ...prevErrors, selectedOffer: "" }));
    }
    setErrors({
      couponCode: "",
      selectedDiscount: "",
      discountValue: "",
      selectedCommunity: "",
      selectedYogaFans: "",
      selectedCourses: "",
      minimumAmount: "",
      selectedCategory: "",
      selectedItem: "",
      commCheck:'',
      courseCheck:'',
      fansCheck:'',
      disValue:'',
      minValue:"",
      expiresAt: "",
      activateNow: "",
      paymentPlan: "",
      notes: ""
    });
  }



  const handleTogglePrivate = () => {
		setIsPrivate(!isPrivate)
	}
  const handleChangeDiscountType = (e) => {
    setSelectedDiscount(e.target.value)
    if (e.target.value !== "sel") {
      setErrors(prevErrors => ({ ...prevErrors, selectedDiscount: "" }));
    }
  }
  
  const handleChangeDiscountValue = (event) => {
    setDiscountValue(event.target.value);
    // Clear the error when a valid input is provided
    if (event.target.value) {
      setErrors(prevErrors => ({ ...prevErrors, discountValue: "" ,disValue:''}));
    }
  };

  const handleChangeMinimumAmount = (event) => {
    setMinimumAmount(event.target.value);
    // Clear the error when a valid input is provided
    if (event.target.value) {
      setErrors(prevErrors => ({ ...prevErrors, minimumAmount: "" ,minValue:""}));
    }
  };

  const [selectedPayment, setSelectedPayment] = useState(''); // Initialize as empty string or default to the first option

const handlePaymentChange = (event) => {
  setSelectedPayment(event.target.value); // Update selected payment option
  
  setAmountInYearAmount(selectedItemObject.amountInYear) 
   setAmountInMonthAmount(selectedItemObject.amountInMonth)
   setErrors(prevErrors => ({ ...prevErrors, paymentPlan: "" }));

};





  const handleChangeCommunity = (event) => {
    const {
      target: { value },
    } = event;
    const id = event.target.value

    setSelectedCommunity(typeof value === 'string' ? value.split(',') : value);
    const selectedObjects = communities.filter(option => id.includes(option._id));
    setSelectedItemsObjectComm(selectedObjects);
    // Reset error message for payment plan
    setErrors(prevErrors => ({ ...prevErrors, paymentPlan: '' }));
  };

  const [selectedItemsPaymentComm, setSelectedItemsPaymentComm] = useState([]); // State to store payment plans for each fan item

  
  const handlePaymentChangeForCommunity = (event,id) => {
    console.log(id)
    const selectedPaymentPlan = event.target.value;

  // Get the selected community's details using the id
  const selectedCommunity = selectedItemsObjectComm?.find(item => item._id === id);
 

  // Create the updated payment plan object for this specific community
  const updatedPaymentPlan = {
    _id: selectedCommunity._id,
    name: selectedCommunity.coummunity_name,
    amount: selectedPaymentPlan === "month" ? selectedCommunity.amountInMonth : selectedCommunity.amountInYear,
    amountInYear: selectedCommunity.amountInYear ,
    amountInMonth: selectedCommunity.amountInMonth ,
    billingCycle: selectedCommunity.interval, // You might want to update the interval here if needed
    paymentPlan: selectedPaymentPlan, // The selected plan (week/month/year)
  };


   // Now check if this community already has a payment plan in the selectedItemsPaymentComm
  // If yes, update the payment plan; if no, add the initial plan to the array
  setSelectedItemsPaymentComm(prevState => {
    const updatedState = [...prevState];

    const existingIndex = updatedState.findIndex(item => item._id === id);

    if (existingIndex !== -1) {
      // Update the payment plan for the existing community item
      updatedState[existingIndex] = updatedPaymentPlan;
    } else {
      // If the payment plan for this community does not exist, add it to the state
      updatedState.push(updatedPaymentPlan);
    }
    return updatedState;
  });
   

  // Reset error message for payment plan
  setErrors(prevErrors => ({ ...prevErrors, paymentPlan: '',commCheck:"",fansCheck:'',courseCheck:"" }));
  };

  const handleChangeYogaFans = (event) => {
    const {
      target: { value },
    } = event;
    const id = event.target.value    
    setSelectedYogaFans(typeof value === 'string' ? value.split(',') : value);
    const selectedObjects = yogaFans.filter(option => id.includes(option._id));
    setSelectedItemsObjectFans(selectedObjects);
    // Reset error message for payment plan
    setErrors(prevErrors => ({ ...prevErrors, paymentPlan: '' }));
  };


  const [selectedItemsPaymentForFansPage, setSelectedItemsPaymentForFansPage] = useState([]); // State to store payment plans for each fan item

  const handlePaymentChangeForFansPage = (event, id) => {
    const selectedPaymentPlan = event.target.value;
    
    // Get the selected fan page's details using plan._id
    const selectedFanPage = selectedItemsObjectFans?.find(item => item._id === id);
  
    // Check if the fan page was found
    if (!selectedFanPage) return;
  
    // Create the updated payment plan object for this specific fan page
    const updatedPaymentPlan = {
      _id: selectedFanPage._id,
      name: selectedFanPage.name,
      amountInYear: selectedFanPage.amountInYear ,
      // amountInMonth: selectedFanPage.amountInMonth * 12,
      amountInMonth: selectedFanPage.amountInMonth ,
      paymentPlan: selectedPaymentPlan, // The selected plan (yearly or monthly)
      amount: selectedPaymentPlan === "month"? selectedFanPage.amountInMonth : selectedPaymentPlan === "year"? selectedFanPage.amountInYear :null,
    };
  
    // Update the state to reflect the selected payment plan for this fan
    setSelectedItemsPaymentForFansPage(prevState => {
      const updatedState = [...prevState];
  
      const existingIndex = updatedState.findIndex(item => item._id === id);
  
      if (existingIndex !== -1) {
        // Update the payment plan for the existing community item
        updatedState[existingIndex] = updatedPaymentPlan;
      } else {
        // If the payment plan for this community does not exist, add it to the state
        updatedState.push(updatedPaymentPlan);
      }
      return updatedState;
    });
  
    // Reset error message for payment plan
    setErrors(prevErrors => ({ ...prevErrors, paymentPlan: '',commCheck:"",fansCheck:'',courseCheck:"" }));
  };

  const handleChangeCourses = (event) => {
    const {
      target: { value },
    } = event;
    const id = event.target.value
  

    setSelectedCourses(typeof value === 'string' ? value.split(',') : value);
    const selectedObjects = course.filter(option => id.includes(option._id));
    setSelectedItemsObjectCourse(selectedObjects);
    // Reset error message for payment plan
    setErrors(prevErrors => ({ ...prevErrors, paymentPlan: '' }));
  };


  const [selectedPaymentForCourse, setSelectedPaymentForCourse] = useState([]);

  const handlePaymentChangeForCourse = (event, id) => {
    const selectedPaymentPlan = event.target.value;

 
    
    // Get the selected course's details using id
    const selectedCourse = selectedItemsObjectCourse?.find(item => item._id === id);
console.log(selectedCourse)
    // Check if the course was found
    if (!selectedCourse) return;
  
    // Create the updated payment plan object for this specific course
    const updatedPaymentPlan = {
      _id: selectedCourse._id,
      name: selectedCourse.course_title,
      installment: selectedCourse.installment,
      amountInWeek: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInWeek : null,
      amountInBiWeek: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInByWeekly : null,
      amountInMonth: selectedCourse.course_paymenttype === "recurring" ? selectedCourse.amountInMonth : null,
      amountInOneTime: selectedCourse.course_paymenttype === "onetimepayment" ? selectedCourse.amountOneTime : null,
      paymentPlan: selectedPaymentPlan,
      interval: selectedCourse.course_paymenttype === "recurring" ? "recurring" : "onetimepayment"
      
    };
  
    // Update the state for selected payments for courses
    // Update the state to reflect the selected payment plan for this fan
    setSelectedPaymentForCourse(prevState => {
      const updatedState = [...prevState];
  
      const existingIndex = updatedState.findIndex(item => item._id === id);
  
      if (existingIndex !== -1) {
        // Update the payment plan for the existing community item
        updatedState[existingIndex] = updatedPaymentPlan;
      } else {
        // If the payment plan for this community does not exist, add it to the state
        updatedState.push(updatedPaymentPlan);
      }
      return updatedState;
    });
  
    // Reset error message for payment plan
    setErrors(prevErrors => ({ ...prevErrors, paymentPlan: '',commCheck:"",fansCheck:'',courseCheck:"" }));
  };

  const renderValue = (selected, options, placeholder) => {
    if (selected.length === 0) return placeholder;
    const selectedLabels = options
      .filter(option => selected.includes(option._id))
      .map(option => option.coummunity_name);
    return selectedLabels.join(', ');
  };

  const renderValueCourse = (selected, options, placeholder) => {
    if (selected.length === 0) return placeholder;
    const selectedLabels = options
      .filter(option => selected.includes(option._id))
      .map(option => option.course_title);
    return selectedLabels.join(', ');
  };

  // yogaFans

  const renderValueFansPage = (selected, options, placeholder) => {
    if (selected.length === 0) return placeholder;
    const selectedLabels = options
      .filter(option => selected.includes(option._id))
      .map(option => option.name);
    return selectedLabels.join(', ');
  };

  const handleActivateNowChange = (newValue) => {
    setActivateNow(newValue);

    if (!newValue) {
        setErrors(prev => ({ ...prev, activateNow: "Activation time is required." }));
        return; // Exit early if no value
    }

    if (newValue.isBefore(dayjs())) {
        setErrors(prev => ({ ...prev, activateNow: "Activation time must be in the future." }));
    } else if (expiresAt && newValue.isAfter(expiresAt)) {
        setErrors(prev => ({ ...prev, activateNow: "Activation time must be before the expiration date." }));
    } else {
        setErrors(prev => ({ ...prev, activateNow: '' }));
    }
};

const handleExpiresAtChange = (newValue) => {
    setExpiresAt(newValue);

    if (!newValue) {
        setErrors(prev => ({ ...prev, expiresAt: "Expiration date is required." }));
        return; // Exit early if no value
    }

    if (newValue.isBefore(dayjs())) {
        setErrors(prev => ({ ...prev, expiresAt: "Expiration time must be greater than the current time." }));
    } else if (activateNow && newValue.isBefore(activateNow)) {
        setErrors(prev => ({ ...prev, expiresAt: "Expiration time must be greater than the activation time." }));
    } else {
        setErrors(prev => ({ ...prev, expiresAt: '' }));
    }
};
  
 

  const [errors, setErrors] = useState({
    couponCode: "",
    selectedDiscount: "",
    discountValue: "",
    selectedCommunity: "",
    selectedYogaFans: "",
    selectedCourses: "",
    disValue:'',
    minValue:'',
    minimumAmount:'',
    selectedCategory:"",
    selectedItem:"",
    commCheck:'',
    courseCheck:'',
    fansCheck:'',
    expiresAt:"",
    activateNow: '',
    paymentPlan:"",
    notes:''
  });

  const modifyString = str => str.split(" ").map((word, index) => index === 0 ? word.slice(0, -1) : word).join(" ");

  const validateFields = () => {
    const newErrors = { ...errors }; // Start with current errors
    console.log(newErrors)

    // Reset errors
    Object.keys(newErrors).forEach(key => {
      newErrors[key] = "";
    });

    // Check required fields
    if (!selectedOffer || selectedOffer === "sel") {
      newErrors.selectedOffer = "Offer type is required.";
      
    }

    if (selectedOffer === "item_Level") {
      if (!couponCode) {
        newErrors.couponCode = "Coupon code is required.";
      }

      if (!selectedDiscount || selectedDiscount === "sel") {
        newErrors.selectedDiscount = "Discount type is required.";
      }

      if (!discountValue) {
        newErrors.discountValue = "Discount value is required.";
      }


      if (discountValue) {

        if (!/^\d*\.?\d*$/.test(discountValue)) {
          newErrors.disValue = "Numberic value is required.";
        }
        
      }


      if (!notes) {
        newErrors.notes = "Description is required.";
      }

      if(!selectedCategory){
        newErrors.selectedCategory = "Category is required.";
      }

      if(selectedCategory){
        if(!selectedItem){
          newErrors.selectedItem = `${modifyString(selectedCategory)} is required`;
        }
      }

      if(selectedCategory === "Yoga-Fans"){
        if(selectedItem){
          if(!selectedPayment){

            newErrors.paymentPlan = `Payment Plan is required`;
          }
        }
      }


      // if (selectedCommunity.length === 0) {
      //   newErrors.selectedCommunity = "At least one community must be selected.";
      // }

      // if (selectedYogaFans.length === 0) {
      //   newErrors.selectedYogaFans = "At least one Yoga-Fan page must be selected.";
      // }

      // if (selectedCourses.length === 0) {
      //   newErrors.selectedCourses = "At least one course must be selected.";
      // }

      // Validate "Expires At"
      // if (!expiresAt) {
      //   newErrors.expiresAt = "Expiration date is required.";
      // } 
      // Validate "Activate Now"
      // if (!activateNow) {
      //   newErrors.activateNow = "Activation time is required.";
      // }


    }
    else if(selectedOffer === "cart_Level"){

      if (!couponCode) {
        newErrors.couponCode = "Coupon code is required.";
      }

      if (!selectedDiscount || selectedDiscount === "sel") {
        newErrors.selectedDiscount = "Discount type is required.";
      }

      if (!discountValue) {
        newErrors.discountValue = "Discount value is required.";
      }

      if (discountValue) {

        if (!/^\d*\.?\d*$/.test(discountValue)) {
          newErrors.disValue = "Numberic value is required.";
        }
        
      }
      if (!minimumAmount) {
        newErrors.minimumAmount = "Minimum Amount is Required.";
      }

      if(minimumAmount){
        if (!/^\d*\.?\d*$/.test(minimumAmount)) {
          newErrors.minValue = "Numberic value is required.";
        }
      }


    

    if (!notes) {
      newErrors.notes = "Description is required.";
    }
    }
    
    
    
    
    else{

      if (!couponCode) {
        newErrors.couponCode = "Coupon code is required.";
      }

      if (!selectedDiscount || selectedDiscount === "sel") {
        newErrors.selectedDiscount = "Discount type is required.";
      }

      if (!discountValue) {
        newErrors.discountValue = "Discount value is required.";
      }


      if (discountValue) {

        if (!/^\d*\.?\d*$/.test(discountValue)) {
          newErrors.disValue = "Numberic value is required.";
        }
        
      }


      console.log(selectedItemsPaymentComm,selectedItemsPaymentForFansPage, selectedPaymentForCourse)

      if(!selectedItemsPaymentComm.length  && !selectedItemsPaymentForFansPage.length  &&  !selectedPaymentForCourse.length  ){
        newErrors.paymentPlan = "Select atleast one Field required.";
        if(!selectedItemsPaymentComm.length  ){
          newErrors.commCheck = `payment Plan is required`;
        }
        if(!selectedItemsPaymentForFansPage.length  ){
          newErrors.fansCheck = `payment Plan is required`;
        }
        if(!selectedPaymentForCourse.length  ){
          newErrors.courseCheck = `payment Plan required`;
        }
      }


      // if(selectedItemsPaymentComm.length  || selectedItemsPaymentForFansPage.length  ||  selectedPaymentForCourse.length  ){
      //   if(!selectedItemsPaymentComm.length  ){
      //     newErrors.commCheck = `payment Plan is required`;
      //   }
      //   if(!selectedItemsPaymentForFansPage.length  ){
      //     newErrors.fansCheck = `payment Plan is required`;
      //   }
      //   if(!selectedPaymentForCourse.length  ){
      //     newErrors.courseCheck = `payment Plan required`;
      //   }
      // }

      if (!notes) {
        newErrors.notes = "Description is required.";
      }
    }

    setErrors(newErrors);
    return newErrors;
  };


  const handleItemChange = (event) => {
  
    let value = event.target.value;
    setSelectedItem([{_id: value}]);

    if(selectedCategory === "Yoga-Fans"){
      // Find the selected object from the yogaFans array
      const selectedObject = yogaFans.find(option => option._id === value);
      setSelectedItemObject(selectedObject);
      
    }else{
      if(selectedCategory === "Communities"){
        // Find the selected object from the yogaFans array
        const selectedObject = communities.find(option => option._id === value);
        setSelectedItemObject(selectedObject);
      }
    }

  
  if(value) setErrors((prev)=>({...prev,selectedItem:''}))
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedItem(''); // Reset selected item when category changes
    if(event.target.value) setErrors((prev)=>({...prev,selectedCategory:''}))
  };

  

  console.log(selectedItemObject)
  // here i have to continue make radio button on show the monthly and yearlky pricw.

  

  
  
  
  const renderOptions = () => {
    let options = [];
    if (selectedCategory === 'Communities') {
      options = communities;
    } else if (selectedCategory === 'Yoga-Fans') {
      options = yogaFans;
    } else if (selectedCategory === 'Courses') {
      options = course;
    } 

   
    
    return options.map((option) => (
      <MenuItem key={option._id} value={option._id}>
        <Checkbox checked={selectedItem && selectedItem[0]._id === option._id} />
        <ListItemText primary={option.coummunity_title || option.name || option.course_title} />
      </MenuItem>
    ));
  };
  const getSelectedItemName = () => {
    const selectedOption = (selectedCategory === 'Communities' ? communities :
      selectedCategory === 'Yoga-Fans' ? yogaFans : course
    ).find(option => option._id === selectedItem[0]._id);

    return selectedOption ? selectedOption.coummunity_title || selectedOption.name || selectedOption.course_title : '';
  };
  // function calculateTotalPrice(communities,courses,fanspages,discountType,discountAmount) {
  //   // Initialize sums for each category
  //   let communitySum = 0;
  //   let courseSum = 0;
  //   let fanspageSum = 0;

    
  //   // 1. Calculate the sum for communities
  //   communities.forEach(community => {
     
  //     if (community.paymentPlan === 'week') communitySum += community.amount * 52;
  //     if (community.paymentPlan === 'month') communitySum += community.amount * 12;
  //     if (community.paymentPlan === 'year') communitySum += community.amount;
  //   });

  
  //   // 2. Calculate the sum for courses
  //   courses.forEach(course => {
  //     if (course.amountInMonth) courseSum += course.amountInMonth * 12;
  //     if (course.amountInWeek) courseSum += course.amountInWeek * 52;
  //     if (course.amountInBiWeek) courseSum += course.amountInBiWeek * 26;
  //     if (course.amountInOneTime) courseSum += course.amountInOneTime;
  //   });

  

  //   // 3. Calculate the sum for fan pages
  //   fanspages.forEach(fanspage => {
  //       if (fanspage.amountInYear) fanspageSum += fanspage.amountInYear;
  //     if (fanspage.amountInMonth) fanspageSum += fanspage.amountInMonth * 12;
  //   });

  

  
  //   // 4. Calculate the total sum
  //   let totalSum = communitySum + courseSum + fanspageSum;
  
  //   // 5. Apply discount based on discount type
  //   let finalDiscountedPrice = totalSum;
  //   if (discountType === "flatDiscount") {
  //     finalDiscountedPrice = totalSum - (parseFloat(discountAmount) || 0);
  //   } else if (discountType === "Percentage") {
  //     finalDiscountedPrice = Math.floor(totalSum - (totalSum * (parseFloat(discountAmount) / 100)));
  //   }
  
  //   // 6. Return the result with the total and discounted price
  //   return [
  //     {
  //       originalSum: totalSum,
  //       discountedPrice: finalDiscountedPrice
  //     }
  //   ];
  // }

  function calculateTotalPrice(communities, courses, fanspages, discountType, discountAmount) {   
    
        // Initialize sums for each category
        let communitySum = 0;
        let courseSum = 0;
        let fanspageSum = 0;
    
        // 1. Calculate the sum for communities
        communities.forEach(community => {
            if (community.paymentPlan === 'month') communitySum += community.amountInMonth * 12;
            if (community.paymentPlan === 'year') communitySum += community.amountInYear;
        });
    
        // 2. Calculate the sum for courses
        courses.forEach(course => {
            if (course.amountInMonth) courseSum += course.amountInMonth * 12;
            if (course.amountInWeek) courseSum += course.amountInWeek * 52;
            if (course.amountInBiWeek) courseSum += course.amountInBiWeek * 26;
            if (course.amountInOneTime) courseSum += course.amountInOneTime;
        });
    
        // 3. Calculate the sum for fan pages
        fanspages.forEach(fanspage => {
            if (fanspage.paymentPlan === 'year') fanspageSum += fanspage.amountInYear;
            if (fanspage.paymentPlan === 'month') fanspageSum += fanspage.amountInMonth * 12;
        });
    
        console.log(communities)
        console.log(courses)
        console.log(fanspages)
    
        // Calculate total sum across all categories
        let totalSum = communitySum + courseSum + fanspageSum;
        
        // Calculate the total number of items across all arrays
        let totalItems = communities.length + courses.length + fanspages.length;
    
        // 4. Apply discount based on discount type
        let discountPerItem = 0;
        let finalDiscountedPrice = 0;
    
        if (discountType === "flatDiscount") {
            // Calculate the flat discount per item
            discountPerItem = (parseFloat(discountAmount) || 0) / totalItems;
    
            // Apply the discount to each item in the arrays
            communities.forEach(community => {
              if (community.paymentPlan === 'week') community.AfterDiscount =  community.amount * 52 - discountPerItem;
              if (community.paymentPlan === 'month') community.AfterDiscount =  community.amountInMonth * 12 - discountPerItem;
              if (community.paymentPlan === 'year') community.AfterDiscount =  community.amountInYear - discountPerItem;
            });
            courses.forEach(course => {
                if (course.amountInMonth) course.AfterDiscount = course.amountInMonth * Number(course.installment) - discountPerItem;
                if (course.amountInWeek) course.AfterDiscount = course.amountInWeek * Number(course.installment) - discountPerItem;
                if (course.amountInBiWeek) course.AfterDiscount = course.amountInBiWeek * Number(course.installment) - discountPerItem;
                if (course.amountInOneTime) course.AfterDiscount = course.amountInOneTime - discountPerItem;
            });
            fanspages.forEach(fanspage => {
                if (fanspage.paymentPlan === 'year') fanspage.AfterDiscount = fanspage.amountInYear - discountPerItem;
                if (fanspage.paymentPlan === 'month') fanspage.AfterDiscount = fanspage.amountInMonth * 12 - discountPerItem;
            });
    
            // 5. Calculate the final discounted price (after applying discount)
        finalDiscountedPrice = totalSum - discountPerItem * totalItems;
        } else if (discountType === "Percentage") {
            // Apply percentage discount to each item in the arrays
            communities.forEach(community => {
    
              
              if (community.paymentPlan === 'month') community.AfterDiscount = community.amountInMonth * 12 - (community.amountInMonth * 12 * (parseFloat(discountAmount) / 100));
              if (community.paymentPlan === 'year') community.AfterDiscount = community.amountInYear - (community.amountInYear *  (parseFloat(discountAmount) / 100))
                
            });
            courses.forEach(course => {
                if (course.amountInMonth) course.AfterDiscount = course.amountInMonth * 12 - (course.amountInMonth * 12 * (parseFloat(discountAmount) / 100));
                if (course.amountInWeek) course.AfterDiscount = course.amountInWeek * 52 - (course.amountInWeek * 52 * (parseFloat(discountAmount) / 100));
                if (course.amountInBiWeek) course.AfterDiscount = course.amountInBiWeek * 26 - (course.amountInBiWeek * 26 * (parseFloat(discountAmount) / 100));
                if (course.amountInOneTime) course.AfterDiscount = course.amountInOneTime - (course.amountInOneTime * (parseFloat(discountAmount) / 100));
            });
            fanspages.forEach(fanspage => {
                if (fanspage.paymentPlan === 'year') fanspage.AfterDiscount = fanspage.amountInYear - (fanspage.amountInYear * (parseFloat(discountAmount) / 100));
                if (fanspage.paymentPlan === 'month') fanspage.AfterDiscount = fanspage.amountInMonth * 12 - (fanspage.amountInMonth * 12 * (parseFloat(discountAmount) / 100));
            });
            // 5. Calculate the final discounted price (after applying percentage discount)
            finalDiscountedPrice = totalSum - (totalSum * (ceil(discountAmount) / 100));
        }
    
    
    
        // The arrays have been directly updated with the price field
        console.log({
            originalSum: totalSum,
            discountedPrice: finalDiscountedPrice,
            discountType: discountType,
            discountAmount: discountAmount,
        });
    
        return [
              {
                originalSum:totalSum,
                discountedPrice:finalDiscountedPrice,
                discountType:selectedDiscount,
                discountAmount:discountValue,
              }
            ];
    }




  // console.log(selectedCommunity)
  //   console.log(selectedYogaFans)
  //   console.log(selectedCourses)
  

  const handleSubmit = async (event) => {

    event.preventDefault();

    const validationErrors = validateFields();
    
    if (Object.keys(validationErrors).some(key => validationErrors[key])) {
      return; // Exit if there are validation errors
    }


    const result = calculateTotalPrice(selectedItemsPaymentComm,selectedPaymentForCourse, selectedItemsPaymentForFansPage, selectedDiscount,discountValue);

console.log(selectedItemsPaymentComm)
   
let data;

    if(selectedOffer === "bundle_Level"){
      
      data = {
        aprrove:true,
         offerType:selectedOffer,
        coupon_code:couponCode,
        discountType:selectedDiscount,
        discountAmount:discountValue,
        // communities:selectedCommunity,
        // fanspages:selectedYogaFans,
        // courses:selectedCourses,
        communities: selectedItemsPaymentComm,
        fanspages:selectedItemsPaymentForFansPage,
        courses: selectedPaymentForCourse,
        expiresAt:expiresAt,
        activate:activateNow,
        notes:notes,
        paymentType:selectedPayment ? selectedPayment : "none",
        amountInYear:amountInYearAmount,
        amountInMonth:amountInMonthAmount,
        minimum_amount:minimumAmount,
        bundle_price:result,
        createdby:params.id
      }
    }
    else{
      if (selectedCategory === 'Communities') {
        setSelectedCommunity(selectedItem)
      } else if (selectedCategory === 'Yoga-Fans') {
       
        setSelectedYogaFans((prevState) => [...prevState, selectedItem])
      } else if (selectedCategory === 'Courses') {
        setSelectedCourses(selectedItem)
      }
// // Prepare data for submission
 data = {
  aprrove:true,
   offerType:selectedOffer,
  coupon_code:couponCode,
  discountType:selectedDiscount,
  discountAmount:discountValue,
  isPrivate:isPrivate,
  // communities:selectedCommunity,
  // fanspages:selectedYogaFans,
  // courses:selectedCourses,
  communities: selectedCategory === 'Communities' ? selectedItem : selectedCommunity ,
  fanspages:selectedCategory === 'Yoga-Fans'? selectedItem : selectedYogaFans ,
  courses:selectedCategory === 'Courses'? selectedItem : selectedCourses ,
  expiresAt:expiresAt,
  activate:activateNow,
  notes:notes,
  paymentType:selectedPayment ? selectedPayment : "none",
  amountInYear:amountInYearAmount,
  amountInMonth:amountInMonthAmount,
  minimum_amount:minimumAmount,
  createdby:params.id
};
    }

    

   
    console.log(data)
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + 'Coupon/addCoupon', data); // Replace with your actual API endpoint
      console.log('Success:', response.data);
      navigate(`/teachers/details/settings/coupons/${params.id}`)
      enqueueSnackbar("Coupon Added Successfully", { variant: 'success' });

      // Handle success (e.g., show a success message, clear the form, etc.)

    } catch (error) {
      console.error('Error:', error);
      enqueueSnackbar(`${error.response.data.error}`, { variant: 'error' });
      // Handle error (e.g., show an error message)
    }
  };

 


  const labelStyle = {
    width: '150px',
    display: 'inline-block',
    textAlign: 'left'
  };

  const inputStyle = {
    width: '500px'
  };



  return (
    <>
    <Box className='companies'>
            <MiniDrawer menuOrder={12} submenuOrder={2}/>
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Settings</Typography>                    
                </Box>
                <Box className='whiteContainer' sx={{minHeight:"80vh"}}>
                <Grid item xs={12} sm={6} md={6} lg={12}>
        <Box className="">
          <Box className="mb-10  p-10">
            <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Add Coupons and Offers</Typography>
          </Box>
          <Box className="mb-20 p-10">
            <Box className="mb-10 p-20 whiteContainer ">
              <form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Box className='mb-20'>
                          <FormControl  >
                            <Typography component='div' className='label'>Select The Type of Offer<Typography component='span' className='star'>*</Typography></Typography>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"

                              value={selectedOffer ? selectedOffer : "sel"}
                              placeholder="Select The Type of Offer"
                              className="select"
                              sx={{
                                minWidth: "500px"
                              }}
                              onChange={handleChangeCouponType}
                            >
                              <MenuItem disabled value={'sel'}>Select Offer Type</MenuItem>
                              {/* ['item_Level', 'cart_Level'], */}
                              <MenuItem value={"item_Level"}>Item Level Offer</MenuItem>
                              <MenuItem value={"cart_Level"}>Cart Level Offer</MenuItem>
                              {auth.bundleApproved && <MenuItem value={"bundle_Level"}>Bundle Level Offer</MenuItem>}
                            </Select>
                            {errors.selectedOffer && (
                              <p style={{ color: 'red' }} className="fz-13 m-0">
                                {errors.selectedOffer}
                              </p>
                            )}
                          </FormControl>
                        </Box>
                        <Box className='mb-20'>

                          <Typography component='div' className='label'>
                          <span>Enter {selectedOffer === "bundle_Level" ? "Bundle name" : "Coupon Code"}</span>
                            <Typography component='span' className='star'>*</Typography>
                            <Typography component='span' style={{ fontSize: 'smaller' }}>
                              {/* This name will be used to create a unique URL for your community */}
                            </Typography>
                          </Typography>
                          <FormControl variant="standard" className='mb-10'>
                            <TextField
                              id="filled-size-small"
                              variant="outlined"
                              size="small"
                              name='name'
                              className='textfield'
                              style={{ width: '500px' }}
                              value={couponCode}
                              onChange={(e) => {
                                setCouponCode(e.target.value);
                                setErrors(prev => ({ ...prev, couponCode: "" }));
                              }}
                            // InputProps={{
                            // 	startAdornment: <InputAdornment position="start"></InputAdornment>,
                            // }}
                            />
                            {errors.couponCode && <p style={{ color: 'red' }} className="fz-13 m-0">{errors.couponCode}</p>}
                          </FormControl>

                          <Typography component='div' className='label'>
                            Choose Discount Type<Typography component='span' className='star'>*</Typography>
                          </Typography>
                          <FormControl className='mb-20' >
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"

                              value={selectedDiscount ? selectedDiscount : "sel"}
                              placeholder="Select Discount Type"
                              className="select"
                              sx={{
                                minWidth: "500px"
                              }}
                              onChange={handleChangeDiscountType}
                            >
                              <MenuItem disabled value={'sel'}>Select Discount Type</MenuItem>

                              <MenuItem value={"flatDiscount"}>Flat Discount</MenuItem>
                              <MenuItem value={"Percentage"}>Percentage</MenuItem>
                            </Select>
                            {errors.selectedDiscount && (
                              <p style={{ color: 'red' }} className="fz-13 m-0">
                                {errors.selectedDiscount}
                              </p>
                            )}
                          </FormControl>

                          <Typography component='div' className='label'>
                            <span>Discount Value</span>
                            <Typography component='span' className='star'>*</Typography>
                            <Typography component='span' style={{ fontSize: 'smaller' }}>
                              {/* This name will be used to create a unique URL for your community */}
                            </Typography>
                          </Typography>
                          <FormControl variant="standard" className='mb-10'>
                            <TextField
                              id="filled-size-small"
                              variant="outlined"
                              size="small"
                              name='name'
                              className='textfield'
                              style={{ width: '500px' }}
                              value={discountValue}
                              onChange={handleChangeDiscountValue}
                              InputProps={{
                                startAdornment: selectedDiscount === "Percentage" ? (
                                  <InputAdornment position="start">%</InputAdornment>
                                ) : <InputAdornment position="start">$</InputAdornment>,
                              }}
                            />
                            {errors.discountValue && (
                              <p style={{ color: 'red' }} className="fz-13 m-0">
                                {errors.discountValue}
                              </p>
                            )}
                            {errors.disValue && (
                              <p style={{ color: 'red' }} className="fz-13 m-0">
                                {errors.disValue}
                              </p>
                            )}
                          </FormControl>
                        </Box>

                        {/* Conditional Rendering Based on Selected Value */}
                        {/* ------------------------------------->>************Item_Level Start**************<<------------------------------------------------------ */}
                        {selectedOffer === "item_Level" && (

                          <Box className="mb-20 ">
                           
                            <Typography component='div' className='label'>
                              <span>Select Category</span>
                              <Typography component='span' className='star'>*</Typography>
                              <Typography component='span' style={{ fontSize: 'smaller' }}>
                                {/* This name will be used to create a unique URL for your community */}
                              </Typography>
                            </Typography>
                            <FormControl className='mb-20 mt-5'>
                              <InputLabel id="category-select-label">Select Category</InputLabel>
                              <Select
                                labelId="category-select-label"
                                id="category-select"
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                sx={{ minWidth: "500px" }}
                              >
                                <MenuItem value="Communities">Communities</MenuItem>
                                <MenuItem value="Yoga-Fans">Yoga-Fans Pages</MenuItem>
                                <MenuItem value="Courses">Courses</MenuItem>
                              </Select>
                              {errors.selectedCategory && (
                                <p style={{ color: 'red' }} className="fz-13 m-0">
                                  {errors.selectedCategory}
                                </p>
                              )}
                            </FormControl>


                            {selectedCategory && (
                              <>
                                <Typography component='div' className='label'>
                                  <span>{`Select ${selectedCategory}`}</span>
                                  <Typography component='span' className='star'>*</Typography>
                                  <Typography component='span' style={{ fontSize: 'smaller' }}>
                                    {/* This name will be used to create a unique URL for your community */}
                                  </Typography>
                                </Typography>
                                <FormControl className='mb-20 mt-5'>
                                  <InputLabel id="item-select-label">Select {selectedCategory}</InputLabel>
                                  <Select
                                    labelId="item-select-label"
                                    id="item-select"
                                    value={selectedItem}
                                    onChange={handleItemChange}
                                    renderValue={getSelectedItemName} // Display only the selected item's name
                                    sx={{ minWidth: "500px" }}
                                  >
                                    {renderOptions()}
                                  </Select>
                                  {errors.selectedItem && (
                                    <p style={{ color: 'red' }} className="fz-13 m-0">
                                      {errors.selectedItem}
                                    </p>
                                  )}
                                </FormControl>
                              </>
                            )}
                            
                            {selectedItemObject && selectedCategory === 'Yoga-Fans' && (
                              <>
                                <Typography component="div" className="label">
                                  <span>Select Payment Amount</span>
                                  <Typography component='span' className='star'>*</Typography>
                                </Typography>


                                <FormControl className="mb-20 mt-5">
                                  <FormLabel component="legend" className="label">
                                    Choose Payment Plan:
                                  </FormLabel>

                                  {/* Radio group for Payment Options */}
                                  <RadioGroup
                                    value={selectedPayment} // The state variable to track selected payment plan
                                    onChange={handlePaymentChange} // Function to handle radio button selection change
                                  >
                                    {/* Radio button for amountInYear, show only if valid amount exists */}
                                    {selectedItemObject.amountInYear ? (
                                      <FormControlLabel
                                        value="year"
                                        control={<Radio />}
                                        label={`Amount per Year: ${selectedItemObject.amountInYear} ${selectedItemObject.currency}`}
                                      />
                                    ) : <Typography component="div" className="label">
                                      <span>Amount per Year: No Amount Available</span>
                                    </Typography>}

                                    {/* Radio button for amountInMonth, show only if valid amount exists */}
                                    {selectedItemObject.amountInMonth ? (
                                      <FormControlLabel
                                        value="month"
                                        control={<Radio />}
                                        label={`Amount per Month: ${selectedItemObject.amountInMonth} ${selectedItemObject.currency}`}
                                      />
                                    ) : <Typography component="div" className="label">
                                      <span>Amount per Month: No Amount Available</span>
                                    </Typography>}

                                  </RadioGroup>

                                  {errors.paymentPlan && (
                                    <p style={{ color: 'red' }} className="fz-13 m-0">
                                      {errors.paymentPlan}
                                    </p>
                                  )}
                                </FormControl>
                              </>
                            )}

                            {selectedItemObject && selectedCategory === 'Communities' && (
                              <>
                                <Typography component="div" className="label">
                                  <span>Select Payment Amount</span>
                                  <Typography component='span' className='star'>*</Typography>
                                </Typography>


                                <FormControl className="mb-20 mt-5">
                                  <FormLabel component="legend" className="label">
                                    Choose Payment Plan:
                                  </FormLabel>

                                  {/* Radio group for Payment Options */}
                                  <RadioGroup
                                    value={selectedPayment} // The state variable to track selected payment plan
                                    onChange={handlePaymentChange} // Function to handle radio button selection change
                                  >
                                    {/* Radio button for amountInYear, show only if valid amount exists */}
                                    {selectedItemObject.amountInYear ? (
                                      <FormControlLabel
                                        value="year"
                                        control={<Radio />}
                                        label={`Amount per Year: ${selectedItemObject.amountInYear} ${selectedItemObject.currency}`}
                                      />
                                    ) : <Typography component="div" className="label">
                                      <span>Amount per Year: No Amount Available</span>
                                    </Typography>}

                                    {/* Radio button for amountInMonth, show only if valid amount exists */}
                                    {selectedItemObject.amountInMonth ? (
                                      <FormControlLabel
                                        value="month"
                                        control={<Radio />}
                                        label={`Amount per Month: ${selectedItemObject.amountInMonth} ${selectedItemObject.currency}`}
                                      />
                                    ) : <Typography component="div" className="label">
                                      <span>Amount per Month: No Amount Available</span>
                                    </Typography>}

                                  </RadioGroup>

                                  {errors.paymentPlan && (
                                    <p style={{ color: 'red' }} className="fz-13 m-0">
                                      {errors.paymentPlan}
                                    </p>
                                  )}
                                </FormControl>
                              </>
                            )}

                            <Box className="mb-20">
                              <FormControlLabel
                                className="checkbox_list"
                                control={
                                  <Checkbox
                                    checked={isPrivate}
                                    onChange={handleTogglePrivate}
                                    name="checkbox-value"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography component="div" className="label mb-0">
                                    Do you want to make this  as Private Coupon ? {" "}
                                  </Typography>
                                }
                              />
                            </Box>
                          </Box>
                        )}
                        {/* ------------------------------------->>************Item_Level End**************<<------------------------------------------------------ */}


                        {/* ------------------------------------->>************cart_Level Start**************<<------------------------------------------------------ */}

                        {selectedOffer === "cart_Level" && (
                          <Box className="mb-20 ">
                            <Typography component='div' className='label'>
                              <span>Minimum Amount</span>
                              <Typography component='span' className='star'>*</Typography>
                              <Typography component='span' style={{ fontSize: 'smaller' }}>
                                {/* This name will be used to create a unique URL for your community */}
                              </Typography>
                            </Typography>
                            <FormControl variant="standard" className='mb-10'>
                              <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                name='name'
                                className='textfield'
                                style={{ width: '500px' }}
                                value={minimumAmount}
                                onChange={handleChangeMinimumAmount}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                              />
                              {errors.minimumAmount && (
                                <p style={{ color: 'red' }} className="fz-13 m-0">
                                  {errors.minimumAmount}
                                </p>
                              )}

                              {errors.minValue && (
                                <p style={{ color: 'red' }} className="fz-13 m-0">
                                  {errors.minValue}
                                </p>
                              )}
                            </FormControl>

                            <Box className="mb-20">
                              <FormControlLabel
                                className="checkbox_list"
                                control={
                                  <Checkbox
                                    checked={isPrivate}
                                    onChange={handleTogglePrivate}
                                    name="checkbox-value"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography component="div" className="label mb-0">
                                    Do you want to make this  as Private Coupon ? {" "}
                                  </Typography>
                                }
                              />
                            </Box>
                          </Box>
                        )}

                        {/* ------------------------------------->>************Cart_Level End**************<<------------------------------------------------------ */}


                        {/* ------------------------------------->>************bundle_Level Start**************<<------------------------------------------------------ */}
                        {selectedOffer === "bundle_Level" && (

                          <Box className="mb-20 ">
                            {/* ----------------------------------------------Communities Start--------------------------------------- */}

                            <Typography component='div' className='label'>
                              Assign Communities
                              {/* <Typography component='span' className='star'>*</Typography> */}
                            </Typography>
                            <FormControl className='mb-20 mt-5'>
                              <InputLabel id="demo-simple-select-label">Select Communities</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiple
                                value={selectedCommunity}
                                onChange={handleChangeCommunity}
                                renderValue={() => renderValue(selectedCommunity, communities, 'Select Yoga Communities')}
                                sx={{ minWidth: "500px" }}
                              >
                                {communities.map((option) => (
                                  <MenuItem key={option._id} value={option._id}>
                                    <Checkbox checked={selectedCommunity.indexOf(option._id) > -1} />
                                    <ListItemText primary={option.coummunity_name} />
                                  </MenuItem>
                                ))}
                              </Select>

                              {/* Display error message if any */}
                              {errors.paymentPlan && (
                                <p style={{ color: 'red' }} className="fz-13 m-0">
                                  {errors.paymentPlan}
                                </p>
                              )}
                            </FormControl>

                            {
                              selectedItemsObjectComm?.length > 0 && (
                                <>
                                  <Typography component="div" className="label">
                                    <span></span>
                                    <Typography component="span" className="star"></Typography>
                                  </Typography>
                                  {/* Loop through each item in the selectedItemObject array */}
                                  {selectedItemsObjectComm?.map((plan, index) => (
                                    <>
                                      <Typography component="div" className="label">
                                        <span>{plan.coummunity_name}</span>
                                        <Typography component="span" className="star">*</Typography>
                                      </Typography>
                                      <FormControl className="mb-20 mt-5" key={index}>

                                        <FormLabel component="legend" className="label">
                                          Choose Payment Plan for {plan.coummunity_name}: {/* Display the plan index */}
                                        </FormLabel>

                                        {/* Radio group for Payment Options */}
                                        <RadioGroup
                                          value={selectedItemsPaymentComm.find(item => item._id === plan._id)?.paymentPlan || ''} // The state variable to track selected payment plan
                                          onChange={(event) => handlePaymentChangeForCommunity(event, plan._id)} // Function to handle radio button selection change
                                        >
                                         {/* Render radio button for amountInYear, if valid */}
                                         {plan.amountInYear ? (
                                            <FormControlLabel
                                              value={`year`} // Unique value for each plan
                                              control={<Radio />}
                                              label={`Amount per Year: ${plan.amountInYear} ${plan.currency}`}
                                            />
                                          ) : (
                                            <Typography component="div" className="label">
                                              <span>Amount per Year: No Amount Available</span>
                                            </Typography>
                                          )}

                                          {/* Render radio button for amountInMonth, if valid */}
                                          {plan.amountInMonth ? (
                                            <FormControlLabel
                                              value={`month`} // Unique value for each plan
                                              control={<Radio />}
                                              label={`Amount per Month: ${plan.amountInMonth} ${plan.currency}`}
                                            />
                                          ) : (
                                            <Typography component="div" className="label">
                                              <span>Amount per Month: No Amount Available</span>
                                            </Typography>
                                          )}
                                        </RadioGroup>
                                        {/* Display error message if any */}
                                        {errors.commCheck && (
                                          <p style={{ color: 'red' }} className="fz-13 m-0">
                                            {errors.commCheck}
                                          </p>
                                        )}

                                      </FormControl>
                                    </>
                                  ))}

                                </>
                              )
                            }

                            {/* ----------------------------------------------Communities End--------------------------------------- */}


                            {/* ----------------------------------------------Yoga-Fans Start--------------------------------------- */}

                            <Typography component='div' className='label'>
                              Assign Yoga-Fan Pages
                            </Typography>
                            <FormControl className='mb-20 mt-5'>
                              <InputLabel id="yoga-fans-select-label">Select Yoga-Fans Pages</InputLabel>
                              <Select
                                labelId="yoga-fans-select-label"
                                id="yoga-fans-select"
                                multiple
                                value={selectedYogaFans}
                                onChange={handleChangeYogaFans}
                                renderValue={() => renderValueFansPage(selectedYogaFans, yogaFans, 'Select Yoga-Fans Pages')}
                                sx={{ minWidth: "500px" }}
                              >
                                {yogaFans.map((option) => (
                                  <MenuItem key={option._id} value={option._id}>
                                    <Checkbox checked={selectedYogaFans.indexOf(option._id) > -1} />
                                    <ListItemText primary={option.name} />
                                  </MenuItem>
                                ))}
                              </Select>

                              {/* Display error message if any */}
                              {errors.paymentPlan && (
                                <p style={{ color: 'red' }} className="fz-13 m-0">
                                  {errors.paymentPlan}
                                </p>
                              )}
                            </FormControl>
                            {
                              selectedItemsObjectFans?.length > 0 && (
                                <>
                                  <Typography component="div" className="label">
                                    <span></span>
                                    <Typography component="span" className="star"></Typography>
                                  </Typography>
                                  {/* Loop through each item in the selectedItemObject array */}
                                  {selectedItemsObjectFans?.map((plan, index) => (
                                    <>
                                      <Typography component="div" className="label">
                                        <span>{plan.name}</span>
                                        <Typography component="span" className="star">*</Typography>
                                      </Typography>
                                      <FormControl className="mb-20 mt-5" key={index}>

                                        <FormLabel component="legend" className="label">
                                          Choose Payment Plan for {plan.name}: {/* Display the plan index */}
                                        </FormLabel>

                                        {/* Radio group for Payment Options */}
                                        <RadioGroup
                                          value={selectedItemsPaymentForFansPage.find(item => item._id === plan._id)?.paymentPlan || ''} // The state variable to track selected payment plan
                                          onChange={(event) => handlePaymentChangeForFansPage(event, plan._id)} // Function to handle radio button selection change
                                        >
                                          {/* Render radio button for amountInYear, if valid */}
                                          {plan.amountInYear ? (
                                            <FormControlLabel
                                              value={`year`} // Unique value for each plan
                                              control={<Radio />}
                                              label={`Amount per Year: ${plan.amountInYear} ${plan.currency}`}
                                            />
                                          ) : (
                                            <Typography component="div" className="label">
                                              <span>Amount per Year: No Amount Available</span>
                                            </Typography>
                                          )}

                                          {/* Render radio button for amountInMonth, if valid */}
                                          {plan.amountInMonth ? (
                                            <FormControlLabel
                                              value={`month`} // Unique value for each plan
                                              control={<Radio />}
                                              label={`Amount per Month: ${plan.amountInMonth} ${plan.currency}`}
                                            />
                                          ) : (
                                            <Typography component="div" className="label">
                                              <span>Amount per Month: No Amount Available</span>
                                            </Typography>
                                          )}
                                        </RadioGroup>
                                        {/* Display error message if any */}
                                        {errors.fansCheck && (
                                          <p style={{ color: 'red' }} className="fz-13 m-0">
                                            {errors.fansCheck}
                                          </p>
                                        )}

                                      </FormControl>
                                    </>
                                  ))}

                                </>
                              )
                            }


                            {/* --------------------------------------Yoga-Fans End---------------------------------------- */}


                            <Typography component='div' className='label'>
                              Assign Courses
                            </Typography>
                            <FormControl className='mb-20 mt-5'>
                              <InputLabel id="courses-select-label">Select Courses</InputLabel>
                              <Select
                                labelId="courses-select-label"
                                id="courses-select"
                                multiple
                                value={selectedCourses}
                                onChange={handleChangeCourses}
                                renderValue={() => renderValueCourse(selectedCourses, course, 'Select Courses')}
                                sx={{ minWidth: "500px" }}
                              >
                                {course.map((option) => (
                                  <MenuItem key={option._id} value={option._id}>
                                    <Checkbox checked={selectedCourses.indexOf(option._id) > -1} />
                                    <ListItemText primary={option.course_title} />
                                  </MenuItem>
                                ))}
                              </Select>
                              {/* Display error message if any */}
                              {errors.paymentPlan && (
                                <p style={{ color: 'red' }} className="fz-13 m-0">
                                  {errors.paymentPlan}
                                </p>
                              )}
                            </FormControl>


                            {
                              selectedItemsObjectCourse?.length > 0 && (
                                <>
                                  <Typography component="div" className="label">
                                    <span></span>
                                    <Typography component="span" className="star"></Typography>
                                  </Typography>
                                  {/* Loop through each item in the selectedItemObject array */}
                                  {selectedItemsObjectCourse?.map((plan, index) => (
                                    <>
                                      <Typography component="div" className="label">
                                        <span>{plan.course_title}</span>
                                        <Typography component="span" className="star">*</Typography>
                                      </Typography>
                                      <FormControl className="mb-20 mt-5" key={index}>

                                        <FormLabel component="legend" className="label">
                                          Choose Payment Plan for {plan.course_title}: {/* Display the plan index */}
                                        </FormLabel>

                                        {/* Radio group for Payment Options */}
                                        <RadioGroup
                                          value={selectedPaymentForCourse.find(item => item._id === plan._id)?.paymentPlan || ''} // The state variable to track selected payment plan
                                          onChange={(event) => handlePaymentChangeForCourse(event, plan._id)} // Function to handle radio button selection change
                                        >
                                          {/* Render radio button for week, if valid */}
                                          {plan.course_paymenttype === "recurring" ? (
                                            <>
                                              {plan.amountInWeek &&
                                                <FormControlLabel
                                                  value={`week`} // Unique value for each plan
                                                  control={<Radio />}
                                                  label={`Amount per Week: ${plan.amountInWeek} ${plan.currency}`}
                                                />}
                                              {
                                                plan.amountInByWeekly &&
                                                <FormControlLabel
                                                  value={`biWeek`} // Unique value for each plan
                                                  control={<Radio />}
                                                  label={`Amount per BiWeek: ${plan.amountInByWeekly} ${plan.currency}`}
                                                />
                                              }

                                              {
                                                plan.amountInMonth &&
                                                <FormControlLabel
                                                  value={`month`} // Unique value for each plan
                                                  control={<Radio />}
                                                  label={`Amount per Month: ${plan.amountInMonth} ${plan.currency}`}
                                                />
                                              }
                                            </>
                                          ) : ''}

                                          {/* Render radio button for onltime, if valid */}
                                          {plan.course_paymenttype === "onetimepayment" ? (
                                            <FormControlLabel
                                              value={`OneTime`} // Unique value for each plan
                                              control={<Radio />}
                                              label={`One Time Amount: ${plan.amountOneTime} ${plan.currency}`}
                                            />
                                          ) : ''}

                                        </RadioGroup>

                                        {/* Display error message if any */}
                                        {errors.courseCheck && (
                                          <p style={{ color: 'red' }} className="fz-13 m-0">
                                            {errors.courseCheck}
                                          </p>
                                        )}

                                      </FormControl>
                                    </>
                                  ))}

                                </>
                              )
                            }

                          </Box>
                        )}
                        {/* ------------------------------------->>************bundle_Level End**************<<-------------------------------------------------------- */}

                        <Typography component='div' className='label'>
                          <span>Description </span>
                          <Typography component='span' className='star'>*</Typography>
                          <Typography component='span' style={{ fontSize: 'smaller' }}>
                            {/* This name will be used to create a unique URL for your community */}
                          </Typography>
                        </Typography>
                        <FormControl variant="standard" className='mb-10'>
                          <TextField
                            id="filled-size-small-textarea"
                            variant="outlined"
                            size="small"
                            name='notes'
                            className='textfield'
                            style={{ width: '500px' }}
                            multiline
                            rows={3} // Adjust the number of rows as needed
                            value={notes}
                            onChange={(e) => {
                              setNotes(e.target.value);
                              setErrors(prev => ({ ...prev, notes: "" }));
                            }}
                          />
                          {errors.notes && <p style={{ color: 'red' }} className="fz-13 m-0">{errors.notes}</p>}
                        </FormControl>
                      </FormGroup>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 4,
                    pt: 3
                  }}
                >
                  <Button type="submit" variant="contained" className='addbtn mr-10'>
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>


          </Box>
        </Box>
      </Grid>
                </Box>
            </Box>
        </Box>
      

    </>
  )
}

export default CouponandOffersettings