import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate} from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import Videotable from './Table'
// import Sidebar from './../../Sidebar'
export default function Video() {
    let navigate = useNavigate()
    // const params = useParams()
    const role = JSON.parse(localStorage.getItem("roledata"))

    const addVideo = () => {
        navigate('/addvideo');
    }


    return (
        <Box className='companies '>
            <MiniDrawer menuOrder={6} />
            {/* <Sidebar subtype={4} /> */}
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Videos</Typography>
                    {role.UserRoles == null ? <Button variant="contained" className='addbtn' onClick={() => addVideo()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Video</Button>
                        : role.UserRoles.Videos.AddVideos && <Button variant="contained" className='addbtn' onClick={() => addVideo()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Video</Button>
                    }                </Box>
                <Box className='whiteContainer '>
                    <Videotable />
                </Box>
            </Box>
        </Box>
    )
}