import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Typography, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const MultiSelectWithCheckboxes = ({ label, options, name, selectedValues, handleChange, renderValue }) => {
  
    const [selectedPaymentPlan,setSelectedPaymentPlan] = useState("")
  
console.log(selectedValues)

  const handlePaymentChange = (event) => {
    const selectedPaymentPlan = event.target.value; // Get the selected payment plan (week, month, or year)
    setSelectedPaymentPlan(selectedPaymentPlan);
    // Call the parent's function to update the payment plan, passing the entityId and selected payment plan
    // handlePaymentChange(entityId, selectedPaymentPlan, entityType); 
};


  
  const handleSelectionChange = (event) => {
    const selectedCommunityIds = event.target.value; // Get the array of selected community ids

    // Find the corresponding community objects based on the selected IDs
    const selectedCommunities = options.filter((option) => selectedCommunityIds.includes(option._id));

console.log(selectedCommunities)

    // Map the selected communities to the updated payment plan format
    const updatedPaymentPlans = selectedCommunities.map((selectedCommunity) => {
        return {
            _id: selectedCommunity._id,
            name: selectedCommunity.coummunity_name,  // Corrected typo from 'coummunity_name' to 'community_name'
            billingCycle:selectedPaymentPlan , // This is the billing interval (week/month/year)
            paymentPlan: selectedPaymentPlan , // Assuming you have selectedPaymentPlan already defined
            amountInYear: selectedCommunity.amountInYear ,
            amountInMonth: selectedCommunity.amountInMonth ,
             // If you want to calculate based on billing cycle, you can modify this line
            amount: selectedPaymentPlan === "month" ? selectedCommunity.amountInMonth : selectedCommunity.amountInYear,

        };
    });

    // Pass the mapped updated payment plans back to the parent
    handleChange(updatedPaymentPlans); // Send the full mapped payment plans array to the parent
};




  // Handle the selection change for courses
  const handleSelectionChangeForCourse = (event) => {
    const selectedCourseIDs = event.target.value; // Get the array of selected course ids

    // Find the corresponding course objects based on the selected IDs
    const selectedCourses = options.filter((option) => selectedCourseIDs.includes(option._id));



    // Map the selected courses to the updated payment plan format
    const updatedPaymentPlans = selectedCourses.map((selectedCourse) => {
      // Determine the payment plan based on the available amounts
      let paymentPlan = '';
    
      if (selectedCourse.amountInWeek) {
        paymentPlan = 'week'; // Payment plan is weekly if amountInWeek is set
      } else if (selectedCourse.amountInByWeekly) {
        paymentPlan = 'biWeek'; // Payment plan is bi-weekly if amountInByWeekly is set
      } else if (selectedCourse.amountInMonth) {
        paymentPlan = 'month'; // Payment plan is monthly if amountInMonth is set
      } else {
        paymentPlan = 'OneTime'; // Default to one-time if none of the above
      }
    
      // Return the updated payment plan object
      return {
        _id: selectedCourse._id,
        name: selectedCourse.course_title,
        installment: selectedCourse.installment,
        amountInWeek: selectedCourse.amountInWeek,
        amountInBiWeek: selectedCourse.amountInByWeekly,
        amountInMonth: selectedCourse.amountInMonth,
        amountInOneTime: selectedCourse.amountOneTime, // Assuming this is for one-time payment
        paymentPlan: selectedPaymentPlan || paymentPlan, // Fallback to selectedPaymentPlan if available
        interval: paymentPlan === 'week' || paymentPlan === 'biWeek' || paymentPlan === 'month' ? 'recurring' : 'onetimepayment',
      };
    });

    // Pass the mapped updated payment plans back to the parent
    handleChange(updatedPaymentPlans); // Send the full mapped payment plans array to the parent
  };



  const handleSelectionChangeForFansPage = (event) => {

    const selectedCourseIDs = event.target.value; // Get the array of selected course ids

    // Find the corresponding course objects based on the selected IDs
    const selectedFanspages = options.filter((option) => selectedCourseIDs.includes(option._id));




    // Map the selected courses to the updated payment plan format
    const updatedPaymentPlans = selectedFanspages.map((selectedFanspage) => {
    


       // Determine the payment plan based on the available amounts
       let paymentPlan = '';
    
       if (selectedFanspage.amountInYear) {
         paymentPlan = 'year'; // Payment plan is weekly if amountInWeek is set
       } else if (selectedFanspage.amountInMonth) {
         paymentPlan = 'month'; // Payment plan is bi-weekly if amountInByWeekly is set
       }
      // Return the updated payment plan object
      return {
        _id: selectedFanspage._id,
        name: selectedFanspage.name,
        amountInYear: selectedFanspage.amountInYear ,
        // amountInMonth: selectedFanPage.amountInMonth * 12,
        amountInMonth: selectedFanspage.amountInMonth ,
        paymentPlan: selectedPaymentPlan || paymentPlan, // The selected plan (yearly or monthly)
        amount: paymentPlan === "month"? selectedFanspage.amountInMonth : paymentPlan === "year"? selectedFanspage.amountInYear :null,
      };
    });

    // Pass the mapped updated payment plans back to the parent
    handleChange(updatedPaymentPlans); // Send the full mapped payment plans array to the parent
  };


  const handlePaymentChangeForUpdate = (event, planId) => {
    const selectedPaymentPlan = event.target.value;
  
    // Update the payment plan for the selected plan in the selectedValues array
    const updatedPlans = selectedValues.map((plan) => {
      if (plan._id === planId) {
        return {
          ...plan,
          paymentPlan: selectedPaymentPlan,
        };
      }
      return plan;
    });
  
    // Update the state (or send the updated plans to the parent component)
    handleChange(updatedPlans);
  };


  // Handle the payment change for a specific community
  const handlePaymentChangeForCommunity = (event,planId) => {
    const selectedPaymentPlan = event.target.value; // Get the selected payment plan (week, month, or year)

     // Update the payment plan for the selected plan in the selectedValues array
     const updatedPlans = selectedValues.map((plan) => {
      if (plan._id === planId) {
        return {
          ...plan,
          paymentPlan: selectedPaymentPlan,
        };
      }
      return plan;
    });
  
    // Update the state (or send the updated plans to the parent component)
    handleChange(updatedPlans);
    
    // handlePaymentChange(communityId, selectedPaymentPlan,entityType); // Call the parent's function to update the payment plan
  };



  return (
    <div>
      {/* Label for the Select */}
      <Typography component="div" className="label">{label}</Typography>

      <FormControl className="mb-20 mt-5">
        <InputLabel>{label}</InputLabel>
        <Select
          multipleamountInYear
          value={selectedValues.map(val => val._id)} // Ensure that we display the ids of selected communities
          onChange={name === "course" ? handleSelectionChangeForCourse : name === "yogaFans" ? handleSelectionChangeForFansPage : handleSelectionChange} // Use the course-specific handler if the name is "course"
         
          renderValue={renderValue}  // Dynamically rendering the selected values (like names of communities)
          sx={{ minWidth: "500px" }}
        >
         
          {options.map((option) => (
            <MenuItem key={option._id} value={option._id}>
              <Checkbox checked={selectedValues.some(val => val._id === option._id)} />
              <ListItemText primary={option.name || option.course_title || option.coummunity_name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Payment Plan rendering (for Communities) */}
      {name === "community" && (
        <div>
          {selectedValues.map((community) => (
            <div key={community._id}>
              <Typography component="div" className="label">{community.coummunity_name || community.name}</Typography>

              <FormControl className="mb-20 mt-5">
                <FormLabel component="legend" className="label">
                  Choose Payment Plan for {community.coummunity_name || community.name }:
                </FormLabel>

                {/* Radio group for Payment Options */}
                <RadioGroup
                  value={community.paymentPlan || ''}  // Set the selected payment plan
                  onChange={(event) => handlePaymentChangeForCommunity(event,community._id)}  // Handle radio button change
                >
                  {/* Render radio button for amountInYear, if valid */}
              {community.amountInYear ? (
                <FormControlLabel
                  value={`year`} // Unique value for each community
                  control={<Radio />}
                  label={`Amount per Year: ${community.amountInYear} ${community.currency || 'USD'}`}
                />
              ) : (
                <Typography component="div" className="label">
                  <span>Amount per Year: No Amount Available</span>
                </Typography>
              )}

              {/* Render radio button for amountInMonth, if valid */}
              {community.amountInMonth ? (
                <FormControlLabel
                  value={`month`} // Unique value for each community
                  control={<Radio />}
                  label={`Amount per Month: ${community.amountInMonth} ${community.currency || 'USD'}`}
                />
              ) : (
                <Typography component="div" className="label">
                  <span>Amount per Month: No Amount Available</span>
                </Typography>
              )}

                </RadioGroup>
              </FormControl>
            </div>
          ))}
        </div>
      )}

      {/* Payment Plan For rendering (course) */}
      {
        name === "course" && (
           
                <>
                  <Typography component="div" className="label">
                    <span></span>
                    <Typography component="span" className="star"></Typography>
                  </Typography>
                  {/* Loop through each item in the selectedItemObject array */}
                  {selectedValues?.map((plan, index) => (
                    <>
                      <Typography component="div" className="label">
                        <span>{plan.course_title || plan.name}</span>
                        <Typography component="span" className="star">*</Typography>
                      </Typography>
                      <FormControl className="mb-20 mt-5" key={index}>

                        <FormLabel component="legend" className="label">
                          Choose Payment Plan for {plan.course_title || plan.name}: {/* Display the plan index */}
                        </FormLabel>

                        {/* Radio group for Payment Options */}
                        <RadioGroup
                          value={plan.paymentPlan || ''} // The state variable to track selected payment plan
                          onChange={(event)=>handlePaymentChange(event)} // Function to handle radio button selection change
                        >
                          {/* Render radio button for week, if valid */}
                          {(plan.course_paymenttype === "recurring" || plan.interval === "recurring") ? (
                            <>
                            {plan.amountInWeek && 
                            <FormControlLabel
                              value={`week`} // Unique value for each plan
                              control={<Radio />}
                              label={`Amount per Week: ${plan.amountInWeek} ${plan.currency || 'USD'}`}
                            />}
                              {
                              plan.amountInByWeekly &&
                                <FormControlLabel
                                  value={`biWeek`} // Unique value for each plan
                                  control={<Radio />}
                                  label={`Amount per BiWeek: ${plan.amountInByWeekly} ${plan.currency || 'USD'}`}
                                />
                              }
                             
                              {
                                plan.amountInMonth &&
                                <FormControlLabel
                                value={`month`} // Unique value for each plan
                                control={<Radio />}
                                label={`Amount per Month: ${plan.amountInMonth} ${plan.currency || 'USD'}`}
                              />
                              }
                            </>
                          ) : ''}

                          {/* Render radio button for onltime, if valid */}
                          {(plan.course_paymenttype === "onetimepayment" || plan.interval === "onetimepayment")? (
                            <FormControlLabel
                              value={`OneTime`} // Unique value for each plan
                              control={<Radio />}
                              label={`One Time Amount: ${plan.amountInOneTime} ${plan.currency || 'USD'}`}
                            />
                          ) : ''}

                        </RadioGroup>

                        
                      </FormControl>
                    </>
                  ))}

                </>
              
        )
      }


      {/* Payment Plan For rendering (FansPage) */}
      {
  name === "yogaFans" && (
    <>
      <Typography component="div" className="label">
        <span></span>
        <Typography component="span" className="star"></Typography>
      </Typography>

      {/* Loop through each item in the selectedValues array */}
      {selectedValues?.map((plan, index) => (
        <>
          <Typography component="div" className="label">
            <span>{plan.course_title || plan.name}</span>
            <Typography component="span" className="star">*</Typography>
          </Typography>

          <FormControl className="mb-20 mt-5" key={index}>
            <FormLabel component="legend" className="label">
              Choose Payment Plan for {plan.course_title || plan.name}
            </FormLabel>

            {/* Radio group for Payment Options */}
            <RadioGroup
              value={plan.paymentPlan || ''} // The state variable to track selected payment plan
              onChange={(event) => handlePaymentChangeForUpdate(event, plan._id)} // Handle radio button selection change
            >
              {/* Render radio button for amountInYear, if valid */}
              {plan.amountInYear ? (
                <FormControlLabel
                  value={`year`} // Unique value for each plan
                  control={<Radio />}
                  label={`Amount per Year: ${plan.amountInYear} ${plan.currency || 'USD'}`}
                />
              ) : (
                <Typography component="div" className="label">
                  <span>Amount per Year: No Amount Available</span>
                </Typography>
              )}

              {/* Render radio button for amountInMonth, if valid */}
              {plan.amountInMonth ? (
                <FormControlLabel
                  value={`month`} // Unique value for each plan
                  control={<Radio />}
                  label={`Amount per Month: ${plan.amountInMonth} ${plan.currency || 'USD'}`}
                />
              ) : (
                <Typography component="div" className="label">
                  <span>Amount per Month: No Amount Available</span>
                </Typography>
              )}

            </RadioGroup>
            
            {/* Display error message if any */}
            {/* {errors.paymentPlan && (
              <p style={{ color: 'red' }} className="fz-13 m-0">
                {errors.paymentPlan}
              </p>
            )} */}
          </FormControl>
        </>
      ))}
    </>
  )
}
    </div>
  );
};

export default MultiSelectWithCheckboxes;
