
import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios'
import { Box, Button, IconButton, FormControlLabel, Tooltip, Card, Checkbox, Link, List, ListItemText, OutlinedInput, ListItemButton, ListItemAvatar, Autocomplete, Input, Breadcrumbs, TableContainer, Select, TablePagination, MenuItem, Dialog, Typography, Grid, FormControl, TextField, DialogContent, DialogActions, DialogTitle, styled, CardContent, AccordionDetails, AccordionSummary, Accordion, Divider, RadioGroup, Radio, InputAdornment, Tabs, Tab } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import { KeyboardArrowLeftRounded, PictureAsPdfOutlined, PlagiarismOutlined, ImageOutlined } from '@mui/icons-material';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import DOMPurify from 'dompurify';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import { usePlacesWidget } from "react-google-autocomplete";
import AutoComplete_Edit from "./Autocomplete";
import Sidebar from './../../Sidebar'
import { AiOutlineInfoCircle } from 'react-icons/ai'; // Example icon from React Icons
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#f3714d',
    },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#f3714d',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#f3714d',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ListAlt />,
        2: <CompareIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = ['Basic Information', 'Community Information', 'Community Description'];

export default function Service_Steppyform() {
    const navigate = useNavigate()
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let location = useLocation()
    const auth = JSON.parse(localStorage.getItem("userData"))

    const locationLat = location.state.data.location_coord.coordinates
    const [vdoThumb, setvdoThumb] = useState(location.state.data.Video)
    const [imgThumb, setimgThumb] = useState(location.state.data.thumbnail)
    const [courseError, setCourseError] = useState('')
    const [teacher, setTeacher] = React.useState([...[], location.state.data.createdby])
    const { enqueueSnackbar } = useSnackbar()
    const [course_id, setCourse_id] = useState(location.state.data.course_id)
    const [community, setCommunity] = React.useState({ title: location.state.data.coummunity_title, description: location.state.data.short_description, name: location.state.data.coummunity_name, amount: location.state.data.amount })
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({ name: "", title: "" })
    const [categoryId, setCategoryId] = React.useState(location.state.data.category_id)
    const [courseId, setCourseId] = React.useState(location.state.data.course_id)
    const [userId, setUserId] = React.useState(location.state.data.user_id)
    const [subcategoryId, setSubCategoryId] = React.useState(location.state.data.subcategory_id ? location.state.data.subcategory_id : "")
    const [catError, setCatError] = React.useState('')
    const [subcatError, setSubCatError] = React.useState('')
    // const [selectedUsers, setSelectedUsers] = useState(location.state.data.user_id);
    const [user, setUser] = React.useState([])
    const [statesingle3, setStatesingle3] = React.useState([...[], location.state.data.profile])
    const [statesingle4, setStatesingle4] = React.useState([...[], location.state.data.profile])
    const [imageError, setImageError] = React.useState('')
    const [imageError2, setImageError2] = React.useState('')
    const [state1, setState1] = React.useState(location.state.data.additionalimages)
    const [state2, setState2] = React.useState(location.state.data.additionalimages)
    const [existingimages, setExistingimages] = React.useState(location.state.data.additionalimages)
    const [statesingle, setStatesingle] = React.useState([...[], location.state.data.image])
    const [statesingle2, setStatesingle2] = React.useState([...[], location.state.data.image])
    const [richeditor, setRichtor] = React.useState(location.state.data.description)
    const [description, setDescription] = React.useState(location.state.data.short_description)
    const [imageAddError, setImageAddError] = React.useState('')
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const dataofeditor = `${richeditor}`
    const [subcat, setSubcat] = React.useState([])
    const [category, setCategory] = React.useState([])
    const emailEditorRef = useRef({ editor: {} });
    const [studio, setStudio] = React.useState([])
    const [school, setSchool] = React.useState([])
    const [descJson, setDescJson] = React.useState(location.state.data.DescriptionJSON)
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [existingvideos, setExistingvideos] = React.useState(location.state.data.existingVideos)
    const [cityName, setCityName] = useState(location.state.data.location_coord.coordinates)
    const [lat, setLat] = useState(locationLat[0])
    const [lng, setLng] = useState(locationLat[1])
    const [formattedadrress, setFormattedAddress] = useState(location.state.data.formatted_address)
    console.log(location.state.data.formatted_address, "location.state.data.formatted_address");
    const [selectedPlan, setSelectedPlan] = React.useState(location.state.data.planId)
    // console.log(location.state.data.planId,"location.state.data.planId");
    const [plans, setPlans] = React.useState([])
    // const [formattedadrress, setFormattedAddress] = useState(location.state.data.formatted_address)
    const [existingAttachments, setExistingAttachments] = useState(location.state.data.pdf)
    const [communitytype, setCommunitytype] = React.useState(location.state.data.community_type)
    const [primaryCommunity, setPrimaryCommunity] = useState(location.state.data.community_primary)
    const [isPublic, setPublic] = React.useState(location.state.data.advertisement)
    const [yogaCommunity, setYogaCommunity] = useState(true)
    const [currency, setCurrency] = React.useState(location.state.data.currency)
    const [interval, setInterval] = React.useState(location.state.data.interval)
    const [ads, setAds] = React.useState([])
    const [selectOwner, setSelectOwner] = useState(location.state.data.ads)
    const params = useParams()

    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            setCityName(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setError(prevError => ({ ...prevError, location: '' }))
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });
    const handleRemoveRestart = () => {
        setSubCategoryId('')
    }

    const handleTogglePublic = () => {
        setPublic(!isPublic)
    }


    const handleYogaCommunity = () => {
        setYogaCommunity(!yogaCommunity)
    }

    const handleEditOwner = (e) => {
        setSelectOwner(e.target.value);
    }

    //add management
    React.useEffect(() => {
        getAds()
    }, [])

    const getAds = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `AddManagement/getAddManagement`, { headers: headerlocal });
            setAds(response.data.getAddManagement);
            setAdsDataUpdated(response.data.getAddManagement)
            console.log(response, "teachers");
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [adsUpdated, setAdsUpdated] = React.useState([])
    const [adsDataUpdated, setAdsDataUpdated] = React.useState([])

    const [selectedAds, setSelectedAds] = React.useState(() => {
        const arrVal = []
        const adsArr = location.state.data.ads
        console.log(location.state.data.ads, "location.state.data.ads");
        if (adsArr.length > 0) {
            adsArr.map((item) => {
                arrVal.push(item.title)
            })
        }
        return arrVal
    });


    React.useEffect(() => {
        getAdsSelected();
    }, [selectedAds, adsDataUpdated])

    function getAdsSelected() {
        var array = []
        const selectedAdss = adsDataUpdated.filter(content => selectedAds.includes(content.title));
        selectedAdss.map((item, i) => {
            var val = { title: item.title }
            Object.assign(item, val)
            array.push(val)
        })
        setAdsUpdated(array)
    }

    const handlechangeAdsSelected = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setSelectedAds(
            typeof value === 'string' ? value.split(',') : value,
        );
        getAdsSelected()
    }

    const handleUpdateCommunity = async (details) => {
        var valid = validate();
        var validnew = validatenew();
        if (valid == true && validnew == true) {
            setTruebutton(true);
            const formData = new FormData();
            if (auth.role_id == 1) {
                const idArray = teacherUpdated.map(id => id.user_id);
                idArray.forEach(id => {
                    formData.append('createdby[]', id);
                });
            } else {
                formData.append('createdby[]', auth._id);
            }
            formData.append("coummunity_name", community.name);
            formData.append("coummunity_title", community.title);
            formData.append("community_type", communitytype);
            formData.append("addCommunityFrom", 'YogaSchool')
            formData.append("community_primary", primaryCommunity);
            formData.append("short_description", description);
            formData.append("category_id", categoryId);
            formData.append("subcategory_id", subcategoryId);
            // formData.append("planId", selectedPlan._id);
            formData.append("unique_link", community.link);
            formData.append('ads', selectOwner === 'Yes' ? JSON.stringify(adsUpdated.length > 0 ? adsUpdated : []) : "")
            formData.append("adsby", selectOwner === 'Yes' ? 'Yes' : 'No');
            formData.append("location_coord", cityName)
            formData.append("latitude", lat)
            formData.append("longitude", lng)
            formData.append("interval", interval);
            formData.append("amount", community.amount)
            formData.append("currency", currency);
            formData.append("advertisement", isPublic ? true : false);
            formData.append('yogaschool', JSON.stringify(schoolUpdated.length > 0 ? schoolUpdated : []))
            formData.append('yogastudio', JSON.stringify(studioUpdated.length > 0 ? studioUpdated : []))
            formData.append("formatted_address", formattedadrress)
            statesingle2.forEach((item) => formData.append("image", item));
            statesingle4.forEach((item) => formData.append("profile", item));
            console.log([...formData], "formdattttta")
            axios.put(process.env.REACT_APP_BASE_URL + `community/updateCommunity/${location.state.data._id}`, formData, { headers: headerformdata }).then((res) => {
                setTruebutton(false);
                if (details === 'editWithNavigate') {
                    console.log(res.data, "res.data.updatecoumuity._id");
                    navigate(`/community/community-details/desc/${res.data.updatecoumuity._id}`)
                } else if (details === 'edit') {
                    enqueueSnackbar("Community updated successfully", { variant: 'success' });
                    navigate(`/yogaschool/details/community/${params.id}`)                }

            }).catch((err) => {
                setTruebutton(false);
                enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
                console.log(err, "error");
            });
        };
    }


    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    const validate = () => {
        if (!community.name && !community.title) {
            setError({ name: "This field is required.", title: "This field is required." })
            // setCatError("This field is required.")
            // setImageError("This field is required.")
            // setCourseError("This field is required.")
            return false
        }
        if (!community.name.trim()) {
            setError({ name: "This field is required." })
            return false
        }
        if (!community.name) {
            setError({ name: "This field is required." })
            return false
        }
        if (!categoryId) {
            setCatError("This field is required.")
        }
        if (!courseId) {
            setCourseError("This field is required.")
        }
        if (!community.title) {
            setError({ title: "This field is required." })
            return false
        }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        if (statesingle4?.length == 0) {
            setImageError2("This field is required.")
            return false
        }

        return true
    }

    const validatenew = () => {
        // if (state2?.length == 0) {
        //     setImageAddError("This field is required.")
        //     return false
        // }
        return true
    }

    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        if (name === 'name') {
            value = value.trim();
        }
        setCommunity({ ...community, [name]: value })
    }

    React.useEffect(() => {
        getCategory()
    }, [])

    React.useEffect(() => {
        getSubCategory()
    }, [categoryId])

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
            setCategory(res.data.category)
        }).catch((error) => {
            console.log(error.response, "error");
        })
    }

    const getSubCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
            .then((res) => {
                setSubcat(res.data.subcategory)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const indexDelete2 = (e, index) => {
        var filteredpreview = statesingle3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle4.filter((val, i) => {
            return i !== index;
        });
        setStatesingle3(filteredpreview);
        setStatesingle4(filteredraw);
    }

    const CategoryData = (e) => {
        setSubCategoryId('')
        setCategoryId(e.target.value);
        setCatError('');
        setError({ coursename: '' });
        setCatError('');
        setSubCatError('')
    }
    const handleCourseChange = (event, value) => {
        setSelectedCourses(value);
        var array = []
        value.map((item, i) => {
            var val = { course_id: item._id }
            Object.assign(item, val)
            array.push(val)

        })
        setCourseId(array);
    };

    const maxFileSizeMB = 1; // Maximum file size allowed in MB

    const validateFileSize = (file) => {
        const fileSizeMB = file.size / (1024 * 1024); // Calculate file size in MB
        return fileSizeMB <= maxFileSizeMB;
    };

    const multipleImgChangeMultiple = (e, index) => {
        setImageAddError('');
        var fileObj = e.target.files;
        var fileArray = [];
        var filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            if (validateFileSize(fileObj[i])) {
                fileArray.push(URL.createObjectURL(fileObj[i]));
                filepath.push(fileObj[i]);
            } else {
                setImageAddError('Image size should be less than 1 MB.');
            }
        }

        // Continue with state updates only for valid files
        if (fileArray.length > 0) {
            var data = state1.filter(x => x != null);
            var data1 = state2.filter(x => x != null);
            setState1(data);
            setState2(data1);
            setState1(prevValue => prevValue.concat(fileArray));
            setState2(prevValue => prevValue.concat(filepath));
        }
    };

    React.useEffect(() => {
        getStudio()
    }, [])

    const getStudio = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaStudioList`, { headers: headerlocal });
            setStudio(response.data.getYogaStudioList);
            setStudioDataUpdated(response.data.getYogaStudioList)
            console.log(response, "school");
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [studioUpdated, setStudioUpdated] = React.useState([])
    const [studioDataUpdated, setStudioDataUpdated] = React.useState([])

    const [selecttedStudio, setSelectedStudio] = React.useState(() => {
        const arrVal = []
        const studioArr = location.state.data.yogastudio
        console.log(location.state.data.yogastudio, "ccccc");
        if (studioArr.length > 0) {
            studioArr.map((item) => {
                arrVal.push(item.username)
            })
        }
        return arrVal
    });

    React.useEffect(() => {
        getStudioSelected();
    }, [selecttedStudio, studioDataUpdated])

    function getStudioSelected() {
        var array = []
        const selectedStudios = studioDataUpdated.filter(content => selecttedStudio.includes(content.username));
        selectedStudios.map((item, i) => {
            var val = { username: item.username, user_id: item._id }
            Object.assign(item, val)
            array.push(val)
        })
        setStudioUpdated(array)
    }

    const handlechangeStudioSelected = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setSelectedStudio(
            typeof value === 'string' ? value.split(',') : value,
        );
        getStudioSelected()
    }

    React.useEffect(() => {
        getSchool()
    }, [])

    const getSchool = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaSchoolList`, { headers: headerlocal });
            setSchool(response.data.getYogaSchoolList);
            setSchoolDataUpdated(response.data.getYogaSchoolList)
            console.log(response, "school");
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [schoolUpdated, setSchoolUpdated] = React.useState([])
    const [schoolDataUpdated, setSchoolDataUpdated] = React.useState([])

    const [selectedSchool, setSelectedSchool] = React.useState(() => {
        const arrVal = []
        const schoolArr = location.state.data.yogaschool
        console.log(location.state.data.yogaschool, "ccccc");
        if (schoolArr.length > 0) {
            schoolArr.map((item) => {
                arrVal.push(item.username)
            })
        }
        return arrVal
    });

    React.useEffect(() => {
        getSchoolSelected();
    }, [selectedSchool, schoolDataUpdated])

    function getSchoolSelected() {
        var array = []
        const selectedSchools = schoolDataUpdated.filter(content => selectedSchool.includes(content.username));
        selectedSchools.map((item, i) => {
            var val = { username: item.username, user_id: item._id }
            Object.assign(item, val)
            array.push(val)
        })
        setSchoolUpdated(array)
    }

    const handlechangeSchoolSelected = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setSelectedSchool(
            typeof value === 'string' ? value.split(',') : value,
        );
        getSchoolSelected()
    }


    React.useEffect(() => {
        getTeacher()
    }, [])

    const getTeacher = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachers`, { headers: headerlocal });
            setTeacher(response.data.teachers);
            setTeacherDataUpdated(response.data.teachers)
            console.log(response, "teachers");
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [teacherUpdated, setTeacherUpdated] = React.useState([])
    const [teacherDataUpdated, setTeacherDataUpdated] = React.useState([])

    const [selectedTeacher, setSelectedTeacher] = React.useState(() => {
        const arrVal = []
        const teacherArr = location.state.data.teacher
        console.log(location.state.data.teacher, "updated teachers");
        if (teacherArr.length > 0) {
            teacherArr.map((item) => {
                arrVal.push(item.username, item.email)
            })
        }
        return arrVal
    });
    React.useEffect(() => {
        getTeacherSelected();
    }, [selectedTeacher, teacherDataUpdated])

    function getTeacherSelected() {
        var array = []
        const selectedTeachers = teacherDataUpdated.filter(content => selectedTeacher.includes(content.username));
        selectedTeachers.map((item, i) => {
            var val = { username: item.username, user_id: item._id }
            Object.assign(item, val)
            array.push(val)
        })
        setTeacherUpdated(array)
    }

    const handlechangeTeacherSelected = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setSelectedTeacher(
            typeof value === 'string' ? value.split(',') : value,
        );
        getTeacherSelected()
    }


    const SingleImage = (e, index) => {
        setImageError('');
        var fileObj = e.target.files;
        var fileArray = [];
        var filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            if (validateFileSize(fileObj[i])) {
                fileArray.push(URL.createObjectURL(fileObj[i]));
                filepath.push(fileObj[i]);
            } else {
                setImageError('Image size should be less than 1 MB.');
            }
        }

        // Continue with state updates only for valid files
        if (fileArray.length > 0) {
            var data = statesingle.filter(x => x != null);
            var data1 = statesingle2.filter(x => x != null);
            setStatesingle(data);
            setStatesingle2(data1);
            setStatesingle(prevValue => prevValue.concat(fileArray));
            setStatesingle2(prevValue => prevValue.concat(filepath));
        }
    };

    const SingleProfileImage = (e, index) => {
        setImageError('');
        var fileObj = e.target.files;
        var fileArray = [];
        var filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            if (validateFileSize(fileObj[i])) {
                fileArray.push(URL.createObjectURL(fileObj[i]));
                filepath.push(fileObj[i]);
            } else {
                setImageError2('Image size should be less than 1 MB.');
            }
        }

        // Continue with state updates only for valid files
        if (fileArray.length > 0) {
            var data = statesingle3.filter(x => x != null);
            var data1 = statesingle4.filter(x => x != null);
            setStatesingle3(data);
            setStatesingle4(data1);
            setStatesingle3(prevValue => prevValue.concat(fileArray));
            setStatesingle4(prevValue => prevValue.concat(filepath));
        }
    };

    const handleChangeStatus = async (e) => {
        setPrimaryCommunity(e.target.checked)
    };

    React.useEffect(() => {
        getPlans()
    }, [])

    const getPlans = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `subscription/GetSubscription`, { headers: headerlocal }).then((res) => {
            setPlans(res.data.getSubscriptiondata)
        }).catch((error) => {
            console.log(error, "Error ");
        })
    }

    return (
        <Box className='withSubmenu'>
            <MiniDrawer menuOrder={2} submenuOrder={4} />
            <Sidebar subtype={6} />
            <Box component="section" className="contentWraper">
                <Box className='whiteContainer p-20'>
                    <Typography component='h6' className='formTitle'>Edit Community</Typography>

                    <Box className='steppy_content'>
                        <Box className="maxw_700">
                            {/* <Grid item xs={12} sm={12} md={8} lg={12}>
                                <FormControl fullWidth>
                                    <Typography component='div' className='label'>
                                        Select Subscription <Typography component='span' className='star'>*</Typography>
                                    </Typography>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedPlan}
                                        disabled
                                        className="select"
                                        onChange={(e) => setSelectedPlan(e.target.value)}
                                    >
                                        <MenuItem disabled value={'sel'}>Select Plans</MenuItem>
                                        {plans && plans.length > 0 ? (
                                            plans.map((data) => (
                                                <MenuItem key={data._id} value={data._id}>
                                                    {data.name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No Plans found</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            <Box className="mb-20">
                                <FormControl fullWidth >
                                    <Typography component='div' className='label'>Select Type of Community<Typography component='span' className='star'>*</Typography></Typography>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={communitytype}
                                        placeholder="Select Community Type"
                                        className="select"
                                        onChange={(e) => { setCommunitytype(e.target.value); setError({ community_type: "" }) }}
                                    >
                                        <MenuItem disabled value={'sel'}>Select Community Type</MenuItem>
                                        <MenuItem value={"public"}>Public Community
                                        </MenuItem>
                                        <MenuItem value={"private"}>Private Community</MenuItem>
                                        <MenuItem value={"secured"}>Secured Community</MenuItem>
                                    </Select>
                                    {/* {error.job_type && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.job_type}</p>} */}
                                </FormControl>
                            </Box>

                            {communitytype == 'private' || communitytype == 'secured' ?

                                <><Grid item xs={12} sm={6} md={4} lg={3}>

                                    <Typography component='div' className='label'>Select Currency<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currency}
                                            placeholder="Select Currency"
                                            className="select"
                                            onChange={(e) => { setCurrency(e.target.value); setError(''); }}
                                        >
                                            <MenuItem disabled>Select Currency</MenuItem>
                                            <MenuItem value={"INR"}>INR</MenuItem>
                                            <MenuItem value={"USD"}>USD</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid><Grid item xs={12} sm={6} md={4} lg={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Amount<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Amount'
                                                name="amount"
                                                onInput={(e) => onlyNumbers(e)}
                                                value={community.amount}
                                                onChange={(e) => {
                                                    handlechange(e);
                                                }} />
                                            {error.title && <p style={{ color: 'red' }} className="fz-13 m-0">{error.title}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} lg={3}>

                                        <Typography component='div' className='label'>Select Interval<Typography component='span' className='star'> * </Typography></Typography>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={interval ? interval : "sel"}
                                                placeholder="Select Interval"
                                                className="select"
                                                onChange={(e) => { setInterval(e.target.value); setError(''); }}
                                            >
                                                <MenuItem disabled value={'sel'}>Select Interval</MenuItem>
                                                <MenuItem value={"week"}>Weekly</MenuItem>
                                                <MenuItem value={"month"}>Monthly</MenuItem>
                                                <MenuItem value={"year"}>Yearly</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {error.interval && <p style={{ color: 'red' }} className="fz-13 m-0">{error.interval}</p>}
                                    </Grid>
                                </> : ""}

                            <Box className="mb-20">
                                <Typography component='div' className='label'>
                                    <span>Community Name </span>
                                    <Typography component='span' className='star'>*</Typography>
                                    {/* Information Icon with Tooltip */}
                                    <Tooltip title="This name will be used to create a unique URL for your community">
                                        <IconButton aria-label="information">
                                            <AiOutlineInfoCircle size={20} />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography component='span' style={{ fontSize: 'smaller' }}>

                                    </Typography>									</Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        name='name'
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={community.name}
                                        onChange={(e) => {
                                            handlechange(e);
                                            setError(prevError => ({ ...prevError, name: '' }));
                                        }}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // }}
                                    />
                                    {error.name && <p style={{ color: 'red' }} className="fz-13 m-0">{error.name}</p>}
                                </FormControl>
                            </Box>



                            <Box className="mb-20">
                                <Typography component='div' className='label'>Community Title <Typography component='span' className='star'>*</Typography></Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        name='title'
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={community.title}
                                        onChange={(e) => {
                                            handlechange(e); setError(prevError => ({ ...prevError, title: '' }))
                                        }}
                                    />
                                    {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                </FormControl>
                            </Box>
                            <Box className=' mb-20' >
                                <Typography component='div' variant='body1' className='fz-14 semibold mr-10'>Primary Community:   </Typography>
                                <FormControlLabel className='m-0' control={<Switch size="small" onChange={(e) => handleChangeStatus(e)} checked={primaryCommunity} />} />
                            </Box>
                            <Box className="mb-20 ">
                                <Typography component='div' className='label'>Would you like to display this ads?</Typography>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-customized-radios"
                                    name="payment_type"
                                    onChange={handleEditOwner}
                                    value={selectOwner}
                                >
                                    <FormControlLabel
                                        value="Yes"
                                        className='radio-btns'
                                        control={<BpRadio />}
                                        label={<Typography className='fz-14'>Yes</Typography>}
                                    />
                                    <FormControlLabel
                                        value="No"
                                        className='radio-btns'
                                        control={<BpRadio />}
                                        label={<Typography className='fz-14'>No</Typography>}
                                    />
                                </RadioGroup>
                            </Box>

                            {selectOwner === 'Yes' && (
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Select Ads <Typography component='span'></Typography></Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            className='select'
                                            value={selectedAds}
                                            onChange={(e) => handlechangeAdsSelected(e,)}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                const selectedAdsss = adsDataUpdated.filter(ads => selected.includes(ads.title));
                                                if (selectedAdsss.length === 0) {
                                                    return "Any ads";
                                                } else if (selectedAdsss.length === 1) {
                                                    return selectedAdsss[0].title;
                                                } else {
                                                    return `${selectedAdsss.length} ads selected`;
                                                }
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {ads && ads.length > 0 ? ads.map((name) => (
                                                <MenuItem key={name._id} value={name.title} >
                                                    <Checkbox checked={selectedAds.indexOf(name.title) > -1} />
                                                    <ListItemText primary={name.title} />
                                                </MenuItem>
                                            )) : <MenuItem>No ads found</MenuItem>}
                                        </Select>
                                        {/* <p className='error-field'>{error.userselect}</p> */}
                                    </FormControl>
                                </Box>
                            )}

                            <Box className="mb-20">
                                <Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
                                <FormControl fullWidth >
                                    <Select
                                        className="select"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={categoryId ? categoryId : 'sel'}
                                        onChange={(e) => { CategoryData(e); }}>
                                        <MenuItem disabled value={'sel'}>Select category</MenuItem>
                                        {category && category?.length > 0 ? category.map((data) => (
                                            <MenuItem value={data._id}>{data.category_title}</MenuItem>
                                        )) :
                                            <MenuItem disabled>{"No Record's found"}</MenuItem>}
                                    </Select>
                                    {catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
                                </FormControl>
                            </Box>
                            <Box className="mb-20">
                                <Typography component='div' className='label'>Select Sub Category</Typography>
                                <Box className="flex-center">
                                    <FormControl fullWidth style={{ flex: 1 }}>
                                        <Select
                                            className="select"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            disabled={categoryId !== '' ? false : true}
                                            value={subcategoryId ? subcategoryId : 'sel18'}
                                            onChange={(e) => { setSubCategoryId(e.target.value); setSubCatError('') }}>
                                            <MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
                                            {subcat && subcat?.length > 0 ? (
                                                subcat.map((data5) => (
                                                    <MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
                                                ))) : (<MenuItem disabled>{"No Record's found"}</MenuItem>)}
                                        </Select>
                                        {subcatError && (<p style={{ color: 'red' }} className="fz-13 m-0">{subcatError}</p>)}
                                    </FormControl>
                                    {subcategoryId !== '' && (
                                        <RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
                                    )}
                                </Box>
                            </Box>

                            <Grid item xs={12} sm={12} md={12}  >
                                <Typography component='div' className='label mb-10'>Cover Image<Typography component='span' className='star'> * </Typography></Typography>

                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                    {statesingle.length < 1 &&
                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                            {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                            <Grid item xs={12} sm={12} md={12}  >
                                {statesingle.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle.length > 0 ? statesingle.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                    <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}

                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}  >
                                <Typography component='div' className='label mb-10'>Community Profile<Typography component='span' className='star'> * </Typography></Typography>

                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                    {statesingle3.length < 1 &&
                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleProfileImage} hidden type="file" />
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                            {imageError2 && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError2}</p>}
                            <Grid item xs={12} sm={12} md={12}  >
                                {statesingle3.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle3.length > 0 ? statesingle3.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete2(e, i)}><Close /></Button>
                                                    <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}

                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                            </Grid>

                            <Box className="mb-10">
                                <Typography component='div' className='label'>Location</Typography>
                                <AutoComplete_Edit cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} formattedadrress={formattedadrress} setFormattedAddress={setFormattedAddress} />
                            </Box>

                            <Grid item xs={12} sm={12} md={12}  >
                                <Box className="mb-10">
                                    <Typography component='div' className='label'>Short Description</Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <textarea
                                            placeholder='Short Description '
                                            className='textarea'
                                            defaultValue={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            rows={4}
                                            cols={40} />
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                    {truebutton === false ?
                        <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                            <Button variant="contained" className='addbtn mr-10' onClick={() => handleUpdateCommunity('edit')}>Save</Button>
                            <Button variant="contained" className='addbtn mr-10' onClick={() => handleUpdateCommunity('editWithNavigate')}>Save + Continue</Button>
                        </Box> : truebutton === true &&
                        <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                            <Button variant="contained" className='addbtn mr-10' >Please Wait</Button>
                            {/* <Button variant="contained" className='addbtn mr-10' onClick={() => handleUpdateCommunity('editWithNavigate')}>Save + Continue</Button> */}
                        </Box>}
                </Box>
            </Box>
        </Box>
    )
}