import React, { useEffect, useRef } from 'react';
import { Box, Button, Card, FormControl, Grid, IconButton, Typography } from '@mui/material';
import { AddOutlined, Close } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
// import './community.css'
const Add_Video = ({ parentFunc }) => {
    const [video, setVideo] = React.useState([]);
    const [error, setError] = React.useState({ video: "", image: "" });

    const inputRef = useRef(null);
    const handleAddClick = () => {
        setVideo(prevVideo => [...prevVideo, { imgpreview: "", imgraw: "", vdopvw: '', vdoraw: '' }]);
    };

    const handleImageChange = (e, index) => {
        const selectedFile = e.target.files[0];

        // Check if the file size exceeds 1 MB (in bytes)
        const maxSize = 1 * 1024 * 1024; // 1 MB in bytes
        if (selectedFile.size > maxSize) {
            setError({ ...error, image: 'Image should be less than 1 MB' });
            return; // Do not proceed further
        }

        setVideo(prevVideo => {
            const addVideo = [...prevVideo];
            addVideo[index] = {
                imgpreview: URL.createObjectURL(selectedFile),
                imgraw: selectedFile
            };
            return addVideo;
        });
    };


    // const handleImageChangeind = (e, index) => {
    //     const selectedFile = e.target.files[0];
    //     setVideo(prevVideo => {
    //         const updatedVideo = [...prevVideo];
    //         updatedVideo[index] = {
    //             imgpreview: URL.createObjectURL(selectedFile),
    //             imgraw: selectedFile,
    //         };
    //         return updatedVideo;
    //     });
    // };

    const resetThumbnailImage = (e, index) => {
        console.log(index, 'hhh');
        setVideo(prevVideo => {
            const removeImage = [...prevVideo];
            removeImage[index] = {
                ...removeImage[index],
                imgpreview: '',
                imgraw: '',
            };
            return removeImage;
        });
    }

    // const handleVideoChange = (e, index) => {
    //     const selectedFile = e.target.files[0];
    //     setVideo(prevVideo => {
    //         const updatedVideo = [...prevVideo];
    //         updatedVideo[index] = {
    //             ...updatedVideo[index],
    //             vdopvw: URL.createObjectURL(selectedFile),
    //             vdoraw: selectedFile,
    //         };
    //         return updatedVideo;
    //     });
    // };

    const handleVideoChange = (e, index) => {
        setError({ video: '' });
        const selectedFile = e.target.files[0];

        // Check if the file size exceeds 25 MB (in bytes)
        const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
        if (selectedFile.size > maxSize) {
            // Show error message or handle the validation as needed
            setError({ video: 'Video should be less than 25 MB' });
            return; // Do not proceed further
        }

        setVideo(prevVideo => {
            const updatedVideo = [...prevVideo];
            updatedVideo[index] = {
                ...updatedVideo[index],
                vdopvw: URL.createObjectURL(selectedFile),
                vdoraw: selectedFile,
            };
            return updatedVideo;
        });
    };

    const handleRemoveVideo = (e, index) => {
        setVideo(prevVideo => {
            const updatedVideo = [...prevVideo];
            updatedVideo[index] = {
                ...updatedVideo[index],
                vdopvw: '',
                vdoraw: '',
            };
            return updatedVideo;
        });
    };

    useEffect(() => {
        parentFunc(video);
    }, [video]);


    return (
        <Box className="">
            {video.map((x, i) => (
                <FormControl key={i} className='my-10' fullWidth style={{ flexDirection: 'column' }}>
                    <Typography className='fz-13  mb-10'>Video #{i + 1}</Typography>
                    <Box className="flex-center flex-wrap">
                        <Box className="mr-10 position_rel minw260">
                            {x.imgpreview === '' || x.imgpreview === undefined ?
                                <Button className="multiple_upload_btn w100" variant="contained" component="label">
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" />Thumbnail image </Box>
                                    <input accept={"image/png, image/jpg,image/jpeg"} onChange={(e) => handleImageChange(e, i)} hidden type="file" />
                                </Button> :
                                <Box className="form-box upload-img">
                                    <Card style={{ width: '200px' }}>
                                        {/* <IconButton color="primary" aria-label="upload picture" component="label" className='uploadImg-btn'>
                                                    <input hidden accept="image/png, image/jpg, image/jpeg" ref={inputRef} type="file" onChange={(e) => handleImageChangeind(e, i)} />
                                                    <AddOutlined />
                                                </IconButton> */}
                                        <Typography component='div' variant='div'>
                                            <Button className='close' onClick={(e) => resetThumbnailImage(e, i)}><Close className='text-danger fz-16' /></Button>
                                        </Typography>
                                        <div className='imgcontent'>
                                            {x.imgpreview === '' || x.imgpreview === undefined ? null :
                                                <img src={x.imgpreview} alt='' height={155} width={195} border={0} />
                                            }
                                        </div>
                                    </Card>
                                </Box>
                            }
                            {error.image && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.image}</p>}
                        </Box>
                        <Box className="minw260">
                            {x.vdopvw === '' || x.vdopvw === undefined ?
                                <Button className="multiple_upload_btn w100 " variant="contained" component="label">
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload video </Box>
                                    <input id="video-upload" accept="video/*" onChange={(e) => handleVideoChange(e, i)} hidden type="file" />
                                </Button> :
                                <Card style={{ width: '200px' }}>
                                    <Button className='close_icon' onClick={(e) => handleRemoveVideo(e, i)}><Close /></Button>
                                    <video controls className='' src={x.vdopvw} height={155} width={195} />
                                </Card>
                            }
                            {error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}

                        </Box>
                    </Box>

                </FormControl>
            ))}
            <Box className="text-right">
                <Button onClick={handleAddClick} className='theme-btn'>+ Add Video</Button>
            </Box>
        </Box>
    );
};

export default Add_Video;
