// import { Box, Button, Divider, Grid, Tooltip, Typography } from '@mui/material'
// import React from 'react'
// import { Sheet } from '@mui/joy'
// import Table from '@mui/joy/Table';
// import { Info } from '@mui/icons-material';
// import axios from 'axios';
// import moment from 'moment';


// export default function Jobs() {
// //UseState's:
//     const [jobdashboard,setJobDashboard] = React.useState([])
//     const [totalJobs,setTotalJobs] = React.useState(0)

// //Hook's State:
//     React.useEffect(()=>{
//         JobsDashboard()
//     },[])

//     const JobsDashboard = () => {
//         axios.get(process.env.REACT_APP_BASE_URL + `Jobs/getlatestJobs`)
//         .then((res)=>{
//             setJobDashboard(res.data.getJobs);
//             setTotalJobs(res.data.totalcount)
//         })
//         .catch((error)=>{
//             console.log(error,"error in Jobsdashboard");
//         })
//     }

//     return (
//         <Box className="individual_table">
//             <Box className="p-10 flex-between flex-center">
//                 <Box className="flex-center">
//                     <Typography component='h6' variant='h6' className='semibold letter_sp fz-16 mr-10'>Jobs</Typography>
//                     <Tooltip title="watch video" className='flex-center text-right'>
//                         <Info className='fz-18' />
//                     </Tooltip>
//                 </Box>
//                 <Typography component='div' variant='body' className='countBox fz-24'>{totalJobs}</Typography>
//             </Box>
//             <Divider />
//             <Box className="p-10">
//                 {/* <Box className="d-flex justify-content-right">
//                     <Tooltip title="watch video" className='flex-center text-right'>
//                         <Button className='theme_btn btn-xs'>Add or Edit Jobs</Button>
//                     </Tooltip>
//                 </Box> */}
//                 <Box className="table_content">
//                     <Sheet sx={{ height: 200, overflow: 'auto' }}>
//                         <Table
//                             aria-label="table with sticky header"
//                             stickyHeader
//                             stickyFooter
//                             stripe="odd"
//                             hoverRow
//                         >
//                             <thead>
//                                 <tr>
//                                     <th className='minw100'>Job Title</th>
//                                     <th className='minw300'>Description</th>
//                                     <th className='text-center minw100'>Added on</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {jobdashboard && jobdashboard?.length > 0 ?
//                                 (jobdashboard && jobdashboard?.map((item,i) => (
//                                     <tr key={i}>
//                                         <td className='semibold fz-12' >{item.Job_title}</td>
//                                         <td  ><Box className='ellipse2 fz-12 text_blue letter_sp'>{item.description ? item.description : '-'}</Box></td>
//                                         <td ><div className='semibold fz-12 text-center'>{moment(item.createdAt).format('MMM Do YY')}</div></td>
//                                     </tr>
//                                 ))) : (
//                                     <p>No data found</p>
//                                 )}
//                             </tbody>
//                         </Table>
//                     </Sheet>
//                 </Box>
//             </Box>
//         </Box>
//     )
// }


import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Sheet } from '@mui/joy';
import Table from '@mui/joy/Table';
import { Info } from '@mui/icons-material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

export default function Jobs() {
  // UseState's:
  const [jobdashboard, setJobDashboard] = React.useState([]);
  const [totalJobs, setTotalJobs] = React.useState(0);
  const [loading, setLoading] = React.useState(true); // Loading state

  // Hook's state:
  React.useEffect(() => {
    // Simulate 1-second loading time before fetching data
    setTimeout(() => {
      JobsDashboard();
    }, 1000);
  }, []);

  const JobsDashboard = () => {
    axios.get(process.env.REACT_APP_BASE_URL + `Jobs/getlatestJobs`)
      .then((res) => {
        setJobDashboard(res.data.getJobs);
        setTotalJobs(res.data.totalcount);
        setLoading(false); // Disable loader after data is fetched
      })
      .catch((error) => {
        console.log(error, "error in Jobsdashboard");
        setLoading(false); // Disable loader if there's an error
      });
  };

  return (
    <Box className="individual_table">
      <Box className="p-10 flex-between flex-center">
        <Box className="flex-center">
          <Typography component='h6' variant='h6' className='semibold letter_sp fz-18 mr-10'>Jobs</Typography>
          <Tooltip title="watch video" className='flex-center text-right'>
            <Info className='fz-18' />
          </Tooltip>
        </Box>
        <Typography component='div' variant='body' className='countBox fz-24'>{totalJobs}</Typography>
      </Box>
      <Divider />
      <Box className="p-10">
      <Box className="d-flex justify-content-right">
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Button className='theme-btn btn-gradient btn-xs'>More</Button>
                    </Tooltip>
                </Box>
        <Box className="table_content">
          <Sheet sx={{ height: 200, overflow: 'auto' }}>
            <Table
              aria-label="table with sticky header"
              stickyHeader
              stickyFooter
              stripe="odd"
              hoverRow
            >
              <thead>
                <tr>
                  <th className='minw100'>Job Title</th>
                  <th className='minw300'>Description</th>
                  <th className='text-center minw100'>Added on</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="3" style={{background:"#fbfcfe", textAlign: "center", padding: "20px" }}>
                      {/* Circular progress loader */}
                      <CircularProgress sx={{ color: "#f3714d" }} />
                    </td>
                  </tr>
                ) : jobdashboard && jobdashboard.length > 0 ? (
                  jobdashboard.map((item, i) => (
                    <tr key={i}>
                      <td className='semibold fz-12'>{item.Job_title}</td>
                      <td><Box className='ellipse2 fz-12 text_blue letter_sp'>{item.description ? item.description : '-'}</Box></td>
                      <td><div className='semibold fz-12 text-center'>{moment(item.createdAt).format('MMM Do YY')}</div></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" style={{background:"#fbfcfe", textAlign: "center", fontWeight: "bold", padding: "20px" }}>
                      <Typography component="p" variant="body1">No data found</Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      </Box>
    </Box>
  );
}
