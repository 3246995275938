import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Curriculum from './curriculum';
import Content_uploader from './content_uploader';
import Pricing from './pricing';
import Drip_schedule from './drip_schedule';
import Quiz from './Quiz';
import Publish from './Publish';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className='courseTabs'
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CourseTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }} className="courseTabs">
            <Box className="whiteContainer">
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Curriculum" {...a11yProps(0)} />
                    <Tab label="Content Uploader" {...a11yProps(1)} />
                    <Tab label="Quiz" {...a11yProps(2)} />
                    <Tab label="Drip Schedule" {...a11yProps(3)} />
                    <Tab label="Pricing" {...a11yProps(4)} />
                    <Tab label="Publish" {...a11yProps(5)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Curriculum/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Content_uploader/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Quiz/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
               <Drip_schedule/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <Pricing/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
                <Publish/>
            </CustomTabPanel>
        </Box>
    );
}
