import * as React from "react";
import Box from "@mui/material/Box";
import { Typography, Grid, Button, Card } from "@mui/material";


import DeleteIcon from "@mui/icons-material/Delete";
import DOMPurify from "dompurify";
import { styled } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { useSnackbar } from "notistack";

import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: "15px",
	},
}));
const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 16,
						top: 15,
						color: (theme) => theme.palette.grey[500],
						border: "1px solid #E5EBF0",
						borderRadius: "8px",
						height: 38,
						width: 38,
					}}
				>
					<CloseIcon className="fz-18" />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

export default function FormPropsTextFields() {
	const headerlocal = {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token"),
	};

	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}
	const params = useParams()

	// const auth = JSON.parse(localStorage.getItem("userData"));

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [newdata, setNewdata] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [openview, setOpenview] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(12);
	const [total, setTotal] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [search, setSearch] = React.useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);

	const menuopen = Boolean(anchorEl);
	const [alldata, setAlldata] = React.useState("");
	const [cat, setCat] = React.useState([]);
	// const [view, setView] = React.useState(false);
	const [deleteid, setDeleteId] = React.useState("");
	const [catid, setCatId] = React.useState("all");




	// const navigate = useNavigate()
	// const params = useParams()
	// const [open, setOpen] = React.useState(false);
	// const { enqueueSnackbar } = useSnackbar()
	// const [deleteId, setDeleteId] = React.useState(null);
	const [state1, setState1] = React.useState([]);
	const [state2, setState2] = React.useState([]);
	const [imageAddError, setImageAddError] = React.useState('');
	// const [user, setUser] = React.useState('')
	// const [newdata, setNewdata] = React.useState([]);
	const [deleteImagePath, setDeleteImagePath] = React.useState(null);
	const [addImageFrom, setaddImageFrom] = React.useState('Teacher')
	const [selectedCard, setSelectedCard] = React.useState(null);
	const [addonsModal, setAddonsModal] = React.useState(false)
	// const [numberOfBlogs, setNumberOfBlogs] = React.useState('');
	const [open1, setOpen1] = React.useState(false);
	const [eventAddons, setEventAddons] = React.useState([])
	// const redirecturl = process.env.REACT_APP_REDIRECT_URL
	const [isLoading, setIsLoading] = React.useState(false);


	// -------------------------------------------------------

	const handleCardClick = (item) => {
		setSelectedCard(item);
	};

	const handleSubmit = async () => {
		const body = {
			userId: auth._id,
			customerId: auth.stripeCustomerId,
			// AddOns : selectedCard // if selectedCard is an array
			AddOns: [selectedCard],   // [selectedCard] because selectedCard is an object.. If multiple items can be selected push each item into selectedCard.
			redirectUrl: process.env.REACT_APP_REDIRECT_URL + `teachers/details/image/${auth._id}`

		}
		console.log(body, 'bodybodybody')
		await axios.put(process.env.REACT_APP_BASE_URL + `subscription/UpdateAddOnsByUserid`, body, { headers: headerlocal })
			.then(res => {
				console.log(res.data?.PaymentLink, "update response console")
				window.open(res.data?.PaymentLink)
				onClose();
			})
			.catch(err => {
				console.log(err, "UpdateAddOnsByUserid catch error")
			})
	};


	// const handleClose = () => {
	//   setOpen1(false);
	// };


	const handlePurchaseAddons = async () => {
		var someData = auth ? auth.planId?.addOns?.filter(item => item.sectionName === 'Images') : [] //display only events addons
		//var someData = auth ? auth.planId?.addOns : [] // display all addons
		setEventAddons(someData)
		setAddonsModal(true)
		setOpen1(false)
	}

	const onClose = () => {
		setAddonsModal(false)
	}

	const auth = JSON.parse(localStorage.getItem("userData"))

	// const multipleImgChangeMultiple = (e, index) => {
	//   setImageAddError('');
	//   var fileObj = [];
	//   var fileArray = [];
	//   var filepath = [];

	//   fileObj.push(e.target.files)
	//   for (let i = 0; i <= fileObj[0].length; i++) {
	//     if (i < fileObj[0].length) {
	//       fileArray.push(URL.createObjectURL(fileObj[0][i]))
	//       filepath.push(fileObj[0][i])
	//     }
	//   }
	//   var data = state1.filter(x => x != null)
	//   var data1 = state2.filter(x => x != null)
	//   setState1(data)
	//   setState2(data1)
	//   setState1(prevValue => prevValue.concat(fileArray))
	//   setState2(prevValue => prevValue.concat(filepath))
	// }


	// const multipleImgChangeMultiple = (e, index) => {
	//   setImageAddError('');

	//   // Validate file size
	//   const maxSize = 1024 * 1024; // 1MB in bytes
	//   const files = e.target.files;
	//   var fileObj = [];
	//   var fileArray = [];
	//   var filepath = [];

	//   for (let i = 0; i < files.length; i++) {
	//     if (files[i].size > maxSize) {
	//       // Handle file size error (for example, set an error state)
	//       setImageAddError('Image size should be less than 1 MB.');
	//       continue; // Skip this file
	//     }

	//     fileObj.push(files[i]);
	//     fileArray.push(URL.createObjectURL(files[i]));
	//     filepath.push(files[i]);
	//   }

	//   var data = state1.filter(x => x != null);
	//   var data1 = state2.filter(x => x != null);
	//   setState1(data);
	//   setState2(data1);
	//   setState1(prevValue => prevValue.concat(fileArray));
	//   setState2(prevValue => prevValue.concat(filepath));
	// }

	// const indexDelete = (e, index) => {
	//   var filteredpreview = state1.filter((value, i) => {
	//     return i !== index;
	//   });
	//   var filteredraw = state2.filter((val, i) => {
	//     return i !== index;
	//   });
	//   setState1(filteredpreview);
	//   setState2(filteredraw);
	// }

	React.useEffect(() => {
		getUser();
	}, []);

	const getUser = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `image/GetImagescreatedby/${params.id}`, { headers: headerlocal });
			console.log(response.data.imagedata, "Image Data data");
			setNewdata(response.data.imagedata);
		} catch (error) {
			console.error("Error fetching images:", error);
			enqueueSnackbar("Error fetching images", { variant: 'error' });
		}
	}

	// const handleDeleteImage = async (imagePath) => {
	//   const body = {
	//     imgpath: imagePath
	//   };

	//   try {
	//     await axios.put(
	//       `${process.env.REACT_APP_BASE_URL}image/deleteImage/${deleteId}`,
	//       body,
	//       { headers: headerlocal }
	//     );

	//     enqueueSnackbar("Image deleted successfully", { variant: 'success' });
	//     getUser();
	//     handleDeleteDialogClose()

	//   } catch (error) {
	//     console.error("Error deleting image:", error);
	//     // enqueueSnackbar("Error deleting image", { variant: 'error' });
	//   }
	// }

	const handleDeleteDialogOpen = (id, imagePath) => {

		setDeleteId(id); // Set the _id of the image to delete
		setDeleteImagePath(imagePath); // Set the image path
		setOpen(true); // Open the delete dialog
	};





	const handleDeleteDialogClose = () => {
		setDeleteId(null);
		setOpen(false);
	};

	// const handleAddNews = () => {
	//   var multipleImage = state2.filter(x => x != null);

	//   {
	//     const formData = new FormData();
	//     // Assuming params is an object containing an 'id' property
	//     // Assuming params is an object containing an 'id' property
	//     formData.append('addImageFrom', addImageFrom)
	//     formData.append('createdby[]', params && params.id);
	//     { multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '') }
	//     console.log([...formData], 'formdata in edit');
	//     axios.post(process.env.REACT_APP_BASE_URL + `image/addimage`, formData, { headers: headerformdata })
	//       .then((res) => {
	//         setState1([]);
	//         navigate(`/teachers/details/image/${params.id}`);
	//         enqueueSnackbar("Additional images added successfully", { variant: 'success' })
	//         console.log(res, "added");
	//         getUser();
	//       })
	//       .catch((err) => {
	//         enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
	//         console.log(err, "error");
	//         if (err.response.data?.status == 3) {
	//           setOpen1(true)
	//         }
	//       })
	//   }
	// }





	// ------------------------------------------------------

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	React.useEffect(() => {
		getCategory();
	}, []);

	const getCategory = async () => {
		await axios
			.get(
				process.env.REACT_APP_BASE_URL +
				`category/GetCategory?size=100&page=0&order=asc`,
				{ headers: headerlocal }
			)
			.then((res) => {
				console.log(res.data);
				setCat(res.data.category);
			})
			.catch((error) => {
				console.log(error.response, "error");
			});
	};

	// const handleClick = (event, data) => {
	//   console.log(data);
	//   setAlldata(data);
	//   setAnchorEl(event.currentTarget);
	// };

	// const handleClose_menu = () => {
	//   setAnchorEl(null);
	// };

	React.useEffect(() => {
		getAboutDetails();
	}, [rowsPerPage, page, search, catid]);

	const getAboutDetails = async () => {
		await axios
			.get(
				process.env.REACT_APP_BASE_URL +
				`AboutUs/GetAboutUs?size=${rowsPerPage}&page=${page}&search=${search}&Category_id=${catid}`,
				{ headers: headerlocal }
			)
			.then((res) => {
				console.log(res.data.aboutUs, "gjfkgnfkjgnfgfkjgnfkfg");
				setNewdata(res.data.aboutUs);
				setTotal(res.data.totalcount);
			})
			.catch((error) => {
				console.log(error, "error in service get");
			});
	};

	React.useEffect(() => {
		GetImages();
	}, [rowsPerPage, page, search, catid]);



	console.log(params.id)
	const GetImages = async () => {
		function filterObjectsWithAdditionalImages(imagedata) {
			return imagedata.filter(item => item?.additionalimages && item?.additionalimages.length > 0);
		}
		try {
			const url = `image/GetImagescreatedby/${params && params.id}`
			// auth && auth.role_id === 3
			//   ? `image/GetImages?size=${rowsPerPage}&page=${page}&search=${search}&Category_id=${catid}`
			//   : `image/GetImagescreatedby/${params && params.id}`;
			const response = await axios.get(process.env.REACT_APP_BASE_URL + url, {
				headers: headerlocal,
			});
			const filteredData = filterObjectsWithAdditionalImages(response.data.imagedata);
			//   setNewdata(response.data.imagedata);



			//   function mergeAdditionalImages(filteredData) {
			//     return filteredData.reduce((mergedImages, item) => {
			//         return mergedImages.concat(item.additionalimages);
			//     }, []);
			// }

			// const mergedImages = mergeAdditionalImages(filteredData);
			console.log(filteredData)
			const result = filteredData.flatMap(item =>
				item.additionalimages.map(image => ({
					_id: item._id,
					additionalimages: image
				}))
			);
			console.log(result)

			setNewdata(result);


			// console.log(mergedImages, "Image Data data");
			setTotal(response.data.totalcount);
		} catch (error) {
			console.error("Error fetching images:", error);
			enqueueSnackbar("Error fetching images", { variant: "error" });
		}
	};

	// const handleDeleteService = async () => {
	//     await axios.put(process.env.REACT_APP_BASE_URL + `AboutUs/deleteAboutUs/${deleteid}`, {}, { headers: headerlocal })
	//         .then((res) => {
	//             enqueueSnackbar("Yoga Retreat deleted successfully", { variant: 'success' })
	//             getAboutDetails()
	//             handleClose()
	//         })
	//         .catch((error) => {
	//             console.log(error);
	//         })
	// }

	const handleDeleteImage = async (imagePath) => {
		setIsLoading(true);
		const body = {
			imgpath: imagePath,
		};


		try {
			const url = `image/deleteImage/${deleteid}`

			await axios.put(
				process.env.REACT_APP_BASE_URL + url,
				body,
				{ headers: headerlocal }
			);
			enqueueSnackbar("Image deleted successfully", { variant: "success" });
			GetImages();
			handleClose();
		} catch (error) {
			setIsLoading(true)
			console.error("Error deleting image:", error);
			enqueueSnackbar("Error deleting image", { variant: "error" });
		} finally {
			setIsLoading(false);
			handleClose();
		}
	};

	// const handleClickOpen = () => {
	//   setAnchorEl(null);
	//   setDeleteId(alldata._id);
	//   setOpen(true);
	// };


	const handleClose = () => {
		setDeleteId("");
		setOpen(false);
	};

	// const handleEdit = () => {

	//   let val = {
	//     data: alldata,
	//   };

	//   alldata && navigate(`/teachers/details/image/edit/${alldata.user_id[0]}`, { state: val });
	// };

	// const handleView = () => {
	//   setAnchorEl(null);
	//   setOpenview(true);
	// };

	// const handleRemove = () => {
	//   setCatId("all");
	//   setSearch("");
	//   setPage(0);
	// };

	const sanitizedContent = DOMPurify.sanitize(alldata.description, {
		ALLOWED_TAGS: ["img"],
	});

	const handleClickClose = () => {
		setOpenview(false);
		setAlldata("");
	};

	return (
		<>
			<Box className="">


				<Box className="whiteContainer p-20">

					<Grid
						container
						columnSpacing={2}
						rowSpacing={2}
						className="servicesList otherList"
					>
						{newdata && newdata.length > 0
							? newdata.map((data, index) => (
								<Grid
									key={index}
									item
									xs={12}
									sm={3}
									md={3}
									sx={{ marginTop: 2 }}
								>
									<Card className="cardGrid">
										<Grid item xs={12} sm={12} md={12} lg={12}>
											<div className="carosuel" >
												{/* Render images side by side here */}

												<div key={index} className="imageContainer" style={{ margin: "10px" }} >
													<img
														width={"100%"}
														height={250}
														src={
															process.env.REACT_APP_BASE_URL +
															`${data.additionalimages}`
														}
														style={{ marginRight: "5px" }}
														alt={`Pic ${index}`}
													/>
													<IconButton
														onClick={() =>
															handleDeleteDialogOpen(data._id, data.additionalimages)
														}
														className="deleteIcon"
													>
														<DeleteIcon className="fz-18" />
													</IconButton>
												</div>

											</div>
										</Grid>
									</Card>
								</Grid>
							))
							: <Grid item xs={12} sm={12} md={12} lg={12}>
								<Typography variant="body1" className='text-center semibold'>No data found</Typography>
							</Grid>}
					</Grid>


					<Dialog
						open={addonsModal}
						onClose={onClose}
						maxWidth="sm"
						fullWidth
					>
						<DialogTitle>Select Details</DialogTitle>
						<DialogContent>
							<Grid container spacing={2}>
								{eventAddons &&
									eventAddons.length > 0 &&
									eventAddons.map((item, index) => (
										<Grid item xs={12} sm={6} md={4} key={index}>
											<Card
												variant="outlined"
												sx={{
													cursor: "pointer",
													"&:hover": { boxShadow: 3 },
													border:
														selectedCard?.count === item?.count
															? "2px solid #1976d2"
															: "1px solid #e0e0e0",
												}}
												onClick={() => handleCardClick(item)}
											>
												<CardContent>
													<Typography variant="h6">
														{item.sectionName}
													</Typography>
													<Typography variant="body1">
														Amount Type: {item.amount_type}
													</Typography>
													<Typography variant="body1">
														Amount: {item.amount}
													</Typography>
													<Typography variant="body1">
														Count: {item?.count}
													</Typography>
												</CardContent>
											</Card>
										</Grid>
									))}
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} color="primary">
								Cancel
							</Button>
							<Button
								onClick={handleSubmit}
								color="primary"
								variant="contained"
								disabled={!selectedCard}
							>
								Submit
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={open1}
						keepMounted
						onClose={handleClose}
						aria-describedby="alert-dialog-slide-description"
						className="custom_modal"
					>
						<DialogTitle>
							Would you like to purchase image Addons?
						</DialogTitle>
						<DialogActions>
							<Button onClick={handleClose} className="cancel_btn">
								No
							</Button>
							<Button onClick={handlePurchaseAddons} className="theme-btn">
								Yes
							</Button>
						</DialogActions>
					</Dialog>
					{/* </Box> */}
					{/* </Box> */}
					<Dialog
						open={open}
						keepMounted
						onClose={handleDeleteDialogClose}
						aria-describedby="alert-dialog-slide-description"
						className="custom_modal"
					>
						<DialogTitle>Would you like to delete this image?</DialogTitle>
						<DialogActions>
							<Button onClick={handleDeleteDialogClose} className="cancel_btn">
								No
							</Button>
							{isLoading ?
								<Button variant="contained" className='theme-btn' disabled>Please Wait...</Button>
								:
								<Button onClick={() => handleDeleteImage(deleteImagePath)} variant="contained" className='theme-btn'>Yes</Button>
							}
						</DialogActions>
					</Dialog>

					{/* ---------------------------------------------------- */}

				</Box>
				<TablePagination
					rowsPerPageOptions={[8, 12, 16, 20, 24]}
					component="div"
					count={total}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					className="DT-pagination"
				/>
			</Box>

			<BootstrapDialog
				onClose={handleClickClose}
				aria-labelledby="customized-dialog-title"
				open={openview}
				className="custom_modal"
				maxWidth="md"
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					About Us
				</DialogTitle>

				<DialogContent dividers>
					<Grid
						container
						columnSpacing={2}
						rowSpacing={1}
						className=" mb-10 align-items-center"
					>
						<Grid item xs={12} sm={12} md={6} lg={6}>
							<div className="carosuel mb-10">
								<Swiper
									modules={[Navigation, Pagination]}
									className="mySwiper "
									slidesPerView={1}
									pagination={{
										clickable: true,
									}}
								>
									{alldata &&
										alldata.allimages &&
										alldata.allimages?.length > 0 &&
										alldata.allimages.map((tdata, i) => (
											<SwiperSlide>
												{" "}
												<img
													width={"100%"}
													height={250}
													alt="imgds"
													src={process.env.REACT_APP_BASE_URL + `${tdata}`}
												/>
											</SwiperSlide>
										))}
								</Swiper>
							</div>
						</Grid>

						<Grid
							style={{ marginTop: "-200px" }}
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
						>
							<Box className="">
								<Typography
									component="span"
									variant="body1"
									className="fz-15 semibold"
								>
									Service:{" "}
								</Typography>
								<Typography component="span" variant="body1" className="fz-15 ">
									{alldata.course_name}
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Grid>
						<div className="carosuel mb-10">
							<img
								alt="pic multi"
								width={"100%"}
								height={250}
								src={process.env.REACT_APP_BASE_URL + `${alldata.image}`}
							/>
						</div>
					</Grid>
					<Typography
						component="div"
						variant="body1"
						className="fz-14 semibold"
					>
						Description:{" "}
					</Typography>
					{sanitizedContent ? (
						<div
							className="w-full editor_view"
							dangerouslySetInnerHTML={{ __html: sanitizedContent }}
						/>
					) : (
						<Typography className="p18_tag">There is no description</Typography>
					)}
				</DialogContent>
				<DialogActions>
					<Button className="cancel_btn" onClick={handleClickClose}>
						Close
					</Button>
				</DialogActions>
			</BootstrapDialog>
		</>
	);
}
