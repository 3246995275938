import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import PanoramaIcon from '@mui/icons-material/Panorama';
import { AssignmentOutlined, Business, FeaturedPlayListOutlined, LockPersonOutlined, VpnKeyRounded } from '@mui/icons-material';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { ListItemButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';


export default function Sidebar({ subtype }) {
    const auth = JSON.parse(localStorage.getItem("userData"))
    const params = useParams()

    let navigate = useNavigate()
    const data = [
        auth && auth.role_id == 1 ? {
            icons: <UploadFileIcon className='fz-18' />,
            text: 'EVENTS',
            link: `/yogastudios/details/events/${params.id}`
        } : "",
        auth && auth.role_id == 1 ? {
            icons: <HeadsetMicIcon className='fz-18' />,
            text: 'PODCAST',
            link: `/yogastudios/details/broadcast/${params.id}`
        } : "",

        auth && auth.role_id == 1 ? {
            icons: <AssignmentOutlined className='fz-18' />,
            text: 'BLOGS',
            link: `/yogastudios/details/blog/${params.id}`
        } : "",
        
        auth && auth.role_id == 1 ? {
            icons: <AssignmentTurnedInIcon className='fz-18' />,
            text: 'COURSE',
            link: `/yogastudios/details/course/${params.id}`
        } : "",   

        auth && auth.role_id == 1 ? {
            icons: <SmartDisplayIcon className='fz-18' />,
            text: 'VIDEOS',
            link: `/yogastudios/details/video/${params.id}`
        } : "",

        auth && auth.role_id == 1 ? {
            icons: <PanoramaIcon className='fz-18' />,
            text: 'IMAGES',
            link: `/yogastudios/details/image/${params.id}`
        } : "",

        auth && auth.role_id == 1 ? {
            icons:  <i class="fa-solid fa-handshake-simple sidebarIcons"></i>,
            text: 'COMMUNITY',
            link: `/yogastudios/details/community/${params.id}`
        } : "",

        auth && auth.role_id == 1 ? {
            icons: <i class="fa-sharp fa-solid fa-people-group"></i>,
            text: 'FAN PAGE',
            link: `/yogastudios/details/fans/${params.id}`
        } : "",
        auth && auth.role_id == 1 ? {
            icons:  <i class="fa-solid fa-book-open-reader sidebarIcons"></i>,
            text: 'BOOK REVIEW',
            link: `/yogastudios/details/bookreview/${params.id}`
        } : "",
    ]

    const [dense, setDense] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };
    const PageNavigation = (url) => {
        navigate(url);

    }

    React.useState(() => {
        setSelectedIndex(subtype);
    }, [subtype])
    return (
        <>
            <Box className='sidebar'>
                <Typography variant="h5" component="h5" >
                    YOGA STUDIO DETAILS
                </Typography>
                {/* <Typography variant="h6" component="h6" >
                    DATA
                </Typography> */}
                <List dense={dense} className='list'>
                    {data.map((item, index) => (
                        item &&
                        <ListItemButton key={index} selected={selectedIndex === index}
                            onClick={(event) => handleListItemClick(event, index)}>
                            {/* {console.log(selectedIndex , index, 'subtype')} */}
                            <ListItemIcon style={{ minWidth: 35, fontSize: 16 }} className='itemIcon'>
                                {item.icons}
                            </ListItemIcon>
                            <ListItemText className='sidebarText'
                                primary={item.text} onClick={() => PageNavigation(item.link)}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Box>
            <Box className='Ressidebar'>
                <Typography variant="h5" component="h5" >
                    COMMUNITY SETTINGS
                </Typography>
                <Typography variant="h6" component="h6" >
                    DATA
                </Typography>
                <List className='list'>

                    {data.map((item, index) => (

                        <ListItem key={index} disablePadding sx={{ display: 'block' }} selected={selectedIndex === index}>
                            <ListItemButton onClick={(event) => handleListItemClick(event, index)} >
                                <ListItemIcon style={{ minWidth: 35, fontSize: 16 }} className='itemIcon'>
                                    {item.icons}
                                </ListItemIcon>
                                <ListItemText className='sidebarText'
                                    primary={item.text} onClick={() => PageNavigation(item.link)}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>


    )
}
