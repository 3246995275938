import React, { useState } from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Checkbox, FormGroup, Grid, InputAdornment, Avatar, Input, TextField, FormControlLabel, RadioGroup, Radio,Tooltip, Typography, IconButton, TextareaAutosize } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DeleteOutline, ViewKanbanOutlined } from '@mui/icons-material'


export default function Editsubscription() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    let location = useLocation()

    let locationData = location.state.data

    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    var editId = locationData.stripePlanId
    // console.log(location.state,"state");

    const [error, setError] = React.useState({ type: "" })
    const [type, setType] = React.useState(locationData.name)
    const [subscriptiontype, setSubscriptionType] = React.useState(location.state.data.usertype)
    const [recordings, setRecordings] = React.useState([])
    const [currency, setCurrency] = React.useState(locationData.currency)
    const [isToggled, setToggled] = React.useState(location.state.data.popular)

    // const [interval, setInterval] = React.useState('month')
    const [interval, setInterval] = React.useState(locationData.interval)
    const [subscription, setSubscription] = React.useState({ plan: location.state.data.name, time: "", amount: location.state.data.amount, currency: "INR", description: locationData.description})

    const [buttondisable, setButtondisable] = React.useState(false)
    const onlyDecimalNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9,.]/g, '') };
    const restrictZero = (e) => {
        if (e.target.value.length === 0 && e.key === '0') {
            e.preventDefault();
            enqueueSnackbar("First digit cannot be zero", { variant: "error" })
        }
    }

    const handleToggle = () => {
		setToggled(!isToggled)
	}
	console.log(!isToggled, "isToggledisToggled");

    const Validator = () => {
        if (!type) {
            setError({ type: "This field is required." })
            return false
        }
        return true
    }

    const validateForm = () => {
        let valid = true;
        const newError = { ...error };
        if (!subscription.plan.trim()) {
            newError.plan = "Subscription Name is required";
            valid = false;
        } else {
            newError.plan = "";
        }

        if (!currency || currency === "Select Currency") {
            newError.currency = "Please select Currency";
            valid = false;
        } else {
            newError.currency = "";
        }

        if (!subscriptiontype){
            newError.subscriptiontype = "User Type is required";
            valid = false;
        } else {
            newError.subscriptiontype = "";
        }
        if (!subscription.amount) {
            newError.amount = "Amount is required";
            valid = false;
        } else {
            newError.amount = "";
        }

        if (!interval || interval === "sel") {
            newError.interval = "Please select Interval";
            valid = false;
        } else {
            newError.interval = "";
        }

        setError(newError);
        return valid;
    };

    const handleSubscription = (e) => {
        const { name, value } = e.target;
        setSubscription({ ...subscription, [name]: value });
    };

    const [modifierArray, setModifierArray] = React.useState([])
    const [modifierOption, setModifierOption] = React.useState({
        sectionName: locationData.addOns.length > 0 ? locationData.addOns[0].sectionName : '',
        amount_type: 'INR',
        amount: locationData.addOns.length > 0 ? locationData.addOns[0].amount : 0,
        count: locationData.addOns.length > 0 ? locationData.addOns[0].count : 0
    });
    
    // const [modifierOption, setModifierOption] = React.useState({ sectionName: locationData.addOns.sectionName, amount_type: 'INR', amount: location.state.data.addOns.addamount, count: location.state.data.count })
    const [checkEvent, setCheckEvent] = React.useState([{ name: 'Event', check: locationData.Event, noOfValue: locationData.noOfEvent }, { name: 'Course', check: locationData.Course, noOfValue: locationData.noOfCourse }, { name: 'Blogs', check: locationData.Blogs, noOfValue: locationData.noOfBlogs }, { name: 'Podcast', check: locationData.Podcast, noOfValue: locationData.noOfPodcast }, { name: 'Videos', check: locationData.Videos, noOfValue: locationData.noOfVideos }, { name: 'Images', check: locationData.Images, noOfValue: locationData.noOfImages},{ name: 'Community', check: locationData.Community, noOfValue: locationData.noOfCommunity }])
    console.log(checkEvent,"checkkkk");
    const handleCheckEvent = (e) => {
        const { name, checked } = e.target;
        setCheckEvent(prevState => prevState.map(item => {
            if (item.name === name) {
                return { ...item, check: checked };
            }
            return item;
        }));
    };

    const [totalEvents, setTotalEvents] = useState(0); 
    const handleChangeValue = (e, index) => {
        const { value } = e.target;
        const numberValue = Number(value);
        setCheckEvent(prevState => {
            const updatedCheckEvent = [...prevState];
            updatedCheckEvent[index] = { ...updatedCheckEvent[index], noOfValue: numberValue };
            return updatedCheckEvent;
        });
    };

    const handleTotal = (e, sectionName, index) => {        
        const { value } = e.target;
        const numberValue = Number(value);      
        if(sectionName === 'Event'){
            setTotalEvents(prev => prev + numberValue);
        }
    };
    console.log(totalEvents,'totalEvents');

    const handleChangeSection = (e) => {
        const { name, value } = e.target;
        setModifierOption(prev => ({ ...prev, [name]: value }));
        setModError(prev => ({ ...prev, [name]: "" }));
    };
    const [modError, setModError] = useState({ sectionName: '', count: '', amount: '' })
    const validateMod = () => {
        if (!modifierOption.sectionName && (modifierOption.count === 0 || modifierOption.count === '') && (modifierOption.amount === 0 || modifierOption.amount === '')) {
            console.log('enter');
            setModError({ ...modError, sectionName: 'please select Section', count: 'please enter count', amount: 'please enter amount' })
            return false
        }
        if (!modifierOption.sectionName) {
            setModError({ ...modError, sectionName: 'please select Section' })
            return false
        }
        if (modifierOption.count === 0 || modifierOption.count === '') {
            setModError({ ...modError, count: 'please enter count' })
            return false
        }
        if (modifierOption.amount === 0 || modifierOption.amount === '') {
            setModError({ ...modError, amount: 'please enter amount' })
            return false
        }

        return true
    }
    console.log(modError, 'modError');

  const handleAddClick = () => {
        const updatedModifierArray = [...modifierArray, modifierOption];   
        
        const index = checkEvent.findIndex(item => item.name === modifierOption.sectionName);
        if (index !== -1 && checkEvent[index].check && modifierOption.sectionName === 'Event') {
            setTotalEvents(prev => prev + Number(modifierOption.count));
        }    

        const newSubscriptionAmount = Number(subscription.amount) + Number(modifierOption.amount);
        setSubscription(prev => ({ ...prev, amount: newSubscriptionAmount }));
    
        setModifierArray(updatedModifierArray);
        setModifierOption({ sectionName: '', amount_type: 'INR', amount: 0, count: 0 });
    };

    console.log(modifierArray, 'modifierarray');

    const handleRemove = (e, index) => {
        let list = [...modifierArray];
        let removedItem = list[index];
        let data = list.filter((item, i) => i !== index);
        const newSubscriptionAmount = Number(subscription.amount) - Number(removedItem.amount);
        setSubscription(prev => ({ ...prev, amount: newSubscriptionAmount }));
        setModifierArray(data);
    };

    const handleSubmit = async () => {
        var Valid = validateForm()
        if (Valid) {
        const body = {
            name: subscription.plan,
            usertype: subscriptiontype,
            currency: currency,
            amount: subscription.amount,
            interval: interval,
            description: subscription.description,
            Event: checkEvent[0].check,
            noOfEvent: checkEvent[0].noOfValue,
            Course: checkEvent[1].check,
            noOfCourse: checkEvent[1].noOfValue,
            Blogs: checkEvent[2].check,
            noOfBlogs: checkEvent[2].noOfValue,
            Podcast: checkEvent[3].check,
            noOfPodcast: checkEvent[3].noOfValue,
            Videos: checkEvent[4].check,
            noOfVideos: checkEvent[4].noOfValue,
            Images: checkEvent[5].check,
            noOfImages: checkEvent[5].noOfValue,
            Community: checkEvent[6].check,
            noOfCommunity: checkEvent[6].noOfValue,
            addOns: modifierArray,
            popular: isToggled
        }
        console.log(body, "datadata")
        axios.put(process.env.REACT_APP_BASE_URL +  `subscription/updateStripePlan/${editId}`, body, headerlocal).then((res) => {
            enqueueSnackbar("Subscription added successfully", { variant: 'success' })
            navigate("/subscription")
            console.log(res,"subscription");
        }).catch((err) => {
            enqueueSnackbar(err && err.response && err.response.data.message ? err.response.data.message : "Somthing went wront", { variant: 'error' })
            console.log(err);
        })
    }
    }


    return (
        <Box className='Addcompany'>
        <MiniDrawer menuOrder={10} />
        <Box component="section" className="contentWraper">
            <Grid container rowSpacing={5} columnSpacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={10} >
                    <Box className=' whiteContainer'>
                        <Box className='content p-20'>
                            <Typography component='h6' className='formTitle'>Edit Subscription Type</Typography>
                            <Grid container rowSpacing={2} columnSpacing={2}>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'>Subscription Name <Typography component='span' className='star'>*</Typography></Typography>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Title'
                                            name="plan"
                                            value={subscription.plan}
                                            disabled
                                            // onChange={handleSubscription}
                                            onChange={(e) => {
                                                handleSubscription(e); setError(prevError => ({ ...prevError, plan: '' }))
                                            }}
                                         
                                        />
                                        {error.plan && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.plan}</p>}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={8} lg={12}>
                                    <FormControl fullWidth >
                                        <Typography component='div' className='label'>Select User Type <Typography component='span' className='star'>*</Typography></Typography>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value="sel"
                                            placeholder=""
                                            className="select"
                                            onChange={(e) => { setSubscriptionType(e.target.value) }}
                                            disabled
                                        >
                                            <MenuItem disabled value={'sel'}>{subscriptiontype}</MenuItem>
                                            {/* <MenuItem value={"user"}>User</MenuItem> */}
                                            <MenuItem value={"teacher"}>Teacher</MenuItem>
                                            <MenuItem value={"yoga school"}>Yoga School</MenuItem>
                                            <MenuItem value={"yoga studio"}>Yoga Studio</MenuItem>
                                        </Select>
                                        {/* {error.job_type && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.job_type}</p>} */}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography component='div' className='label'>Select Currency<Typography component='span' className='star'></Typography></Typography>
                                    <FormControl fullWidth >
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currency}
                                            placeholder="Select Currency"
                                            className="select"
                                            onChange={(e) => { setCurrency(e.target.value); setError('') }}
                                        >
                                            <MenuItem disabled >Select Currency</MenuItem>
                                            <MenuItem value={"INR"}>INR</MenuItem>
                                            <MenuItem value={"USD"}>USD</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'>Amount<Typography component='span' className='star'>*</Typography></Typography>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Amount'
                                            name="amount"
                                            disabled
                                            value={subscription.amount}
                                            // onChange={handleSubscription}
                                            onChange={(e) => {
                                                handleSubscription(e); setError(prevError => ({ ...prevError, amount: '' }))
                                            }}
                                        />
                                        {error.amount && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.amount}</p>}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography component='div' className='label'>Select Interval<Typography component='span' className='star'></Typography></Typography>
                                    <FormControl fullWidth >
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={interval ? interval : "sel"}
                                            placeholder="Select Interval"
                                            className="select"
                                            onChange={(e) => { setInterval(e.target.value); setError('') }}
                                        >
                                            <MenuItem disabled value={'sel'}>Select Interval</MenuItem>
                                            <MenuItem value={"week"}>Weekly</MenuItem>
                                            <MenuItem value={"month"}>Monthly</MenuItem>
                                            <MenuItem value={"year"}>Yearly</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isToggled}
												onChange={handleToggle}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to add this as popular? </Typography>}
									/>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'>Description<Typography component='span' className='star'></Typography></Typography>
                                        <textarea
                                            className='textarea'
                                            placeholder='Description'
                                            name="description"
                                            value={subscription.description}
                                            onChange={handleSubscription}
                                            rows={4}
                                            cols={40} />
                                        {error.description && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.description}</p>}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} className='res-grid'>
                                    <Box >
                                        <Typography className=' fz-18 semibold' component='h4' variant='h4'>Permissions</Typography>
                                        <Box className='mt-30'>
                                            <Grid container columnSpacing={2} rowSpacing={2} className='mt-10 modifier_container' >
                                                {checkEvent && checkEvent.map((item, index) => (

                                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                                                        <Box className="flex-center ">
                                                        <FormControlLabel control={<Checkbox onChange={handleCheckEvent} name={item.name} checked={item.check} size='small' />} />
                                                            <Typography className=' fz-16 semibold mr-10 minw80' component='h5' variant='h5'>{item.name}</Typography>                                                                
                                                           
                                                                <TextField
                                                                    id={`filled-size-small-${item.name}`}
                                                                    type='number'
                                                                    variant="outlined"
                                                                    size="small"
                                                                    className='textfield'
                                                                    placeholder={`Number of ${item.name}`}
                                                                    name={`noOf${item.name}`}
                                                                    value={item.noOfValue}
                                                                    onChange={(e) => handleChangeValue(e, index)}
                                                                    onBlur={(e) => handleTotal(e, item.name, index)}                                                                   
                                                                />                                       
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} className='res-grid'>
                                    <Typography component='div' variant='h6' className='fz-16 mt-20 semibold'>Add ons</Typography>
                                    {modifierArray ? modifierArray.length > 0 && modifierArray.map((item, i) => (
                                        <>
                                            <Grid container columnSpacing={2} rowSpacing={2} className='mt-10 modifier_container' >
                                                <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            className='textfield'
                                                            placeholder='Modifier name'
                                                            name="sectionName"
                                                            value={item.sectionName}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name='amount_type'
                                                            className='select'
                                                            value={item.amount_type}
                                                        >
                                                            <MenuItem value={'INR'} key={'INR'}>INR</MenuItem>
                                                            <MenuItem value={'USD'} key={'USD'}>USD</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={5} md={2} lg={2} xl={2}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            // onInput={(e) => onlyDecimalNumbers(e)}
                                                            // onKeyDown={restrictZero}
                                                            name='count'
                                                            className='textfield'
                                                            placeholder="Number of value"
                                                            value={item.count}

                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={10} sm={5} md={2} lg={2} xl={2}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            startAdornment={<InputAdornment position="start" >{modifierOption.amount_type == 'USD' ? '$' : modifierOption.amount_type == 'INR' ? '₹' : ''}</InputAdornment>}
                                                            onInput={(e) => onlyDecimalNumbers(e)}
                                                            onKeyDown={restrictZero}
                                                            name='amount'
                                                            className='textfield'
                                                            placeholder="amount"
                                                            value={item.amount}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start" >{modifierOption.amount_type == 'USD' ? '$' : modifierOption.amount_type == 'EUR' ? '₹' : ''}</InputAdornment>,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                                    <Tooltip title="Remove"><IconButton className='trash_icon'
                                                        onClick={(e) => handleRemove(e, i)}
                                                    ><DeleteOutline /></IconButton></Tooltip>
                                                </Grid>
                                            </Grid>
                                        </>)) : ""}
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} className='res-grid'>
                                    <Grid container columnSpacing={2} rowSpacing={2} className='mt-10 modifier_group'>
                                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                            <label className='form-labels fz-13 semibold' for="my-input">Select <Typography component='span' className='star'></Typography></label>
                                            <Box className="form-box ">
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        className='select'
                                                        name='sectionName'
                                                        onChange={(e) => handleChangeSection(e)}
                                                        value={modifierOption.sectionName}
                                                    >
                                                        {checkEvent.map((row, i) => (
                                                            <MenuItem value={row.name} key={row.name}>{row.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <span style={{ color: "red", fontSize: 12 }}>{modError.sectionName}</span>
                                        </Grid>


                                        <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>

                                            <label className='form-labels fz-13 semibold' for="my-input">Select Currency</label>
                                            <Box className="form-box ">
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name='amount_type'
                                                        className='select'
                                                        onChange={(e) => handleChangeSection(e)}
                                                        value={modifierOption.amount_type}
                                                    >
                                                        <MenuItem value={'INR'} key={'INR'}>INR</MenuItem>
                                                        <MenuItem value={'USD'} key={'USD'}>USD</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                            <label className='form-labels fz-13 semibold' for="my-input">Count</label>
                                            <Box className="form-box" >
                                                <FormControl fullWidth>
                                                    <TextField

                                                        onInput={(e) => onlyDecimalNumbers(e)}
                                                        onKeyDown={restrictZero}
                                                        name='count'
                                                        onChange={e => handleChangeSection(e)}
                                                        className='textfield'
                                                        placeholder="Number of value"
                                                        value={modifierOption.count}

                                                    />
                                                </FormControl>
                                                <span style={{ color: "red", fontSize: 12 }}>{modError.count}</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                            <label className='form-labels fz-13 semibold' for="my-input">Amount</label>
                                            <Box className="form-box" >
                                                <FormControl fullWidth>
                                                    <TextField

                                                        startAdornment={<InputAdornment position="start" >{modifierOption.amount_type == 'USD' ? '$' : modifierOption.amount_type == 'INR' ? '₹' : ''}</InputAdornment>}
                                                        onInput={(e) => onlyDecimalNumbers(e)}
                                                        onKeyDown={restrictZero}
                                                        name='amount'
                                                        onChange={e => handleChangeSection(e)}
                                                        className='textfield'
                                                        placeholder="Amount"
                                                        value={modifierOption.amount}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start" >{modifierOption.amount_type == 'USD' ? '$' : modifierOption.amount_type == 'INR' ? '₹' : ''}</InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                <span style={{ color: "red", fontSize: 12 }}>{modError.amount}</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3} lg={3} xl={3} >
                                            <Box className="benefit-btn "><Button className='theme-btn'
                                                onClick={handleAddClick}
                                            >+ Add Additional</Button></Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                <Button
                                    onClick={handleSubmit}
                                    variant="contained" className='theme-btn mr-10'>Update</Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    </Box >
    )
}
