import React, { useState, useEffect } from 'react';
import MultiSelectWithCheckboxes from './MultiSelectWithCheckboxes';

const EditBundle = ({
  selectedCommunity, selectedYogaFans, selectedCourses, 
  onUpdateCommunity, onUpdateYogaFans, onUpdateCourses,
  communities, yogaFans, courses
}) => {
  const [localSelectedCommunity, setLocalSelectedCommunity] = useState(selectedCommunity);
  const [localSelectedYogaFans, setLocalSelectedYogaFans] = useState(selectedYogaFans);
  const [localSelectedCourses, setLocalSelectedCourses] = useState(selectedCourses);

  useEffect(() => {
    setLocalSelectedCommunity(selectedCommunity);
    setLocalSelectedYogaFans(selectedYogaFans);
    setLocalSelectedCourses(selectedCourses);
  }, [selectedCommunity, selectedYogaFans, selectedCourses]);

  // Handle change for Communities (checkbox change)
  const handleChangeCommunity = (selectedCommunities) => {
    // Update local state with the selected community objects (full objects, not just _id)
    setLocalSelectedCommunity(selectedCommunities);

    // Notify parent of the updated community selection (send the full community objects)
    onUpdateCommunity(selectedCommunities);
  };

  // Handle the change for Yoga-Fans
  const handleChangeYogaFans = (selectedFansPage) => {
    
    setLocalSelectedYogaFans(selectedFansPage); // update local state
    onUpdateYogaFans(selectedFansPage); // notify parent of the change
  };

  // Handle change for Courses
  const handleChangeCourses = (selectedCourse) => {
   
    setLocalSelectedCourses(selectedCourse); // update local state
    onUpdateCourses(selectedCourse); // notify parent of the change
  };

  
  


  return (
    <div>
      {/* Communities */}
      <MultiSelectWithCheckboxes
        label="Assign Communities"
        options={communities}
        name="community"
        selectedValues={localSelectedCommunity}
        handleChange={handleChangeCommunity} 
        renderValue={(selectedValues) => selectedValues.map(id => communities.find(c => c._id === id)?.coummunity_name).join(' , ')}
      />

      {/* Yoga-Fans */}
      <MultiSelectWithCheckboxes
        label="Assign Yoga-Fans Pages"
        options={yogaFans}
        name={"yogaFans"}
        selectedValues={localSelectedYogaFans}
        handleChange={handleChangeYogaFans}
        renderValue={(selectedValues) => selectedValues.map(id => yogaFans.find(c => c._id === id)?.name).join(' , ')}
      />

      {/* Courses */}
      <MultiSelectWithCheckboxes
        label="Assign Courses"
        options={courses}
        name={"course"}
        selectedValues={localSelectedCourses}
        handleChange={handleChangeCourses}
        renderValue={(selectedValues) => selectedValues.map(id => courses.find(c => c._id === id)?.course_title).join(' , ')}
      />
    </div>
  );
};

export default EditBundle;
