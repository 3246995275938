import React, { useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import Sidebar from './../../Sidebar'
// import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Typography, Grid,Box, Button,Input, TextField, InputAdornment, Card, Menu, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
 import { SearchOutlined, Visibility as VisibilityIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

import { Close } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
        '#E80A89',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
        '#E80A89',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

function Service_Steppyform() {
  const headerlocal = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    Authorization: localStorage.getItem("token")
  }
  const headerformdata = {
    'Content-Type': 'multipart/form-data',
    Authorization: localStorage.getItem("token")
  }
  const navigate = useNavigate()
  const params = useParams()
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar()
  const [deleteId, setDeleteId] = React.useState(null);
  const [state1, setState1] = React.useState([]);
  const [state2, setState2] = React.useState([]);
  const [imageAddError, setImageAddError] = React.useState('');
  const [user, setUser] = useState('')
  const [newdata, setNewdata] = React.useState([]);
  const [deleteImagePath, setDeleteImagePath] = React.useState(null);
  const [addImageFrom, setaddImageFrom] = useState('YogaSchool')
  // const multipleImgChangeMultiple = (e, index) => {
  //   setImageAddError('');
  //   var fileObj = [];
  //   var fileArray = [];
  //   var filepath = [];

  //   fileObj.push(e.target.files)
  //   for (let i = 0; i <= fileObj[0].length; i++) {
  //     if (i < fileObj[0].length) {
  //       fileArray.push(URL.createObjectURL(fileObj[0][i]))
  //       filepath.push(fileObj[0][i])
  //     }
  //   }
  //   var data = state1.filter(x => x != null)
  //   var data1 = state2.filter(x => x != null)
  //   setState1(data)
  //   setState2(data1)
  //   setState1(prevValue => prevValue.concat(fileArray))
  //   setState2(prevValue => prevValue.concat(filepath))
  // }
  

  const multipleImgChangeMultiple = (e, index) => {
		setImageAddError('');
	
		// Validate file size
		const maxSize = 1024 * 1024; // 1MB in bytes
		const files = e.target.files;
		var fileObj = [];
		var fileArray = [];
		var filepath = [];
	
		for (let i = 0; i < files.length; i++) {
			if (files[i].size > maxSize) {
				// Handle file size error (for example, set an error state)
				setImageAddError('Image size should be less than 1 MB.');
				continue; // Skip this file
			}
			
			fileObj.push(files[i]);
			fileArray.push(URL.createObjectURL(files[i]));
			filepath.push(files[i]);
		}
	
		var data = state1.filter(x => x != null);
		var data1 = state2.filter(x => x != null);
		setState1(data);
		setState2(data1);
		setState1(prevValue => prevValue.concat(fileArray));
		setState2(prevValue => prevValue.concat(filepath));
	}

  const indexDelete = (e, index) => {
    var filteredpreview = state1.filter((value, i) => {
      return i !== index;
    });
    var filteredraw = state2.filter((val, i) => {
      return i !== index;
    });
    setState1(filteredpreview);
    setState2(filteredraw);
  }
  React.useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + `image/GetImagesByogaschoolidId?user_id=${params.id}`, { headers: headerlocal });
      console.log(response.data, "Image Data data");
      setNewdata(response.data.data);
    } catch (error) {
      console.error("Error fetching images:", error);
      enqueueSnackbar("Error fetching images", { variant: 'error' });
    }
  }

  const handleDeleteImage = async (imagePath) => {
    const body = {
        imgpath: imagePath
    };

    try {
        await axios.put(
            `${process.env.REACT_APP_BASE_URL}image/deleteImage/${deleteId}`,
            body,
            { headers: headerlocal }
        );
       
        enqueueSnackbar("Image deleted successfully", { variant: 'success' });
        getUser();
        handleDeleteDialogClose()

    } catch (error) {
        console.error("Error deleting image:", error);
        // enqueueSnackbar("Error deleting image", { variant: 'error' });
    }
  }

  const handleDeleteDialogOpen = (id, imagePath) => {
    setDeleteId(id); // Set the _id of the image to delete
    setDeleteImagePath(imagePath); // Set the image path
    setOpen(true); // Open the delete dialog
};



const handleDeleteDialogClose = () => {
    setDeleteId(null);
    setOpen(false);
};

  const handleAddNews = () => {
    var multipleImage = state2.filter(x => x != null);

    {
      const formData = new FormData();
      // Assuming params is an object containing an 'id' property
      // Assuming params is an object containing an 'id' property
      formData.append('addImageFrom',addImageFrom )
      formData.append('createdby[]', params && params.id);
      { multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '') }
      console.log([...formData], 'formdata in edit');
      axios.post(process.env.REACT_APP_BASE_URL + `image/addimage`, formData, { headers: headerformdata })
        .then((res) => {
          setState1('')
          navigate(`/yogaschool/details/image/${params.id}`);
          enqueueSnackbar("Additional images added successfully", { variant: 'success' })
          console.log(res, "added");
          getUser();
        })
        .catch((err) => {
          enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
          console.log(err, "error");
        })
    }
  }

  // const validatenew = () => {
  //   if (state2?.length === 0) {
  //     setImageAddError("This field is required.")
  //     return false
  //   }
  //   return true
  // }

  return (
    <Box className=''>
      {/* <MiniDrawer menuOrder={6} />
      <Sidebar subtype={5} /> */}
      <Box component="section" className="contentWraper">
        <Box className='whiteContainer p-20'>
          <Typography component='h6' className='formTitle'>Images</Typography>
          <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList">
                        {newdata && newdata.length > 0 ? newdata.map((data, index) => (
                          console.log(data,"dataaaaaa"),
                            <Grid key={index} item xs={12} sm={6} md={6} sx={{ marginTop: 2 }}>
                                <Card className="cardGrid">
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className="carosuel">
                                            {/* Render images side by side here */}
                                            {data && data.additionalimages && data.additionalimages?.length > 0 && data.additionalimages.map((tdata, i) => (
                                                <div key={i} className="imageContainer">
                                                    <img
                                                        width={'100%'}
                                                        height={250}
                                                        src={process.env.REACT_APP_BASE_URL + `${tdata}`}
                                                        style={{ marginRight: '5px' }}
                                                        alt={`Image ${i}`}
                                                    />
                                                    <IconButton onClick={() => handleDeleteDialogOpen(data._id, tdata)} className="deleteIcon">
                                                        <DeleteIcon />
                                                    </IconButton>

                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                </Card>
                            </Grid>
                        )) : (
                            // <Grid item xs={12} sm={12} md={12} lg={12}>
                            //     <Typography variant="body1" className='text-center semibold'>No data found</Typography>
                            // </Grid>
                            ""
                        )}
                    </Grid>

          <Box className="steppy_content mb-10">
            <Grid container columnSpacing={1} rowSpacing={1} className=" mb-10">
              <Grid item xs={12} sm={12} md={12}  >
                <Box className=" ">
                  <label className='form-labels ' for="my-input" >Additional Images</label>
                  {state1.length < 1 &&
                    <Button className="multiple_upload_btn" variant="contained" component="label">
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                      <input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChangeMultiple} hidden multiple type="file" />
                    </Button>
                  }
                </Box>
              </Grid>
              {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0 ml-20" >{imageAddError}</p>}
              <Grid item xs={12} sm={12} md={12}  >
                {state1.length > 0 &&
                  <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container">
                    {state1.length > 0 ? state1.map((x, i) => (
                      <Grid item xs={6} sm={6} md={4} lg={3} >
                        <Box className='uploaded_img' >
                          {/* <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button> */}
                          <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                        </Box>
                      </Grid>
                    )) : ""}
                    <Grid item xs={6} sm={6} md={4} lg={3} >
                      <label htmlFor="contained-button-file1" className="w-full">
                        <Box className='multiple_upload_btn' >
                          <Input
                            inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                            onChange={multipleImgChangeMultiple}
                            style={{ display: "none", }}
                            id="contained-button-file1"
                            type="file"
                          />
                          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                        </Box>
                      </label>
                    </Grid>
                  </Grid>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
              </Grid>

            </Grid>
          </Box>

          <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
            <Button onClick={handleAddNews} variant="contained" className='theme-btn'>Submit</Button>
          </Box>

        </Box >
      </Box >
      <Dialog
                        open={open}
                        keepMounted
                        onClose={handleDeleteDialogClose}
                        aria-describedby="alert-dialog-slide-description"
                        className='custom_modal'
                    >
                        <DialogTitle>Would you like to delete this image?</DialogTitle>
                        <DialogActions>
                            <Button onClick={handleDeleteDialogClose} className='cancel_btn'>No</Button>
                            <Button onClick={() => handleDeleteImage(deleteImagePath)} className='theme-btn'>Yes</Button>

                        </DialogActions>
                    </Dialog>
    </Box >
  )
}

export default Service_Steppyform;
