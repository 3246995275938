import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'

export default function AddLinks() {
//UseState's:
    let action = useLocation() 
    const state = action.state || {};
    const item = state.item;
    const from = state.from;

    console.log(state,"+state");

    React.useEffect(()=>{
        if(item){
            setFacebook(item.facebook)
            setInstagram(item.instagram)
            setTiktok(item.tiktok)
            setTwitter(item.twitter)
            setPinterest(item.pinterest)
            setWhatsapp(item.whatsapp)
        }
        else{
            setFacebook('')
            setInstagram('')
            setTiktok('')
            setTwitter('')
            setPinterest('')
            setWhatsapp('')
        }
    },[])


    let tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    const [facebook,setFacebook] = React.useState('')
    const [instagram,setInstagram] = React.useState('')
    const [twitter,setTwitter] = React.useState('')
    const [pinterest,setPinterest] = React.useState('')
    const [whatsapp,setWhatsapp] = React.useState('')
    const [tiktok,setTiktok] = React.useState('')
    const [error, setError] = React.useState({ name: "", description: "" })


//add links functionality:
const handleLink = () => {
    let body = {
        "instagram":instagram,
        "facebook": facebook,
        "twitter": twitter,
        "tiktok": tiktok,
        "pinterest":pinterest,
        "whatsapp":whatsapp
    }
    axios.post(process.env.REACT_APP_BASE_URL + 'SocailMediaLinks/SocialMediaLinks',body)
    .then((res)=>{
        enqueueSnackbar(from ? 'Social Media Links Updated Successfully' : 'Social Media Links Added Successfully',{variant:'success'})
        setFacebook('')
        setInstagram('')
        setTiktok('')
        setTwitter('')
        setPinterest('')
        setWhatsapp('')
        navigate('/viewlinks')
    })
    .catch((error)=>{
        enqueueSnackbar(error.response.data.message,{variant:'error'})
        console.log(error,"error in handleLink");
    })
}

return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={1} submenuOrder={2} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>{from== 'edit' ? "Edit Social Media Links" : "Add Social Media Links"}</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Facebook Link </Typography>
                                            {/* <Typography component='div' className='label'>Facebook Link <Typography component='span' className='star'>*</Typography></Typography> */}
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Facebook link'
                                                name="name"
                                                value={facebook}
                                                onChange={(e)=>setFacebook(e.target.value)}
                                            />
                                        </FormControl>    
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Instagram Link </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Instagram link'
                                                name="name"
                                                value={instagram}
                                                onChange={(e)=>setInstagram(e.target.value)}
                        
                                            />
                                        </FormControl>    
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Twitter Link </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Twitter link'
                                                name="name"
                                                value={twitter}
                                                onChange={(e)=>setTwitter(e.target.value)}
                                            />
                                        </FormControl>    
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Pinterest Link </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Pinterest link'
                                                name="name"
                                                value={pinterest}
                                                onChange={(e)=>setPinterest(e.target.value)}
                                            />
                                        </FormControl>    
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Whatsapp Link </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Whatsapp link'
                                                name="name"
                                                value={whatsapp}
                                                onChange={(e)=>setWhatsapp(e.target.value)}
                                            />
                                        </FormControl>    
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>TikTok Link </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Tiktok link'
                                                name="name"
                                                value={tiktok}
                                                onChange={(e)=>setTiktok(e.target.value)}
                        
                                            />
                                        </FormControl>    
                                    </Grid>
                                </Grid>

                                
                                
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='theme-btn' onClick={()=>handleLink()}>{from == 'edit' ? "Update" : "Submit"}</Button>
                                    </Box> 
                                    
                                
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}