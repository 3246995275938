import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../../../components/drawer'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Checkbox, ListItemText, FormControlLabel, Input, OutlinedInput, RadioGroup, Radio } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import EmailEditor from "react-email-editor";
import { usePlacesWidget } from "react-google-autocomplete";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import AutoComplete from './Autocomplete';
import Sample from './sample.json'
import Sidebar from './../../Sidebar'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function BpRadio(props) {
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	);
}
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#f3714d',
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#f3714d',
	},
});

const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 40,
	height: 20,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 0,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(20px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 18.5,
		height: 18.5,
	},
	'& .MuiSwitch-track': {
		borderRadius: 18.5 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .QontoStepIcon-completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .QontoStepIcon-circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;
	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#f3714d',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#f3714d',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundColor:
			'#f3714d',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
		backgroundColor:
			'#f3714d',
	}),
}));

function ColorlibStepIcon(props) {
	const { active, completed, className } = props;

	const icons = {
		1: <ListAlt />,
		2: <CompareIcon />,
		3: <Dvr />,
	};
	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};

const steps = ['Basic Information', 'Course Information', 'Course Description'];

export default function Service_Steppyform() {
	const params = useParams()
	const navigate = useNavigate()
	const currentDate = new Date();
	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}
	const [activeStep, setActiveStep] = React.useState(0);
	const { enqueueSnackbar } = useSnackbar()
	const [course, setCourse] = React.useState({ courseName: '', description: "", fees: "", owner: "", specialization: "", address: "" })
	const [truebutton, setTruebutton] = React.useState(false)
	const [error, setError] = React.useState({ courseName: "", status: "", start_date: "", end_date: "", video: "", location: "", fees: "", owner: "", specialization: "", address: "" })
	const [categoryId, setCategoryId] = React.useState('')
	const [subcategoryId, setSubCategoryId] = React.useState('')
	const [catError, setCatError] = React.useState('')
	const [subcatError, setSubCatError] = React.useState('')
	const [imageError, setImageError] = React.useState('')
	const [imageAddError, setImageAddError] = React.useState('')
	const [state1, setState1] = React.useState([])
	const [state2, setState2] = React.useState([])
	const [statesingle, setStatesingle] = React.useState([])
	const [statesingle2, setStatesingle2] = React.useState([])
	const [convertedContent, setConvertedContent] = React.useState(null);
	const [description, setDescription] = React.useState('')
	const [richeditor, setRichtor] = React.useState("")
	const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
	const dataofeditor = `${richeditor}`
	const [selectedFromDateTime, setSelectedFromDateTime] = useState(dayjs(new Date()));
	const [selectedToDateTime, setSelectedToDateTime] = useState(dayjs(new Date()));
	const [selectedVideo, setSelectedVideo] = useState([]);
	const [videoPreviewUrl, setVideoPreviewUrl] = useState([]);
	const [category, setCategory] = React.useState([])
	const [subcat, setSubcat] = React.useState([])
	const [cityName, setCityName] = useState('')
	const [lat, setLat] = useState(0.0)
	const [lng, setLng] = useState(0.0)
	const [status, setStatus] = React.useState('offline')
	const [isPublic, setPublic] = React.useState(false)
	const [isPrimary, setPrimary] = React.useState(false)
	const [selectGallert, setSelectGallery] = useState('Image')
	const [selectOwner, setSelectOwner] = useState('Teacher')
	const [teacher, setTeacher] = React.useState([])
	const [community, setCommunity] = React.useState([])
	const [addCourseFrom, setaddCourseFrom] = useState('YogaSchool')

	const auth = JSON.parse(localStorage.getItem('userData'))

	const emailEditorRef = useRef({ editor: {} });
	
	const [designEditor, setDesignEditor] = useState(Sample)
	const onLoad = () => {
		emailEditorRef.current.editor.loadDesign(designEditor);
	};

	const handleEditor = (e) => {
		const unlayer = emailEditorRef.current?.editor;
		unlayer?.exportHtml((data) => {
			const { design, html } = data;
			const sanitizedHtml = DOMPurify.sanitize(html);
			const editor = { "body": design.body }
			setDesignEditor(editor)
			enqueueSnackbar('Saved changes', { variant: 'info' });
		})
	}


	React.useEffect(() => {
		if (dataofeditor) {
			try {
				const contentState = stateFromHTML(dataofeditor);
				setEditorState(EditorState.createWithContent(contentState));
			} catch (error) {
				console.error("Error during HTML to ContentState conversion:", error);
			}
		}
	}, [dataofeditor]);

	const handleEditGallery = (e) => {
		setSelectGallery(selectGallert === 'Image' ? 'Video' : 'Image')
	}

	const handleEditOwner = (e) => {
		setSelectOwner(e.target.value);
		setTeacherName([]);
		setCommunityName([]);
	}

	React.useEffect(() => {
		getTeacher()
	}, [])

	const getTeacher = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachers`, { headers: headerlocal });
			setTeacher(response.data.teachers);
			// console.log(response, "teachers");
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedTeacher, setSelecttedTeacher] = React.useState([])
	const [teacherName, setTeacherName] = React.useState([])
	const handlechangeTeacher = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setTeacherName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getTeacherval()
	}
	React.useEffect(() => {
		getTeacherval()
	}, [teacherName])

	function getTeacherval() {
		var array = []
		const selecttedTeacher = teacher.filter(content => teacherName.includes(content.username));
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedTeacher.map((item, i) => {
			var val = { username: item.username }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedTeacher(array)
	}


	React.useEffect(() => {
		getCommunity()
	}, [])

	const getCommunity = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunity`, { headers: headerlocal });
			setCommunity(response.data.community);
			
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedCommunity, setSelecttedCommunity] = React.useState([])
	const [communityName, setCommunityName] = React.useState([])
	const handlechangeCommunity = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setCommunityName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getCommunityval()
	}
	React.useEffect(() => {
		getCommunityval()
	}, [communityName])

	function getCommunityval() {
		var array = []
		const selecttedCommunity = community.filter(content => communityName.includes(content.coummunity_name));		
		selecttedCommunity.map((item, i) => {
			var val = {
				coummunity_name
					: item.coummunity_name
			}
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedCommunity(array)
	}


	React.useEffect(() => {
		getCategory()
	}, [])

	React.useEffect(() => {
		getSubCategory()
	}, [categoryId])


	const getCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
			setCategory(res.data.category)
		}).catch((error) => {
			console.log(error.response, "error");
		})
	}
	const getSubCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
			.then((res) => { setSubcat(res.data.subcategory) }).catch((error) => {
				console.log(error.response, "error");
			})
	}

	const handleRemoveRestart = () => {
		setSubCategoryId('')
	}

	const handleTogglePublic = () => {
		setPublic(!isPublic)
	}
	const handleTogglePrimary = () => {
		setPrimary(!isPrimary)
	}

	let name, value;
	const handlechange = (e) => {
		name = e.target.name;
		value = e.target.value;
		setCourse({ ...course, [name]: value })
	}

	const multipleImgChange = (e, index) => {
		setImageAddError('');
	
		// Validate file size
		const maxSize = 1024 * 1024; // 1MB in bytes
		const files = e.target.files;
		var fileObj = [];
		var fileArray = [];
		var filepath = [];
	
		for (let i = 0; i < files.length; i++) {
			if (files[i].size > maxSize) {
				// Handle file size error (for example, set an error state)
				setImageAddError('Image size should be less than 1 MB.');
				continue; // Skip this file
			}
			
			fileObj.push(files[i]);
			fileArray.push(URL.createObjectURL(files[i]));
			filepath.push(files[i]);
		}
	
		var data = state1.filter(x => x != null);
		var data1 = state2.filter(x => x != null);
		setState1(data);
		setState2(data1);
		setState1(prevValue => prevValue.concat(fileArray));
		setState2(prevValue => prevValue.concat(filepath));
	}

	const indexDelete = (e, index) => {
		var filteredpreview = state1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = state2.filter((val, i) => {
			return i !== index;
		});
		setState1(filteredpreview);
		setState2(filteredraw);
	}

	const SingleImage = (e, index) => {
        setImageError('');
        setError('');
    
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
    
        fileObj.push(e.target.files);
    
        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);
    
            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);
    
                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };

	const indexDelete1 = (e, index) => {
		var filteredpreview = statesingle.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = statesingle2.filter((val, i) => {
			return i !== index;
		});
		setStatesingle(filteredpreview);
		setStatesingle2(filteredraw);
	}
	const handleNext = () => {
		var valid = validate()
		if (valid === true) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
		if (valid === true && validatenew === true) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};


	const validate = () => {		
		if (!course.courseName && !status && statesingle2?.length == 0 && !course.fees) {
			setError({ courseName: "This field is required.", status: "This field is required.", fees: "This field is required" })
			setCatError("This field is required.")
			setImageError("This field is required.")
			return false
		}
		if (!course.courseName) {
			setError({ courseName: "This field is required." })
			return false
		}
		if (!status) {
			setError({ status: "This field is required." })
			return false
		}
		if (!categoryId) {
			setCatError("This field is required.")
		}
		if (selectedFromDateTime && selectedToDateTime && selectedFromDateTime >= selectedToDateTime) {
			if (selectedFromDateTime.isSame(selectedToDateTime, 'minute')) {
				setError(prevError => ({
					...prevError,
					end_date: "End date cannot be same as start date."
				}));
			} else {
				setError(prevError => ({
					...prevError,
					end_date: "End date must be after the start date."
				}));
			}
			return false;
		}
		if (!course.fees) {
			setError({ fees: "This field is required." })
			return false
		}
		// if (!course.owner) {
		// 	setError({ owner: "This field is required." })
		// 	return false
		// }
		// if (!course.specialization) {
		// 	setError({ specialization: "This field is required." })
		// 	return false
		// }
		// if (!course.address) {
		// 	setError({ address: "This field is required." })
		// 	return false
		// }
		// if (!cityName) {
		// 	setError({ location: "This field is required." })
		// 	return false
		// }
		// if (currentDate <= selectedFromDateTime ) {
		// 	setError({ fromTime: "Please select a date and time greater than the present date and time." })
		// 	return false
		// }
		// if (selectedFromDateTime < selectedToDateTime ) {
		// 	setError({ toTime: "Please select a date and time greater than the from date and time." })
		// 	return false
		// }
		if (statesingle2?.length == 0) {
			setImageError("This field is required.")
			return false
		}

		return true
	}

	const validatenew = () => {
		// if (state2?.length == 0 && selectedVideo?.length == 0) {
		// 	setError({ video: "This field is required." })
		// 	setImageAddError("This field is required.")
		// 	return false
		// }
		// if (state2?.length == 0) {
		// 	setImageAddError("This field is required.")
		// 	return false
		// }
		// if (selectedVideo?.length == 0) {
		// 	setError({ video: "This field is required." })
		// 	return false
		// }
		// return true
	}


	const handleAddService = () => {
		var multipleImage = state2.filter(x => x != null);
		var valid = validate()
		// var validnew = validatenew()

		if (valid == true) {
			const unlayer = emailEditorRef.current?.editor;
			unlayer?.exportHtml((data) => {
				const { design, html } = data;
				const sanitizedHtml = DOMPurify.sanitize(html);
				setTruebutton(true)
				const formData = new FormData();
				// formData.append("createdby", params && params.id)
				formData.append('addCourseFrom','YogaSchool' )
				formData.append('createdby[]', params && params.id);
				formData.append("course_name", course.courseName)
				formData.append("status", status)
				formData.append("description", sanitizedHtml)
				formData.append("start_date", selectedFromDateTime)
				formData.append("end_date", selectedToDateTime)
				formData.append("fees", course.fees)
				formData.append('teacher', JSON.stringify(selecttedTeacher.length > 0 ? selecttedTeacher : []))
				formData.append('community', JSON.stringify(selecttedCommunity.length > 0 ? selecttedCommunity : []))
				// formData.append("specialization", course.specialization)
				// formData.append("address", course.address)
				formData.append("location_coord", cityName)
				formData.append("latitude", lat)
				formData.append("longitude", lng)
				formData.append("formatted_address", cityName)
				formData.append("shortDescription", description)
				formData.append("Category_id", categoryId)
				formData.append("Subcategory_id", subcategoryId)
				formData.append("DescriptionJSON", design && JSON.stringify(design.body))
				formData.append("public", isPublic)
				formData.append("primary", isPrimary)
				formData.append("courseby", selectOwner === 'Teacher' ? 'Teacher' : 'Community');
				formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')
				{ statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
				{
					selectGallert === 'Image' ? <>
						{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
						{selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
					</>
						:
						<>
							{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
							{selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}</>
				}

				// function convertLatLongToNumbers(formData) {
				// 	const tempData = {};
				// 	for (let pair of formData.entries()) {
				// 		const key = pair[0].trim();
				// 		let value = pair[1];
				// 		if (key === 'latitude' || key === 'longitude') {
				// 			value = parseFloat(value);
				// 		}
				// 		tempData[key] = value;
				// 	}
				// 	formData = new FormData();
				// 	for (const [key, value] of Object.entries(tempData)) {
				// 		formData.append(key, value);
				// 	}
				// 	return formData;
				// }
				// const convertedFormData = convertLatLongToNumbers(formData);
				console.log([...formData], 'formdata in course');
				axios.post(process.env.REACT_APP_BASE_URL + `course/addCourse`, formData, { headers: headerformdata }).then((res) => {
					setTruebutton(false)
					navigate(`/yogaschool/details/course/${params && params.id}`);
					setCourse({ courseName: '', description: "", fees: "", owner: "" })
					setCategoryId('');
					setSubCategoryId('');

					setState1([])
					setState2([])
					enqueueSnackbar("Course added successfully", { variant: 'success' })
					console.log(res, "course added");
				}).catch((err) => {
					setTruebutton(false)
					enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
					console.log(err, "error");
				})
			})
		}
	}

	const handleFromDateTimeChange = (newValue) => {
		console.log(newValue);
		setSelectedFromDateTime(newValue);
		setError(prevError => ({ ...prevError, start_date: '' }))
	};
	const handleToDateTimeChange = (newValue) => {
		setSelectedToDateTime(newValue);
		setError(prevError => ({ ...prevError, end_date: '' }))
	};

	// const handleVideoChange = (e, index) => {
	// 	setError(prevError => ({ ...prevError, video: '' }))
	// 	var fileObj = [];
	// 	var fileArray = [];
	// 	var filepath = [];
	// 	fileObj.push(e.target.files)
	// 	for (let i = 0; i <= fileObj[0].length; i++) {
	// 		if (i < fileObj[0].length) {
	// 			fileArray.push(URL.createObjectURL(fileObj[0][i]))
	// 			filepath.push(fileObj[0][i])
	// 		}
	// 	}
	// 	setVideoPreviewUrl(fileArray)
	// 	setSelectedVideo(filepath)
	// }

	const handleVideoChange = (e, index) => {
		setError({ video: '' });	
			var fileObj = [];
		var fileArray = [];
		var filepath = [];
		fileObj.push(e.target.files);
		for (let i = 0; i < fileObj[0].length; i++) {
			if (fileObj[0][i].size > 25 * 1024 * 1024) { // Corrected comparison
				setError({ video: 'Video should be less than 25 MB' });
				return;
			}
			fileArray.push(URL.createObjectURL(fileObj[0][i]));
			filepath.push(fileObj[0][i]);
		}
	
		setVideoPreviewUrl(fileArray);
		setSelectedVideo(filepath);
	}

	const clearVideo = () => {
		setSelectedVideo([]);
		setVideoPreviewUrl([]);
	};

	// const { ref, autocompleteRef } = usePlacesWidget({
	// 	// need to change this API key 
	// 	apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
	// 	onPlaceSelected: (place) => {
	// 		setCityName(place.formatted_address)
	// 		setLat(place.geometry.location.lat());
	// 		setLng(place.geometry.location.lng());
	// 		setError(prevError => ({ ...prevError, location: '' }))
	// 	},
	// 	options: {
	// 		types: "(regions)" | 'establishment'
	// 	},
	// });
	const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

	console.log(selectGallert, 'selectGallertselectGallertselectGallert');

	return (
		<Box className='withSubmenu'>
            <MiniDrawer menuOrder={2} submenuOrder={4} />
            <Sidebar subtype={3} />
			<Box component="section" className="contentWraper">
				<Box className='whiteContainer p-20'>
					<Typography component='h6' className='formTitle mb-20'>Add Course</Typography>

					<Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					{activeStep === 0 &&
						<Box className='steppy_content'>
							<Box className="maxw_700">
								<Box className="mb-20">
									<Typography component='div' className='label'>Course Name <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											size="small"
											name='courseName'
											placeholder='Name'
											className='textfield'
											style={{ width: '100%' }}
											value={course.courseName}
											onChange={(e) => {
												handlechange(e); setError(prevError => ({ ...prevError, courseName: '' }))
											}}
										/>
										{error.courseName && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.courseName}</p>}
									</FormControl>
								</Box>

								<Box className='mb-20'>
									<FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isPublic}
												onChange={handleTogglePublic}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to make this event as Public? </Typography>}
									/>
									<FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isPrimary}
												onChange={handleTogglePrimary}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to make this event as Primary? </Typography>}
									/>
								</Box>

								<Box className='mb-20'>
									<Typography component='div' className='label'>Select Mode<Typography component='span' className='star'>*</Typography></Typography>
									<FormControl fullWidth >
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={status ? status : "sel"}
											placeholder="Select mode of course"
											className="select"
											onChange={(e) => { setStatus(e.target.value) }}
										>
											<MenuItem disabled value={'sel'}>Select status of course</MenuItem>
											<MenuItem value={"online"}>Online</MenuItem>
											<MenuItem value={"offline"}>Offline</MenuItem>
										</Select>
										{error.status && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.status}</p>}
									</FormControl>
								</Box>

								<Box className="mb-20">
									<Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl fullWidth >
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={categoryId ? categoryId : 'sel'}
											onChange={(e) => { setCategoryId(e.target.value); setCatError("") }}>
											<MenuItem disabled value={'sel'} >Select category</MenuItem>
											{category && category?.length > 0 ? category.map((data) => (
												<MenuItem value={data._id}>{data.category_title}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>}
										</Select>
										{catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography component='div' className='label' >
										Select Sub Category
									</Typography>
									<FormControl fullWidth style={{ flex: 1 }}>
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											disabled={categoryId !== '' ? false : true}
											value={subcategoryId ? subcategoryId : 'sel18'}
											onChange={(e) => {
												setSubCategoryId(e.target.value);
												setSubCatError('');
												setCatError('');
												setSubCatError('');
											}}
										>
											<MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
											{subcat && subcat?.length > 0 ? (
												subcat.map((data5) => (
													<MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
												))) : (<MenuItem disabled>{"No Record's found"}</MenuItem>)}
										</Select>
										{subcatError && (<p style={{ color: 'red' }} className="fz-13 m-0">{subcatError}</p>)}
									</FormControl>
									{subcategoryId !== '' && (
										<RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
									)}
								</Box>


								<Box className="mb-20">
									<Typography component='div' className='label'>Start Date <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth className="datepicker">
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
												<DateTimePicker
													onChange={handleFromDateTimeChange}
													value={selectedFromDateTime && selectedFromDateTime}
													format="DD-MM-YYYY hh:mm A"
													minDate={dayjs()}
													ampm={true}
													viewRenderers={{
														hours: renderTimeViewClock,
														minutes: renderTimeViewClock,
														seconds: renderTimeViewClock,
													}}
													className="textfield"
												/>
											</DemoContainer>
										</LocalizationProvider>
										{error.start_date && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.start_date}</p>}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography component='div' className='label'>End Date<Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth className="datepicker">
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
												<DateTimePicker
													size="small"
													onChange={handleToDateTimeChange}
													value={selectedToDateTime && selectedToDateTime}
													format="DD-MM-YYYY hh:mm A"
													minDate={dayjs(selectedFromDateTime)}
													ampm={true}
													viewRenderers={{
														hours: renderTimeViewClock,
														minutes: renderTimeViewClock,
														seconds: renderTimeViewClock,
													}}
													className="textfield"
												/>
											</DemoContainer>
										</LocalizationProvider>
										{error.end_date && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.end_date}</p>}
									</FormControl>
								</Box>



								<Box className="mb-20">
									<Typography component='div' className='label'>Fees <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Fees'
											size="small"
											name='fees'
											onInput={(e) => onlyNumbers(e)}
											className='textfield'
											style={{ width: '100%' }}
											value={course.fees}
											onChange={(e) => {
												handlechange(e); setError(prevError => ({ ...prevError, fees: '' }))
											}}
										/>
										{error.fees && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.fees}</p>}
									</FormControl>
								</Box>

								{/* <Box className="mb-20 ">
									<Typography component='div' className='label'>Teacher/Community</Typography>
									<RadioGroup
										row
										aria-labelledby="demo-customized-radios"
										name="payment_type"
										onChange={handleEditOwner}
										value={selectOwner}
									>
										<FormControlLabel
											value="Teacher"
											className='radio-btns'
											control={<BpRadio />}
											label={<Typography className='fz-14'>Teacher</Typography>}
										/>
										<FormControlLabel
											value="Community"
											className='radio-btns'
											control={<BpRadio />}
											label={<Typography className='fz-14'>Community</Typography>}
										/>
									</RadioGroup>
								</Box> */}

								{/* {selectOwner === 'Teacher' ? <>
									<Box className="mb-20">
										<label className='form-labels mb-0' for="my-input">Select Teacher</label>
										<FormControl fullWidth>
											<Select
												labelId="demo-multiple-checkbox-label"
												id="demo-multiple-checkbox"
												multiple
												className='select'
												value={teacherName}
												onChange={(e) => handlechangeTeacher(e,)}
												input={<OutlinedInput />}
												renderValue={(selected) => {
													const selecttedTeacher = teacher.filter(content => selected.includes(content.username));
													if (selecttedTeacher.length === 0) {
														return "Any Teacher";
													} else if (selecttedTeacher.length === 1) {
														return selecttedTeacher[0].username;
													} else {
														return `${selecttedTeacher.length} Teacher selected`;
													}
												}}
												MenuProps={MenuProps}
											>
												{teacher && teacher.length > 0 ? teacher.map((name) => (
													<MenuItem key={name._id} value={name.username} >
														<Checkbox checked={teacherName.indexOf(name.username) > -1} />
														<ListItemText primary={name.username} />
													</MenuItem>
												)) : <MenuItem>No teacher found</MenuItem>}
											</Select>
											{/* <p className='error-field'>{error.userselect}</p> */}
										{/* </FormControl> */}
									{/* </Box> */}
								{/* </> : <> */}
									{/* <Box className="mb-20">
										<Typography component='div' className='label'>Select Community</Typography>
										<FormControl fullWidth>
											<Select
												labelId="demo-multiple-checkbox-label"
												id="demo-multiple-checkbox"
												multiple
												className='select'
												value={communityName}
												onChange={(e) => handlechangeCommunity(e,)}
												input={<OutlinedInput />}
												renderValue={(selected) => {
													const selecttedCommunity = community.filter(content => selected.includes(content.coummunity_name
													));
													if (selecttedCommunity.length === 0) {
														return "Any Community";
													} else if (selecttedCommunity.length === 1) {
														return selecttedCommunity[0].coummunity_name
															;
													} else {
														return `${selecttedCommunity.length} Community selected`;
													}
												}}
												MenuProps={MenuProps}
											>
												{community && community.length > 0 ? community.map((name) => (
													<MenuItem key={name._id} value={name.coummunity_name
													} >
														<Checkbox checked={communityName.indexOf(name.coummunity_name) > -1} />
														<ListItemText primary={name.coummunity_name
														} />
													</MenuItem>
												)) : <MenuItem>No community found</MenuItem>}
											</Select>
											{/* <p className='error-field'>{error.userselect}</p> */}
										{/* </FormControl> */}
									{/* </Box>  */}
								{/* </>} */}





								{status === 'offline' &&
									<Box className="mb-20">
										<Typography component='div' className='label'>Location</Typography>
										<AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} />
									</Box>}

								<Box className="mb-20">
								<Typography component='div' className='label'>Cover Image <Typography component='span' className='star'>*</Typography></Typography>
									<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
										{statesingle.length < 1 &&
											<Button className="multiple_upload_btn" variant="contained" component="label">
												<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
												<input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
											</Button>
										}
									</Box>
								</Box>
								{imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
								<Box className="mb-20">
									{statesingle.length > 0 &&
										<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
											{statesingle.length > 0 ? statesingle.map((x, i) => (
												<Grid item xs={6} sm={4} md={4} lg={4} >
													<Box className='uploaded_img' >
														<Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
														<img src={x} alt='' height={155} width={195} />
													</Box>
												</Grid>
											)) : ""}
										</Grid>
									}
								</Box>
								<Box className="mb-20">
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
								</Box>

								<Box className="mb-20">
									<Typography component='div' className='label'>Short Description </Typography>
									<FormControl variant="standard" fullWidth>
										<textarea
											placeholder='Short Description '
											className='textarea'
											defaultValue={description}
											onChange={(e) => setDescription(e.target.value)}
											rows={4}
											cols={40} />
									</FormControl>
								</Box>
								{
									activeStep === 0 &&
									<>
										<Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
											<Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
										</Box>
									</>
								}
							</Box>
						</Box >
					}
					{activeStep === 1 &&
						<Box className="steppy_content mb-10">
							<Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">

								<Grid item xs={12} sm={4} md={4} lg={4}>
									<Typography component='div' className='label'>Images/Video </Typography>

								</Grid>
								<Grid item xs={12} sm={8} md={8} lg={8}>
									<Box className=" ">
										<RadioGroup
											row
											aria-labelledby="demo-customized-radios"
											name="payment_type"
											onChange={handleEditGallery}
											value={selectGallert}
										>
											<FormControlLabel value="Image" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Additional images</Typography>} />
											<FormControlLabel value="Video" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Video</Typography>} />
										</RadioGroup>
									</Box>
								</Grid>
								{selectGallert === 'Image' ? <>
									<Grid item xs={12} sm={12} md={12}  >
										<Typography component='div' className='label'>Additional Images</Typography>
										<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
											{state1.length < 1 &&
												<Button className="multiple_upload_btn" variant="contained" component="label">
													<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
													<input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden multiple type="file" />
												</Button>
											}
										</Box>
									</Grid>
									<Grid item xs={12} sm={12} md={12}  >
										{state1.length > 0 &&
											<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
												{state1.length > 0 ? state1.map((x, i) => (
													<Grid item xs={6} sm={4} md={3} lg={3} >
														<Box className='uploaded_img' >
															<Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
															<img src={x} alt='' height={155} width={195} />
														</Box>
													</Grid>
												)) : ""}
												<Grid item xs={6} sm={4} md={3} lg={3} >
													<label htmlFor="contained-button-file1" className="w-full">
														<Box className='multiple_upload_btn' >
															<Input
																inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
																onChange={multipleImgChange}
																style={{ display: "none", }}
																id="contained-button-file1"
																type="file"
															/>
															<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
														</Box>
													</label>
												</Grid>
											</Grid>}
									</Grid>
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
									{imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}
								</> : <>
									<Grid item xs={12} sm={12} md={12}>
									<Typography component='div' className='label'>Video</Typography>

										<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
											{selectedVideo && selectedVideo?.length === 0 &&
												<Button className="multiple_upload_btn" variant="contained" component="label">
													<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
														<FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
													</Box>
													<input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
												</Button>
											}
											<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
												{selectedVideo && selectedVideo?.length > 0 &&
													<Grid item xs={6} sm={4} md={4} lg={4} >
														<Box className="uploaded_vdo" >
															<Button className='close_icon' onClick={clearVideo}><Close /></Button>
															<video className='uploaded_vdo' controls>
																<source src={videoPreviewUrl} type={selectedVideo.type} />
															</video>
														</Box></Grid>}
											</Grid>
										</Box>
										{error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}
									</Grid>


								</>}

								{/* <Grid item xs={12} sm={12} md={6} lg={6} className='mb-10'>
                                    <Typography component='div' className='label'>Description</Typography>
                                    <div className="editorclass">
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={handleEditorChange}
                                            toolbarClassName="toolbar"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorcontent"

                                            toolbar={{
                                                options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline'],
                                                },
                                                blockType: {
                                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                },
                                                list: {
                                                    options: ['unordered', 'ordered'],
                                                },
                                                textAlign: {
                                                    options: ['left', 'center', 'right', 'justify'],
                                                },
                                                link: {
                                                    options: ['link'],
                                                },
                                                emoji: {
                                                    options: [
                                                        '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                                                        '😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
                                                    ],
                                                },
                                                image: {
                                                    uploadEnabled: true,
                                                    previewImage: true,
                                                    inputAccept: 'image/*',
                                                    alt: { present: false, mandatory: false },
                                                },
                                            }}
                                        />
                                    </div>
                                </Grid> */}
								{
									activeStep === 1 &&
									<Grid item xs={12} sm={12} md={12}>

										<Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
											<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>

											<Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
										</Box>
									</Grid>
								}


							</Grid>
						</Box>
					}
					{activeStep === 2 &&
						<Box className="steppy_content mb-10">
							<Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
								<EmailEditor ref={emailEditorRef}
									onLoad={onLoad}
									projectId={144031}
									tools={{
										"custom#product_tool": {
											// data: { products },
											// properties: {
											// 	productLibrary: {
											// 		editor: {
											// 			data: {
											// 				products,
											// 			},
											// 		},
											// 	},
											// },
										},
									}}
									options={{
										designTags: {
											business_name: "My business",
											current_user_name: "Ron",
										},
										customCSS: [
											"https://examples.unlayer.com/examples/product-library-tool/productTool.css",
										],
										customJS: [
											window.location.protocol + "//" +
											window.location.host + "/custom.js",
										],
									}}
								/>
							</Grid>
							<Box className='heading-btns mt-20' style={{ textalign: 'end' }}>
								<Button onClick={handleEditor} variant="contained" className='theme-btn  mr-10'>SaveDesign</Button>
							</Box>
						</Box>
					}

					<>
						{activeStep === 2 && truebutton === false ?
							<>
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
									<Button onClick={handleAddService} variant="contained" className='theme-btn'>Submit</Button>
								</Box>
							</>
							:
							activeStep === 2 && truebutton === true &&
							<>
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
									<Button variant="contained" className='theme-btn  mr-10'>Please wait...</Button>
								</Box>
							</>
						}
					</>


				</Box >
			</Box >
		</Box >
	)
}