import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, Avatar, Input, TextField, FormControlLabel, RadioGroup, Radio, Typography, IconButton, TextareaAutosize } from '@mui/material'
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'
import Switch from '@mui/material/Switch';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import PropTypes from 'prop-types';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { usePlacesWidget } from "react-google-autocomplete";
import AutoComplete from "./AutoComplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#f3714d',
    },
});

export default function AddMerit() {
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let location = useLocation()
    var data = location.state ? location.state.result : null
    const [truebutton, setTruebutton] = React.useState(false)
    const [user, setUser] = React.useState({ firstname: '', specialization: "" });
    const [error, setError] = React.useState({ firstname: "", image: "", specialization: "", link: "", location: "" })
    const [isToggled, setToggled] = React.useState(false)
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [cityName, setCityName] = React.useState('')
    const [lat, setLat] = React.useState('')
    const [lng, setLng] = React.useState('')
    const [link, setLink] = React.useState('')
    const [merit, setMerit] = React.useState(true)

    let username, uservalue;
    const handleChange = (e) => {
        const hasAlphanumeric = /[a-zA-Z]/;
        username = e.target.name;
        uservalue = e.target.value;

        setError({ ...error, [username]: '' })
        setError('')
        if (username === 'location') {
            setCityName(uservalue);
            setError({ ...error, location: '' });
        }
        if (username === 'specialization') {
            if (!hasAlphanumeric.test(uservalue)) {
                setError({ ...error, specialization: "Specialization field must contain at least one letter." });
            } else {
                setError({ ...error, specialization: "" });
            }
        }
        setUser({ ...user, [username]: uservalue })

        if (username === 'firstname') {
            if (!hasAlphanumeric.test(uservalue)) {

                if(/^[^a-zA-Z0-9]+$/.test(uservalue)){
          
                    setError({...error, firstname: "Name should not contain only special characters" })
                    return false
                }
                if (/^\d+$/.test(uservalue)) {
                    setError({...error, firstname: "Name should not be only numbers" })
                    return false
                    
                  }
                // setError({ ...error, firstname: "Name field can't contain only numbers and special characters." });
            } else {
                setError({ ...error, firstname: "" });
            }
        }
    };

    const SingleImage = (e, index) => {
        setError('');
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            let file = fileObj[0][i];
            // Check if file size is less than 1MB (1000000 bytes)
            if (file.size > 1000000) {
                setError({ ...error, image: "Image should be less than 1 MB" });
                continue; // Skip adding this file to state
            }

            fileArray.push(URL.createObjectURL(file));
            filepath.push(file);
        }

        var data = statesingle.filter(x => x != null);
        var data1 = statesingle2.filter(x => x != null);

        setStatesingle(data);
        setStatesingle2(data1);
        setStatesingle(prevValue => prevValue.concat(fileArray));
        setStatesingle2(prevValue => prevValue.concat(filepath));
    };


    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }
    const handleaddphoto = (e) => {
        setUser({ ...user, Imageprew: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] })
        setError('')
    }

    const handleToggle = () => {
        setToggled(!isToggled)
    }

    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            setCityName(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setError(prevError => ({ ...prevError, location: '' }))
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });

    const urlRegex = /^(ftp|http[s]?):\/\/(?:www\.)?([^\s.]+\.[^\s]{2,}|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(:\d+)?(\/\S*)?$/;
    const validation = () => {
        const hasAlphanumeric = /[a-zA-Z]/;

        if (!user.firstname && statesingle2?.length == 0 && !cityName) {
            setError({ firstname: "This field is required.", image: "This field is required.", location: "This field is required.", link: "This field is required" })
            return false
        }
        else if (!user.firstname) {
            setError({ firstname: "First name can not be empty" })
            return false
        }

        else if (!hasAlphanumeric.test(user.firstname)) {
            setError({ firstname: "Name field can't contain only numbers and special characters." });
            return false
        }
        // else if (statesingle2?.length == 0) {
        //     setError({ image: "Image cannot be empty" })
        //     return false
        // }
        else if (user.specialization && !hasAlphanumeric.test(user.specialization)) {
            setError({ specialization: "Specialization field must contain at least one letter." });
            return false
        }
        else if (!cityName) {
            setError({ ...error, location: "Location can not be empty." });  // Update location error
            return false;
        }
        else if (!link) {
            setError({ link: "link can not be empty" })
            return false
        }
        else if (!urlRegex.test(String(link))) {
            setError({ link: "Please enter a valid URL, example:https://www.abc.com/" })
            return false
        }

        return true
    }

    const addMeritDetails = () => {
        const validator = validation()
        if (validator == true) {
            setTruebutton(true)
            const formData = new FormData();
            { statesingle2 ? statesingle2.forEach((item) => { formData.append("image", item) }) : formData.append("image", '') }
            formData.append("name", user.firstname);
            formData.append("merit", merit);
            formData.append("specialization", user.specialization);
            formData.append("link", link); // Corrected typo in "link" key
            formData.append("address", cityName); // Corrected typo in "location" key
            for (const value of formData.values()) {
                console.log(value);
            }
            axios.post(process.env.REACT_APP_BASE_URL + `Admin/addyogaMerits`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
            
                    setUser({ firstname: '', specialization: "" });
                    setLink('')
                    setStatesingle('')
                    setCityName('')
                    navigate('/teachermerit')
                })
                .catch((err) => {
                    setTruebutton(false)
                    console.error("Error occurred:", err);
                    if (err.response) {
        
                        enqueueSnackbar("Server responded with an error", { variant: 'error' });
                    } else if (err.request) {
                        console.error("Request made but no response received:", err.request);
                        enqueueSnackbar("No response received from the server", { variant: 'error' });
                    } else {
                        console.error("Error setting up the request:", err.message);
                        enqueueSnackbar("Error setting up the request", { variant: 'error' });
                    }
                });
        }
    }

    return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={2} submenuOrder={3} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Teachers of Great Merit</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                        {error.firstname && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.firstname}</p>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}  >
                                        <Box className="flex-center flex-between">
                                            <Typography component='div' className='label'>Primary Image </Typography>
                                            <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                {statesingle.length < 1 &&
                                                    <Button className="upload_btn" variant="contained" component="label">
                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                        <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                                    </Button>}
                                            </Box>
                                        </Box>
                                        {error.image && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.image}</p>}

                                    </Grid>
                                    {statesingle.length > 0 &&
                                        <Grid item xs={12} sm={12} md={12}  >
                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                {statesingle.length > 0 ? statesingle.map((x, i) => (
                                                    <Grid item xs={6} sm={4} md={4} lg={4} >
                                                        <Box className='uploaded_img' >
                                                            <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                            <img src={x} alt='' height={155} width={195} />
                                                        </Box>
                                                    </Grid>
                                                )) : ""}
                                            </Grid>
                                        </Grid>}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Tagline/Specialization</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='specialization'
                                                className='textfield'
                                                placeholder='Specialization'
                                                value={user.specialization}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    {error.specialization && <p style={{ color: 'red' }} className="fz-13 m-0">{error.specialization}</p>}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Location<Typography component='span' className='star'>*</Typography></Typography>
                                            <AutoComplete
                                                cityName={cityName}
                                                setCityName={setCityName}
                                                lat={lat}
                                                setLat={setLat}
                                                lng={lng}
                                                setLng={setLng}
                                                className="textfield"
                                                name='location'
                                            />
                                            {error.location && <p style={{ color: 'red' }} className="fz-13 m-0">{error.location}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Website Link <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                placeholder='Link'
                                                size="small"
                                                name='link'
                                                className='textfield'
                                                style={{ width: '100%' }}
                                                value={link}
                                                onChange={(e) => {
                                                    setLink(e.target.value); setError('')
                                                }}
                                            />
                                        </FormControl>
                                        {error.link && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.link}</p>}
                                    </Grid>
                                </Grid>
                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn ' onClick={addMeritDetails}>Save</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn '>Please Wait</Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}