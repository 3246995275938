

import React, { useEffect, useRef, useState } from "react";
import axios from 'axios'
import { Box, Button, Grid } from '@mui/material';
import DOMPurify from 'dompurify';
import EmailEditor from "react-email-editor";
import { useCommunity } from "../Context/CommunityContext";
import { useSnackbar } from 'notistack'
import Sample from "../sample.json"
import { useNavigate } from "react-router-dom";

export default function Desc({ params }) {
   
	const auth = JSON.parse(localStorage.getItem("userData"))
    console.log(auth)
    const [communityData, setCommunityData] = useState(null)
    
    useEffect(() => {
        // Retrieve and parse community data from local storage when the component mounts
        const communityDataString = localStorage.getItem('communityData');
        if (communityDataString) {
            setCommunityData(JSON.parse(communityDataString));
        }
    }, []);
	console.log(params, 'paramschild1');
	console.log(communityData)

    console.log(params)

    const { editorJSON, fetchCommunity } = useCommunity();
    // const [descJson, setDescJson] = React.useState(location.state.data.DescriptionJSON)
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const [buttondisable, setButtondisable] = React.useState(false)
    const [designEditor, setDesignEditor] = React.useState(Sample)
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const [richeditor, setRichtor] = React.useState("")
    const dataofeditor = `${richeditor}`

    const unlayerSampleJSON = {
        "body": editorJSON && JSON.parse(editorJSON),
    }
    const emailJSON = unlayerSampleJSON;
    React.useEffect(() => {
        fetchCommunity(params.id);
    }, [params.id]);

    const emailEditorRef = useRef({ editor: {} });

    const onLoad = () => {
        emailEditorRef.current.editor.loadDesign(designEditor);
    };

    // React.useEffect(() => {
    //     if (dataofeditor) {
    //         try {
    //             const contentState = stateFromHTML(dataofeditor);
    //             setEditorState(EditorState.createWithContent(contentState));
    //         } catch (error) {
    //             console.error("Error during HTML to ContentState conversion:", error);
    //         }
    //     }
    // }, [dataofeditor]);

    const handleEditor = () => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml((data) => {
            const { design, html } = data;
            const sanitizedHtml = DOMPurify.sanitize(html);
            const editor = { "body": design.body };
            setDesignEditor(editor); // Update local state with the edited design
            enqueueSnackbar('Saved changes', { variant: 'info' });
        });
    };


    const handleUpdateCommunity = async () => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml((data) => {
            const { design, html } = data;
            const sanitizedHtml = DOMPurify.sanitize(html);
            let body = {
                description: sanitizedHtml,
                DescriptionJSON: design && JSON.stringify(design.body)
            };
            axios.post(process.env.REACT_APP_BASE_URL + `community/updateDetails/${communityData?._id}`, body, { headers: headerlocal })
                .then((res) => {
                    enqueueSnackbar("Community updated successfully", { variant: 'success' });
                    // Optionally trigger a fetch after successful update
                    // fetchCommunity(params.id);
                    // navigate(`/teachers/details/community/${params.id}`)
                })
                .catch((err) => {
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
                    console.error(err, "error");
                });
        });
    };


    return (
        <Box sx={{ width: '100%' }}>
            <Box className="steppy_content mb-10">
                <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
                    <EmailEditor ref={emailEditorRef}
                        onLoad={onLoad}
                        projectId={144031}
                        tools={{
                            "custom#product_tool": {
                                // data: { products },
                                // properties: {
                                // 	productLibrary: {
                                // 		editor: {
                                // 			data: {
                                // 				products,
                                // 			},
                                // 		},
                                // 	},
                                // },
                            },
                        }}
                        options={{
                            designTags: {
                                business_name: "My business",
                                current_user_name: "Ron",
                            },
                            customCSS: [
                                "https://examples.unlayer.com/examples/product-library-tool/productTool.css",
                            ],
                            customJS: [
                                window.location.protocol + "//" +
                                window.location.host + "/custom.js",
                            ],
                        }}
                    />
                </Grid>
                <Box className='flex-center justify-content-right mt-20' >
                    <Button onClick={handleEditor} variant="contained" className='theme-btn  mr-10'>Save Design</Button>
                    {buttondisable === false ? 
                    <Button variant="contained" onClick={handleUpdateCommunity} className='theme-btn' >Submit</Button> :
                     <Button variant="contained" className='theme-btn'>Please wait...</Button>}
                </Box>
            </Box>
        </Box>
    );
}