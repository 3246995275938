import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, Avatar, Input, TextField, FormControlLabel, RadioGroup, Radio, Typography, IconButton, TextareaAutosize } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined, AddAPhoto } from '@mui/icons-material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'
import Avatarimage from "../../../assets/images/Avatar.png"
import { locale } from 'moment';


export default function AddCategory() {
    let tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let location = useLocation()
    var data = location.state ? location.state.result : null
    const [category, setCategory] = React.useState({ title: "", description: "" })
    const [error, setError] = React.useState({ title: "", description: "" })
    const [truebutton, setTruebutton] = React.useState(false)


    let name, value;
    const handleCategory = (e) => {
        name = e.target.name;
        value = e.target.value
        setCategory({ ...category, [name]: value })
    }
    const Validator = () => {
        if (!category.title) {
            setError({ title: "This field is required." })
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        var Valid = Validator()
        if (Valid) {
            setTruebutton(true)

            let body = {
                createdby: auth && auth._id,
                category_title: category.title.toString(),
                description: category.description.toString(),
            }
            axios.post(process.env.REACT_APP_BASE_URL + 'Category/addCategory', body, tokendata).then((res) => {
                setTruebutton(false)
                enqueueSnackbar("Category added successfully", { variant: 'success' })
                navigate('/category')
            }).catch((err) => {
                setTruebutton(false)
                enqueueSnackbar(err && err.response && err.response.data.message ? err.response.data.message : "Somthing went wrong", { variant: 'error' })
                console.log(err);
            })
        }
    }


    return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={8} submenuOrder={1} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Category</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Title'
                                                name="title"
                                                value={category.title}
                                                onChange={(e) => { handleCategory(e); setError({ title: "" }) }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description</Typography>
                                            <textarea
                                                className='textarea'
                                                placeholder='Description'
                                                name="description"
                                                value={category.description}
                                                onChange={(e) => handleCategory(e)}
                                                rows={4}
                                                cols={40}
                                                style={{ resize: 'none' }} />

                                        </FormControl>
                                    </Grid>

                                </Grid>
                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button onClick={handleSubmit} variant="contained" className='theme-btn mr-10'>Submit</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait...</Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}