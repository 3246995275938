import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Button, FormControl, InputAdornment, TextField, Grid } from '@mui/material';
import { SearchOutlined, CloseOutlined, InfoOutlined, DeleteOutline } from '@mui/icons-material';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack'
import DialogContent from '@mui/material/DialogContent';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function DataTable() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }

    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [total, setTotal] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState(false);
    const [deleteid, setDeleteId] = React.useState('');
    const [error, setError] = React.useState({ subcategoryname: "" })
    const [search, setSearch] = React.useState('');
    let name, value;

    const params = useParams()
    console.log(params)

    let navigate = useNavigate()



    React.useEffect(() => {
        getJobdetails()
    }, [page, rowsPerPage, search])


    const getJobdetails = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Jobs/GetJobs?search=${search}&size=${rowsPerPage}&page=${page}&isActive=all`, { headers: headerlocal }).then((res) => {
            console.log(res.data, "Job Details");
            setRows(res.data.jobs)
            setTotal(res.data.totalcount)
        }).catch((error) => {
            console.log(error.response, "error in getting job details");
        })
    }

    const handleDeleteCategory = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `Jobs/DeleteJobs/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                enqueueSnackbar("Job deleted successfully", { variant: 'success' })
                getJobdetails()
                handleClose()
            }).catch((error) => {
                console.log(error, "error");
            })
    }

    const handleClickOpen = (id) => {
        setDeleteId(id)
        setOpen(true);
    };
    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleRemove = (e) => {
        setSearch('');
        setPage(0)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEdit = (row) => {
        console.log(row, 'rowrow');
        let obj = {
            data: row
        }
        navigate(`/teachers/details/career/editjobdetails/${params.id}`, { state: obj })
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box component='div' className='DTsearch mr-10 p-20'>
                <FormControl variant="standard">
                    <Typography component='div' className='label mr-10'>Search :</Typography>
                    <TextField
                        id="filled-size-small"
                        variant="outlined"
                        size="small"
                        value={search}
                        className='textfield search'
                        onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                        placeholder='Search Here...'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {search !== '' && (
                                        <IconButton onClick={handleRemove} size="small">
                                            <CloseOutlined className='fz-18' />
                                        </IconButton>
                                    )}
                                    <SearchOutlined className='fz-18' />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </Box>

            <TableContainer>
                <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <TableHead className='DT-head'>
                        <TableRow >
                            {/* <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px' }}>Drag row</TableCell> */}
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Job Title </strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Company Name </strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Job Type </strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Description</strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Hours/Project Wise </strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Area</strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>City</strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Skill Set</strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="right" > <strong>Experience</strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Nature Of Job</strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="right" > <strong>Minimum Salary</strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="right" > <strong>Maximum Salary</strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="right" > <strong>No of Positions</strong>  </TableCell>
                            <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="center" > <strong>Action</strong>  </TableCell>
                        </TableRow>
                    </TableHead>

                    <DragDropContext  >
                        <Droppable droppableId="characters">
                            {(provided) => (

                                <TableBody className="characters "   {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        rows && rows?.length > 0 ? rows.map((row, i) => {

                                            return (


                                                <TableRow>
                                                    <TableCell align="left" className='DT-row'>{row && row.Job_title}</TableCell>
                                                    <TableCell align="left" className='DT-row'>{row && row.company_name}</TableCell>
                                                    <TableCell align="left" className='DT-row'>{row && row.Job_type}</TableCell>
                                                    <TableCell align="left" className='DT-row'>{row.description ? row.description.slice(0, 50) + "..." : "-"}</TableCell>
                                                    <TableCell align="left" className='DT-row'>{row && row.hours_projectwise}</TableCell>
                                                    <TableCell align="left" className='DT-row'>{row.area ? row.area : "-"}</TableCell>
                                                    <TableCell align="left" className='DT-row'>{row && row.city}</TableCell>
                                                    <TableCell align="left" className='DT-row'>{row && row.skill_set_required}</TableCell>
                                                    <TableCell align="right" className='DT-row'>{row && row.experience}</TableCell>
                                                    <TableCell align="left" className='DT-row'>{row && row.nature_of_the_job}</TableCell>
                                                    <TableCell align="right" className='DT-row'>{row.min_salary ?`${row.currency} ${row.min_salary.toLocaleString('en-US', { maximumFractionDigits: 2 })}` : '-'}</TableCell>
                                                    <TableCell align="right" className='DT-row'>{row.max_salary ?`${row.currency} ${row.max_salary.toLocaleString('en-US', { maximumFractionDigits: 2 })}` : '-'}</TableCell>
                                                    <TableCell align="right" className='DT-row'>{row && row.number_of_position_open}</TableCell>
                                                    <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }} >
                                                        <Box className="actionbtns">
                                                            <Tooltip title="Edit"><Box className='a_tag' onClick={() => handleEdit(row)}><EditOutlinedIcon className='icons text-grey' /></Box></Tooltip>
                                                            <Tooltip title="Delete"><Box sx={{ color: "red" }} onClick={(e) => handleClickOpen(row._id)}><DeleteOutline className='icons text-grey' /></Box></Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>


                                            );
                                        })
                                            :
                                            <TableRow >
                                                <TableCell align="center" colSpan={6} className='DT-row'><p className='text-center semibold m-0 fz-16'>No data found</p></TableCell>
                                            </TableRow>}



                                </TableBody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='DT-pagination'
            />

            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                className='custom_modal'
            >
                <DialogTitle>{"Would you like to delete this job?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDeleteCategory} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}