import React, { useState } from 'react';
import MiniDrawer from '../../../components/drawer';
import { Box, Button, IconButton, FormControlLabel, Tooltip, Card, Checkbox, Link, ListItemText, OutlinedInput, Input, Breadcrumbs, TableContainer, Select, TablePagination, MenuItem, Dialog, Typography, Grid, FormControl, TextField, DialogContent, DialogActions, DialogTitle, styled, CardContent, AccordionDetails, AccordionSummary, Accordion, Divider, RadioGroup, Radio, InputAdornment, Tabs, Tab } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import AudioPlayer from 'react-h5-audio-player';

export default function AddPodacast() {
    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 15,
                            color: (theme) => theme.palette.grey[500],
                            border: '1px solid #E5EBF0',
                            borderRadius: '8px',
                            height: 38,
                            width: 38
                        }}
                    >
                        <CloseIcon className='fz-18' />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    function BpRadio(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }

    
    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#f3714d',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#f3714d',
        },
    });


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const auth = JSON.parse(localStorage.getItem('userData'))
    let tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    };
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const [podcast, setPodcast] = React.useState({ title: "", description: "" });
    const [error, setError] = React.useState({ title: "", description: "", audio: "", image: "",video: "" });
    const [audios, setAudio] = React.useState([])
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [school, setSchool] = React.useState([])
	const [studio, setStudio] = React.useState([])
    const [selectedVideo, setSelectedVideo] = React.useState([]);
    const [videoPreviewUrl, setVideoPreviewUrl] = React.useState([]);
    const [community, setCommunity] = React.useState([])
    const [user, setUser] = React.useState([])
    const [isToggled, setToggled] = React.useState(false)
    const [primary, setPrimary] = React.useState(false)
    const [audioSize, setAudioSize] = React.useState('')
    const [videoSize, setVideoSize] = React.useState('')
    const [imageError, setImageError] = React.useState('')
    const [selectGallert, setSelectGallery] = useState('Podcast')
    const [isLoading,setIsLoading] = React.useState(false)

    const handlePodcast = (e) => {
        const { name, value } = e.target;
        setPodcast({ ...podcast, [name]: value });
        setError('')
        setImageError('')
    };

    const handleEditGallery = (e) => {
		setSelectGallery(selectGallert === 'Podcast' ? 'Video' : 'Podcast')
	}

    React.useEffect(() => {
        console.log("Audio size:", audioSize, "MB");
    }, [audioSize]);

    const [audioPreview, setAudioPreview] = useState(null)
    const handleAudio = (e) => {
        const file = e.target.files[0];
        setAudio(file)
        setAudioPreview(URL.createObjectURL(file))
        if (!file) {
            setError({ ...error, audio: "Please select an audio file." });
            return;
        }
        const fileSizeInBytes = file.size;
        const fileSizeInKB = fileSizeInBytes / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;
        const roundedFileSizeInMB = fileSizeInMB.toFixed(1);
        setAudioSize(String(roundedFileSizeInMB));
    }
    console.log(audioPreview, audios, 'audiosaudiosaudios');

    const SingleImage = (e, index) => {
        setImageError('');
        setError('');
    
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
    
        fileObj.push(e.target.files);
    
        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);
    
            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);
    
                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    // const handleVideoChange = (e, index) => {
    //     setError(prevError => ({ ...prevError, video: '' }))
    //     var fileObj = [];
    //     var fileArray = [];
    //     var filepath = [];
    //     fileObj.push(e.target.files)
    //     for (let i = 0; i <= fileObj[0].length; i++) {
    //         if (i < fileObj[0].length) {
    //             fileArray.push(URL.createObjectURL(fileObj[0][i]))
    //             filepath.push(fileObj[0][i])
    //         }
    //     }
    //     setVideoPreviewUrl(fileArray)
    //     setSelectedVideo(filepath)
    // }

    const handleVideoChange = (e) => {
        setError({ video: '' });

        const fileObj = e.target.files;
        const fileArray = [];
        const filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            const videofileSize = fileObj[i].size;
            const VideofileSizeInMB = videofileSize / (1024 * 1024);
            const roundedVideoFileSizeInMB = VideofileSizeInMB.toFixed(1);
            console.log(roundedVideoFileSizeInMB,"video sizeee");
            setVideoSize(String(roundedVideoFileSizeInMB));
            if (videofileSize > 25 * 1024 * 1024) { 
                setError({ video: 'Video should be less than 25 MB' });
                return;
            }

            fileArray.push(URL.createObjectURL(fileObj[i]));
            filepath.push(fileObj[i]);
            
            console.log(`File ${i + 1}: ${fileObj[i].name}, Size: ${VideofileSizeInMB.toFixed(2)} MB`);
        }

        setVideoPreviewUrl(fileArray);
        setSelectedVideo(filepath);
    };

    const clearVideo = () => {
        setSelectedVideo([]);
        setVideoPreviewUrl([]);
    };

    const handleToggle = () => {
        setToggled(!isToggled)
    }

    const handlePrimary = () => {
        setPrimary(!primary)
    }

    console.log("isToggled:", selectedVideo);

    console.log(audios, 'audioaudioaudioaudioaudioaudioaudioaudioaudio');

    const Validator = () => {
        if (!podcast.title  && statesingle2?.length == 0) {
            setError({ title: "This field is required.", description: "This field is required.", audio: 'This field is required.' })
            setImageError("This field is required.")
            return false
        }
        if (!podcast.title) {
            setError({ title: "This field is required." })
            return false
        }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        if (selectGallert === 'Podcast') {
            if (audios.length === 0) {
                setError({audio : "Audio upload is required."})
                return false
            }
        } else if (selectGallert === 'Video') {
            if (selectedVideo.length === 0) {
                setError({ video: "Video upload is required." })
                return false
            }
        }
        return true
    }

    React.useEffect(() => {
		getStudio()
	}, [])

	const getStudio = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaStudioList`, { headers: headerformdata });
			setStudio(response.data.getYogaStudioList);
			console.log(response, "hhhhhh");
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedStudio, setSelecttedStudio] = React.useState([])
	const [studioName, setStudioName] = React.useState([])
	const handlechangeStudio = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setStudioName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getStudioval()
	}
	React.useEffect(() => {
		getStudioval()
	}, [studioName])

	function getStudioval() {
		var array = []
		const selecttedStudio = studio.filter(content => studioName.includes(content.username));
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedStudio.map((item, i) => {
			var val = { user_id: item._id, username: item.username, email: item.email }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedStudio(array)
	}

	React.useEffect(() => {
		getSchool()
	}, [])

	const getSchool = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaSchoolList`, { headers: headerformdata });
			setSchool(response.data.getYogaSchoolList);
			console.log(response, "kkkk");
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedSchool, setSelecttedSchool] = React.useState([])
	const [schoolName, setSchoolName] = React.useState([])
	const handlechangeSchool = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setSchoolName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getSchoolval()
	}
	React.useEffect(() => {
		getSchoolval()
	}, [schoolName])

	function getSchoolval() {
		var array = []
		const selecttedSchool = school.filter(content => schoolName.includes(content.username));
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedSchool.map((item, i) => {
			var val = { user_id: item._id, username: item.username, email: item.email }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedSchool(array)
	}

    React.useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getUserdata`, { headers: headerformdata });
            setUser(response.data.getuserdata);
            console.log(response.data, 'response.data.user');
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    React.useEffect(() => {
        getCommunity()
    }, [])

    const getCommunity = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunityList`, { headers: headerformdata });
            setCommunity(response.data.community);
            console.log(response.data, 'response.data.community');
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [selecttedUsers, setSelecttedUsers] = React.useState([])
    const [userName, setUserName] = React.useState([])
    const handleSelectUser = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setUserName(
            typeof value === 'string' ? value.split(',') : value,
        );
        getArrayval()
    }
    React.useEffect(() => {
        getArrayval()
    }, [userName])
    function getArrayval() {
        var array = []
        const selectedUser = user.filter(content => userName.includes(content.username));
        //console.log(selectedUser, 'selectedUser');
        selectedUser.map((item, i) => {
            var val = { username: item.username, user_id: item._id, email: item.email, image: item.image ? item.image : '' }
            Object.assign(item, val)
            array.push(val)
        })
        setSelecttedUsers(array)
    }
    console.log(selecttedUsers, "selecttedUsersselecttedUsersselecttedUsers");

    const [selecttedCommunity, setSelecttedCommunity] = React.useState([])
    const [coummunityName, setCommunityName] = React.useState([])
    const handlechangeCommunity = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setCommunityName(
            typeof value === 'string' ? value.split(',') : value,
        );
        getCommunityval()
    }
    React.useEffect(() => {
        getCommunityval()
    }, [coummunityName])

    function getCommunityval() {
        var array = []
        const selectedCommunity = community.filter(content => coummunityName.includes(content.coummunity_name));
        // console.log(selectedCourse,'selectedCourseselectedCourse');
        selectedCommunity.map((item, i) => {
            var val = { coummunity_name: item.coummunity_name, coummunity_title: item.coummunity_title, category_id: item.category_id, _id: item._id, image: item.image ? item.image : '' }
            Object.assign(item, val)
            array.push(val)
        })
        setSelecttedCommunity(array)
    }
    console.log(selecttedCommunity, "selecttedCourseselecttedCourse");


    const handleSubmit = async () => {
        setIsLoading(true)
        const isValid = Validator();
        if (isValid) {
            const formData = new FormData();
            // formData.append("createdby", auth && auth._id)
            // const idArray = selecttedUsers.map(id => id.user_id);
            // idArray.forEach(id => {
            //     formData.append('createdby[]', id);
            // });
            if (selecttedUsers.length > 0) {
                const idArray = selecttedUsers.map(id => id.user_id);
                idArray.forEach(id => {
                    formData.append('createdby[]', id);
                });
                formData.append('createdby[]', auth._id);
            } else {
                formData.append('createdby[]', auth._id);
            }
            // if (auth.role_id == 1) {
            //     const idArray = selecttedUsers.map(id => id.user_id);
            //     idArray.forEach(id => {
            //         formData.append('createdby[]', id);
            //     });
            // } else {
            //     formData.append('createdby[]', auth._id);
            // }

            formData.append('name', podcast.title);
            formData.append('description', podcast.description);
            // formData.append('audio', audios);
            // { selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '') }
            formData.append('public', isToggled);
            formData.append('yogastudio', JSON.stringify(selecttedStudio.length > 0 ? selecttedStudio : []))
			formData.append('yogaschool', JSON.stringify(selecttedSchool.length > 0 ? selecttedSchool : []))
            formData.append('primary', primary);
            formData.append('community_id', JSON.stringify(selecttedCommunity.length > 0 ? selecttedCommunity : []))
            formData.append('user_id', JSON.stringify(selecttedUsers.length > 0 ? selecttedUsers : []))
    
            formData.append('gallery', selectGallert === 'Podcast' ? 'Podcast' : 'Video')
            
            statesingle2.forEach((item) => formData.append("thumbnail", item));
            {
                selectGallert === 'Podcast' ? <>
                      {formData.append('audio', audios)}
                    {  formData.append('audio_size', audioSize)}
                </>
                    :
                    <>
                        { selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '') }
                        {  formData.append('audio_size', videoSize)}
                        </>   }
            console.log([...formData], 'podcast added');

            axios.post(process.env.REACT_APP_BASE_URL + 'podcast/addPodcast', formData, { headers: headerformdata }).then((res) => {
               console.log(res,"added");
                navigate('/podcast')
                enqueueSnackbar("Podcast added successfully", { variant: 'success' });
            }).catch((err) => {
                enqueueSnackbar(err && err.response && err.response.data.error ? err.response.data.error : "Something went wrong", { variant: 'error' });
                console.log(err);
            }).finally(() => {
                setIsLoading(false)
            })
        }
    };

    console.log(selectGallert, 'selectGallertselectGallertselectGallert');

    return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={15} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Podcast</Typography>

                                <FormControl variant="standard" fullWidth className='mb-20'>
                                    <Typography component='div' className='label '>Title <Typography component='span' className='star'>*</Typography></Typography>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        className='textfield'
                                        placeholder='Title'
                                        name="title"
                                        value={podcast.title}
                                        onChange={handlePodcast}

                                    />
                                    {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                </FormControl>

                                <Box className="flex-center  mb-20">
                                    <Typography component='div' className='label mb-0 mr-10'>Primary</Typography>
                                    <Box className='heading-btns ' style={{ textAlign: 'end' }}>
                                        <FormControlLabel
                                            className='m-0'
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            control={<Switch size='small' checked={primary} onChange={handlePrimary} />}
                                        />
                                    </Box>
                                </Box>
                                <Box className=" mb-20">
                                    <Typography component='div' className='label'>Image <Typography component='span' className='star'>*</Typography></Typography>
                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                        {statesingle.length < 1 &&

                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                            </Button>
                                        }
                                    </Box>
                                    {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                                </Box>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
									<Typography className=' fz-12 mt-10 mb-20' component='p' variant='p'><strong>Note: </strong>Image size should be more than 220px * 250px for better quality picture.</Typography>
								</Grid>
                                {statesingle.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle.length > 0 ? statesingle.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                    <img src={x} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}
                                    </Grid>
                                }


                                <Box className="mb-20">
                                    <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'>Description</Typography>
                                        <textarea
                                            className='textarea'
                                            placeholder='Description'
                                            name="description"
                                            value={podcast.description}
                                            onChange={handlePodcast}
                                            rows={4}
                                            cols={40} />
                                        {/* {error.description && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.description}</p>} */}
                                    </FormControl>
                                </Box>

                                <Grid item xs={12} sm={4} md={4} lg={4}>
									<Typography component='div' className='label'>Audio/Video<Typography component='span' className='star'>*</Typography> </Typography>

								</Grid>
								<Grid item xs={12} sm={8} md={8} lg={8}>
									<Box className=" ">
										<RadioGroup
											row
											aria-labelledby="demo-customized-radios"
											name="payment_type"
											onChange={handleEditGallery}
											value={selectGallert}
										>
											<FormControlLabel value="Podcast" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Audio</Typography>} />
											<FormControlLabel value="Video" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Video</Typography>} />
										</RadioGroup>
									</Box>
								</Grid>

                                {selectGallert === 'Podcast' ? <>

                                <Box className="mb-20">
                                    <FormControl variant="standard" fullWidth>
                                        <Box className='flex-center flex-between flex-wrap w100 ' >
                                            <Typography component='div' className='label'>Upload Audio </Typography>
                                            <Button className="upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                <input name="audio" accept="audio/*" onChange={handleAudio} hidden type="file" />
                                            </Button>
                                            <br></br><br></br>
                                        </Box>
                                    </FormControl>
                                    {error.audio && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.audio}</p>}

                                </Box>

                                {audioPreview !== null && <Box className='audioPlayer_container mb-20'>
                                    <AudioPlayer
                                        // autoPlay
                                        src={audioPreview && audioPreview.slice(0, 4) === "blob" ? audioPreview : process.env.REACT_APP_BASE_URL + `${audioPreview}`}
                                        onPlay={e => console.log("onPlay")}
                                        className='audio_player'
                                    />
                                </Box>} </> : <>

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Video</Typography>
                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                        {selectedVideo && selectedVideo?.length === 0 &&
                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                </Box>
                                                <input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
                                            </Button>
                                        }
                                        {selectedVideo && selectedVideo?.length > 0 &&
                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                    <Box className="uploaded_vdo" >
                                                        <Button className='close_icon' onClick={clearVideo}><Close /></Button>
                                                        <video className='uploaded_vdo' controls>
                                                            <source src={videoPreviewUrl} type={selectedVideo.type} />
                                                        </video>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Box>
                                    {error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}
                                </Box>
                                </> }
                                {auth.role_id == 1 ?         <Box className="mb-20">

                                    <Typography component='div' className='label'>Select Users</Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            className='select'
                                            value={userName}
                                            onChange={(e) => handleSelectUser(e,)}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                const selectedUser = user.filter(content => selected.includes(content.username));
                                                if (selectedUser.length === 0) {
                                                    return "Any User";
                                                } else if (selectedUser.length === 1) {
                                                    return selectedUser[0].username;
                                                } else {
                                                    return `${selectedUser.length} Users selected`;
                                                }
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {user && user.length > 0 ? user.map((name) => (
                                                <MenuItem key={name._id} value={name.username} >
                                                    <Checkbox checked={userName.indexOf(name.username) > -1} />
                                                    <ListItemText primary={name.username} />
                                                </MenuItem>
                                            )) : <MenuItem>No user found</MenuItem>}
                                        </Select>
                                        {/* <p className='error-field'>{error.userselect}</p> */}
                                    </FormControl>
                                </Box> : '' }

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Select Community</Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            className='select'
                                            value={coummunityName}
                                            onChange={(e) => handlechangeCommunity(e,)}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                const selectedCommunity = community.filter(content => selected.includes(content.coummunity_name));
                                                if (selectedCommunity.length === 0) {
                                                    return "Any Community";
                                                } else if (selectedCommunity.length === 1) {
                                                    return selectedCommunity[0].coummunity_name;
                                                } else {
                                                    return `${selectedCommunity.length} Community selected`;
                                                }
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {community && community.length > 0 ? community.map((name) => (
                                                <MenuItem key={name._id} value={name.coummunity_name} >
                                                    <Checkbox checked={coummunityName.indexOf(name.coummunity_name) > -1} />
                                                    <ListItemText primary={name.coummunity_name} />
                                                </MenuItem>
                                            )) : <MenuItem>No user found</MenuItem>}
                                        </Select>
                                        {/* <p className='error-field'>{error.userselect}</p> */}
                                    </FormControl>
                                </Box>

								<Box className='mb-20'>
									<Typography component='div' className='label'>Select School</Typography>
									<FormControl fullWidth>
										<Select
											labelId="demo-multiple-checkbox-label"
											id="demo-multiple-checkbox"
											multiple
											className='select'
											value={schoolName}
											onChange={(e) => handlechangeSchool(e,)}
											input={<OutlinedInput />}
											renderValue={(selected) => {
												const selecttedSchool = school.filter(content => selected.includes(content.username
												));
												if (selecttedSchool.length === 0) {
													return "Any School";
												} else if (selecttedSchool.length === 1) {
													return selecttedSchool[0].username
														;
												} else {
													return `${selecttedSchool.length} School selected`;
												}
											}}
											MenuProps={MenuProps}
										>
											{school && school.length > 0 ? school.map((name) => (
												<MenuItem key={name._id} value={name.username
												} >
													<Checkbox checked={schoolName.indexOf(name.username) > -1} />
													<ListItemText primary={name.username
													} />
												</MenuItem>
											)) : <MenuItem>No school found</MenuItem>}
										</Select>
									</FormControl>
								</Box>

								<Box className='mb-20'>
									<Typography component='div' className='label'>Select Studio</Typography>
									<FormControl fullWidth>
										<Select
											labelId="demo-multiple-checkbox-label"
											id="demo-multiple-checkbox"
											multiple
											className='select'
											value={studioName}
											onChange={(e) => handlechangeStudio(e,)}
											input={<OutlinedInput />}
											renderValue={(selected) => {
												const selecttedStudio = studio.filter(content => selected.includes(content.username
												));
												if (selecttedStudio.length === 0) {
													return "Any studio";
												} else if (selecttedStudio.length === 1) {
													return selecttedStudio[0].username
														;
												} else {
													return `${selecttedStudio.length} studio selected`;
												}
											}}
											MenuProps={MenuProps}
										>
											{studio && studio.length > 0 ? studio.map((name) => (
												<MenuItem key={name._id} value={name.username
												} >
													<Checkbox checked={studioName.indexOf(name.username) > -1} />
													<ListItemText primary={name.username
													} />
												</MenuItem>
											)) : <MenuItem>No studio found</MenuItem>}
										</Select>

									</FormControl>
								</Box>

                                <Box className="mb-20 flex-center">
                                    <Typography component='div' className='label mb-0 mr-10'>Public</Typography>
                                    <Box className='heading-btns'>
                                        <FormControlLabel
                                            className='m-0'
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            control={<Switch size='small' checked={isToggled} onChange={handleToggle} />}
                                        />
                                    </Box>
                                </Box>
                                <Box className="mb-20">


                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        {
                                            isLoading ? 
                                        
                                        <Button variant="contained" className='addbtn mr-10' disabled >Saving...</Button>:

                                        <Button variant="contained" className='addbtn mr-10' onClick={handleSubmit}>Save</Button>

                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}