// import React, { useEffect, useRef } from 'react';
import React, { useCallback, useEffect } from 'react';
// import { Box, Button, Card, FormControl, Grid, IconButton, Typography } from '@mui/material';
import { Box, Button, FormControl, Grid, IconButton, Typography } from '@mui/material';
// import { AddOutlined, Close } from '@mui/icons-material';
import { Close } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
// import './community.css'
const AddVideo = ({ parentFunc,setVideoAddError }) => {
    const [video, setVideo] = React.useState([{ imgpreview: "", imgraw: "", vdopvw: '', vdoraw: '' }]);


    const memoizedParentFunc = useCallback((videoData) => {
        parentFunc(videoData);
    }, [parentFunc]);
    const [error, setError] = React.useState({ video: "", image: "" });

    // const inputRef = useRef(null);
    const handleAddClick = () => {
        if (video.every(v => v.imgpreview && v.vdopvw)) {
            setVideo(prevVideo => [...prevVideo, { imgpreview: "", imgraw: "", vdopvw: '', vdoraw: '' }]);
            setError({ ...error, video: "", image: "" }); // Clear errors on successful addition
        } else {
            setVideoAddError("Please fill in both image and video fields before adding another.");
        }
        
        // setVideo(prevVideo => [...prevVideo, { imgpreview: "", imgraw: "", vdopvw: '', vdoraw: '' }]);
    };

    console.log(video)
    const handleImageChange = (e, index) => {
        const selectedFile = e.target.files[0];

        // Check if a file is selected
        if (!selectedFile) {
            // setError({ ...error, image: "Please select an image file." });
            setVideoAddError("Please select an image file")
            return;
        }

        // Check if the file size exceeds 1 MB (in bytes)
        const maxSize = 1 * 1024 * 1024; // 1 MB in bytes
        if (selectedFile.size > maxSize) {
            // setError({ ...error, image: "Image should be less than 1 MB" });
            setVideoAddError("Image should be less than 1 MB")
            return;
        }

        setVideo(prevVideo => {
            const addVideo = [...prevVideo];
            addVideo[index] = {
                imgpreview: URL.createObjectURL(selectedFile),
                imgraw: selectedFile
            };
            return addVideo;
        });

        // Clear image error if validation passes
        setError({ ...error, image: "" });
    };


    const resetThumbnailImage = (e,index) => {
        
        setVideo(prevVideo => {
            const removeImage = [...prevVideo];
            removeImage[index] = {
                ...removeImage[index],
                imgpreview: '',
                imgraw: '',
            };
            return removeImage;
        });
    };
   


    const handleVideoChange = (e, index) => {
        const selectedFile = e.target.files[0];
        // console.log(selectedFile)
        if(!video[0].imgpreview){
            // setError({ ...error, image: "Please select an Thumbnail image." });
            setVideoAddError("Please select an Thumbnail image First.")
            return;
        }
        

        // Check if a file is selected
        if (!selectedFile) {
            // setError({ ...error, video: "Please select a video file." });
            setVideoAddError("Please select a video file.")
            return;
        }

        // Check if the file size exceeds 25 MB (in bytes)
        const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
        // console.log(selectedFile)
        if (selectedFile.size > maxSize) {
            // setError({ ...error, video: "Video should be less than 25 MB" });
            setVideoAddError("Video should be less than 25 MB")

            return;
        }

        setVideo(prevVideo => {
            const updatedVideo = [...prevVideo];
            updatedVideo[index] = {
                ...updatedVideo[index],
                vdopvw: URL.createObjectURL(selectedFile),
                vdoraw: selectedFile,
            };
            return updatedVideo;
        });

        // Clear video error if validation passes
        setError({ ...error, video: "" });
    };


    const handleRemoveVideo = (e, index) => {
        setVideo(prevVideo => {
            const updatedVideo = [...prevVideo];
            updatedVideo[index] = {
                ...updatedVideo[index],
                vdopvw: '',
                vdoraw: '',
            };
            return updatedVideo;
        });
    };
    const handleRemoveEntry = (index) => {
        setVideo(prevVideo => prevVideo.filter((_, i) => i !== index));
    };

    
    useEffect(() => {
        memoizedParentFunc(video);
    }, [video, memoizedParentFunc]);

    return (
        // <Box className={video.length ? "d-flex align-items-end flex-wrap" : ''}>
        <Box>
          
                <Box className="flex">
                    {video.map((x, i) => (
                        <FormControl key={i} className='' fullWidth >
                            <Grid container className="mb-10" columnSpacing={2} rowSpacing={2} style={{display:"flex"}}>

                                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} >
                                    <Box className='d-flex'>
                                        <Typography className='fz-13  mb-10 mr-10'>{i + 1}</Typography>
                                        <Box>
                                            {x.imgpreview === '' || x.imgpreview === undefined ?
                                                <Button className="multiple_upload_btn" sx={{width:"180px"}} variant="contained" component="label">
                                                    <Box ><FileUploadOutlinedIcon className="fz-18 mr-5" />Thumbnail image </Box>
                                                    <input accept={"image/png, image/jpg,image/jpeg"} onChange={(e) => handleImageChange(e, i)} hidden type="file" />
                                                </Button> :
                                                // <Box className="form-box upload-img">
                                                //     <Card style={{ width: '200px' }}>
                                                //         <Typography component='div' variant='div'>
                                                //             <Button className='close' onClick={(e) => resetThumbnailImage(e, i)}><Close /></Button>
                                                //         </Typography>
                                                //         <Box  >
                                                //             {x.imgpreview === '' || x.imgpreview === undefined ? null :
                                                //                 <img src={x.imgpreview} alt='' height={155} width={195} border={0} />
                                                //             }
                                                //         </Box>
                                                //     </Card>
                                                // </Box>
                                                <Grid container columnSpacing={2} rowSpacing={2} className="">
                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                    <Box sx={{position: 'relative',display:"flex", flexDirection:"column" }}   >
                                                        {/* <Button className='close_icon' onClick={(e) => handleRemoveVideo(e, i)}><Close /></Button> */}
                                                        <Button 
                                                        className='close_icon' 
                                                        onClick={(e) => resetThumbnailImage(e, i)}
                                                        sx={{
                                                            position: 'absolute',
                                                            // top: 8, // Adjust this value as needed
                                                            // right: 0, // Adjust this value as needed
                                                            top: 4,
                                                            left: 171,
                                                            zIndex: 1
                                                        }}
                                                        ><Close /></Button>
                                                        {/* <video style={{marginRight:"15px"}} controls className='' src={x.vdopvw} height={155} width={195} /> */}
                                                        <Box  >
                                                             {x.imgpreview === '' || x.imgpreview === undefined ? null :
                                                                <img src={x.imgpreview} alt='' height={155} width={195} border={0} />
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            }
                                        </Box>
                                    </Box>
                                    {error.image && <p style={{ color: 'red' }} className="fz-13 m-0">{error.image}</p>}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                    <Box className="" >
                                        {x.vdopvw === '' || x.vdopvw === undefined ?
                                        
                                            <Button className="multiple_upload_btn" sx={{width:"180px"}} variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload video </Box>
                                                <input id="video-upload" accept="video/*" onChange={(e) => handleVideoChange(e, i)} hidden type="file" />
                                            </Button> :
                                            <Grid container columnSpacing={2} rowSpacing={2} className="">
                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                    <Box  sx={{position: 'relative',display:"flex", flexDirection:"column" }} >
                                                        <Button 
                                                        className='close_icon' 
                                                        onClick={(e) => handleRemoveVideo(e, i)}
                                                        sx={{
                                                            position: 'absolute',
                                                            // top: 8, // Adjust this value as needed
                                                            // right: 0, // Adjust this value as needed
                                                            top: 26,
                                                            left: 173,
                                                            zIndex: 1
                                                        }}><Close /></Button>
                                                        <video style={{marginRight:"15px"}} controls className='' src={x.vdopvw} height={155} width={195} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Box>
                                    {error.video && <p style={{ color: 'red' }} className="fz-13 m-0">{error.video}</p>}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                <Box>
                                    <IconButton 
                                    // className='close_icon' 
                                    onClick={() => handleRemoveEntry(i)}
                                    sx={{
                                        position:"absolute",
                                        // top: 26,
                                        // right: 10,
                                        top: -40,
                                        right: 412,
                                        zIndex: 1,
                                        fontSize: "24px",
                                    
                                    }}
                                   
                                    ><Close/></IconButton>
                                </Box>
                            </Grid>
                            </Grid>
                        </FormControl>

                    ))}
                </Box>
         
            <Button className='theme-btn' 
            onClick={handleAddClick} 
            sx={{
                mt:"10px" ,
                backgroundColor: (video.every(v => v.imgpreview && v.vdopvw) ? 'blue' : 'grey'),
                cursor: (video.every(v => v.imgpreview && v.vdopvw) ? 'pointer' : 'not-allowed')}}
                disabled={!video.every(v => v.imgpreview && v.vdopvw)}
                >
                    + Add Video
                </Button>
            
        </Box>

    );
};

export default AddVideo;
