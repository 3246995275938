import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './Datatable'

function Management() {
    let navigate = useNavigate()
    const role = JSON.parse(localStorage.getItem("roledata"))

    const addoffer = () => {
        navigate('/addoffer');
    }
    return (
        <Box className='companies'>
            <MiniDrawer menuOrder={16} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Ad Management</Typography>
                    {role.UserRoles == null ? <Button variant="contained" className='addbtn' onClick={() => addoffer()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Ads</Button>
                        : role.UserRoles.AddManagements.AddAddManagements && <Button variant="contained" className='addbtn' onClick={() => addoffer()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Ads</Button>
                    }                </Box>
                <DataTable />
            </Box>
        </Box>
    )

}

export default Management

