import React, { useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import Sidebar from './../../Sidebar'
import MiniDrawer from '../../../components/drawer';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, MenuItem, Checkbox, ListItemText, TextField, Select, OutlinedInput, Typography, Input } from '@mui/material'
import { Close } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#E80A89',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#E80A89',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function Service_Steppyform() {
	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [state1, setState1] = React.useState([]);
	const [studio, setStudio] = React.useState([])
	const [state2, setState2] = React.useState([]);
	const [imageAddError, setImageAddError] = React.useState('');
	const [comError,setComError] = React.useState([])
	const [community, setCommunity] = React.useState([])
	const [user, setUser] = React.useState([])
	const [school, setSchool] = React.useState([])
	const auth = JSON.parse(localStorage.getItem('userData'))
	const [truebutton, setTruebutton] = React.useState(false)


	const multipleImgChange = (e, index) => {
		setImageAddError('');

		// Validate file size
		const maxSize = 1024 * 1024; // 1MB in bytes
		const files = e.target.files;
		var fileObj = [];
		var fileArray = [];
		var filepath = [];

		for (let i = 0; i < files.length; i++) {
			if (files[i].size > maxSize) {
				// Handle file size error (for example, set an error state)
				setImageAddError('Image size should be less than 1 MB.');
				continue; // Skip this file
			}

			fileObj.push(files[i]);
			fileArray.push(URL.createObjectURL(files[i]));
			filepath.push(files[i]);
		}

		var data = state1.filter(x => x != null);
		var data1 = state2.filter(x => x != null);
		setState1(data);
		setState2(data1);
		setState1(prevValue => prevValue.concat(fileArray));
		setState2(prevValue => prevValue.concat(filepath));
	}


	const indexDelete = (e, index) => {
		var filteredpreview = state1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = state2.filter((val, i) => {
			return i !== index;
		});
		setState1(filteredpreview);
		setState2(filteredraw);
	}

	React.useEffect(() => {
		getStudio()
	}, [])

	const getStudio = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaStudioList`, { headers: headerlocal });
			setStudio(response.data.getYogaStudioList);
			console.log(response, "hhhhhh");
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedStudio, setSelecttedStudio] = React.useState([])
	const [studioName, setStudioName] = React.useState([])
	const handlechangeStudio = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setStudioName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getStudioval()
	}
	React.useEffect(() => {
		getStudioval()
	}, [studioName])

	function getStudioval() {
		var array = []
		const selecttedStudio = studio.filter(content => studioName.includes(content.username));
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedStudio.map((item, i) => {
			var val = { user_id: item._id, username: item.username, email: item.email }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedStudio(array)
	}

	React.useEffect(() => {
		getSchool()
	}, [])

	const getSchool = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaSchoolListImages`, { headers: headerlocal });
			setSchool(response.data.getYogaSchoolLists);
			console.log(response, "kkkk");
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedSchool, setSelecttedSchool] = React.useState([])
	const [schoolName, setSchoolName] = React.useState([])
	const handlechangeSchool = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setSchoolName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getSchoolval()
	}
	React.useEffect(() => {
		getSchoolval()
	}, [schoolName])

	function getSchoolval() {
		var array = []
		const selecttedSchool = school.filter(content => schoolName.includes(content.username));
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedSchool.map((item, i) => {
			var val = { user_id: item._id, username: item.username, email: item.email }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedSchool(array)
	}

	React.useEffect(() => {
		getUser()
	}, [])

	const getUser = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachersListForImages`, { headers: headerformdata });
			setUser(response.data.teachers);
			console.log(response.data, 'response.data.user');
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	React.useEffect(() => {
		getCommunity()
	}, [])

	const getCommunity = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunityList`, { headers: headerformdata });
			setCommunity(response.data.community);
			console.log(response.data, 'response.data.community');
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedUsers, setSelecttedUsers] = React.useState([])
	const [userName, setUserName] = React.useState([])
	const handleSelectUser = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setUserName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getArrayval()
	}
	React.useEffect(() => {
		getArrayval()
	}, [userName])
	function getArrayval() {
		var array = []
		const selectedUser = user.filter(content => userName.includes(content.username));
		//console.log(selectedUser, 'selectedUser');
		selectedUser.map((item, i) => {
			var val = { user_id: item._id }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedUsers(array)
	}
	console.log(selecttedUsers, "selecttedUsersselecttedUsersselecttedUsers");

	const [selecttedCommunity, setSelecttedCommunity] = React.useState([])
	const [coummunityName, setCommunityName] = React.useState([])
	const handlechangeCommunity = (event, item) => {
		setComError('')
		const {
			target: { value },
		} = event;
		setCommunityName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getCommunityval()
	}
	React.useEffect(() => {
		getCommunityval()
	}, [coummunityName])

	function getCommunityval() {
		var array = []
		const selectedCommunity = community.filter(content => coummunityName.includes(content.coummunity_name));
		// console.log(selectedCourse,'selectedCourseselectedCourse');
		selectedCommunity.map((item, i) => {
			var val = item._id
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedCommunity(array)
	}
	console.log(selecttedCommunity, "selecttedCourseselecttedCourse");

	const handleAddImages = () => {
		setTruebutton(true)
		var multipleImage = state2.filter(x => x != null);
		var valid = validatenew();

		// if(selecttedCommunity.length <= 0){
		// 	setComError("Please select a community")
		// 	return false
		// }

		if (valid === true) {
			const formData = new FormData();
			// const idArray = selecttedUsers.map(id => id.user_id);
			// idArray.forEach(id => {
			// 	formData.append('createdby[]', id);
			// });
			if (selecttedUsers.length > 0) {
				const idArray = selecttedUsers.map(id => id.user_id);
				idArray.forEach(id => {
					formData.append('createdby[]', id);
				});
				formData.append('createdby[]', auth._id);
			} else {
				formData.append('createdby[]', auth._id);
			}
			// if (auth.role_id == 1) {
			// 	const idArray = selecttedUsers.map(id => id.user_id);
			// 	idArray.forEach(id => {
			// 		formData.append('createdby[]', id);
			// 	});
			// } else {
			// 	formData.append('createdby[]', auth._id);
			// }

			{ multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '') }
			// formData.append('community_id', JSON.stringify(selecttedCommunity.length > 0 ? selecttedCommunity : []))
			const idArrayCommunities = selecttedCommunity.map(id => id);
			idArrayCommunities.forEach(id => {
				formData.append('community_id[]', id); // Use 'community_id[]' to send as array
			});
			// formData.append('user_id', JSON.stringify(selecttedUsers.length > 0 ? selecttedUsers : []))
			// Assuming selecttedUsers is an array of user IDs
			let usersData = selecttedUsers.map(userId => ({ user_id: userId }));

			// Append to FormData
			formData.append('user_id', JSON.stringify(usersData));
			formData.append('yogastudio', JSON.stringify(selecttedStudio.length > 0 ? selecttedStudio : []))
			formData.append('yogaschool', JSON.stringify(selecttedSchool.length > 0 ? selecttedSchool : []))
			console.log(...formData, "image");
			

			axios.post(process.env.REACT_APP_BASE_URL + `image/addimage`, formData, { headers: headerformdata })
				.then((res) => {
					// setTruebutton(false)
					navigate('/image');
					setState1([]);
					setState2([]);
					enqueueSnackbar("Additional images added successfully", { variant: 'success' })
					console.log(res, "added");
				})
				.catch((err) => {
					// setTruebutton(false)
					enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
					console.log(err, "error");
				})
				.finally(()=>{
					setTruebutton(false)

				})
		}
	}

	

	const validatenew = () => {
		if (state2?.length === 0) {
			setImageAddError("This field is required.")
			return false
		}
		
		
		return true
	}

	return (
		<Box className=''>
			<MiniDrawer menuOrder={6} />
			{/* <Sidebar subtype={5} /> */}
			<Box component="section" className="contentWraper">
				<Grid container>
					<Grid item xs={12} sm={12} md={10} lg={6} xl={5}>
						<Box className='whiteContainer p-20'>
							<Typography component='h6' className='formTitle mb-20'>Images</Typography>
							{auth.role_id === 1 ? <Box className="mb-20">
								<Typography component='div' className='label'>Select Teacher</Typography>
								<FormControl fullWidth>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										className='select'
										value={userName}
										onChange={(e) => handleSelectUser(e,)}
										input={<OutlinedInput />}
										renderValue={(selected) => {
											const selectedUser = user.filter(content => selected.includes(content.username));
											if (selectedUser.length === 0) {
												return "Any User";
											} else if (selectedUser.length === 1) {
												return selectedUser[0].username;
											} else {
												return `${selectedUser.length} Users selected`;
											}
										}}
										MenuProps={MenuProps}
									>
										{user && user.length > 0 ? user.map((name) => (
											<MenuItem key={name._id} value={name.username} >
												<Checkbox checked={userName.indexOf(name.username) > -1} />
												<ListItemText primary={name.username}/>
											</MenuItem>
										)) : <MenuItem>No Teachers found</MenuItem>}
									</Select>
									{/* <p className='error-field'>{error.userselect}</p> */}
								</FormControl>
							</Box> : ''}

							{auth.role_id === 1 ? <Box className="mb-20">
								{/* <Typography component='div' className='label'>Select Community<Typography component='span' className='star'> * </Typography></Typography> */}
								<Typography component='div' className='label'>Select Community</Typography>
								<FormControl fullWidth>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										className='select'
										value={coummunityName}
										onChange={(e) => handlechangeCommunity(e,)}
										input={<OutlinedInput />}
										renderValue={(selected) => {
											const selectedCommunity = community.filter(content => selected.includes(content.coummunity_name));
											if (selectedCommunity.length === 0) {
												return "Any Community";
											} else if (selectedCommunity.length === 1) {
												return selectedCommunity[0].coummunity_name;
											} else {
												return `${selectedCommunity.length} Community selected`;
											}
										}}
										MenuProps={MenuProps}
									>
										{community && community.length > 0 ? community.map((name) => (
											<MenuItem key={name._id} value={name.coummunity_name} >
												<Checkbox checked={coummunityName.indexOf(name.coummunity_name) > -1} />
												<ListItemText primary={name.coummunity_name} />
											</MenuItem>
										)) : <MenuItem>No user found</MenuItem>}
									</Select>
									{/* <p className='error-field'>{error.userselect}</p> */}
									
								</FormControl>
								{/* {comError && <Typography style={{ color: 'red' }} className="fz-13 m-0" >{comError}</Typography>} */}
							</Box> : ''}

							{auth.role_id === 1 ? <Box className="mb-10">
								<Typography component='div' className='label'>Select School</Typography>
								<FormControl fullWidth>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										className='select'
										value={schoolName}
										onChange={(e) => handlechangeSchool(e,)}
										input={<OutlinedInput />}
										renderValue={(selected) => {
											const selecttedSchool = school.filter(content => selected.includes(content.username
											));
											if (selecttedSchool.length === 0) {
												return "Any School";
											} else if (selecttedSchool.length === 1) {
												return selecttedSchool[0].username
													;
											} else {
												return `${selecttedSchool.length} School selected`;
											}
										}}
										MenuProps={MenuProps}
									>
										{school && school.length > 0 ? school.map((name) => (
											<MenuItem key={name._id} value={name.username
											} >
												<Checkbox checked={schoolName.indexOf(name.username) > -1} />
												<ListItemText primary={name.username
												} />
											</MenuItem>
										)) : <MenuItem>No school found</MenuItem>}
									</Select>
								</FormControl>
							</Box> : ''}

							{auth.role_id == 1 ? <Box className="mb-10">
								<Typography component='div' className='label'>Select Studio</Typography>
								<FormControl fullWidth>
									<Select
										labelId="demo-multiple-checkbox-label"
										id="demo-multiple-checkbox"
										multiple
										className='select'
										value={studioName}
										onChange={(e) => handlechangeStudio(e,)}
										input={<OutlinedInput />}
										renderValue={(selected) => {
											const selecttedStudio = studio.filter(content => selected.includes(content.username
											));
											if (selecttedStudio.length === 0) {
												return "Any studio";
											} else if (selecttedStudio.length === 1) {
												return selecttedStudio[0].username
													;
											} else {
												return `${selecttedStudio.length} studio selected`;
											}
										}}
										MenuProps={MenuProps}
									>
										{studio && studio.length > 0 ? studio.map((name) => (
											<MenuItem key={name._id} value={name.username
											} >
												<Checkbox checked={studioName.indexOf(name.username) > -1} />
												<ListItemText primary={name.username
												} />
											</MenuItem>
										)) : <MenuItem>No studio found</MenuItem>}
									</Select>

								</FormControl>
							</Box> : ''}

							<Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">
								<Grid item xs={12} sm={12} md={12}  >
									<Typography component='div' className='label'>Additional Images <Typography component='span' className='star'> * </Typography></Typography>
									{state1.length < 1 &&
										<Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">
											<Grid item xs={6} sm={4} md={4} lg={4} >
												<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
													<Button className="multiple_upload_btn w100" variant="contained" component="label">
														<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
														<input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden multiple type="file" />
													</Button>
												</Box>
											</Grid>
										</Grid>
									}
								</Grid>
								{/* {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0 ml-20" >{imageAddError}</p>} */}


								<Grid item xs={12} sm={12} md={12}  >
									{state1.length > 0 &&
										<Box className="multiple_upload_container">
											<Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">
												{state1.length > 0 ? state1.map((x, i) => (
													<Grid item xs={6} sm={4} md={4} lg={4} >
														<Box className='uploaded_img' >
															<Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
															<img src={x} alt='' height={155} width={195} />
														</Box>
													</Grid>
												)) : ""}
												<Grid item xs={6} sm={4} md={4} lg={4} >
													<label htmlFor="contained-button-file1" className="w-full">
														<Box className='multiple_upload_btn' >
															<Input
																inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
																onChange={multipleImgChange}
																style={{ display: "none", }}
																id="contained-button-file1"
																type="file"
															/>
															<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
														</Box>
													</label>
												</Grid>
											</Grid>
										</Box>
									}
								</Grid>

								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<Typography className=' fz-12 mt-10 mb-20' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
									</Grid>
									{imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}
								</Grid>

							</Grid>
							{/* {truebutton ?
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button onClick={handleAddNews} variant="contained" className='theme-btn'>Submit</Button>
								</Box>
								: truebutton === true &&
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button variant="contained" className='addbtn mr-10'>Please Wait...</Button>
								</Box>
							} */}

								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									{
										truebutton ? 
									<Button variant="contained" className='addbtn mr-10' disabled>Please Wait...</Button>:
									<Button onClick={handleAddImages} variant="contained" className='theme-btn'>Submit</Button>

									}
								</Box>


						</Box>
					</Grid>
				</Grid>
			</Box >
		</Box >
	)
}

export default Service_Steppyform;
