import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Stack, TextField, Typography, styled } from '@mui/material'
import { Add, Instagram, Remove } from '@mui/icons-material'
import userlogo from '../../../assets/images/upcoming.png'

export default function Comments() {
    const [isVisible, setIsVisible] = useState(false);
    const [isReply, setReply] = useState(false);

    const showReplies = () => {
        setIsVisible(true);
    };
    const hideReplies = () => {
        setIsVisible(false);
    };

    const handleReply = () => {
        setReply(!isReply);
    };

    return (
        <Box className="comments_section my-30">
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={12} >
                    <Box className='receiver '>
                        <Box className="comment_each ">
                            <Box className="flex-center ">
                                <Box className="collapseBtns">
                                    <Button onClick={showReplies} className='text_primary'><Add className='fz-16' /></Button>
                                    <Typography>1</Typography>
                                    <Button onClick={hideReplies} className='text_primary'><Remove className='fz-16' /></Button>
                                </Box>
                                <Box className="">
                                    <img src={userlogo} />
                                </Box>
                                <Box>
                                    <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>Ayush</Typography>
                                    <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>3 hr ago</Typography>
                                </Box>
                            </Box>
                            <Button className='' onClick={handleReply}>
                                <Typography component='span' variant='span' className='text_blue fz-12 semibold '><i class="fa-solid fa-reply"></i> Reply</Typography>
                            </Button>
                        </Box>
                        <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 mt-20'>Hello</Typography>
                        {isReply &&
                            <Box className="comment_box">
                                <img src={userlogo} />
                                <TextField fullWidth id="outlined-basic" placeholder='Add a comment' multiline rows={1} variant="outlined" className='textfield text_white' />
                                <Button className='fz-16 theme-btn' >Reply</Button>
                                <Button className='fz-16 cancel_btn' onClick={() => setReply(false)} >Cancel</Button>
                            </Box>
                        }


                    </Box>
                    <Box className='sender'>
                        <Box className="comment_each ">
                            <Box className="flex-center ">
                                <Box className="collapseBtns">
                                    <Button onClick={showReplies} className='text_primary'><Add className='fz-16' /></Button>
                                    <Typography>2</Typography>
                                    <Button onClick={hideReplies} className='text_primary'><Remove className='fz-16' /></Button>
                                </Box>
                                <Box className="">
                                    <img src={userlogo} />
                                </Box>
                                <Box className='flex-center flex-wrap'>
                                    <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>Admin</Typography>
                                    <Typography component='span' variant='span' className='text_white fz-12 semibold mr-10 badge'>You</Typography>
                                    <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>2 hr ago</Typography>
                                </Box>
                            </Box>
                            <Box className='flex-center '>

                                <Button className=' mr-10' >
                                    <Typography component='span' variant='span' className='text_primary fz-12 semibold '><i class="fa-solid fa-trash"></i> Delete</Typography>
                                </Button>
                                <Button className='' >
                                    <Typography component='span' variant='span' className='text_blue fz-12 semibold '><i class="fa-solid fa-reply"></i> Reply</Typography>
                                </Button>
                            </Box>
                        </Box>
                        <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 '>Hi</Typography>
                    </Box>
                    <div className={`content ${isVisible ? 'show' : 'hide'}`}>
                        <Box className='sender '>
                            <Box className="comment_each ">
                                <Box className="flex-center flex-wrap">
                                    <Box className="collapseBtns">
                                        <Button onClick={showReplies} className='text_primary'><Add className='fz-16' /></Button>
                                        <Typography>0</Typography>
                                        <Button onClick={hideReplies} className='text_primary'><Remove className='fz-16' /></Button>
                                    </Box>
                                    <Box className="">
                                        <img src={userlogo} />
                                    </Box>
                                    <Box>
                                        <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>Ayush</Typography>
                                        <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>3 hrs ago</Typography>
                                    </Box>
                                </Box>
                                <Button className='' >
                                    <Typography component='span' variant='span' className='text_blue fz-12 semibold '><i class="fa-solid fa-reply"></i> Reply</Typography>
                                </Button>
                            </Box>
                            <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 '><Typography component='span' variant='body1' className='text_blue semibold fz-14'>@Admin</Typography> Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
                        </Box>
                        <Box className='sender '>
                            <Box className="comment_each ">
                                <Box className="flex-center flex-wrap">
                                    <Box className="collapseBtns">
                                        <Button onClick={showReplies} className='text_primary'><Add className='fz-16' /></Button>
                                        <Typography>0</Typography>
                                        <Button onClick={hideReplies} className='text_primary'><Remove className='fz-16' /></Button>
                                    </Box>
                                    <Box className="">
                                        <img src={userlogo} />
                                    </Box>
                                    <Box>
                                        <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>Ayush</Typography>
                                        <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>3 hrs ago</Typography>
                                    </Box>
                                </Box>
                                <Button className='' >
                                    <Typography component='span' variant='span' className='text_blue fz-12 semibold '><i class="fa-solid fa-reply"></i> Reply</Typography>
                                </Button>
                            </Box>
                            <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 '><Typography component='span' variant='body1' className='text_blue semibold fz-14'>@Admin</Typography> Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
                        </Box>
                    </div>
                    {/* <Box className="doubleReply_box">
                        <Box className='sender '>
                            <Box className="flex-center">
                                <Box className="w100">
                                    <Box className="comment_each ">
                                        <Box className="flex-center ">
                                            <Box className="">
                                                <img src={userlogo} />
                                            </Box>
                                            <Box>
                                                <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>username</Typography>
                                                <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>date</Typography>
                                            </Box>
                                        </Box>
                                        <Button className=' mr-10' >
                                            <Typography component='span' variant='span' className='text_primary fz-12 semibold '><i class="fa-solid fa-trash"></i> Delete</Typography>
                                        </Button>

                                    </Box>
                                    <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 '><Typography component='span' variant='body1' className='text_blue semibold fz-14'>@username</Typography>content</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box> */}
                </Grid>
            </Grid>
            <Box className="comment_box">
                <img src={userlogo} />
                <TextField fullWidth id="outlined-basic" placeholder='Add a comment' multiline rows={1} variant="outlined" className='textfield text_white' />
                <Button className='fz-16 theme-btn' >Send</Button>
            </Box>
        </Box>
    )
}
