import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Stack, TextField, Typography, styled } from '@mui/material'
import { Add, Instagram, Remove } from '@mui/icons-material'
import userlogo from '../../../assets/images/upcoming.png'
import { Link, useLocation, useParams } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function Comments(props) {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const location = useLocation()
    const auth = JSON.parse(localStorage.getItem('userData'))
    const [blog_id, setBlog_id] = useState(location && location.state)
    const [blogID, setBlogID] = useState(props.blogId);
    const [comments, setComments] = useState([])
    const [replyModal, setReplyModal] = useState(false)
    const [content, setContent] = useState('')
    const [replydata, setReplydata] = React.useState('')
    const [isVisible, setIsVisible] = useState(false);
    const [isReply, setReply] = useState(false);
    const [isVisible1,setIsVisible1] = useState(false);


    const showReplies = () => {
        setIsVisible(true);
    };

    const showAnotherReplies = () => {
        setIsVisible1(true)
    }

    const hideReplies = () => {
        setIsVisible(false);
    };


     const hideAnotherReplies = () => {
        setIsVisible1(false);
    };

    React.useEffect(() => {
        getComments()
    }, [location])

    const getComments = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `BlogComments/getcommentsbyid/${blogID}`, { headers: headerlocal }).then((res) => {
            setComments(res.data)
            console.log(res, "hhhh");
        }).catch((error) => {
            console.log(error.response, "error");
        })
    }

    const handleReply = (data) => {
        setReplydata(data._id);
        setReply(!isReply);
    }

    const commentPost = async (e, parent_id) => {
        try {
            let body = {
                "parentId": parent_id,
                "blogId": blogID,
                "user_id": auth && auth._id,
                "content": content
            }
            await axios.post(process.env.REACT_APP_BASE_URL + `BlogComments/AddBlogComments`, body, { headers: headerlocal }).then((res) => {
                console.log(res, "comments");
                setContent('')
                getComments()
            }).catch((error) => {
                console.log(error.response, "error");
            })
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const handleDeletecomment = (id) => {
        let body = {
            commentId: id,

        }
        axios.put(process.env.REACT_APP_BASE_URL + `BlogComments/replydelete`, body)
            .then((res) => {
                getComments()

            })
            .catch((error) => {
                console.log(error);
            })
    }



    return (
        <Box className="comments_section my-30">
            <Grid container columnSpacing={2} rowSpacing={2}>
                {comments && comments.length > 0 ? 
                (comments && comments.map((data, i) => (
                    <Grid item xs={12} sm={12} md={12} key={data._id} >
                        <Box className='receiver '>
                            <Box className="comment_each ">
                                <Box className="flex-center ">
                                {data.replies?.length > 0 && <Box className="collapseBtns">
                                        <Button onClick={showReplies} className='text_primary'><Add className='fz-16' /></Button>
                                        <Typography>{data.replies?.length}</Typography>
                                        <Button onClick={hideReplies} className='text_primary'><Remove className='fz-16' /></Button>
                                    </Box>}
                                    <Box className="">
                                        {data.user?.image?.length > 0 ? (
                                            <img
                                                src={process.env.REACT_APP_BASE_URL + `${data.user.image}`}
                                                height={50}
                                                width={50}
                                                style={{ borderRadius: '50%' }}
                                                className='mr-10'
                                            />
                                        ) : (
                                            < AccountCircleIcon className="mr-10" fontSize="large" />
                                        )}
                                    </Box>
                                    <Box>
                                        <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>{data.user && data.user?.username}</Typography>
                                        <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>{moment(data.createdAt).fromNow()}</Typography>
                                    </Box>
                                </Box>
                                <Button className='' onClick={() => handleReply(data)}>
                                    <Typography component='span' variant='span' className='text_blue fz-12 semibold '><i class="fa-solid fa-reply"></i> Reply</Typography>
                                </Button>
                            </Box>
                            <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 mt-20'>{data.content}</Typography>
                            {replydata === data._id && isReply &&
                                <Box className="comment_box">
                                    {data.prof1?.length > 0 ? (
                                        <img
                                            src={process.env.REACT_APP_BASE_URL + `${data.prof1}`}
                                            height={50}
                                            width={50}
                                            style={{ borderRadius: '50%' }}
                                            className='mr-10'
                                        />
                                    ) : (
                                        < AccountCircleIcon className="mr-10" fontSize="large" />
                                    )}
                                    <TextField fullWidth id="outlined-basic" placeholder='Add a comment' multiline rows={1} variant="outlined" className='textfield text_white' value={content}
                                        onChange={(e) => setContent(e.target.value)} />
                                    <Button className='fz-16 theme-btn mr-10' onClick={(e) => commentPost(e, data._id)} >Reply</Button>
                                    <Button className='fz-16 cancel_btn' onClick={() => setReply(false)} >Cancel</Button>
                                </Box>
                            }
                        </Box>
                         {isVisible == true && data.replies.map((reply) => {
                            return (
                                <><Box className='sender' key={reply._id}>
                                    <Box className="comment_each ">
                                        <Box className="flex-center ">
                                            <Box className="collapseBtns">
                                                <Button onClick={showAnotherReplies} className='text_primary'><Add className='fz-16' /></Button>
                                                <Typography>{reply.replies?.length}</Typography>
                                                <Button onClick={hideAnotherReplies} className='text_primary'><Remove className='fz-16' /></Button>
                                            </Box>
                                            <Box className="">
                                                {data.prof1?.length > 0 ? (
                                                    <img
                                                        src={process.env.REACT_APP_BASE_URL + `${data.prof1}`}
                                                        height={50}
                                                        width={50}
                                                        style={{ borderRadius: '50%' }}
                                                        className='mr-10' />
                                                ) : (
                                                    <AccountCircleIcon className="mr-10" fontSize="large" />
                                                )}
                                            </Box>
                                            <Box className='flex-center flex-wrap'>
                                                <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>Admin</Typography>
                                                <Typography component='span' variant='span' className='text_white fz-12 semibold mr-10 badge'>You</Typography>
                                                <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>{moment(reply.createdAt).fromNow()}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className='flex-center '>

                                            <Button className=' mr-10'>
                                                <Typography component='span' variant='span' className='text_primary fz-12 semibold ' onClick={() => handleDeletecomment(reply._id)}><i class="fa-solid fa-trash"></i> Delete</Typography>
                                            </Button>
                                            <Button className=''>
                                                <Typography component='span' variant='span' className='text_blue fz-12 semibold '><i class="fa-solid fa-reply"></i> Reply</Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 '>{reply.content}</Typography>
                                </Box>
                                {isVisible1 == true && reply && reply.replies.map((info,i)=>(
                                    <Box className="doubleReply_box">
                                        <Box className='sender '>
                                            <Box className="flex-center">
                                                <Box className="w100">
                                                    <Box className="comment_each ">
                                                        <Box className="flex-center ">
                                                            <Box className="">
                                                                <img src={userlogo} />
                                                            </Box>
                                                            <Box>
                                                                <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>{info.user?.username}</Typography>
                                                                <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>{moment(info.createdAt).fromNow()}</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Button className=' mr-10'>
                                                            <Typography component='span' variant='span' className='text_primary fz-12 semibold '><i class="fa-solid fa-trash"></i> Delete</Typography>
                                                        </Button>

                                                    </Box>
                                                    <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 '><Typography component='span' variant='body1' className='text_blue semibold fz-14'>@{reply && reply.user?.username && "Admin"}</Typography> {info.content}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                                
                                </>
                            )
                        })}

                        
                       
                    </Grid>
                ))) : (
                        <p style={{color:'gray',textAlign:'center'}}>No Comments found</p>
                )}
            </Grid>
           
        </Box>
    )
}
