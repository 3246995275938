import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Grid, FormControl, TextField, InputAdornment, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCommunity } from "../Context/CommunityContext";

export default function DataTable({ params }) {
    let tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const { fetchCommunity } = useCommunity();
    const { enqueueSnackbar } = useSnackbar();

    const [addDisable, setAddDisable] = useState(false);
    const [communityRules, setCommunityRules] = useState([{ name: '' }]);
    const [errors, setErrors] = useState([]);
    const [community, setCommunity] = React.useState([])
    



    const handleCommunityRuleChange = (index, value) => {
        const updatedRules = [...communityRules];
        updatedRules[index] = { ...updatedRules[index], name: value };
        setCommunityRules(updatedRules);

        // Clear the error message for the current rule
        const updatedErrors = [...errors];
        updatedErrors[index] = '';
        setErrors(updatedErrors);
    }

    const addCommunityRule = () => {
        setCommunityRules([...communityRules, { name: '' }]);
        setErrors([...errors, '']);
    }

    const removeCommunityRule = (index) => {
        const updatedRules = [...communityRules];
        updatedRules.splice(index, 1);
        setCommunityRules(updatedRules);

        const updatedErrors = [...errors];
        updatedErrors.splice(index, 1);
        setErrors(updatedErrors);
    }


    React.useEffect(() => {
        getCommunity()
    }, [])

    const getCommunity = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunityList`, { headers: headerformdata });
            setCommunity(response.data.community);
            console.log(response.data, 'cs');
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    

    const validateRules = () => {
        let isValid = true;
        const newErrors = communityRules.map(rule => {
            if (!rule.name.trim()) {
                isValid = false;
                return 'This Field is Required.';
            }
            return '';
        });
        setErrors(newErrors);
        return isValid;
    }

    const addRuleDetails = () => {
        if (!validateRules()) {
            return;
        }

        let body = {
            rules: communityRules.map(rule => rule.name),
            community_id: params && params.id,  
        };
      console.log(body,"rulessss");
        axios.post(process.env.REACT_APP_BASE_URL + `community/updateCommunityRules/${params.id}`, body, tokendata)
            .then((res) => {
                fetchCommunity();
                setCommunityRules([{ name: '' }]);
                setErrors(['']);
                enqueueSnackbar("Community Rules added successfully", { variant: 'success' });
            }).catch((err) => {
                enqueueSnackbar(err && err.response && err.response.message ? err.response.message : "Something went wrong", { variant: 'error' })
            })
    }

    

    return (
        <Box sx={{ width: '100%' }} className="whiteContainer ">
            <Grid container className='newmerchant'>
            <Typography component='h6' className='formTitle'>Add Community Rules</Typography>

                <Grid item sm={12} md={12} className="w-100">
                    <Box className="form-box staff_list">
                        {communityRules.map((rule, index) => (
                            <Grid container spacing={2} key={index}>
                                <Grid item xs={6} sm={6} md={6}>
                                    <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'>Community Rule <Typography component='span' className='star'>*</Typography></Typography>
                                        <TextField
                                            id={`rule_${index}`}
                                            variant="outlined"
                                            size="small"
                                            name={`rule_${index}`}
                                            placeholder='Community Rule'
                                            podcasts    className='textfield'
                                            style={{ width: '100%' }}
                                            value={rule.name || ''}
                                            onChange={(e) => handleCommunityRuleChange(index, e.target.value)}
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            error={!!errors[index]}
                                            helperText={errors[index]}
                                        />
                                    </FormControl>
                                </Grid>
                                {index > 0 && (
                                    <Grid item xs={6} sm={6} md={6} className="d-flex align-items-end">
                                        <IconButton onClick={() => removeCommunityRule(index)}>
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                        ))}
                        <Grid item  xs={6} sm={6} md={6} className="text-right">
                            <Button onClick={addCommunityRule} className="theme-btn">
                                <Add /> Add Community Rule
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="text-right">
                            <Button variant="contained" onClick={addRuleDetails} disabled={addDisable}  className='theme-btn'>
                                {addDisable ? 'Please wait...' : 'Submit'}
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
