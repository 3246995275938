import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, IconButton, FormControlLabel, Tooltip, Card, Checkbox, Link, ListItemText, OutlinedInput, Input, Breadcrumbs, TableContainer, Select, TablePagination, MenuItem, Dialog, Typography, Grid, FormControl, TextField, DialogContent, DialogActions, DialogTitle, styled, CardContent, AccordionDetails, AccordionSummary, Accordion, Divider, RadioGroup, Radio, InputAdornment, Tabs, Tab } from '@mui/material';
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import { AiOutlineInfoCircle } from 'react-icons/ai'; // Example icon from React Icons
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Sidebar from './../../Sidebar'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#f3714d',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#f3714d',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ListAlt />,
        2: <CompareIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = ['Basic Information', 'Community Information', 'Community Description'];

export default function Service_Steppyform() {
    let location = useLocation()
    const auth = JSON.parse(localStorage.getItem("userData"))
    const navigate = useNavigate()
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const params = useParams()
    console.log(params, "params");
    console.log(location.state, "location.state");
    const { enqueueSnackbar } = useSnackbar()
    const [selectedPlan, setSelectedPlan] = React.useState('')
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({ amount: "", interval: "" })
    const [subcategoryId, setSubCategoryId] = React.useState('')
    const [imageError, setImageError] = React.useState('')
    const [imageError2, setImageError2] = React.useState('')
    const [imageAddError, setImageAddError] = React.useState('')
    const [state1, setState1] = React.useState([...[], location.state.data.image])
    const [state2, setState2] = React.useState([...[], location.state.data.image])
    const [statesingle, setStatesingle] = React.useState([...[], location.state.data.image])
    const [statesingle2, setStatesingle2] = React.useState([...[], location.state.data.image])
    const [state3, setState3] = React.useState([...[], location.state.data.profile])
    const [state4, setState4] = React.useState([...[], location.state.data.profile])
    const [statesingle3, setStatesingle3] = React.useState([...[], location.state.data.profile])
    const [statesingle4, setStatesingle4] = React.useState([...[], location.state.data.profile])
    const [convertedContent, setConvertedContent] = React.useState(null);
    const [description, setDescription] = React.useState(location.state.data.short_description)
    const [currency, setCurrency] = React.useState(location.state.data.currency)
    const [amount, setAmount] = React.useState(location.state.data.amount)
    const [category, setCategory] = React.useState([])
    const [interval, setInterval] = React.useState(location.state.data.interval)
    const [communitytype, setCommunitytype] = React.useState('')
    const [publicFanPage, setPublicFanPage] = useState(location.state.data.public)
    const [title, setTitle] = useState(location.state.data.name)
    const [planId, setPlanId] = useState(location.state.data.stripePlanId)
    // console.log(planId,'plannnnnn');

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }
    const SingleImage = (e, index) => {
        setImageError('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };
    const SingleProfileImage = (e, index) => {
        setImageError2('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError2('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle3.filter(x => x != null);
                    var data1 = statesingle4.filter(x => x != null);
                    setStatesingle3(data);
                    setStatesingle4(data1);
                    setStatesingle3(prevValue => prevValue.concat(fileArray));
                    setStatesingle4(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };
    const indexDelete2 = (e, index) => {
        var filteredpreview = statesingle3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle4.filter((val, i) => {
            return i !== index;
        });
        setStatesingle3(filteredpreview);
        setStatesingle4(filteredraw);
    }

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const validate = () => {
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        if (statesingle4?.length == 0) {
            setImageError2("This field is required.")
            return false
        }
        if (!publicFanPage) {
            if (!amount) {
                setError({ amount: "This field is required." })
                return false
            }
            if (!interval) {
                setError({ interval: "This field is required." })
                return false
            }
        }
        return true
    }

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    const handleChangeStatus = async (e) => {
        setPublicFanPage(e.target.checked)
    };

    const handleEditFanPage = (e, details) => {
        var valid = validate();
        console.log(valid, 'validvalid');
        // var validnew = validatenew();
        if (valid == true) {
            const formData = new FormData();
            formData.append('createdby[]', params && params.id);
            formData.append("addFanPageFrom", 'YogaStudio')
            formData.append("amount", amount);
            formData.append("interval", interval);
            formData.append("short_description", description);
            formData.append("currency", currency);
            formData.append("addFanPageFrom", 'studio')
            formData.append("subcategory_id", subcategoryId);
            formData.append("name", title);
            formData.append("public", publicFanPage);
            statesingle2.forEach((item) => formData.append("newimage", item));
            statesingle4.forEach((item) => formData.append("profile", item));

            console.log([...formData], "formdattttta")
            axios.put(process.env.REACT_APP_BASE_URL + `FanPage/updateFansPage/${planId}`, formData, { headers: headerformdata })
                .then((res) => {
                    console.log(res, "fan page");
                    setTruebutton(false);
                    enqueueSnackbar("Fan Page updated successfully", { variant: 'success' });
                    navigate(`/yogastudios/details/fans/${params && params.id}`)
                    setInterval('')
                    setTitle('')
                    setAmount('')
                    setStatesingle([]);
                    setStatesingle2([]);
                    setDescription('');
                    setStatesingle3([]);
                    setStatesingle4([]);
                }).catch((err) => {
                    setTruebutton(false);
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
                    console.log(err, "error");
                });
            // });
        }
    };

    return (
        <Box className='withSubmenu'>
            <MiniDrawer menuOrder={2} submenuOrder={4} />
            <Sidebar subtype={7} />
            <Box component="section" className="contentWraper">

                <Grid item xs={12} sm={12} md={8} lg={6}  >
                    <Box className='whiteContainer p-20'>
                        <Typography component='h6' className='formTitle mb-20'>Edit Fan Page</Typography>
                        <Box className="">

                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <FormControl variant="standard" fullWidth>
                                    <Typography component='div' className='label'> Title <Typography component='span' className='star'>*</Typography></Typography>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        className='textfield'
                                        placeholder='Title'
                                        name="title"
                                        value={title}
                                        onChange={(e) => { setTitle(e.target.value); setError(''); }}

                                    />
                                    {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={8} lg={12}>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}  >
                                <Typography component='div' className='label mb-10'>Cover Image<Typography component='span' className='star'> * </Typography></Typography>

                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                    {statesingle.length < 1 &&
                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                            {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                            <Grid item xs={12} sm={12} md={12}  >
                                {statesingle.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle.length > 0 ? statesingle.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                    <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}

                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}  >
                                <Typography component='div' className='label mb-10'>Profile Image<Typography component='span' className='star'> * </Typography></Typography>

                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                    {statesingle3.length < 1 &&
                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleProfileImage} hidden type="file" />
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                            {imageError2 && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError2}</p>}
                            <Grid item xs={12} sm={12} md={12}  >
                                {statesingle3.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle3.length > 0 ? statesingle3.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete2(e, i)}><Close /></Button>
                                                    <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}

                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                            </Grid>

                            <Box className='mb-20 '>
                                <Typography component='div' variant='body1' className='fz-14 semibold mr-10'>Public Fan Page ?   </Typography>
                                <FormControlLabel className='m-0' control={<Switch size="small" onChange={(e) => handleChangeStatus(e)} checked={publicFanPage} />} />
                            </Box>

                            {!publicFanPage ?
                                <><Grid item xs={12} sm={6} md={4} lg={3}>

                                    <Typography component='div' className='label'>Select Currency<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currency}
                                            placeholder="Select Currency"
                                            className="select"
                                            onChange={(e) => { setCurrency(e.target.value); setError(''); }}
                                        >
                                            <MenuItem disabled>Select Currency</MenuItem>
                                            <MenuItem value={"INR"}>INR</MenuItem>
                                            <MenuItem value={"USD"}>USD</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid><><Grid item xs={12} sm={6} md={4} lg={3}>
                                    <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'>Amount<Typography component='span' className='star'> * </Typography></Typography>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Amount'
                                            name="amount"
                                            onInput={(e) => onlyNumbers(e)}
                                            value={amount}
                                            onChange={(e) => { setAmount(e.target.value); setError(''); }} />
                                    </FormControl>
                                    {error.amount && <p style={{ color: 'red' }} className="fz-13 m-0">{error.amount}</p>}

                                </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>

                                            <Typography component='div' className='label'>Select Interval<Typography component='span' className='star'> * </Typography></Typography>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={interval ? interval : "sel"}
                                                    placeholder="Select Interval"
                                                    className="select"
                                                    onChange={(e) => { setInterval(e.target.value); setError(''); }}
                                                >
                                                    <MenuItem disabled value={'sel'}>Select Interval</MenuItem>
                                                    <MenuItem value={"week"}>Weekly</MenuItem>
                                                    <MenuItem value={"month"}>Monthly</MenuItem>
                                                    <MenuItem value={"year"}>Yearly</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {error.interval && <p style={{ color: 'red' }} className="fz-13 m-0">{error.interval}</p>}
                                        </Grid></></>
                                : ""}

                            <Grid item xs={12} sm={12} md={12}  >
                                <Box className="mb-10">
                                    <Typography component='div' className='label'>Short Description</Typography>

                                    <FormControl variant="standard" fullWidth>
                                        <textarea
                                            placeholder='Short Description '
                                            className='textarea'
                                            defaultValue={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            rows={4}
                                            cols={40}
                                            style={{ resize: 'none' }} />
                                    </FormControl>
                                </Box>
                            </Grid>

                            {truebutton === false ?
                                <>
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>

                                        {/* <Button onClick={handleAddService} variant="contained" className='theme-btn'>Submit</Button> */}
                                        <Button variant="contained" className='addbtn mr-10' onClick={(e) => handleEditFanPage(e, 'add')}>Save</Button>
                                        {/* <Button variant="contained" className='addbtn mr-10' onClick={(e) => handleAddService(e, 'addWithNavigate')}>Save + Continue</Button> */}
                                    </Box>
                                </>
                                :
                                truebutton === true &&
                                <>
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>

                                        <Button variant="contained" className='theme-btn  '>Please wait...</Button>
                                    </Box>
                                </>
                            }
                        </Box>
                        {/* } */}
                    </Box >
                </Grid>

            </Box >
        </Box >
    )
}