import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
	stepConnectorClasses,
} from "@mui/material/StepConnector";
import MiniDrawer from "../../../../../components/drawer";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Sidebar from "./../../Sidebar";
import {
	Button,
	Card,
	Box,
	CardContent,
	Dialog,
	DialogActions,
	Checkbox,
	ListItemText,
	Input,
	DialogContent,
	InputBase,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	InputAdornment,
	MenuItem,
	OutlinedInput,
	Select,
	Switch,
	TextField,
	Typography,
	TextareaAutosize,
	CardActions,
	Menu,
	RadioGroup,
	Radio,
	IconButton,
	Tooltip,
} from "@mui/material";
import { Category, Close, Dvr, ListAlt, WidthFull } from "@mui/icons-material";
import CompareIcon from "@mui/icons-material/Compare";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { usePlacesWidget } from "react-google-autocomplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import EmailEditor from "react-email-editor";
import { number } from "echarts";
import AutoComplete from "./Autocomplete";
import Sample from "./sample.json";
import VideocamIcon from "@mui/icons-material/Videocam";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 40,
	height: 20,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 0,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(20px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 18.5,
		height: 18.5,
	},
	"& .MuiSwitch-track": {
		borderRadius: 18.5 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#db0a07" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
	display: "flex",
	height: 22,
	alignItems: "center",
	...(ownerState.active && {
		color: "#784af4",
	}),
	"& .QontoStepIcon-completedIcon": {
		color: "#784af4",
		zIndex: 1,
		fontSize: 18,
	},
	"& .QontoStepIcon-circle": {
		width: 8,
		height: 8,
		borderRadius: "50%",
		backgroundColor: "currentColor",
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;
	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: "#f3714d",
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: "#f3714d",
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
	zIndex: 1,
	color: "#fff",
	width: 50,
	height: 50,
	display: "flex",
	borderRadius: "50%",
	justifyContent: "center",
	alignItems: "center",
	...(ownerState.active && {
		backgroundColor: "#f3714d",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	}),
	...(ownerState.completed && {
		backgroundColor: "#f3714d",
	}),
}));

function ColorlibStepIcon(props) {
	const { active, completed, className } = props;

	const icons = {
		1: <ListAlt />,
		2: <CompareIcon />,
		3: <Dvr />,
	};
	return (
		<ColorlibStepIconRoot
			ownerState={{ completed, active }}
			className={className}
		>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};

function BpRadio(props) {
	return (
		<Radio
			sx={{
				"&:hover": {
					bgcolor: "transparent",
				},
			}}
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	);
}
const BpIcon = styled("span")(({ theme }) => ({
	borderRadius: "50%",
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === "dark"
			? "0 0 0 1px rgb(16 22 26 / 40%)"
			: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
	backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
	backgroundImage:
		theme.palette.mode === "dark"
			? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
			: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
	".Mui-focusVisible &": {
		outline: "2px auto rgba(19,124,189,.6)",
		outlineOffset: 2,
	},
	"input:hover ~ &": {
		backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
	},
	"input:disabled ~ &": {
		boxShadow: "none",
		background:
			theme.palette.mode === "dark"
				? "rgba(57,75,89,.5)"
				: "rgba(206,217,224,.5)",
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: "#f3714d",
	backgroundImage:
		"linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
	"&:before": {
		display: "block",
		width: 16,
		height: 16,
		backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
		content: '""',
	},
	"input:hover ~ &": {
		backgroundColor: "#f3714d",
	},
});

const steps = ["Basic Information", "Event Information", "Event Description"];

export default function Service_Steppyform() {
	const params = useParams();
	const navigate = useNavigate();
	const auth = JSON.parse(localStorage.getItem("userData"));
	const headerlocal = {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token"),
	};
	const headerformdata = {
		"Content-Type": "multipart/form-data",
		Authorization: localStorage.getItem("token"),
	};
	const [activeStep, setActiveStep] = React.useState(0);
	const { enqueueSnackbar } = useSnackbar();
	const [event, setEvent] = React.useState({
		eventName: "",
		description: "",
		organizer: "",
		link: "",
	});
	const [truebutton, setTruebutton] = React.useState(false);
	const [error, setError] = React.useState({
		eventName: "",
		status: "",
		link: "",
		organizer: "",
		fromTime: "",
		toTime: "",
		video: "",
		location: "",
	});
	const [categoryId, setCategoryId] = React.useState("");
	const [subcategoryId, setSubCategoryId] = React.useState("");
	const [catError, setCatError] = React.useState("");
	const [subcatError, setSubCatError] = React.useState("");
	const [imageError, setImageError] = React.useState("");
	const [imageAddError, setImageAddError] = React.useState("");
	const [thumbimageAddError, setThumbImageAddError] = React.useState("");
	const [state1, setState1] = React.useState([]);
	const [state2, setState2] = React.useState([]);
	const [statesingle, setStatesingle] = React.useState([]);
	const [statesingle2, setStatesingle2] = React.useState([]);
	// const [convertedContent, setConvertedContent] = React.useState(null);
	const [description, setDescription] = React.useState("");
	const [richeditor, setRichtor] = React.useState("");
	const [editorState, setEditorState] = React.useState(
		EditorState.createEmpty()
	);
	const dataofeditor = `${richeditor}`;
	const [selectedFromDateTime, setSelectedFromDateTime] = useState(
		dayjs(new Date())
	);
	const [selectedToDateTime, setSelectedToDateTime] = useState(
		dayjs(new Date()).add(2, "hour")
	);
	const [selectedVideo, setSelectedVideo] = useState([]);
	const [videoPreviewUrl, setVideoPreviewUrl] = useState([]);
	const [category, setCategory] = React.useState([]);
	const [subcat, setSubcat] = React.useState([]);
	const [cityName, setCityName] = useState("");
	const [lat, setLat] = useState(0.0);
	const [lng, setLng] = useState(0.0);
	const [status, setStatus] = useState("");
	const [isToggled, setToggled] = React.useState(false);
	const [isPublic, setPublic] = React.useState(true);
	const [isPrimary, setPrimary] = React.useState(false);
	const [selectGallert, setSelectGallery] = useState("Image");
	const [tag, setTag] = React.useState([]);
	const [newTag, setNewTag] = useState("");
	const [checkboxValue, setCheckboxValue] = useState(true);
	const [tagError, setTagError] = useState("");
	const [designEditor, setDesignEditor] = useState(Sample);
	const [addEventFrom, setaddEventFrom] = useState("Teacher");
	const [yogaEvent, setYogaEvent] = React.useState(true);
	const [ads, setAds] = React.useState([]);
	const [selectedCard, setSelectedCard] = useState(null);
	const [selectOwner, setSelectOwner] = useState("No");
	const [addImg1, setAddImg1] = React.useState([]);
	const [addImg2, setAddImg2] = React.useState([]);
	const emailEditorRef = useRef({ editor: {} });
	const [addonsModal, setAddonsModal] = React.useState(false);
	const [numberOfEvents, setNumberOfEvents] = useState("");
	// const redirecturl = process.env.REACT_APP_REDIRECT_URL;
	const [open, setOpen] = React.useState(false);
	// const [amount] = useState("1000"); // Fixed amount value
	// const data = [
	//   { sectionName: "Event", amount_type: "INR", amount: "30", count: "3" },
	//   { sectionName: "Event", amount_type: "INR", amount: "50", count: "5" },
	// ];
	const handleNumberOfEventsChange = (event) => {
		setNumberOfEvents(event.target.value);
	};

	const handleCardClick = (item) => {
		setSelectedCard(item);
	};

	const handleSubmit = async () => {
		const body = {
			userId: auth._id,
			customerId: auth.stripeCustomerId,
			// AddOns : selectedCard // if selectedCard is an array
			AddOns: [selectedCard], // [selectedCard] because selectedCard is an object.. If multiple items can be selected push each item into selectedCard.
			redirectUrl:
				process.env.REACT_APP_REDIRECT_URL +
				`teachers/details/events/${auth._id}`,
		};

		await axios
			.put(
				process.env.REACT_APP_BASE_URL + `subscription/UpdateAddOnsByUserid`,
				body,
				{ headers: headerlocal }
			)
			.then((res) => {
				console.log(res.data?.PaymentLink, "update response console");
				window.open(res.data?.PaymentLink);
				onClose();
			})
			.catch((err) => {
				console.log(err, "UpdateAddOnsByUserid catch error");
			});
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handlePurchaseAddons = async () => {
		var someData = auth
			? auth.planId?.addOns?.filter((item) => item.sectionName == "Event")
			: []; //display only events addons
		// var someData = auth ? auth.planId?.addOns : [] // display all addons
		setEventAddons(someData);
		setAddonsModal(true);
		setOpen(false);
	};

	const onClose = () => {
		setAddonsModal(false);
	};

	const onLoad = () => {
		emailEditorRef.current.editor.loadDesign(designEditor);
	};

	React.useEffect(() => {
		if (dataofeditor) {
			try {
				const contentState = stateFromHTML(dataofeditor);
				setEditorState(EditorState.createWithContent(contentState));
			} catch (error) {
				console.error("Error during HTML to ContentState conversion:", error);
			}
		}
	}, [dataofeditor]);

	const handleEditGallery = (e) => {
		setSelectGallery(selectGallert === "Image" ? "Video" : "Image");
	};

	React.useEffect(() => {
		getCategory();
	}, []);

	React.useEffect(() => {
		getSubCategory();
	}, [categoryId]);

	const getCategory = async () => {
		await axios
			.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, {
				headers: headerlocal,
			})
			.then((res) => {
				setCategory(res.data.category);
			})
			.catch((error) => {
				console.log(error.response, "error");
			});
	};
	const getSubCategory = async () => {
		await axios
			.get(
				process.env.REACT_APP_BASE_URL +
				`SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`,
				{ headers: headerlocal }
			)
			.then((res) => {
				setSubcat(res.data.subcategory);
			})
			.catch((error) => {
				console.log(error.response, "error");
			});
	};

	const handleRemoveRestart = () => {
		setSubCategoryId("");
	};

	React.useEffect(() => {
		getTags();
	}, []);

	const getTags = async () => {
		try {
			const response = await axios.get(
				process.env.REACT_APP_BASE_URL + `Tags/GetTags`,
				{ headers: headerlocal }
			);
			setTag(response.data.Tag);
			console.log(response, "tags");
		} catch (error) {
			console.log(error.response, "error");
		}
	};

	const [selecttedTag, setSelecttedTag] = React.useState([]);
	const [tagName, setTagName] = React.useState([]);
	const handlechangeTag = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setTagName(typeof value === "string" ? value.split(",") : value);
		getTagval();
	};
	React.useEffect(() => {
		getTagval();
	}, [tagName]);

	function getTagval() {
		var array = [];
		const selecttedTag = tag.filter((content) =>
			tagName.includes(content.tagname)
		);
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedTag.map((item, i) => {
			var val = { tagname: item.tagname };
			Object.assign(item, val);
			array.push(val);
		});
		setSelecttedTag(array);
	}
	//console.log(selecttedCourse,"selecttedCourseselecttedCourse");

	let name, value;
	const handlechange = (e) => {
		name = e.target.name;
		value = e.target.value;

		setEvent({ ...event, [name]: value });

		setError('')
		setImageError('')
		setCatError('')
	};

	const multipleImgChange = (e, index) => {
		setImageAddError("");

		// Validate file size
		const maxSize = 1024 * 1024; // 1MB in bytes
		const files = e.target.files;
		var fileObj = [];
		var fileArray = [];
		var filepath = [];

		for (let i = 0; i < files.length; i++) {
			if (files[i].size > maxSize) {
				// Handle file size error (for example, set an error state)
				setImageAddError("Image size should be less than 1 MB.");
				continue; // Skip this file
			}

			fileObj.push(files[i]);
			fileArray.push(URL.createObjectURL(files[i]));
			filepath.push(files[i]);
		}

		var data = state1.filter((x) => x != null);
		var data1 = state2.filter((x) => x != null);
		setState1(data);
		setState2(data1);
		setState1((prevValue) => prevValue.concat(fileArray));
		setState2((prevValue) => prevValue.concat(filepath));
	};

	const indexDelete = (e, index) => {
		var filteredpreview = state1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = state2.filter((val, i) => {
			return i !== index;
		});
		setState1(filteredpreview);
		setState2(filteredraw);
	};

	const SingleImage = (e, index) => {
		setImageError("");
		setError("");

		var fileObj = [];
		var fileArray = [];
		var filepath = [];

		fileObj.push(e.target.files);

		for (let i = 0; i < fileObj[0].length; i++) {
			const img = new Image();
			img.src = URL.createObjectURL(fileObj[0][i]);

			img.onload = function () {
				if (fileObj[0][i].size > 1000000) {
					// 1 MB in bytes
					setImageError("Image size should be less than 1 MB.");
				} else {
					fileArray.push(URL.createObjectURL(fileObj[0][i]));
					filepath.push(fileObj[0][i]);

					var data = statesingle.filter((x) => x != null);
					var data1 = statesingle2.filter((x) => x != null);
					setStatesingle(data);
					setStatesingle2(data1);
					setStatesingle((prevValue) => prevValue.concat(fileArray));
					setStatesingle2((prevValue) => prevValue.concat(filepath));
				}
			};
		}
	};

	const indexDelete1 = (e, index) => {
		var filteredpreview = statesingle.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = statesingle2.filter((val, i) => {
			return i !== index;
		});
		setStatesingle(filteredpreview);
		setStatesingle2(filteredraw);
	};
	const handleNext = () => {

		const valid = validate();

		if (valid === true) {
			if (activeStep === 1) {
				const validatation = validatenew();

				if (validatation === true) {
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleToggle = () => {
		setToggled(!isToggled);
	};


	const handleTogglePublic = () => {
		setPublic(!isPublic);
	};


	const handleTogglePrimary = () => {
		setPrimary(!isPrimary);
	};

	const handleYogaEvent = () => {
		setYogaEvent(!yogaEvent);
	};

	const handleCheckboxChange = (event) => {
		setCheckboxValue(event.target.checked);
	};

	const handleEventVerify = (event) => {
		setCheckboxValue(event.target.value === "verify");
	};

	const handleEditOwner = (e) => {
		setSelectOwner(e.target.value);
	};

	//ads
	React.useEffect(() => {
		getAds();
	}, []);

	const getAds = async () => {
		try {
			const response = await axios.get(
				process.env.REACT_APP_BASE_URL + `AddManagement/getAddManagement`,
				{ headers: headerlocal }
			);
			setAds(response.data.getAddManagement);

		} catch (error) {
			console.log(error.response, "error");
		}
	};

	const [selecttedAds, setSelecttedAds] = React.useState([]);
	const [adsName, setAdsName] = React.useState([]);
	const [eventAddons, setEventAddons] = React.useState([]);
	const handlechangeAds = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setAdsName(typeof value === "string" ? value.split(",") : value);
		getAdsval();
	};
	React.useEffect(() => {
		getAdsval();
	}, [adsName]);

	function getAdsval() {
		var array = [];
		const selecttedAds = ads.filter((content) =>
			adsName.includes(content.title)
		);
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedAds.map((item, i) => {
			var val = { title: item.title };
			Object.assign(item, val);
			array.push(val);
		});
		setSelecttedAds(array);
	}

	let urlRegex =
		/^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
	const validate = () => {

		if (
			!event.eventName &&
			!status &&
			!event.organizer &&
			!categoryId &&
			statesingle2?.length === 0
		) {
			setError({
				eventName: "This field is required.",
				status: "This field is required.",
				organizer: "This field is required.",
				location: "This field is required.",
			});

			setCatError("This field is required.");
			setImageError("This field is required.");
			return false;
		}
		if (!event.eventName) {
			setError({ eventName: "This field is required." });
			return false;
		}
		if (!status) {
			setError({ status: "This field is required." });
			return false;
		}

		if (!event.organizer) {
			setError({ organizer: "This field is required." });
			return false;
		}
		if (!categoryId) {
			setCatError("This field is required.");
			return false;
		}

		if (event.link && !urlRegex.test(String(event.link))) {
			setError({ link: "Please enter valid URL" });
			return false;
		}
		if (status === "offline") {
			if (!cityName) {
				setError({ location: "This field is required." });
				return false;
			}
		}

		if (selectedFromDateTime && selectedToDateTime) {
			if (selectedFromDateTime.isSame(selectedToDateTime)) {
				setError((prevError) => ({
					...prevError,
					toTime: "End date cannot be the same as start date.",
				}));
				return false; // Exit function early if validation fails
			} else if (selectedToDateTime.isBefore(selectedFromDateTime)) {
				setError((prevError) => ({
					...prevError,
					toTime: "End date must be later than the start date.",
				}));
				return false; // Exit function early if validation fails
			}
		}
		// if (!cityName) {
		// 	setError({ location: "This field is required." })
		// 	return false
		// }
		// if (currentDate <= selectedFromDateTime ) {
		// 	setError({ fromTime: "Please select a date and time greater than the present date and time." })
		// 	return false
		// }
		// if (selectedFromDateTime < selectedToDateTime ) {
		// 	setError({ toTime: "Please select a date and time greater than the from date and time." })
		// 	return false
		// }

		if (statesingle2?.length === 0) {
			setImageError("This field is required.");
			return false;
		}





		return true;
	};

	const validatenew = () => {

		if (state2?.length === 0) {
			setImageAddError("This field img is required.");

			if (addImg2?.length === 0 && selectedVideo?.length === 0) {
				setThumbImageAddError("This field add is required.");
				setError({ video: "This field is required." });
				return false
			}

		}
		// else if (addImg2?.length === 0 && selectedVideo?.length === 0) {
		//   setThumbImageAddError("This field add is required.");
		//   setError({ video: "This field is required." });
		//   return false;
		// }

		// if (state2?.length === 0) {
		// 	setImageAddError("This (multiimag)field is required.")
		// 	return false
		// }
		// if (selectedVideo?.length === 0) {
		// 	setError({ video: "This (video)field is required." })
		// 	return false
		// }

		return true;
	};

	// const getEventsAddons = async () => {
	// 	await axios.get(`http://192.168.1.16:4000/Subscription/getSubscriptionPlansByid/66ac891d3260bc8c744b5434`)
	// 		.then(res => {
	// 			console.log(res.data, 'addons response')
	// 			setEventAddons([])
	// 		})
	// 		.catch(err => {
	// 			console.log(err, "error in getAddons")
	// 		})
	// }

	const handleAddService = () => {
		var multipleImage = state2.filter((x) => x != null);
		var valid = validate();
		var validnew = validatenew();
		if (valid === true && validnew === true) {
			setTruebutton(true);
			const unlayer = emailEditorRef.current?.editor;
			unlayer?.exportHtml((data) => {
				const { design, html } = data;
				const sanitizedHtml = DOMPurify.sanitize(html);
				const formData = new FormData();
				formData.append("addEventFrom", addEventFrom);
				formData.append("approve_event", true);
				formData.append("createdby[]", params && params.id);
				formData.append("event_name", event.eventName);
				formData.append("status", status);
				formData.append("event_Organizer", event.organizer);
				formData.append("category_id", categoryId);
				formData.append("Subcategory_id", subcategoryId);
				formData.append("fromTime", selectedFromDateTime);
				formData.append("ToTime", selectedToDateTime);
				formData.append("location_coord", cityName);
				formData.append("latitude", lat);
				formData.append("longitude", lng);
				formData.append("yogaevent", yogaEvent);
				formData.append("event_description", sanitizedHtml);
				formData.append(
					"DescriptionJSON",
					design && JSON.stringify(design.body)
				);
				formData.append("shortDescription", description);
				formData.append("link", event.link);
				formData.append("formatted_address", cityName);
				formData.append("mega_event", isToggled);
				formData.append("public", isPublic);
				formData.append("primary_event", isPrimary);
				// formData.append("verifybadgeEVent", checkboxValue)
				formData.append(
					"tags",
					JSON.stringify(selecttedTag.length > 0 ? selecttedTag : [])
				);
				formData.append(
					"gallery",
					selectGallert === "Image" ? "Image" : "Video"
				);
				formData.append("adsby", selectOwner === "Yes" ? "Yes" : "No");
				formData.append(
					"ads",
					JSON.stringify(selecttedAds.length > 0 ? selecttedAds : [])
				);
				{
					statesingle2
						? statesingle2.forEach((item) => {
							formData.append("newimage", item);
						})
						: formData.append("newimage", "");
				}
				{
					selectGallert === "Image" ? (
						<>
							{multipleImage
								? multipleImage.forEach((item) => {
									formData.append("additionalimages", item);
								})
								: formData.append("additionalimages", "")}
							{selectedVideo
								? selectedVideo.forEach((item) => {
									formData.append("Video", "");
								})
								: formData.append("Video", "")}
						</>
					) : (
						<>
							{multipleImage
								? multipleImage.forEach((item) => {
									formData.append("additionalimages", "");
								})
								: formData.append("additionalimages", "")}
							{selectedVideo
								? selectedVideo.forEach((item) => {
									formData.append("Video", item);
								})
								: formData.append("Video", "")}
							{addImg2
								? addImg2.forEach((item) => {
									formData.append("thumbnail", item);
								})
								: formData.append("thumbnail", "")}
						</>
					);
				}
				// function convertLatLongToNumbers(formData) {
				// 	const tempData = {};
				// 	for (let pair of formData.entries()) {
				// 		const key = pair[0].trim();
				// 		let value = pair[1];
				// 		if (key === 'latitude' || key === 'longitude') {
				// 			value = parseFloat(value);
				// 		}
				// 		tempData[key] = value;
				// 	}
				// 	formData = new FormData();
				// 	for (const [key, value] of Object.entries(tempData)) {
				// 		formData.append(key, value);
				// 	}
				// 	return formData;
				// }
				console.log([...formData], "formDatain event");
				// const convertedFormData = convertLatLongToNumbers(formData);
				axios
					.post(process.env.REACT_APP_BASE_URL + `Event/AddEvent`, formData, {
						headers: headerformdata,
					})
					.then((res) => {
						setTruebutton(false);
						navigate(`/teachers/details/events/${params && params.id}`);
						setEvent({
							eventName: "",
							description: "",
							organizer: "",
							link: "",
						});
						setCategoryId("");
						setSubCategoryId("");
						setState1([]);
						setState2([]);
						enqueueSnackbar("Event added successfully", { variant: "success" });
					})
					.catch((err) => {
						setTruebutton(false);
						enqueueSnackbar(
							err.response && err.response.data.error
								? err.response.data.error
								: "Something went wrong.",
							{ variant: "error" }
						);
						if (err.response.data?.status == 3) {
							setOpen(true);
						}
						console.log(err, "error");
					});
			});
		}
	};

	console.log(cityName, "cityNamecityNamecityNamecityName");

	const handleFromDateTimeChange = (newValue) => {
		console.log(newValue);
		setSelectedFromDateTime(newValue);
		setError((prevError) => ({ ...prevError, fromTime: "" }));
	};
	const handleToDateTimeChange = (newValue) => {
		setSelectedToDateTime(newValue);
		setError((prevError) => ({ ...prevError, toTime: "" }));
	};

	const handleVideoChange = (e, index) => {
		setError({ video: "" });

		var fileObj = [];
		var fileArray = [];
		var filepath = [];
		fileObj.push(e.target.files);
		for (let i = 0; i < fileObj[0].length; i++) {
			if (fileObj[0][i].size > 25 * 1024 * 1024) {
				// Corrected comparison
				setError({ video: "Video should be less than 25 MB" });
				return;
			}
			fileArray.push(URL.createObjectURL(fileObj[0][i]));
			filepath.push(fileObj[0][i]);
		}

		setVideoPreviewUrl(fileArray);
		setSelectedVideo(filepath);
	};

	const clearVideo = () => {
		// alert("jiii")
		setSelectedVideo([]);
		setVideoPreviewUrl([]);
	};

	console.log(selectedCard, "selectedCard");

	// const [loc_coordinates, setLoc_coordinates] = useState({ type: "Point", coordinates: ['', ''] })

	// const { ref, autocompleteRef } = usePlacesWidget({

	// 			apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
	// 	onPlaceSelected: (place) => {
	// 		console.log(place, 'placeplace123');
	// 		setCityName(place.formatted_address)
	// 		setLat(place.geometry.location.lat());
	// 		setLng(place.geometry.location.lng());
	// 		setLoc_coordinates(prevState => ({
	// 			...prevState,
	// 			coordinates: [place.geometry.location.lat(), place.geometry.location.lng()]
	// 		}));
	// 		setError(prevError => ({ ...prevError, location: '' }))
	// 	},
	// 	options: {
	// 		types: "(regions)" | 'establishment'
	// 	},
	// });
	console.log(selectGallert, "selectGallertselectGallertselectGallert");

	const handleNewTagChange = (e) => {
		setNewTag(e.target.value);
	};

	const validationtag = () => {
		if (!newTag) {
			setTagError("Fill the field to add new tag");
			return false;
		}
		return true;
	};

	const AdditionalImgChange = (e) => {
		setThumbImageAddError("");

		var fileObj = [];
		var fileArray = [];
		var filepath = [];

		fileObj.push(e.target.files);
		for (let i = 0; i <= fileObj[0].length; i++) {
			if (i < fileObj[0].length) {
				fileArray.push(URL.createObjectURL(fileObj[0][i]));
				filepath.push(fileObj[0][i]);
			}
		}
		setAddImg1((prevValue) => prevValue.concat(fileArray));
		setAddImg2((prevValue) => prevValue.concat(filepath));
		// setError(prevError => ({ ...prevError, : '' }))
	};

	const additionalDelete = (e, index) => {
		var filteredpreview = addImg1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = addImg2.filter((val, i) => {
			return i !== index;
		});
		setAddImg1(filteredpreview);
		setAddImg2(filteredraw);
	};

	const handleAddNewTag = () => {
		var validtag = validationtag();
		if (validtag == true) {
			let body = {
				tagname: newTag,
				createdby: auth && auth._id,
			};
			axios
				.post(
					process.env.REACT_APP_BASE_URL + "tags/addTags",
					body,
					headerformdata
				)
				.then((res) => {
					enqueueSnackbar("Tag added successfully", { variant: "success" });
					console.log(res, "tags addedd");
					setTag([...tag, { _id: res.data._id, tagname: newTag }]);
					setTagName([...tagName, newTag]);
					setNewTag("");
				})
				.catch((err) => {
					enqueueSnackbar(
						err && err.response && err.response.data.message
							? err.response.data.message
							: "Something went wrong",
						{ variant: "error" }
					);
					console.log(err);
				});
		}
	};

	const handleEditor = (e) => {
		const unlayer = emailEditorRef.current?.editor;
		unlayer?.exportHtml((data) => {
			const { design, html } = data;
			const sanitizedHtml = DOMPurify.sanitize(html);
			const editor = { body: design.body };
			setDesignEditor(editor);
			enqueueSnackbar("Saved changes", { variant: "info" });
		});
	};

	return (
		// <Box className='withSubmenu'>
		<Box className={auth.role_id == 1 ? "withSubmenu" : ""}>
			{auth.role_id == 1 ? (
				<MiniDrawer menuOrder={2} submenuOrder={1} />
			) : (
				<MiniDrawer menuOrder={3} />
			)}
			{auth.role_id == 1 ? <Sidebar subtype={0} /> : ""}
			<Box component="section" className="contentWraper">
				<Box className="whiteContainer p-20">
					<Typography component="h6" className="formTitle">
						Add Events
					</Typography>

					<Stepper
						alternativeLabel
						activeStep={activeStep}
						connector={<ColorlibConnector />}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>
									{label}
								</StepLabel>
							</Step>
						))}
					</Stepper>
					{activeStep === 0 && (
						<Box className="steppy_content">
							<Box className="maxw_700">
								<Box className="mb-20">
									<Typography component="div" className="label">
										Event Name{" "}
										<Typography component="span" className="star">
											*
										</Typography>
									</Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder="Event Name"
											size="small"
											name="eventName"
											className="textfield"
											style={{ width: "100%" }}
											value={event.eventName}
											onChange={(e) => {
												handlechange(e);
												setError((prevError) => ({
													...prevError,
													eventName: "",
												}));
											}}
										/>
										{error.eventName && (
											<p style={{ color: "red" }} className="fz-13 m-0">
												{error.eventName}
											</p>
										)}
									</FormControl>
								</Box>

								<Box className="mb-20">
									<FormControlLabel
										className="checkbox_list"
										control={
											// <Checkbox
											// 	checked={checkboxValue}
											// 	onChange={handleEventVerify}
											// 	name='checkbox-value'
											// 	color='primary'
											// />
											<RadioGroup
												className="checkbox_list "
												value={checkboxValue ? "verify" : "guarantee"}
												onChange={handleEventVerify}
											>
												<Grid container spacing={1} alignItems="center">
													<Grid item xs={12} sm={6}>
														<FormControlLabel
															value="verify"
															control={<BpRadio color="primary" />}
															label={
																<Typography
																	component="div"
																	className="label mb-0"
																>
																	Verified
																</Typography>
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6}>
														<FormControlLabel
															value="guarantee"
															control={<BpRadio color="primary" />}
															label={
																<Typography
																	component="div"
																	className="label mb-0"
																>
																	Guaranteed
																</Typography>
															}
														/>
													</Grid>
												</Grid>
											</RadioGroup>
										}
										label={
											<Typography component="div" className="label mb-0">
												Do you want this event verified or guaranteed?{" "}
											</Typography>
										}
									/>
									<FormControlLabel
										className="checkbox_list"
										control={
											<Checkbox
												checked={isToggled}
												onChange={handleToggle}
												name="checkbox-value"
												color="primary"
											/>
										}
										label={
											<Typography component="div" className="label mb-0">
												Do you want to add this event as Mega Event?{" "}
											</Typography>
										}
									/>
									<FormControlLabel
										className="checkbox_list"
										control={
											<Checkbox
												checked={isPublic}
												onChange={handleTogglePublic}
												name="checkbox-value"
												color="primary"
											/>
										}
										label={
											<Typography component="div" className="label mb-0">
												Do you want to make this event as Public?{" "}
											</Typography>
										}
									/>
									<FormControlLabel
										className="checkbox_list"
										control={
											<Checkbox
												checked={isPrimary}
												onChange={handleTogglePrimary}
												name="checkbox-value"
												color="primary"
											/>
										}
										label={
											<Typography component="div" className="label mb-0">
												Do you want to make this event as Primary?{" "}
											</Typography>
										}
									/>
									<FormControlLabel
										className="checkbox_list"
										control={
											<Checkbox
												checked={yogaEvent}
												onChange={handleYogaEvent}
												name="checkbox-value"
												color="primary"
											/>
										}
										label={
											<Typography component="div" className="label mb-0">
												Is this Event a yogaevent.com event?{" "}
											</Typography>
										}
									/>
								</Box>

								<Box className="mb-20">
									<Typography component="div" className="label">
										Select Mode
										<Typography component="span" className="star">
											*
										</Typography>
									</Typography>
									<FormControl fullWidth>
										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={status ? status : "sel"}
											placeholder="Select mode of event"
											className="select"
											onChange={(e) => {
												setStatus(e.target.value);
												setError((prevError) => ({ ...prevError, status: "" }));
											}}
										>
											<MenuItem disabled value={"sel"}>
												Select status of event
											</MenuItem>
											<MenuItem value={"online"}>Online</MenuItem>
											<MenuItem value={"offline"}>Offline</MenuItem>
										</Select>
										{error.status && (
											<p style={{ color: "red" }} className="fz-13 m-0">
												{error.status}
											</p>
										)}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography component="div" className="label">
										Select Tags
										<Typography component="span" className="star"></Typography>
									</Typography>
									<FormControl fullWidth>
										<Select
											labelId="demo-multiple-checkbox-label"
											id="demo-multiple-checkbox"
											multiple
											className="select"
											value={tagName}
											onChange={(e) => handlechangeTag(e)}
											input={<OutlinedInput />}
											renderValue={(selected) => {
												const selecttedTag = tag.filter((content) =>
													selected.includes(content.tagname)
												);
												if (selecttedTag.length === 0) {
													return "Any Tags";
												} else if (selecttedTag.length === 1) {
													return selecttedTag[0].tagname;
												} else {
													return `${selecttedTag.length} Tags selected`;
												}
											}}
											MenuProps={MenuProps}
										>
											{tag && tag.length > 0 ? (
												tag.map((name) => (
													<MenuItem key={name._id} value={name.tagname}>
														<Checkbox
															checked={tagName.indexOf(name.tagname) > -1}
														/>
														<ListItemText primary={name.tagname} />
													</MenuItem>
												))
											) : (
												<MenuItem>No user found</MenuItem>
											)}
										</Select>
										{/* <p className='error-field'>{error.userselect}</p> */}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography component="div" className="label">
										New Tag
									</Typography>
									<Box className="flex-center align-items-start">
										<FormControl
											variant="standard"
											fullWidth
											className="flex-1"
										>
											<TextField
												id="filled-size-small"
												variant="outlined"
												placeholder="New Tag"
												size="small"
												name="newTag"
												className="textfield mr-10 "
												value={newTag}
												onChange={handleNewTagChange}
											/>
											{tagError && (
												<p style={{ color: "red" }} className="fz-13 m-0">
													{tagError}
												</p>
											)}
										</FormControl>
										<Button
											onClick={handleAddNewTag}
											variant="contained"
											className="theme-btn "
										>
											Add Tag
										</Button>
									</Box>
								</Box>

								<Box className="mb-20">
									<Typography component="div" className="label">
										Event Organizer{" "}
										<Typography component="span" className="star">
											*
										</Typography>
									</Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder="Event Organizer"
											size="small"
											name="organizer"
											className="textfield"
											style={{ width: "100%" }}
											value={event.organizer}
											onChange={(e) => {
												handlechange(e);
												setError((prevError) => ({ ...prevError, organizer: "" }));
											}}

										/>
										{error.organizer && (
											<p style={{ color: "red" }} className="fz-13 m-0">
												{error.organizer}
											</p>
										)}
									</FormControl>
								</Box>

								{/* <Box className="mb-20 ">
									<Typography component='div' className='label'>Would you like to display this ads?</Typography>
									<RadioGroup
										row
										aria-labelledby="demo-customized-radios"
										name="payment_type"
										onChange={handleEditOwner}
										value={selectOwner}
									>
										<FormControlLabel
											value="Yes"
											className='radio-btns'
											control={<BpRadio />}
											label={<Typography className='fz-14'>Yes</Typography>}
										/>
										<FormControlLabel
											value="No"
											className='radio-btns'
											control={<BpRadio />}
											label={<Typography className='fz-14'>No</Typography>}
										/>
									</RadioGroup>
								</Box> */}

								{/* {selectOwner === 'Yes' && (
									<Grid>
										<Typography component='div' className='label'>Select Ads</Typography>
										<FormControl fullWidth>
											<Select
												labelId="demo-multiple-checkbox-label"
												id="demo-multiple-checkbox"
												multiple
												className='select'
												value={adsName}
												onChange={handlechangeAds}
												input={<OutlinedInput />}
												renderValue={(selected) => {
													const selectedAds = ads.filter(content => selected.includes(content.title));
													if (selectedAds.length === 0) {
														return "Any ads";
													} else if (selectedAds.length === 1) {
														return selectedAds[0].title;
													} else {
														return `${selectedAds.length} ads selected`;
													}
												}}
												MenuProps={MenuProps}
											>
												{ads && ads.length > 0 ? ads.map((name) => (
													<MenuItem key={name._id} value={name.title}>
														<Checkbox checked={adsName.indexOf(name.title) > -1} />
														<ListItemText primary={name.title} />
													</MenuItem>
												)) : <MenuItem>No ads found</MenuItem>}
											</Select>
										</FormControl>
									</Grid>
								)} */}

								<Box className="mb-20">
									<Typography component="div" className="label">
										Select Category{" "}
										<Typography component="span" className="star">
											*
										</Typography>
									</Typography>
									<FormControl fullWidth>
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={categoryId ? categoryId : "sel"}
											onChange={(e) => {
												setCategoryId(e.target.value);
												setCatError("");
											}}
										>
											<MenuItem disabled value={"sel"}>
												Select category
											</MenuItem>
											{category && category?.length > 0 ? (
												category.map((data) => (
													<MenuItem value={data._id} key={data._id}>
														{data.category_title}
													</MenuItem>
												))
											) : (
												<MenuItem disabled>{"No Record's found"}</MenuItem>
											)}
										</Select>
										{catError && (
											<p style={{ color: "red" }} className="fz-13 m-0">
												{catError}
											</p>
										)}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography
										component="div"
										className="label"
										style={{ marginRight: "10px" }}
									>
										Select Sub Category
									</Typography>
									<FormControl fullWidth style={{ flex: 1 }}>
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											disabled={categoryId !== "" ? false : true}
											value={subcategoryId ? subcategoryId : "sel18"}
											onChange={(e) => {
												setSubCategoryId(e.target.value);
												setSubCatError("");
												setError({ servicename: "" });
												setCatError("");
												setSubCatError("");
											}}
										>
											<MenuItem disabled value={"sel18"}>
												Select sub category
											</MenuItem>
											{subcat && subcat?.length > 0 ? (
												subcat.map((data5) => (
													<MenuItem value={data5._id}>
														{data5.subcategory_title}
													</MenuItem>
												))
											) : (
												<MenuItem disabled>{"No Record's found"}</MenuItem>
											)}
										</Select>
										{subcatError && (
											<p style={{ color: "red" }} className="fz-13 m-0">
												{subcatError}
											</p>
										)}
									</FormControl>
									{subcategoryId !== "" && (
										<RestartAltIcon
											style={{ cursor: "pointer", marginLeft: "10px" }}
											onClick={() => handleRemoveRestart()}
										/>
									)}
								</Box>

								<Box className="mb-20">
									<Typography component="div" className="label">
										Link
									</Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder="Link"
											size="small"
											name="link"
											className="textfield"
											style={{ width: "100%" }}
											value={event.link}
											onChange={(e) => {
												handlechange(e);
											}}
										/>
										{error.link && (
											<p style={{ color: "red" }} className="fz-13 m-0">
												{error.link}
											</p>
										)}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography component="div" className="label">
										Start Date{" "}
										<Typography component="span" className="star">
											*
										</Typography>
									</Typography>
									<FormControl
										variant="standard"
										fullWidth
										className="datepicker"
									>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer
												components={["DateTimePicker", "DateTimePicker"]}
											>
												<DateTimePicker
													onChange={handleFromDateTimeChange}
													value={selectedFromDateTime && selectedFromDateTime}
													minDate={dayjs()}
													renderInput={(props) => <TextField {...props} />}
													format="DD-MM-YYYY hh:mm a"
													viewRenderers={{
														hours: renderTimeViewClock,
														minutes: renderTimeViewClock,
														seconds: renderTimeViewClock,
													}}
													className="textfield"
												/>
											</DemoContainer>
										</LocalizationProvider>
										{error.fromTime && (
											<p style={{ color: "red" }} className="fz-13 m-0">
												{error.fromTime}
											</p>
										)}
									</FormControl>
								</Box>
								<Box className="mb-20">
									<Typography component="div" className="label">
										End Date
										<Typography component="span" className="star"></Typography>
									</Typography>
									<FormControl
										variant="standard"
										fullWidth
										className="datepicker"
									>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer
												components={["DateTimePicker", "DateTimePicker"]}
											>
												<DateTimePicker
													size="small"
													className="textfield"
													onChange={handleToDateTimeChange}
													value={selectedToDateTime && selectedToDateTime}
													minDate={dayjs(selectedFromDateTime)}
													renderInput={(props) => <TextField {...props} />}
													format="DD-MM-YYYY hh:mm a"
													viewRenderers={{
														hours: renderTimeViewClock,
														minutes: renderTimeViewClock,
														seconds: renderTimeViewClock,
													}}
												/>
											</DemoContainer>
										</LocalizationProvider>
										{error.toTime && (
											<p style={{ color: "red" }} className="fz-13 m-0">
												{error.toTime}
											</p>
										)}
									</FormControl>
								</Box>

								{status === "offline" && (
									<Box className="mb-20">
										<Typography component="div" className="label">
											Location
											<Typography component="span" className="star">
												*
											</Typography>
										</Typography>
											<AutoComplete
												cityName={cityName}
												setError={setError}
												setCityName={setCityName}
												lat={lat}
												setLat={setLat}
												lng={lng}
												setLng={setLng}
												className="textfield"
											/>
										{error.location && (
											<p style={{ color: "red" }} className="fz-13 m-0">
												{error.location}
											</p>
										)}
									</Box>
								)}



								<Grid item xs={12} sm={12} md={12}>
									<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
										<Typography component="div" className="label">
											Primary Image{" "}
											<Typography component="span" className="star">
												{" "}
												*{" "}
											</Typography>
										</Typography>
										{statesingle.length < 1 && (
											<Button
												className="upload_btn"
												variant="contained"
												component="label"
											>
												<Box
													sx={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}
												>
													<FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
													Upload
												</Box>
												<input
													accept={"image/png, image/jpg,image/jpeg"}
													onChange={SingleImage}
													hidden
													type="file"
												/>
											</Button>
										)}
									</Box>
								</Grid>
								{imageError && (
									<p style={{ color: "red" }} className="fz-13 m-0">
										{imageError}
									</p>
								)}
								<Grid item xs={12} sm={12} md={12}>
									{statesingle.length > 0 && (
										<Grid
											container
											columnSpacing={1}
											rowSpacing={1}
											className="multiple_upload_container mb-10"
										>
											{statesingle.length > 0
												? statesingle.map((x, i) => (
													<Grid item xs={6} sm={4} md={4} lg={4}>
														<Box className="uploaded_img">
															<Button
																className="close_icon"
																onClick={(e) => indexDelete1(e, i)}
															>
																<Close />
															</Button>
															<img src={x} alt="" height={155} width={195} />
														</Box>
													</Grid>
												))
												: ""}
										</Grid>
									)}
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Typography
										className=" fz-12 mt-10 mb-20"
										component="p"
										variant="p"
									>
										<strong>Note:</strong> Image size should be more than 280px
										* 280px for better quality picture.
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<Box className="mb-10">
										<Typography component="div" className="label">
											Short Description{" "}
										</Typography>
										<FormControl variant="standard" fullWidth>
											<textarea
												placeholder="Short Description "
												className="textarea"
												defaultValue={description}
												onChange={(e) => setDescription(e.target.value)}
												rows={4}
												cols={40}
											/>
										</FormControl>
									</Box>
								</Grid>
								{activeStep === 0 && (
									<>
										<Box
											className="heading-btns mt-40"
											style={{ textAlign: "end" }}
										>
											<Button
												onClick={handleNext}
												variant="contained"
												className="theme-btn  "
											>
												Next
											</Button>
										</Box>
									</>
								)}
							</Box>
						</Box>
					)}
					{activeStep === 1 && (
						<Box className="steppy_content mb-10">
							<Grid
								container
								columnSpacing={2}
								rowSpacing={2}
								className=" mb-10"
							>
								<Grid item xs={12} sm={12} md={6} lg={4}>
									<Typography component="div" className="label">
										Images/Video{" "}
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={8}>
									<Box>
										<RadioGroup
											row
											aria-labelledby="demo-customized-radios"
											name="payment_type"
											onChange={handleEditGallery}
											value={selectGallert}
										>
											<FormControlLabel
												value="Image"
												className="radio-btns"
												control={<BpRadio />}
												label={
													<Typography className="fz-14">
														Additional images
													</Typography>
												}
											/>
											<FormControlLabel
												value="Video"
												className="radio-btns"
												control={<BpRadio />}
												label={<Typography className="fz-14">Video</Typography>}
											/>
										</RadioGroup>
									</Box>
								</Grid>
								{selectGallert === "Image" ? (
									<>
										<Grid item xs={12} sm={12} md={12}>
											<Box className="mb-10 ">
												<Typography component="div" className="label">
													Additional Images
													<Typography component="span" className="star">
														*
													</Typography>
												</Typography>

												{state1.length < 1 && (
													<Button
														className="multiple_upload_btn"
														variant="contained"
														component="label"
													>
														<Box
															sx={{
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
															}}
														>
															<FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
															Upload
														</Box>
														<input
															accept={"image/png, image/jpg,image/jpeg"}
															onChange={multipleImgChange}
															hidden
															multiple
															type="file"
														/>
													</Button>
												)}
											</Box>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											{state1.length > 0 && (
												<Grid
													container
													columnSpacing={1}
													rowSpacing={1}
													className="multiple_upload_container mb-10"
												>
													{state1.length > 0
														? state1.map((x, i) => (
															<Grid item xs={6} sm={4} md={3} lg={3}>
																<Box className="uploaded_img">
																	<Button
																		className="close_icon"
																		onClick={(e) => indexDelete(e, i)}
																	>
																		<Close />
																	</Button>
																	<img
																		src={x}
																		alt=""
																		height={155}
																		width={195}
																	/>
																</Box>
															</Grid>
														))
														: ""}
													<Grid item xs={6} sm={4} md={3} lg={3}>
														<label
															htmlFor="contained-button-file1"
															className="w-full"
														>
															<Box className="multiple_upload_btn">
																<Input
																	inputProps={{
																		accept: "image/png, image/jpg,image/jpeg",
																	}}
																	onChange={multipleImgChange}
																	style={{ display: "none" }}
																	id="contained-button-file1"
																	type="file"
																/>
																<Box
																	sx={{
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																	}}
																>
																	<FileUploadOutlinedIcon />
																</Box>
															</Box>
														</label>
													</Grid>
												</Grid>
											)}
											<Typography
												className=" fz-12 mt-10 mb-10"
												component="p"
												variant="p"
											>
												<strong>Note:</strong> Image size should be more than
												280px * 280px for better quality picture.
											</Typography>
											{imageAddError && (
												<p style={{ color: "red" }} className="fz-13 m-0">
													{imageAddError}
												</p>
											)}
										</Grid>
									</>
								) : (
									<>
										{/* <Grid item xs={12} sm={12} md={12}>
										<Typography component='div' className='label'>Video</Typography>
										<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
											{selectedVideo && selectedVideo?.length === 0 &&
												<Button className="multiple_upload_btn" variant="contained" component="label">
													<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
														<FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
													</Box>
													<input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
												</Button>
											}
											<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
												{selectedVideo && selectedVideo?.length > 0 &&
													<Grid item xs={6} sm={4} md={4} lg={4} >
														<Box  >
															<Button className='close_icon' onClick={clearVideo}><Close /></Button>
															<video className='uploaded_vdo' controls>
																<source src={videoPreviewUrl} type={selectedVideo.type} />
															</video>
														</Box></Grid>}
											</Grid>
										</Box>
										{error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}
									</Grid> */}
										<Grid item xs={12} sm={12} md={12}>
											<label className="form-labels" for="my-input">
												Thumbnail Images
											</label>
											<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
												{addImg1.length < 1 && (
													<Button
														className="multiple_upload_btn"
														variant="contained"
														component="label"
													>
														<Box
															sx={{
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
															}}
														>
															<FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
															Upload
														</Box>
														<input
															accept={"image/png, image/jpg,image/jpeg"}
															onChange={AdditionalImgChange}
															hidden
															multiple
															type="file"
														/>
													</Button>
												)}
											</Box>

											{thumbimageAddError && (
												<p style={{ color: "red" }} className="fz-13 m-0">
													{thumbimageAddError}
												</p>
											)}

											<Grid item xs={12} sm={12} md={12}>
												{addImg1.length > 0 && (
													<Grid
														container
														columnSpacing={1}
														rowSpacing={1}
														className="multiple_upload_container mb-10"
													>
														{addImg1.length > 0
															? addImg1.map((x, i) => (
																<Grid item xs={6} sm={4} md={4} lg={4}>
																	<Box className="uploaded_img">
																		<Button
																			className="close_icon"
																			onClick={(e) => additionalDelete(e, i)}
																		>
																			<Close />
																		</Button>
																		<img
																			src={x}
																			alt=""
																			height={155}
																			width={195}
																		/>
																	</Box>
																</Grid>
															))
															: ""}
													</Grid>
												)}
											</Grid>
											<Grid item xs={12} sm={12} md={12} lg={12}>
												<Typography
													className=" fz-12 mt-10 mb-10"
													component="p"
													variant="p"
												>
													<strong>Note:</strong> Image size should be more than
													1920px * 1000px for better quality picture.
												</Typography>
											</Grid>

											<Grid item xs={12} sm={12} md={12}>
												<Typography component="div" className="label">
													Video
												</Typography>
												<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
													{selectedVideo && selectedVideo?.length === 0 && (
														<Button
															className="multiple_upload_btn"
															variant="contained"
															component="label"
														>
															<Box
																sx={{
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																}}
															>
																<FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
																Upload
															</Box>
															<input
																id="video-upload"
																accept="video/*"
																onChange={handleVideoChange}
																hidden
																type="file"
															/>
														</Button>
													)}
													<Grid
														container
														columnSpacing={1}
														rowSpacing={1}
														className="multiple_upload_container mb-10"
													>
														{selectedVideo && selectedVideo?.length > 0 && (
															<Grid item xs={6} sm={4} md={4} lg={4}>
																<Box>
																	<Button
																		className="close_icon"
																		onClick={clearVideo}
																	>
																		<Close />
																	</Button>
																	<video className="uploaded_vdo" controls>
																		<source
																			src={videoPreviewUrl}
																			type={selectedVideo.type}
																		/>
																	</video>
																</Box>
															</Grid>
														)}
													</Grid>
												</Box>
												{error.video && (
													<p style={{ color: "red" }} className="fz-13 m-0">
														{error.video}
													</p>
												)}
											</Grid>
										</Grid>
									</>
								)}

								{activeStep === 1 && (
									<Grid item xs={12} sm={12} md={12}>
										<Box
											className="heading-btns mt-40"
											style={{ textAlign: "end" }}
										>
											<Button
												onClick={handleBack}
												variant="contained"
												className="theme-btn  mr-10"
											>
												Back
											</Button>

											<Button
												onClick={handleNext}
												variant="contained"
												className="theme-btn  "
											>
												Next
											</Button>
										</Box>
									</Grid>
								)}
							</Grid>
						</Box>
					)}

					{activeStep === 2 && (
						<Box className="steppy_content mb-10">
							<Grid item xs={12} sm={12} md={12} lg={12} className="mb-10">
								<EmailEditor
									ref={emailEditorRef}
									onLoad={onLoad}
									projectId={144031}
									tools={{
										"custom#product_tool": {
											// data: { products },
											// properties: {
											// 	productLibrary: {
											// 		editor: {
											// 			data: {
											// 				products,
											// 			},
											// 		},
											// 	},
											// },
										},
									}}
									options={{
										designTags: {
											business_name: "My business",
											current_user_name: "Ron",
										},
										customCSS: [
											"https://examples.unlayer.com/examples/product-library-tool/productTool.css",
										],
										customJS: [
											window.location.protocol +
											"//" +
											window.location.host +
											"/custom.js",
										],
									}}
								/>
							</Grid>
							<Box className="heading-btns mt-20" style={{ textalign: "end" }}>
								<Button
									onClick={handleEditor}
									variant="contained"
									className="theme-btn  mr-10"
								>
									SaveDesign
								</Button>
							</Box>
						</Box>
					)}
					{/* <>
						{activeStep === 2 && truebutton === false ?
							<>
								<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
									<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
									<Button onClick={handleAddService} variant="contained" className='theme-btn'>Submit</Button>
								</Box>
							</> : activeStep === 2 && truebutton === true && <>
								<Box className='heading-btns mt-20' style={{ textalign: 'end' }}>
									<Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
									<Button variant="contained" className='theme-btn  mr-10'>Please wait...</Button>
								</Box>
							</>}</> */}
					{activeStep === 2 && (
						<Box className="heading-btns mt-20" style={{ textAlign: "end" }}>
							<Button
								onClick={handleBack}
								variant="contained"
								className="theme-btn  mr-10"
							>
								Back
							</Button>
							{truebutton === false ? (
								<Button
									onClick={handleAddService}
									variant="contained"
									className="theme-btn"
								>
									Submit
								</Button>
							) : (
								<Button variant="contained" className="theme-btn  mr-10">
									Please wait...
								</Button>
							)}
						</Box>
					)}
				</Box>

				<Dialog open={addonsModal} onClose={onClose} maxWidth="sm" fullWidth>
					<DialogTitle>Select Details</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							{eventAddons && eventAddons.length > 0 ? (
								eventAddons.map((item, index) => (
									<Grid item xs={12} sm={6} md={4} key={index}>
										<Card
											variant="outlined"
											sx={{
												cursor: "pointer",
												"&:hover": { boxShadow: 3 },
												border:
													selectedCard?.count === item?.count
														? "2px solid #1976d2"
														: "1px solid #e0e0e0",
											}}
											onClick={() => handleCardClick(item)}
										>
											<CardContent>
												<Typography variant="h6">{item.sectionName}</Typography>
												<Typography variant="body1">
													Amount Type: {item.amount_type}
												</Typography>
												<Typography variant="body1">
													Amount: {item.amount}
												</Typography>
												<Typography variant="body1">
													Count: {item?.count}
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								))
							) : (
								<Grid container justifyContent="center" alignItems="center">
									<Grid item xs={12} sm={6} md={4}>
										<Card
											variant="outlined"
											sx={{
												cursor: "pointer",
												"&:hover": { boxShadow: 3 },
												mt: 5,
											}}
										>
											<CardContent>
												<Typography variant="body1">
													Currently your plan don't have any addons
												</Typography>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							)}
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} color="primary">
							Cancel
						</Button>
						{eventAddons && eventAddons.length > 0 ? (
							<Button
								onClick={handleSubmit}
								color="primary"
								variant="contained"
								disabled={!selectedCard}
							>
								Submit
							</Button>
						) : null}
					</DialogActions>
				</Dialog>

				<Dialog
					open={open}
					keepMounted
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
					className="custom_modal"
				>
					<DialogTitle>Would you like to purchase Event Addons?</DialogTitle>
					<DialogActions>
						<Button onClick={handleClose} className="cancel_btn">
							No
						</Button>
						<Button onClick={handlePurchaseAddons} className="theme-btn">
							Yes
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</Box>
	);
}
