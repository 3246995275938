
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../../../components/drawer'
import Description from './Podcast'
import Sidebar from './../../Sidebar'
import PodcastGridview from './Podcast'
import { useNavigate, useParams } from 'react-router-dom'
import { PlaylistAddRounded } from '@mui/icons-material'

export default function NewPodcast() {
    const params = useParams()
    let navigate = useNavigate()

    const addPodcast = () => {        
        navigate(`/yogastudios/details/broadcast/add/${params.id}`);
    }

    return (
        <Box className=' withSubmenu'>
            <MiniDrawer menuOrder={2} submenuOrder={5} />
            <Sidebar subtype={1} />
            {/* <Box component="section" className="contentWraper">
                <Description params={params}/>
            </Box> */}
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Podcast</Typography>
                    <Button variant="contained" className='addbtn' onClick={() => addPodcast()} > <PlaylistAddRounded className='fz-18 mr-5' /> Add Podcast</Button>
                </Box>
                <PodcastGridview />
            </Box>
        </Box>
    )
}


