import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../components/drawer';
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, TextField, FormControlLabel, Typography } from '@mui/material'
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#f3714d',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#f3714d',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ListAlt />,
        2: <CompareIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};



export default function Service_Steppyform() {
    const navigate = useNavigate()
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let location = useLocation()
    const [activeStep, setActiveStep] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar()
    const [about, setAbout] = React.useState({ title: location.state.data.title, link: location.state.data.link })
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({ title: "", subtitle: "", link: "" })
    const [imageError, setImageError] = React.useState('')
    const [statesingle, setStatesingle] = React.useState([...[], location.state.data.image])
    const [statesingle2, setStatesingle2] = React.useState([...[], location.state.data.image])
    const [description, setDescription] = React.useState(location.state.data.description)
    const [description1, setDescription1] = React.useState(location.state.data.subtitle)


    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        setAbout({ ...about, [name]: value })
    }

    const handleDescriptionChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 50) {
            setDescription(inputValue);
        }
    };

    const SingleImage = (e, index) => {
        setImageError('');
        const fileObj = e.target.files;

        const fileArray = [];
        const filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            let file = fileObj[i];
            // Check if file size is less than 1MB (1000000 bytes)
            if (file.size > 1000000) {
                setImageError("Image should be less than 1 MB");
                continue; // Skip adding this file to state
            }

            fileArray.push(URL.createObjectURL(file));
            filepath.push(file);
        }

        // Updating states based on your logic
        const data = statesingle.filter(x => x != null);
        const data1 = statesingle2.filter(x => x != null);

        setStatesingle(prevValue => [...prevValue, ...fileArray]);
        setStatesingle2(prevValue => [...prevValue, ...filepath]);
    };


    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const validate = () => {
        let urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
        if (!about.title && !about.subtitle && statesingle?.length == 0 && !about.link) {
            setError({ title: "This field is required.", link: "This field is required." })
            setImageError("This field is required.")
            return false;
        }
        if (!about.title) {
            setError({ title: "This field is required." })
            return false;
        }
        if (statesingle?.length == 0) {
            setImageError("This field is required.")
            return false;
        }
        if (!about.link) {
            setError({ link: "This field is required." })
            return false;
        }
        if (!urlRegex.test(String(about.link))) {
            setError({ link: "Please enter valid URL" });
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        var valid = validate()
        if (valid == true) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("title", about.title)
            formData.append("subtitle", description1)
            formData.append("description", description)
            formData.append("link", about.link)
            { statesingle2 ? statesingle2.forEach((item) => { formData.append("image", item) }) : formData.append("image", '') }
            axios.put(process.env.REACT_APP_BASE_URL + `AboutUs/UpdateAboutUs/${location.state.data._id}`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
                    navigate('/about');
                    setAbout({ title: "", subtitle: "", description: "", link: "" });
                    enqueueSnackbar("Yoga Retreats updated successfully", { variant: 'success' })
                }).catch((err) => {
                    setTruebutton(false)
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
                    console.log(err.response, "error");
                })

        }
    }

    return (
        <Box className=''>
            <MiniDrawer menuOrder={9} />
            <Box component="section" className="contentWraper">
                <Grid container>
                    <Grid item xs={12} sm={12} md={8} lg={6}  >
                        <Box className='whiteContainer p-20'>
                            <Typography component='h6' className='formTitle mb-20'>Edit Yoga Retreats</Typography>
                            <Box className="">
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Yoga Retreat<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="title"
                                            name="title"
                                            placeholder="Yoga Retreat"
                                            size="small"
                                            className='textfield'
                                            value={about.title}
                                            onChange={(e) => {
                                                handlechange(e); setError(prevError => ({ ...prevError, title: '' }))
                                            }}
                                        />
                                        {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                    </FormControl>
                                </Box>
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Short Description<Typography component='span'></Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <textarea
                                            placeholder='Long Description  '
                                            className='textarea'
                                            defaultValue={description1}
                                            onChange={(e) => setDescription1(e.target.value)}
                                            rows={4}
                                            cols={40} 
                                            style={{resize:'none'}}/>
                                    </FormControl>
                                </Box>
                                <Grid item xs={12} sm={12} md={12}  >
                                    <Box className="mb-20">
                                        <Typography component='div' className='label'>Long Description </Typography>

                                        <FormControl variant="standard" fullWidth>
                                            <textarea
                                                placeholder='Long Description  '
                                                className='textarea'
                                                defaultValue={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                rows={4}
                                                cols={40} 
                                                style={{resize:'none'}}/>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}  >
                                    <Typography component='div' className='label'>Cover Image <Typography component='span' className='star'> * </Typography></Typography>

                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                        {statesingle.length < 1 &&
                                            <Button className="upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                            </Button>
                                        }
                                    </Box>
                                    {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}

                                </Grid>
                                <Grid item xs={12} sm={12} md={12}  >
                                    {statesingle.length > 0 &&
                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                            {statesingle.length > 0 ? statesingle.map((x, i) => (
                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                    <Box className='uploaded_img' >
                                                        <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                        <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                    </Box>
                                                </Grid>
                                            )) : ""}

                                        </Grid>
                                    }
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
                                </Grid>
                                <Box className="mb-10">
                                    <Typography component='div' className='label'>Link <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            placeholder='Link'
                                            size="small"
                                            name='link'
                                            className='textfield'
                                            value={about.link}
                                            onChange={(e) => {
                                                handlechange(e); setError(prevError => ({ ...prevError, link: '' }))
                                            }}
                                        />
                                        {error.link && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.link}</p>}
                                    </FormControl>
                                </Box>
                            </Box>
                            {truebutton === false ?
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleSubmit} variant="contained" className='theme-btn'>Update</Button>
                                </Box>
                                : truebutton === true &&
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button variant="contained" className='theme-btn'>Please Wait</Button>
                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}