// import { Box, Button, Divider, Grid, Tooltip, Typography } from '@mui/material'
// import React from 'react'
// import { Sheet } from '@mui/joy'
// import Table from '@mui/joy/Table';
// import { Info } from '@mui/icons-material';
// import axios from 'axios';
// import moment from 'moment';
// import { useParams } from 'react-router-dom';

// export default function OnlineCourse() {
// //UseState's:
//     const [coursedashboard,setCourseDashboard] = React.useState([])
//     const [totalcourse,setTotalCourse] = React.useState(0)
//     const params = useParams()

// //Hook's State:
//     React.useEffect(()=>{
//         ELearningDashboard()
//     },[])

//     const ELearningDashboard = () => {
//         axios.get(process.env.REACT_APP_BASE_URL + `Course/GetCourse?createdby=${params && params.id}&size=${10}&page=${10}&search=${''}&Category_id=${"all"}`)
//         .then((res)=>{
//             setCourseDashboard(res.data.getCourse);
//             setTotalCourse(res.data.totalcount)
//         })
//         .catch((error)=>{
//             console.log(error,"error in ELearningDashboard");
//         })
//     }




//     return (
//         <Box className="individual_table">
//             <Box className="p-10 flex-between flex-center">
//                 <Box className="flex-center">
//                     <Typography component='h6' variant='h6' className='semibold letter_sp fz-16 mr-10'>Courses</Typography>
//                     <Tooltip title="watch video" className='flex-center text-right'>
//                         <Info className='fz-18' />
//                     </Tooltip>
//                 </Box>
//                 <Typography component='div' variant='body' className='countBox fz-24'>{totalcourse}</Typography>
//             </Box>
//             <Divider />
//             <Box className="p-10">
//                 {/* <Box className="d-flex justify-content-right">
//                     <Tooltip title="watch video" className='flex-center text-right'>
//                         <Button className='theme_btn btn-xs'>Add or Edit Online Courses</Button>
//                     </Tooltip>
//                 </Box> */}
//                 <Box className="table_content">
//                     <Sheet sx={{ height: 200, overflow: 'auto' }}>
//                         <Table
//                             aria-label="table with sticky header"
//                             stickyHeader
//                             stickyFooter
//                             stripe="odd"
//                             hoverRow
//                         >
//                             <thead>
//                                 <tr>
//                                     <th className='minw100'>Course Name</th>
//                                     <th className='minw100 text-center'>Added on</th>
//                                     <th>Description</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {coursedashboard && coursedashboard?.length > 0 ?
//                                 (coursedashboard && coursedashboard?.map((item,i) => (
//                                     <tr key={i}>
//                                         <td className='semibold fz-12' >{item.course_name}</td>
//                                         <td className='semibold fz-12 text-center' >{moment(item.createdAt).format('MMM Do YY')}</td>
//                                         <td ><div className='ellipse2 fz-12 text_blue letter_sp'>{item.shortDescription ? item.shortDescription : '-'}</div></td>
//                                     </tr>
//                                 ))) : (
//                                     <p>No data found</p>
//                                 )}
//                             </tbody>
//                         </Table>
//                     </Sheet>
//                 </Box>
//             </Box>
//         </Box>
//     )
// }


import { Box, Divider, Tooltip, Typography, CircularProgress, Button } from '@mui/material';
import React from 'react';
import { Sheet } from '@mui/joy';
import Table from '@mui/joy/Table';
import { Info } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';

export default function OnlineCourse() {
  // UseState's:
  const [coursedashboard, setCourseDashboard] = React.useState([]);
  const [totalcourse, setTotalCourse] = React.useState(0);
  const [loading, setLoading] = React.useState(true); // Loading state
  const params = useParams();

  // Hook's State:
  React.useEffect(() => {
    // Simulate 1-second loading time before fetching data
    setTimeout(() => {
      ELearningDashboard();
    }, 1000);
  }, []);

  const ELearningDashboard = () => {
    axios.get(process.env.REACT_APP_BASE_URL + `Course/GetCourse?createdby=${params && params.id}&size=${10}&page=${10}&search=${''}&Category_id=${"all"}`)
      .then((res) => {
        setCourseDashboard(res.data.getCourse);
        setTotalCourse(res.data.totalcount);
        setLoading(false); // Disable loader after data is fetched
      })
      .catch((error) => {
        console.log(error, "error in ELearningDashboard");
        setLoading(false); // Disable loader if there's an error
      });
  };

  return (
    <Box className="individual_table">
      <Box className="p-10 flex-between flex-center">
        <Box className="flex-center">
          <Typography component='h6' variant='h6' className='semibold letter_sp fz-18 mr-10'>Courses</Typography>
          <Tooltip title="watch video" className='flex-center text-right'>
            <Info className='fz-18' />
          </Tooltip>
        </Box>
        <Typography component='div' variant='body' className='countBox fz-24'>{totalcourse}</Typography>
      </Box>
      <Divider />
      <Box className="p-10">
        <Box className="d-flex justify-content-right">
          <Tooltip title="watch video" className='flex-center text-right'>
            <Button className='theme-btn btn-gradient btn-xs'>More</Button>
          </Tooltip>
        </Box>
        <Box className="table_content">
          <Sheet sx={{ height: 200, overflow: 'auto' }}>
            <Table
              aria-label="table with sticky header"
              stickyHeader
              stickyFooter
              stripe="odd"
              hoverRow
            >
              <thead>
                <tr>
                  <th className='minw100'>Course Name</th>
                  <th className='minw100 text-center'>Added on</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="3" style={{ background: "#fbfcfe", textAlign: "center", padding: "20px" }}>
                      {/* Circular progress loader */}
                      <CircularProgress sx={{ color: "#f3714d" }} />
                    </td>
                  </tr>

                ) : coursedashboard && coursedashboard.length > 0 ? (
                  coursedashboard.map((item, i) => (
                    <tr key={i}>
                      <td className='semibold fz-12'>{item.course_name}</td>
                      <td className='semibold fz-12 text-center'>{moment(item.createdAt).format('MMM Do YY')}</td>
                      <td>
                        <div className='ellipse2 fz-12 text_blue letter_sp'>{item.shortDescription ? item.shortDescription : '-'}</div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" style={{ background: "#fbfcfe", textAlign: "center", fontWeight: "bold", padding: "20px" }}>
                      <Typography component="p" variant="body1">No data found</Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      </Box>
    </Box>
  );
}
