
// firebase.js
import { initializeApp } from '@firebase/app'

import { getMessaging, getToken, onMessage } from '@firebase/messaging';
const firebaseConfig = {
  apiKey: "AIzaSyDiQK1X2_VjCmayO3eTDHHzyZwDB6gQkzY",
  authDomain: "yoga-5006f.firebaseapp.com",
  databaseURL: "https://yoga-5006f-default-rtdb.firebaseio.com",
  projectId: "yoga-5006f",
  storageBucket: "yoga-5006f.appspot.com",
  messagingSenderId: "359278173131",
  appId: "1:359278173131:web:c27cebec6e144645f6f44e",
  measurementId: "G-Q4RKEGTZNG"
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async () => {
  try {
    console.log('inside setup notifications')
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      const token = await getToken(messaging, {
       vapidKey : "BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo"
      }).catch((err) => {
        console.log(err,"err in get token")
      })
      console.log(token,'FCM Token');

      // return token
    } else {
      console.log('Notification permission denied.');
    }
    // onMessage(messaging, (payload) => {
    //   console.log(payload,"Foreground Message");
    // });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};
export { messaging, setupNotifications };
