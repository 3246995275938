// import { Box, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { Box, Button, Divider, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Sheet } from "@mui/joy";
import Table from "@mui/joy/Table";
import { Info } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export default function EventTable() {
	//UseState's:

	// const token = JSON.parse(localStorage.getItem("logintoken"));
	// const auth = JSON.parse(localStorage.getItem('Userdetails'))
	const [edashboard, setEdashboard] = React.useState([]);
	const [totalevents, setTotalEvents] = React.useState(0);
	const [loading, setLoading] = React.useState(true);
	const params = useParams();

	//Hook's state:
	// React.useEffect(()=>{
	//     EventDashboard()
	// },[])

	// const EventDashboard = () => {
	//     axios.get(process.env.REACT_APP_BASE_URL + `Event/GetEventsbyCreatorId/${params.id}`)
	//     .then((res)=>{
	//         console.log(res.data.event)
	//         setEdashboard(res.data.event);
	//         setTotalEvents(res.data.totalcount)
	//     })
	//     .catch((error)=>{
	//         console.log(error,"error in EventDashboard");
	//     })
	// }

	useEffect(() => {
		const fetchData = () => {
			axios
				.get(
					`${process.env.REACT_APP_BASE_URL}Event/GetEventsbyCreatorId/${params.id}`

				)
				.then((res) => {
					console.log(res.data);
					setEdashboard(res.data.event);
					setTotalEvents(res.data.totalcount);
					console.log(res.data.event);

					setLoading(false); // Disable loader after data is fetched
				})
				.catch((error) => {
					console.log(error, "error in EventDashboard");
					setLoading(false); // Disable loader even if there's an error
				});
		};

		// Show loader for 1 second before fetching data
		setTimeout(fetchData, 1000);
	}, []);

	// const handleNavigate = (url) => {
	//     let newurl = window.open(url, "_blank");
	//     newurl.focus();
	//   };

	return (
		<Box className="individual_table ">
			<Box className="p-10 flex-between flex-center">
				<Box className="flex-center">
					<Typography
						component="h6"
						variant="h6"
						className="semibold letter_sp fz-18 mr-10"
					>
						Events
					</Typography>
					<Tooltip title="watch video" className="flex-center text-right">
						<Info className="fz-18" />
					</Tooltip>
				</Box>
				{/* <Typography component='h6' variant='h6' className='text_orange semibold letter_sp fz-16 mr-10' style={{ textDecoration: 'underline',marginLeft:'140px',cursor:'pointer' }} 
                 onClick={() =>
                    handleNavigate(
                      `https://yoga-admin.247sites.in/redirectpage/${auth?.email}/${token}`
                    )
                  }>more</Typography> */}
				<Typography
					component="div"
					variant="body"
					className="countBox letter_sp fz-24"
				>
					{totalevents}
				</Typography>
			</Box>
			<Divider />
			<Box className="p-10">
				<Box className="d-flex justify-content-right">
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Button className='theme-btn btn-gradient btn-xs'>More</Button>
                    </Tooltip>
                </Box>
				<Box className="table_content ">
					<Sheet sx={{ height: 200, overflow: "auto" }}>
						<Table
							aria-label="table with sticky header"
							stickyHeader
							stickyFooter
							stripe="odd"
							hoverRow
						>
							<thead>
								<tr>
									<th className="minw100">Event Name</th>
									<th>Recent Activity</th>
								</tr>
							</thead>
							{/* <tbody>
                                {edashboard && edashboard?.length > 0 ?
                                (edashboard && edashboard?.map((item,i) => (
                                    <tr key={i}>
                                        <td>
                                            <Box className="semibold fz-12 mb-10 letter_sp">{item.event_name}</Box>
                                            <Box className="ellipse2 fz-12 text_blue letter_sp">{item.shortDescription ? item.shortDescription : '-'}</Box>
                                        </td>
                                        <td width={100} className='semibold fz-12 letter_sp'>{moment(item.createdAt).format('MMM Do YY')}</td>
                                    </tr>
                                ))) : (
                                    <tr>
                                        <td colSpan="2" style={{background:"white" ,textAlign: 'center', fontWeight: 'bold', padding: '20px' }}>
                                            <Typography component="p" variant="body1">
                                            No data found
                                            </Typography>
                                        </td>
                                    </tr>
                                )}
                            </tbody> */}
							<tbody>
								{loading ? (
									<tr>
										<td
											colSpan="2"
											style={{
												background: "#fbfcfe",
												textAlign: "center",
												padding: "20px",
											}}
										>
											{/* Circular progress loader */}
											<CircularProgress sx={{ color: "#f3714d" }} />
										</td>
									</tr>
								) : edashboard.length > 0 ? (
									edashboard.map((item, i) => (
										<tr key={i}>
											<td>
												<Box className="semibold fz-12 mb-10 letter_sp">
													{item.event_name}
												</Box>
												<Box className="ellipse2 fz-12 text_blue letter_sp">
													{item.shortDescription ? item.shortDescription : "-"}
												</Box>
											</td>
											<td width={100} className="semibold fz-12 letter_sp">
												{moment(item.createdAt).format("MMM Do YY")}
											</td>
										</tr>
									))
								) : (
									<tr>
										<td
											colSpan="2"
											style={{
												background: "#fbfcfe",
												textAlign: "center",
												fontWeight: "bold",
												padding: "20px",
											}}
										>
											<Typography component="p" variant="body1">
												No data found
											</Typography>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Sheet>
				</Box>
			</Box>
		</Box>
	);
}
