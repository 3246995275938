import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography, TableBody, IconButton,Grid, TextField, InputAdornment, Box, Button, FormControl, FormGroup } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack'
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { ExpandMore, SearchOutlined,CloseOutlined, DeleteOutline, UnfoldMore, HighlightOffRounded, SettingsInputComponent } from '@mui/icons-material';
import moment from 'moment'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { header, headerformdata } from "../../../components/axiousheader"
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >

                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DataTable() {
    const tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [deletearry, setDeleteArray] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [categoryDetails, setCategoryDetails] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('')
    const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)

    React.useEffect(() => {
        getCategoryDetails()
    }, [page, rowsPerPage, searchTerm])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getCategoryDetails = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory?size=${rowsPerPage}&page=${page}&search=${searchTerm}`, tokendata).then((res) => {
            setCategoryDetails(res.data.category)
            setCount(res.data.totalcount || res.data.category.length || 0);
        }).catch((err) => {
            console.log(err);
        })
    }

    // React.useEffect(() => {
    //     getTimeline()
    // }, [])

    // const getTimeline = async() => {
    //     try {
    //         const response = await axios.get(process.env.REACT_APP_BASE_URL + `Post/getallpost`, { headers: headerformdata });
    //         setTimeline(response.data);
    //         console.log(response.data, 'response.datavgfgfgfg');
    //     } catch (error) {
    //         console.log(error.response, "error");
    //     }
    // }


    const handleDeleteData = () => {
        setDeletemodel(true)
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(categoryDetails);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;
        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        } else if (before.setService == true && after.setService == false) {
            currElIndexNumber = before.index_position - 512;
        } else if (before.setService == false && after.setService == true) {
            currElIndexNumber = after.index_position + 512;

        } else if (before.setService == false && after.setService == true) {
            currElIndexNumber = before.index_position - 512;
        } else if (before.setService == true && after.setService == false) {
            currElIndexNumber = after.index_position + 512;
        }
        else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }
        setCategoryDetails(fromvar);
        var body = {
            index_position: currElIndexNumber
        }
        axios.put(process.env.REACT_APP_BASE_URL + `Category/updateCategoryPosition/${final._id}`, body, tokendata)
            .then((res) => {
                getCategoryDetails();
            }).catch((err) =>
                console.log(err.response, "drag & drop")
            )
    }

    const handleDelete = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `Category/DeleteCategory/${deleteid}`, {}, tokendata,).then((res) => {
            handleClose()
            getCategoryDetails()
            enqueueSnackbar("Category deleted successfully", { variant: 'success' })
        }).catch((err) => {
            console.log(err);
        })
    }

    const EditData = (row) => {
        let obj = {
            data: row
        }
        navigate("/editcategory", { state: obj })
    }

    const handleOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleClose = () => {
        setDeleteId('')
        setOpen(false)
    }

    const handleRemove = (e) => {
        setSearchTerm(''); 
        setPage(0)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Toolbar className='toolbar comptoolbar'>
                    {deletearry && deletearry.length > 0 && <Button className='del-btn' onClick={handleDeleteData}>Delete</Button>}
                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={searchTerm}
                                className='textfield search'
                                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchTerm !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>
                <TableContainer id='table'>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
                        <TableHead className='DT-head'>
                            <TableRow className=''>
                                <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'>Drag row</TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Title</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Description</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'> <strong>Action</strong>  </TableCell>
                            </TableRow>
                        </TableHead>

                        <DragDropContext onDragEnd={handleOnDragEnd} >
                            <Droppable droppableId="characters">
                                {(provided) => (
                                    <TableBody className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                        {categoryDetails && categoryDetails.length > 0 ? categoryDetails.map((row, i) => {
                                            return (
                                                <Draggable key={row._id} draggableId={row._id} index={i} >
                                                    {(provided) => (
                                                        <TableRow className='tbody-row' key={row._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} hover role="checkbox">
                                                            <TableCell className='DT-row' style={{ width: 200 }} align="center"><DragHandleIcon /></TableCell >
                                                            <TableCell component="th" scope="row" padding="none" className='DT-row'>{row.category_title ? row.category_title : '-'}</TableCell>
                                                            <TableCell align="left" className='DT-row'>{row.description ? row.description : '-'}</TableCell>
                                                            <TableCell align="center" className='DT-row'>
                                                                <Box className="actionbtns">
                                                                    <Tooltip className='p-0 mr-5' title="Edit">
                                                                        <IconButton onClick={() => EditData(row)}>
                                                                            <EditOutlinedIcon className='icons text-grey' />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip className='p-0' title="Delete">
                                                                        <IconButton onClick={() => handleOpen(row._id)}>
                                                                            <DeleteOutline className='icons text-grey' />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </Draggable>
                                            );
                                        }) : <TableRow><TableCell colSpan={4} className='semibold text-center' align='center'>No data found</TableCell></TableRow>}
                                    </TableBody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Paper>
            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete Category?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

