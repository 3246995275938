import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../../components/drawer'
import Description from './Desc'
import Sidebar from '../Sidebar'
import Settings_sidebar from '../Sidebar'
import {  useParams } from 'react-router-dom';


export default function CommunityDesc() {
    
    const params = useParams()
    // const location = useLocation()
    // const [id,setId] = React.useState("")
    // console.log(location)
    // setId(location.state) 
    // console.log()
    

    return (
        <Box className='settings withSubmenu'>
            <MiniDrawer menuOrder={7}/>
            <Sidebar subtype={0} id={params.id}/>
            <Box component="section" className="contentWraper">
                <Description params={params}/>
            </Box>
        </Box>
    )
}
