import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Sidebar from './../../Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import MiniDrawer from '../../../../../components/drawer'
import DataTable from './Course'

export default function Course() {
    const params = useParams()
    let navigate = useNavigate()
    const addCourse = () => {
        navigate(`/yogastudios/details/course/add/${params.id}`);
    }
    return (
        <Box className='companies withSubmenu'>
            <MiniDrawer menuOrder={2} submenuOrder={5} />
            <Sidebar subtype={3} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Course</Typography>
                    <Button variant="contained" className='addbtn' onClick={() => addCourse()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Course</Button>
                </Box>
                <Box className='whiteContainer'>
                <DataTable />
                </Box>
            </Box>
        </Box>
    )
}


