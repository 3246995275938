
import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Checkbox, Typography, Input, FormControlLabel, RadioGroup, Radio } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import DOMPurify from 'dompurify';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EmailEditor from "react-email-editor";
import Sidebar from './../../Sidebar'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#f3714d',
    },
});
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));


export default function Service_Steppyform() {
    const navigate = useNavigate()
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let location = useLocation()
    const [activeStep, setActiveStep] = React.useState(0);
    const params = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const [bookreviews, setBookreviews] = React.useState({ booktitle: location.state.data.Book_title, description: location.state.data.Book_shortdescription, publisher: location.state.data.Book_published_by, author: location.state.data.author, pagecount: location.state.data.page_count, photographedby: location.state.data.photographed_by, isbn: location.state.data.Isbn, reviewed_by: location.state.data.reviewed_by })
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({ booktitle: "" })
    const [categoryId, setCategoryId] = React.useState(location.state.data.category_id)
    const [subcategoryId, setSubCategoryId] = React.useState(location.state.data.subcategory_id ? location.state.data.subcategory_id : "")
    const [catError, setCatError] = React.useState('')
    const [subcatError, setSubCatError] = React.useState('')
    const [imageError, setImageError] = React.useState('')
    const [state1, setState1] = React.useState(location.state.data.additionalimages)
    const [state2, setState2] = React.useState(location.state.data.additionalimages)
    const [existingimages, setExistingimages] = React.useState(location.state.data.additionalimages)
    const [statesingle, setStatesingle] = React.useState([...[], location.state.data.image])
    const [statesingle2, setStatesingle2] = React.useState([...[], location.state.data.image])
    const [richeditor, setRichtor] = React.useState(location.state.data.description)
    const [description, setDescription] = React.useState(location.state.data.Book_shortdescription)
    const [imageAddError, setImageAddError] = React.useState('')
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const dataofeditor = `${richeditor}`
    const [convertedContent, setConvertedContent] = React.useState(null);
    // const [values, setValues] = React.useState(dayjs(location.state.data.Date));
    const [review_posted_online, setReview_posted_online] = React.useState(dayjs(location.state.data.review_posted_online))
    const [selectedVideo, setSelectedVideo] = useState([...[], location.state.data.Video]);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState([...[], location.state.data.Video]);
    const [subcat, setSubcat] = React.useState([])
    const [category, setCategory] = React.useState([])
    const emailEditorRef = useRef({ editor: {} });
    // const [descJson, setDescJson] = React.useState(location.state.data.Book_DescriptionJSON)
    // const [isToggled, setToggled] = React.useState(location.state.data.Book_publish)
    const [isPrimary, setPrimary] = React.useState(location.state.data.primary)
    const [isPublic, setPublic] = React.useState(location.state.data.public)
    const [selectGallert, setSelectGallery] = useState(location.state.data.gallery)
    const auth = JSON.parse(localStorage.getItem('userData'))
    const [selectedDate, setSelectedDate] = React.useState(dayjs(location.state.data.Date));



    //Text-Editor
    // const unlayerSampleJSON = {
    //     "body": JSON.parse(descJson),
    // }
    // const emailJSON = unlayerSampleJSON;

    // const onLoad = () => {
    //     emailEditorRef.current.editor.loadDesign(emailJSON);
    // };
    // const handleEditor = (e) => {
    //     const unlayer = emailEditorRef.current?.editor;
    //     unlayer?.exportHtml((data) => {
    //         const { design, html } = data;
    //         const sanitizedHtml = DOMPurify.sanitize(html);
    //         setDescJson(JSON.stringify(design.body))
    //         enqueueSnackbar('Saved changes', { variant: 'info' });
    //     })
    // }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const handleReviewDate = (date) => {
        setReview_posted_online(date);
    }
    React.useEffect(() => {
        if (dataofeditor) {
            try {
                const contentState = stateFromHTML(dataofeditor);
                setEditorState(EditorState.createWithContent(contentState));
            } catch (error) {
                console.error("Error during HTML to ContentState conversion:", error);
            }
        }
    }, [dataofeditor]);

    React.useEffect(() => {
        convertContentToHTML();
    }, [editorState]);

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
    }

    const convertContentToHTML = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentAsHTML = stateToHTML(currentContent);
        setConvertedContent(currentContentAsHTML)
    }
    const handleRemoveRestart = () => {
        setSubCategoryId('')
    }
    // const handleToggle = () => {
    // 	setToggled(!isToggled)
    // }
    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }
    const handleTogglePublic = () => {
        setPublic(!isPublic)
    }
    const handleEditGallery = (e) => {
        setSelectGallery(selectGallert === 'Image' ? 'Video' : 'Image')
    }

    const handlePrimary = async (e, data) => {
        const body = {
            primary: e.target.checked,
        };
        try {
            const response = await axios.put(process.env.REACT_APP_BASE_URL + `BookReview/updatebookreviewPrimary/${data._id}`, body, { headers: headerlocal });
            // getEvents();
        } catch (error) {
            enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
            console.error("Error while updating mega event", error);
        }
        console.log(body, "primary");
    }


    const handleUpdateService = async () => {
        var multipleImage = state2.filter(x => x != null);
        var valid = validate()
        // var valid11 = validatenew()
        if (valid == true) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("createdby[]", params && params.id)
            formData.append("Book_title", bookreviews.booktitle)
            formData.append("Date", selectedDate)
            formData.append("category_id", categoryId)
            formData.append("subcategory_id", subcategoryId)
            formData.append("Book_published_by", bookreviews.publisher)
            formData.append("author", bookreviews.author)
            formData.append("photographed_by", bookreviews.photographedby)
            formData.append("page_count", bookreviews.pagecount)
            formData.append("Isbn", bookreviews.isbn)
            formData.append("review_posted_online", review_posted_online)
            formData.append("reviewed_by", bookreviews.reviewed_by)
            // formData.append("Book_publish", isToggled)
            formData.append("public", isPublic)
            formData.append("primary", isPrimary)
            formData.append("Book_shortdescription", description)
            // formData.append("Book_Description", sanitizedHtml)
            // formData.append("Book_DescriptionJSON", design && JSON.stringify(design.body))
            formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')
            { statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
            {
                selectGallert === 'Image' ? <>
                    {multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
                    {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
                    {existingimages ? existingimages.forEach((item) => { formData.append("existingimages", item) }) : formData.append("existingimages", '')}
                </>
                    :
                    <>
                        {multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
                        {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}
                        {existingimages ? existingimages.forEach((item) => { formData.append("existingimages", '') }) : formData.append("existingimages", '')}
                    </>
            }
            console.log("Video data:", formData.get('Video'));
            axios.put(process.env.REACT_APP_BASE_URL + `BookReview/updateBookReview/${location.state.data._id}`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
                    navigate(`/yogaschool/details/bookreview/${params.id}`);
                    setBookreviews({ booktitle: "", description: "" })
                    setCategoryId('');
                    setSubCategoryId('');
                    setState1([])
                    setState2([])
                    setSelectedVideo([]);
                    setVideoPreviewUrl([]);
                    enqueueSnackbar("Book Review updated successfully", { variant: 'success' })
                    console.log(res, "edit Book Review");
                }).catch((err) => {
                    setTruebutton(false)
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
                    console.log(err.response, "error");
                })
        }
    }


    const validate = () => {
        if (!bookreviews.booktitle && statesingle2?.length == 0 && !bookreviews.publisher) {
            setError({ booktitle: "This field is required.", publisher: "This field is required." })
            setCatError("This field is required.")
            setImageError("This field is required.")
            return false
        }
        if (!bookreviews.booktitle) {
            setError({ booktitle: "This field is required." })
            return false
        }
        if (!bookreviews.publisher) {
            setError({ publisher: "This field is required." })
            return false
        }
        if (!categoryId) {
            setCatError("This field is required.")
        }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        return true
    }

    const validatenew = () => {
        // if (state2?.length == 0 && selectedVideo?.length == 0) {
        //     setError({ video: "This field is required." })
        //     setImageAddError("This field is required.")
        //     return false
        // }
        // if (state2?.length == 0) {
        //     setImageAddError("This field is required.")
        //     return false
        // }
        // if (selectedVideo?.length == 0) {
        //     setError({ video: "This field is required." })
        //     return false
        // }
        return true
    }

    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        setBookreviews({ ...bookreviews, [name]: value })
    }

    React.useEffect(() => {
        getCategory()
    }, [])

    React.useEffect(() => {
        getSubCategory()
    }, [categoryId])

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
            setCategory(res.data.category)
        }).catch((error) => {
            console.log(error.response, "error");
        })
    }

    const getSubCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
            .then((res) => {
                setSubcat(res.data.subcategory)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }
    const maxFileSizeMB = 1; // Maximum file size allowed in MB

    const validateFileSize = (file) => {
        const fileSizeMB = file.size / (1024 * 1024); // Calculate file size in MB
        return fileSizeMB <= maxFileSizeMB;
    };


    const multipleImgChangeMultiple = (e, index) => {
        setImageAddError('');
        var fileObj = e.target.files;
        var fileArray = [];
        var filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            if (validateFileSize(fileObj[i])) {
                fileArray.push(URL.createObjectURL(fileObj[i]));
                filepath.push(fileObj[i]);
            } else {
                setImageAddError('Image size should be less than 1 MB.');
            }
        }

        // Continue with state updates only for valid files
        if (fileArray.length > 0) {
            var data = state1.filter(x => x != null);
            var data1 = state2.filter(x => x != null);
            setState1(data);
            setState2(data1);
            setState1(prevValue => prevValue.concat(fileArray));
            setState2(prevValue => prevValue.concat(filepath));
        }
    };

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const handleNext = () => {
        var valid = validate()
        if (valid === true) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const CategoryData = (e) => {
        setSubCategoryId('')
        setCategoryId(e.target.value);
        setCatError('');
        setError({ coursename: '' });
        setCatError('');
        setSubCatError('')
    }

    const clearVideo = (e, index) => {
        setSelectedVideo([]);
        setVideoPreviewUrl([]);
    };

    const SingleImage = (e, index) => {
        setImageError('');
        const fileObj = e.target.files;

        const fileArray = [];
        const filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            let file = fileObj[i];
            // Check if file size is less than 1MB (1000000 bytes)
            if (file.size > 1000000) {
                setImageError("Image should be less than 1 MB");
                continue; // Skip adding this file to state
            }

            fileArray.push(URL.createObjectURL(file));
            filepath.push(file);
        }

        // Updating states based on your logic
        const data = statesingle.filter(x => x != null);
        const data1 = statesingle2.filter(x => x != null);

        setStatesingle(prevValue => [...prevValue, ...fileArray]);
        setStatesingle2(prevValue => [...prevValue, ...filepath]);
    };
    // const handleVideoChange = (e, index) => {
    //     setError(prevError => ({ ...prevError, video: '' }))
    //     var fileObj = [];
    //     var fileArray = [];
    //     var filepath = [];
    //     fileObj.push(e.target.files)
    //     for (let i = 0; i <= fileObj[0].length; i++) {
    //         if (i < fileObj[0].length) {
    //             fileArray.push(URL.createObjectURL(fileObj[0][i]))
    //             filepath.push(fileObj[0][i])
    //         }
    //     }
    //     setVideoPreviewUrl(fileArray)
    //     setSelectedVideo(filepath)
    // }

    const handleVideoChange = (e, index) => {
        setImageError('')
        setError({ video: '' });
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files);
        for (let i = 0; i < fileObj[0].length; i++) {
            if (fileObj[0][i].size > 25 * 1024 * 1024) { // Corrected comparison
                setError({ video: 'Video should be less than 25 MB' });
                return;
            }
            fileArray.push(URL.createObjectURL(fileObj[0][i]));
            filepath.push(fileObj[0][i]);
        }

        setVideoPreviewUrl(fileArray);
        setSelectedVideo(filepath);
    }

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };


    return (
        <Box className='withSubmenu'>
            <MiniDrawer menuOrder={2} submenuOrder={4} />
            <Sidebar subtype={8} />
            <Box component="section" className="contentWraper">
                <Box className='whiteContainer p-20'>
                    <Typography component='h6' className='formTitle mb-20'>Edit Book Review</Typography>
                    <Box className='steppy_content'>
                        <Box className="maxw_700">
                            <Box className="mb-10">
                                <Typography component='div' className='label'>Book Title <Typography component='span' className='star'>*</Typography></Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        placeholder='Title'
                                        size="small"
                                        name='booktitle'
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={bookreviews.booktitle}
                                        onChange={(e) => {
                                            handlechange(e); setError(prevError => ({ ...prevError, booktitle: '' }))
                                        }}
                                    />
                                    {error.booktitle && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.booktitle}</p>}
                                </FormControl>
                            </Box>


                            <Box className="mb-10">
                                <Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
                                <FormControl fullWidth >
                                    <Select
                                        className="select"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={categoryId ? categoryId : 'sel'}
                                        onChange={(e) => { CategoryData(e); }}>
                                        <MenuItem disabled value={'sel'}>Select category</MenuItem>
                                        {category && category?.length > 0 ? category.map((data) => (
                                            <MenuItem value={data._id}>{data.category_title}</MenuItem>
                                        )) :
                                            <MenuItem disabled>{"No Record's found"}</MenuItem>}
                                    </Select>
                                    {catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
                                </FormControl>
                            </Box>

                            <Typography component='div' className='label' style={{ marginRight: '10px' }}>Select Sub Category</Typography>
                            <Box className="mb-10" style={{ display: 'flex', alignItems: 'center' }}>
                                <FormControl fullWidth style={{ flex: 1 }}>
                                    <Select
                                        className="select"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        disabled={categoryId !== '' ? false : true}
                                        value={subcategoryId ? subcategoryId : 'sel18'}
                                        onChange={(e) => { setSubCategoryId(e.target.value); setSubCatError('') }}>
                                        <MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
                                        {subcat && subcat?.length > 0 ? (
                                            subcat.map((data5) => (
                                                <MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
                                            ))) : (<MenuItem disabled>{"No Record's found"}</MenuItem>)}
                                    </Select>
                                    {subcatError && (<p style={{ color: 'red' }} className="fz-13 m-0">{subcatError}</p>)}
                                </FormControl>
                                {subcategoryId !== '' && (
                                    <RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
                                )}
                            </Box>

                            <Box className="mb-20">
                                <Typography component='div' className='label'>Published Date</Typography>
                                <FormControl variant="standard" fullWidth className="datepicker">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            className='textfield w100'
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                            minDate={dayjs().subtract(100, 'year')}
                                            maxDate={dayjs().add(10, 'year')}
                                            format="DD/MM/YYYY"
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>

                            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box className='mb-10 d-flex align-items-start justify-content-between' >
                                        <Box className=' text-right' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography component='div' variant='body1' className='fz-14 '>Book Publish:</Typography>
                                            <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<IOSSwitch onClick={handleToggle} checked={isToggled} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Box>
                                </Grid> */}

                            <Box className='mb-20'>
                                <FormControlLabel className="checkbox_list"
                                    control={
                                        <Checkbox
                                            checked={isPublic}
                                            onChange={handleTogglePublic}
                                            name='checkbox-value'
                                            color='primary'
                                        />
                                    }
                                    label={<Typography component='div' className='label mb-0'>Do you want to make this event as Public? </Typography>}
                                />
                                <FormControlLabel className="checkbox_list"
                                    control={
                                        <Checkbox
                                            checked={isPrimary}
                                            onChange={handleTogglePrimary}
                                            name='checkbox-value'
                                            color='primary'
                                        />
                                    }
                                    label={<Typography component='div' className='label mb-0'>Do you want to make this event as Primary? </Typography>}
                                />
                            </Box>


                            <Box className="mb-10">
                                <Typography component='div' className='label'>Publisher <Typography component='span' className='star'>*</Typography></Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        placeholder='Publisher'
                                        size="small"
                                        name='publisher'
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={bookreviews.publisher}
                                        onChange={(e) => {
                                            handlechange(e); setError(prevError => ({ ...prevError, publisher: '' }))
                                        }}
                                    />
                                    {error.publisher && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publisher}</p>}
                                </FormControl>
                            </Box>

                            <Box className="mb-10">
                                <Typography component='div' className='label'>Author</Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        placeholder='Author'
                                        size="small"
                                        name='author'
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={bookreviews.author}
                                        onChange={(e) => {
                                            handlechange(e);
                                        }}
                                    />
                                    {/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
                                </FormControl>
                            </Box>


                            <Box className="mb-10">
                                <Typography component='div' className='label'>Reviewed By</Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        placeholder='Reviewed By'
                                        size="small"
                                        name='reviewed_by'
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={bookreviews.reviewed_by}
                                        onChange={(e) => {
                                            handlechange(e);
                                        }}
                                    />
                                    {/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
                                </FormControl>
                            </Box>

                            <Box className="mb-20">
                                <Typography component='div' className='label'>Review Posted Online<Typography component='span' ></Typography></Typography>
                                <FormControl variant="standard" fullWidth className="datepicker">
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DatePicker
                                            className='textfield w100'
                                            value={review_posted_online}
                                            onChange={handleReviewDate}
                                            renderInput={(params) => <TextField {...params} />}
                                            minDate={dayjs().subtract(100, 'year')}
                                            maxDate={dayjs().add(10, 'year')}
                                            format="DD/MM/YYYY"
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>

                            <Box className="mb-10">
                                <Typography component='div' className='label'>Photographed By</Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        placeholder='Photographed By'
                                        size="small"
                                        name='photographedby'
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={bookreviews.photographedby}
                                        onChange={(e) => {
                                            handlechange(e);
                                        }}
                                    />
                                    {/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
                                </FormControl>
                            </Box>

                            <Box className="mb-10">
                                <Typography component='div' className='label'>Page Count</Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        placeholder='Page Count'
                                        size="small"
                                        name='pagecount'
                                        onInput={(e) => onlyNumbers(e)}
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={bookreviews.pagecount}
                                        onChange={(e) => {
                                            handlechange(e);
                                        }}
                                    />
                                    {/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
                                </FormControl>
                            </Box>

                            <Box className="mb-10">
                                <Typography component='div' className='label'>ISBN</Typography>
                                <FormControl variant="standard" fullWidth>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        placeholder='ISBN'
                                        size="small"
                                        name='isbn'
                                        className='textfield'
                                        style={{ width: '100%' }}
                                        value={bookreviews.isbn}
                                        onChange={(e) => {
                                            handlechange(e);
                                        }}
                                    />
                                    {/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
                                </FormControl>
                            </Box>

                            <Grid item xs={12} sm={12} md={12}  >
                                <Typography component='div' className='label'>Cover Image <Typography component='span' className='star'> * </Typography></Typography>

                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                    {statesingle.length < 1 &&
                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                            {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                            <Grid item xs={12} sm={12} md={12}  >
                                {statesingle.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle.length > 0 ? statesingle.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                    <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}

                                    </Grid>

                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}  >
                                <Box className="mb-10">
                                    <Typography component='div' className='label'>Short Description </Typography>

                                    <FormControl variant="standard" fullWidth>
                                        <textarea
                                            placeholder='Short Description'
                                            className='textarea'
                                            defaultValue={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            rows={4}
                                            cols={40} />
                                    </FormControl>
                                </Box>
                            </Grid>



                        </Box>
                    </Box >
                    <>
                        {truebutton === false ?
                            <Box className='heading-btns mt-20 w100' style={{ textAlign: 'center', marginLeft: '360px', marginRight: 'auto' }}>
                                {/* <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button> */}
                                <Button onClick={handleUpdateService} variant="contained" className='theme-btn'>Update</Button>
                            </Box>
                            : truebutton === true &&
                            <Box className='heading-btns mt-20 w100' style={{ textAlign: 'center', marginLeft: '360px', marginRight: 'auto' }}>
                                {/* <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button> */}
                                <Button variant="contained" className='theme-btn'>Please Wait</Button>
                            </Box>}
                    </>
                </Box>
            </Box>
        </Box>
    )
}