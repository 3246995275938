import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import { Card, Typography } from '@mui/material';
import {  Typography } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import axios from 'axios';
// import InputAdornment from '@mui/material/InputAdornment';
// import { SearchOutlined, Delete as DeleteIcon } from '@mui/icons-material';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch, { SwitchProps } from '@mui/material/Switch';
import Switch from '@mui/material/Switch';

import { useSnackbar } from 'notistack';

const Videotable = () => {
    
    // const auth = JSON.parse(localStorage.getItem('userData'))
    const { enqueueSnackbar } = useSnackbar();
    const [videos, setVideos] = React.useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectedVideo, setSelectedVideo] = React.useState(null);
    const [search, setSearch] = React.useState("");
    const [catid, setCatId] = React.useState('all');
    const [deleteIndex, setDeleteIndex] = React.useState(null);
    const [isDeleting, setIsDeleting] = React.useState(false)

    // React.useEffect(() => {
    //     getVideos();
    // }, [search, catid]);

    // const getVideos = async () => {
    //     try {
    //         const res = await axios.get(process.env.REACT_APP_BASE_URL + `video/GetVideos`, {
    //             params: {
    //                 search: search,
    //                 Category_id: catid
    //             },
    //             headers: headerlocal
    //         });
    //         console.log(res,"Videoss");
    //         setVideos(res.data.videodata);
    //     } catch (error) {
    //         console.log(error, "error in fetching videos");
    //     }
    // };

    const auth = React.useMemo(() => {
        return JSON.parse(localStorage.getItem('userData')) || {};
    }, []);

    React.useEffect(() => {
        getVideos(search,catid,auth._id,auth);
    }, [search,catid,auth._id,auth]);

    const getVideos = async (search = '', catid = '',authid,auth) => {
        try {
            const url = auth && auth.role_id === 1 ? `video/GetVideos?search=${search}&Category_id=${catid}` : `video/GetVideocreatedby/${authid}`
            const res = await axios.get(process.env.REACT_APP_BASE_URL + url, {
                params: {
                    search: search,
                    Category_id: catid
                },
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    Authorization: localStorage.getItem("token")
                }
            });
            console.log(res, "Videoss");
            setVideos(res.data.videodata);
        } catch (error) {
            console.log(error, "error in fetching videos");
        }
    };
    

    const handleDelete = async () => {
        if (selectedVideo === null || deleteIndex === null) {
            // console.log("Invalid video or index:", selectedVideo, deleteIndex);
            return;
        }

        const { _id, thumbnail, Video } = selectedVideo;
        const thumbnailToDelete = thumbnail[deleteIndex];
        const videoToDelete = Video[deleteIndex];

        const body = {
            thumbnailpath: process.env.REACT_APP_BASE_URL + thumbnailToDelete,
            videopath: process.env.REACT_APP_BASE_URL + videoToDelete
        };
        // console.log(body,"bodyyy");

        try {
            setIsDeleting(true);
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}video/deleteVideo/${_id}`, body);
            console.log(response, "response");
            if (response.status === 200) {
                enqueueSnackbar("Video and Thumbnail deleted successfully", { variant: 'success' });
                // Update state to reflect deletion in frontend
                const updatedVideos = [...videos];
                updatedVideos.forEach(video => {
                    if (video._id === _id) {
                        video.thumbnail.splice(deleteIndex, 1);
                        video.Video.splice(deleteIndex, 1);
                    }
                });
                setVideos(updatedVideos);
                handleCloseDeleteDialog();
            } else {
                enqueueSnackbar("Failed to delete Video and Thumbnail", { variant: 'error' });
            }
        } catch (error) {
            console.log(error, "error deleting video and thumbnail");
            enqueueSnackbar("Error deleting Video and Thumbnail", { variant: 'error' });
        }finally{
            setIsDeleting(false);
        }
    };

    const handleClickOpenDeleteDialog = (video, index) => {
        setSelectedVideo(video);
        setDeleteIndex(index); // Set the index of item to delete
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setSelectedVideo(null);
        setDeleteIndex(null); // Reset delete index
        setOpenDeleteDialog(false);
    };

    // const handleSearchChange = (e) => {
    //     setSearch(e.target.value);
    //     setCatId('all');
    // };

    // const handleResetSearch = () => {
    //     setSearch('');
    //     setCatId('all');
    // };

    setSearch('');
    setCatId('all');

    // const handleChangeStatus = async (e, video, type) => {
       
    //     // let veried = video.createdby[0].isSubscribed
    //     // if (e.target.checked && !veried) {
    //     //     enqueueSnackbar('Please check the email for the payment link and complete the process.', { variant: 'error' });
    //     // } else {
    //         if (type === 'Approve') {
    //             const body = {
    //                 approve_videos: e.target.checked,
    //             };
    //             console.log(body, "approve");
    //             try {
    //                 await axios.put(process.env.REACT_APP_BASE_URL + `video/Updateapprove_video/${video._id}`,
    //                      body, 
    //                      { headers: {
    //                         'Content-Type': 'application/json',
    //                         "Access-Control-Allow-Origin": "*",
    //                         Authorization: localStorage.getItem("token")
    //                     }  });
    //                 getVideos();
    //             } catch (error) {
    //                 console.error("Error while updating video approval status", error);
    //             }
    //         }
    //     // }
    // };

    const handleChangeStatus = async (e, video, type) => {
        if (type === 'Approve') {
            // Optimistically update the UI
            const updatedVideos = videos.map(v => {
                if (v._id === video._id) {
                    return { ...v, approve_videos: e.target.checked };
                }
                return v;
            });
            setVideos(updatedVideos);
    
            const body = {
                approve_videos: e.target.checked,
            };
    
            try {
                await axios.put(
                    process.env.REACT_APP_BASE_URL + `video/Updateapprove_video/${video._id}`,
                    body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: localStorage.getItem('token'),
                        },
                    }
                );
                // Success - No need to update the state again
            } catch (error) {
                console.error('Error while updating video approval status', error);
                // Revert optimistic UI update on error
                const revertedVideos = videos.map(v => {
                    if (v._id === video._id) {
                        return { ...v, approve_videos: !e.target.checked };
                    }
                    return v;
                });
                setVideos(revertedVideos);
                enqueueSnackbar('Failed to update approval status', { variant: 'error' });
            }
        }
    };
    console.log(videos)

    return (
        <>
            <Box>
                <Grid container rowSpacing={2} columnSpacing={2} style={{ marginBottom: 10 }} className='align-items-end'>
                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            id="filled-size-small"
                            variant="outlined"
                            size="small"
                            value={search}
                            className='textfield search'
                            onChange={handleSearchChange}
                            placeholder='Search Here...'
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                            }}
                        />
                    </Grid> */}
                    {/* {search !== '' &&
                        <Grid item xs={12} sm={4} md={2} lg={2} className='d-flex align-items-end mb-5'>
                            <Button variant="contained" className='addbtn theme-btn' onClick={handleResetSearch}>Reset</Button>
                        </Grid>} */}
                </Grid>
                <Box className='whiteContainer p-20'>
                    <Grid container columnSpacing={1} rowSpacing={1} className="servicesList otherList">
                        {videos.length > 0  ? (
                            videos.map((video, index) => (
                                <Grid key={index} item xs={12} sm={12} md={12} lg={9} xl={8} sx={{ marginTop: 2 }}>
                                    {video.Video.length !== 0 &&
                                    <div className="thumbnails-videos-horizontal-container">
                                        {video.thumbnail.map((thumbnail, idx) => (
                                            <div key={idx} className="thumbnail-video-horizontal-item d-flex align-items-end flex-wrap">

                                                <Box className='mb-10 d-flex flex-wrap'>
                                                    <img
                                                        src={process.env.REACT_APP_BASE_URL + thumbnail}
                                                        alt={`Thumbnail ${idx}`}
                                                        className="thumbnail-horizontal mr-10"
                                                        style={{ width: '200px', height: 150 }} // Example: Set width to 200px
                                                    />
                                                    <Box className='videoContainer'>
                                                        <video controls className="video-horizontal" >
                                                            <source src={process.env.REACT_APP_BASE_URL + video.Video[idx]} type="video/mp4" />
                                                        </video>
                                                    </Box>
                                                </Box>

                                                <CardContent>
                                                {video.createdby[0]?._id === auth._id ? '' : <Box className='flex-center' >
                                                    <Typography component='div' variant='body1' className='fz-14 mr-5 semibold '>Approved:</Typography>
                                                    <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<Switch size="small" onChange={(e) => handleChangeStatus(e, video, 'Approve')} checked={video.approve_videos} />} />
                                                </Box>}
                                            </CardContent>
                                                <Button
                                                    className="theme-btn mb-10"
                                                    onClick={() => handleClickOpenDeleteDialog(video, idx)}
                                                >
                                                    Delete Video
                                                </Button>

                                            </div>
                                        ))}
                                    </div>
                        }
                                </Grid>
                                

                            ))
                        ) : (
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="body1" className='text-center semibold'>No Videos Found</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>

            {/* Delete Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title">
                <DialogTitle id="alert-dialog-title">Would You like to delete this thumbnail and video?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="secondary">
                        Cancel
                    </Button>
                    {
                        isDeleting ? 
                        <Button color="primary" disabled>Delete</Button>:
                    <Button onClick={handleDelete} color="primary" autoFocus>Delete</Button>
                    }
                    
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Videotable;
