import { Box, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'
import logo from './../../../assets/images/footer-logo.png'
import { Instagram, LocationOn, MailOutline } from '@mui/icons-material'
import { Link } from 'react-router-dom'

export default function Footer() {
    const Links1 = [
        { text: "macchiato" },
        { text: "mocha" },
        { text: "barista" },
        { text: "crema java" },
        { text: "decaffeinated" },
        { text: "Galao redeye" },
        { text: "dripper mazagran" },
        { text: "Caffeine plunger" },
        { text: "aromatic roast" },
    
    ]
    const Links2 = [
        { text: "Caffeine plunger" },
        { text: "mocha" },
        { text: "barista" },
        { text: "crema java" },
        { text: "decaffeinated" },
    ]
    const Links3 = [
        { text: "Caffeine plunger" },
        { text: "mocha" },
        { text: "barista" },
        { text: "decaffeinated" },
        { text: "crema java" },
        { text: "Galao redeye" },
        { text: "dripper mazagran" },
    ]
    return (
        <footer>
            <Box className="footer_main">
                <Box className="footer_main_in container">
                    <Grid container >
                        <Grid item xs={12} sm={4} md={4} lg={2} className='minw260'>
                            <Box className="footer_main_left">
                                <img src={logo} height={100} />
                                <Box className="socialmedia_icons d-flex">
                                    <Link to="/"><i class="fa-brands fa-facebook-f"></i></Link>
                                    <Link to="/"><Instagram /></Link>
                                    <Link to="/"><i class="fa-brands fa-twitter"></i></Link>
                                    <Link to="/"><i class="fa-brands fa-pinterest-p"></i></Link>
                                    <Link to="/"><i class="fa-brands fa-whatsapp"></i></Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={7}>
                            <Box className="footer_main_center flex-between">
                                <Box>
                                    <Typography component='h6' variant='body1' className="mb-10 semibold fz-18">Links 1</Typography>
                                    <List className='list_main'>
                                        {Links1.map((item, index) => (
                                            <ListItem className='list_item' disablePadding>
                                                <ListItemButton className='p-0'>
                                                    <ListItemText primary={item.text} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                                <Box>
                                    <Typography component='h6' variant='body1' className="mb-10 semibold fz-18">Links 2</Typography>
                                    <List className='list_main'>
                                        {Links2.map((item, index) => (
                                            <ListItem className='list_item' disablePadding>
                                                <ListItemButton className='p-0'>
                                                    <ListItemText primary={item.text} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                                <Box>
                                    <Typography component='h6' variant='body1' className="mb-10 semibold fz-18">Links 3</Typography>
                                    <List className='list_main'>
                                        {Links3.map((item, index) => (
                                            <ListItem className='list_item' disablePadding>
                                                <ListItemButton className='p-0'>
                                                    <ListItemText primary={item.text} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3}>
                            <Box className="footer_main_right">
                                <Box className="d-flex mb-20">
                                    <LocationOn className='mr-10' />
                                    <Typography component='span' variant='body1' className='fw-300 fz-14' >First Floor, AH Chambers 1, # 3 J, 204, 3rd Cross, 5th Main Rd, Arya Towers, Kormangala, Bengaluru, Karnataka 560088</Typography>
                                </Box>
                                <Box className="d-flex mb-20">
                                    <i class="fa-solid fa-phone mr-10 fz-20"></i>
                                    <Typography component='span' variant='body1' className='fw-300 fz-14' >+91 99010 32469</Typography>
                                </Box>
                                <Box className="d-flex">
                                <i class="fa-solid fa-envelope mr-10 fz-20 "></i>
                                    <Box>
                                        <Typography component='div' variant='body1' className='fw-300 fz-14' >info@yogadirectory.com</Typography>
                                        <Typography component='div' variant='body1' className='fw-300 fz-14' >www.yogadirectory.com </Typography>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </footer>
    )
}
