import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './Datatable_Podcast'
import PodcastGridview from './Podcast_cardview'
import { Box, Button, Grid, Typography } from '@mui/material'
// import Header from '../Header'
// import Footer from '../Footer'
// import podImg from '../../assets/images/pod3.jpg'
// import audio from '../../assets/images/audio.mp3'
// import Menu from '../Menu'
// import Spacing from '../Spacing'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export default function Podcast() {
    let location = useLocation()
    const [podcastData, setPodcastData] = useState(location.state.podcastData)
   
    return (
        <Box className='podcastDetails' >
            <MiniDrawer menuOrder={15} />
            <Box className=' container contentWraper'>
                    <Box className='custom_card'>
                        <Box className='bannerContent'>
                            <Grid container >
                                <Grid item xs={12} sm={12} md={12} lg={4}>
                                    <img src={process.env.REACT_APP_BASE_URL + (podcastData && podcastData.thumbnail)} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={8} className='flex-col'>
                                    <Box className='Podcontent'>
                                        <Typography component='h4' variant='h4' className='text_black mb-10 semibold '>{podcastData && podcastData.name}</Typography>
                                        <Box className=' mb-10'>
                                            <Typography component='span' variant='body1' className='text_grey mb-10 fw-400  '>Size : </Typography>
                                            <Typography component='span' variant='body1' className='text_black mb-10  semibold'>{podcastData.audio_size + "mb"}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography component='span' variant='body1' className='text_black mb-10  fw-400 '>{podcastData && podcastData.description}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className='audioPlayer_container'>
                                        <AudioPlayer
                                            autoPlay
                                            src={process.env.REACT_APP_BASE_URL + (podcastData && podcastData.audio)}
                                            onPlay={e => console.log("onPlay")}
                                            className='audio_player'
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
        </Box>
    )
}