import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography, Avatar } from '@mui/material';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import prof1 from '../../../../../assets/images/profile.png';
import moment from 'moment';

export default function Comment_section(props) {
    // console.log(props.getTimeLine, "+++++");

    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    };

    const auth = JSON.parse(localStorage.getItem('userData'))
    const [content, setContent] = useState('');
    const [comments, setComments] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [replyModal, setReplyModal] = useState(false)
    const [replydata, setReplydata] = React.useState('')

    useEffect(() => {
        if (props.data._id) {
            getReplies(props.data._id);
        }
    }, []);


    const getReplies = (fanpageid) => {
        axios.get(process.env.REACT_APP_BASE_URL + `Fanpagecomment/getCommentsByFanPageByIds/${fanpageid}`)
            .then((res) => {
                setComments(res.data.data);
                console.log(res.data, "postss");
            })
            .catch((error) => {
                console.error("Error fetching comments:", error);
            });
    };


    // const handleReply = (info) => {
    //     setReplydata(info)
    //     setReplyModal(true)
    // }
    const handleReply = (data) => {
        setReplydata(data); // Set the reply data for the clicked comment
        setReplyModal(true); // Open the reply modal
    }


    const commentPost = () => {
        const body = {
            fanpageid: replydata.fanpageid,
            parentId: replydata._id,
            user_id: auth && auth._id,
            content: content,
        };
        axios.post(process.env.REACT_APP_BASE_URL + `Fanpagecomment/addFanspageComments`, body)
            .then((res) => {
                console.log(res.data, "Comment added");
                setContent('');
                getReplies(replydata.fanpageid);
            })
            .catch((error) => {
                console.error("Error posting comment:", error);
            });
    };

    const handleDeletecomment = (id) => {
        console.log(id, "iddddd", replydata._id);
        let body = {
            commentId: replydata._id,
            replyId: id,
        }
        axios.put(process.env.REACT_APP_BASE_URL + `Fanpagecomment/replydelete`, body)
            .then((res) => {
                console.log(res,'inside res')
                getReplies(props.data._id)
                // enqueueSnackbar("comment deleted successfully", { variant: 'success' })

            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        // <Box className="comments_section my-30 mw100">
        //     <Grid container columnSpacing={2} rowSpacing={2}>
        //         {comments && comments.map((data, i) => (
        //             <Grid item xs={12} sm={12} md={12} key={data._id} >
        //                 <Box className='receiver' >
        //                     <Box className="comment_each ">
        //                         <Box className="flex-center ">
        //                             <Box className="">
        //                                 <img src={prof1} width="30" height="30" />
        //                             </Box>
        //                             <Box>
        //                                 <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>{data.user_id && data.user_id.username}</Typography>
        //                                 <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>{moment(data.createdAt).fromNow()}</Typography>
        //                             </Box>
        //                         </Box>
        //                     </Box>
        //                     <Box >
        //                         <Typography component='div' variant='body1' className='text_grey letter_sp fz-12 fw-400'>{data.content}</Typography>
        //                         <br />
        //                         {replyModal === false && <Typography component='div' variant='body1' className='text_grey letter_sp fz-12 fw-400 reply-button'
        //                             onClick={() => handleReply(data,i)}>Replies</Typography>}
        //                     </Box>
        //                     {

        //                         replyModal && data.replies.length > 0 && data.replies.map((item, i) => {
        //                             { console.log(item,"itemmmmm")}
        //                             return item && item.user_id._id === auth._id ? <Grid item xs={12} sm={12} md={12} >
        //                                 <Box className='sender'>
        //                                     <Box className="comment_each ">
        //                                         <Box className="flex-center ">
        //                                             <Box className="">
        //                                                 <img src={prof1} width="30" height="30" />
        //                                             </Box>
        //                                             <Box>
        //                                                 <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>{item.user_id && item.user_id.username}</Typography>
        //                                                 {/* <Typography component='span' variant='span' className='text_white fz-12 semibold mr-20 badge'>You</Typography> */}
        //                                                 <Typography component='span' variant='body1' className='text_grey letter_sp mr-20  fz-12 semibold '>{moment(item.createdAt).fromNow()}</Typography>
        //                                             </Box>
        //                                         </Box>
        //                                         <Box className='flex-center '>
        //                                             <Button className='btn mr-10'>

        //                                                 <Button className='btn mr-10' onClick={() => handleDeletecomment(item._id)}>
        //                                                     <Typography component='span' variant='span' className='text_primary fz-14 semibold '><i class="fa-solid fa-trash"></i> Delete</Typography>
        //                                                 </Button>
        //                                             </Button>
        //                                         </Box>
        //                                     </Box>
        //                                     <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 fw-400 '>{item.content}</Typography>
        //                                 </Box>
        //                             </Grid> :
        //                              <Grid item xs={12} sm={12} md={12} key={item._id}>
        //                                 <Box className='receiver' >
        //                                     <Box className="comment_each ">
        //                                         <Box className="flex-center ">
        //                                             <Box className="">
        //                                                 <img src={prof1} width="30" height="30" />
        //                                             </Box>
        //                                             <Box>
        //                                                 <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>{ }</Typography>
        //                                                 <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>{moment(data.createdAt).fromNow()}</Typography>
        //                                             </Box>
        //                                         </Box>
        //                                     </Box>
        //                                     <Box >
        //                                         <Typography component='div' variant='body1' className='text_grey letter_sp fz-12 fw-400'>{item.content}</Typography>
        //                                     </Box>
        //                                 </Box>
        //                             </Grid>
        //                         })}
        //                     {
        //                         replyModal  && <Box className="comment_box flex-center">
        //                             <img src={prof1} width="30" height="30" />
        //                             <TextField fullWidth id="outlined-basic" value={content} onChange={(e) => setContent(e.target.value)} placeholder='Add a comment' multiline rows={2} variant="outlined" className='textfield text_white' />
        //                             <Button className='fz-16 theme_btn' onClick={(e) => commentPost(e, data._id)}>Send</Button>
        //                         </Box>
        //                     }
        //                 </Box>
        //             </Grid>
        //         ))}
        //     </Grid>
        // </Box>

        <Box className="comments_section my-30 mw100">
            <Grid container columnSpacing={2} rowSpacing={2}>
                {comments && comments.map((data, i) => (
                    <Grid item xs={12} sm={12} md={12} key={data._id} >
                        <Box className='receiver' >
                            <Box className="comment_each ">
                                <Box className="flex-center ">
                                    <Box className="flex-center">

                                        {data.prof1?.length > 0 ? (
                                            <img
                                                src={process.env.REACT_APP_BASE_URL + `${data.prof1}`}
                                                height={50}
                                                width={50}
                                                style={{ borderRadius: '50%' }}
                                                className='mr-10'
                                            />
                                        ) : (
                                            < AccountCircleIcon className="mr-10" fontSize="large" />
                                        )}
                                        <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>{data.user_id && data.user_id.username}</Typography>

                                    </Box>
                                    <Box className="ml-auto">
                                        <Typography component='div' variant='body1' className='text-grey letter_sp fz-12 fw-400 '>{moment(data.createdAt).fromNow()}</Typography>

                                    </Box>

                                </Box>

                            </Box>
                            <Box>
                                <Typography component='div' variant='body1' className='py-20 letter_sp fz-14 fw-400'>{data.content}</Typography>
                                <Typography
                                    component='div'
                                    variant='body1'
                                    className='text_grey letter_sp fz-14 fw-400 reply-button'
                                    onClick={() => handleReply(data)}
                                >
                                    <i class="fa-solid fa-reply"></i>  Replies
                                </Typography>
                            </Box>
                            {/* Render replies only if replyModal is true and it matches the current comment's _id */}
                            {replyModal && replydata && replydata._id === data._id && data.replies.length > 0 && (
                                data.replies.map((item) => (
                                    <Grid item xs={12} sm={12} md={12} key={item._id}>
                                        <Box className={item.user_id._id === auth._id ? 'sender' : 'receiver'}>
                                            <Box className="comment_each ">
                                                <Box className="flex-center ">
                                                    <Box className="">
                                                        {data.prof1?.length > 0 ? (
                                                            <img
                                                                src={process.env.REACT_APP_BASE_URL + `${data.prof1}`}
                                                                height={50}
                                                                width={50}
                                                                style={{ borderRadius: '50%' }}
                                                                className='mr-10'
                                                            />
                                                        ) : (
                                                            < AccountCircleIcon className="mr-10" fontSize="large" />
                                                        )}
                                                        {/* <img src={prof1} width="30" height="30" alt="Profile" /> */}
                                                    </Box>
                                                    <Box>
                                                        <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>Admin</Typography>
                                                        <Typography component='span' variant='body1' className='text_grey letter_sp mr-20 fz-12 semibold '>{moment(item.createdAt).fromNow()}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Typography component='div' variant='body1' className='text_grey letter_sp fz-12 fw-400'>{item.content}</Typography>
                                                </Box>
                                                <Box className='flex-center '>
                                                    <Button className='btn mr-10'>
                                                        <Button className='btn mr-10' onClick={() => handleDeletecomment(item._id)}>
                                                            <Typography component='span' variant='span' className='text_primary fz-14 semibold '><i class="fa-solid fa-trash"></i> Delete</Typography>
                                                        </Button>
                                                    </Button>
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Grid>
                                ))
                            )}
                            {/* Render comment box only if replyModal is true and it matches the current comment's _id */}
                            {replyModal && replydata && replydata._id === data._id && (
                                <Box className="comment_box d-flex mt-20">
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                        placeholder='Add a comment'
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        className='textfield text_white'
                                    />
                                    {data.prof1?.length > 0 ? (
                                        <img
                                            src={process.env.REACT_APP_BASE_URL + `${data.prof1}`}
                                            height={50}
                                            width={50}
                                            style={{ borderRadius: '50%' }}
                                            className='mr-10'
                                        />
                                    ) : (
                                        < AccountCircleIcon className="ml-10" fontSize="large" />
                                    )}
                                    {/* <img src={prof1} width="30" height="30" alt="Profile" /> */}

                                </Box>
                            )}
                            <Box className="text-right" style={{paddingRight: 40}}>
                                {replyModal && replydata && replydata._id === data._id && <Button className='fz-16 theme-btn mt-20' onClick={(e) => commentPost(e, data._id)}>Send</Button>}
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>

    )
}
