import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MiniDrawer from '../../../../../../components/drawer'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import dayjs from 'dayjs';
import axios from 'axios'
import EditBundle from './EditBundle';
import { ceil } from 'lodash';

const Editcoupon = () => {

  const headerlocal = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    Authorization: localStorage.getItem("token")
  }
    const params = useParams()
    const location = useLocation();
    const navigate = useNavigate()
    const { couponData } = location.state || {}; // Use optional chaining for safety
    console.log(couponData)

    const [communities, setCommunities] = useState([]);
    const [yogaFans, setYogaFans] = useState([]);
    const [courses, setCourses] = useState([]);
    const [loading,setLoading] = useState('false')


  const [minimumAmount, setMinimumAmount] = useState(couponData.minimum_amount)


      // Inside your component:
  const [amountInYearAmount, setAmountInYearAmount] = useState(0);  // default to checked
  const [amountInMonthAmount, setAmountInMonthAmount] = useState(0);
  

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  const [selectedCommunity, setSelectedCommunity] = useState('');
  const [selectedYogaFans, setSelectedYogaFans] = useState('');
  const [selectedCourses, setSelectedCourses] = useState('');
  const [selectedItemObject, setSelectedItemObject] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(couponData.paymentType);
const [isPrivate, setIsPrivate] = React.useState(couponData.isPrivate)


    
    // const [selectedCommunity, setSelectedCommunity] = useState([]);
    // const [selectedYogaFans, setSelectedYogaFans] = useState([]);
    // const [selectedCourses, setSelectedCourses] = useState([]);
  

  useEffect(() => {
    // Function to determine the selected category based on the array lengths
    const setCategoryBasedOnArrays = () => {
      const { communities, fanspages, courses } = couponData;
      
      // Check which array has values and set selectedCategory accordingly
      if (communities.length > 0) {
        setSelectedCategory('Communities');
        setSelectedItem(communities)
      } else if (fanspages.length > 0) {
        setSelectedCategory('Yoga-Fans');
        setSelectedItem(fanspages)

      } else if (courses.length > 0) {
        setSelectedCategory('Courses');
        setSelectedItem(courses)

      } else {
        setSelectedCategory(''); // If all arrays are empty, clear selectedCategory
      }
    };
    

    setCategoryBasedOnArrays(); // Set the category when the couponData changes
  }, [couponData]);


  console.log(selectedItem)
  


    
  const [errors, setErrors] = useState({
    couponCode: "",
    selectedDiscount: "",
    discountValue: "",
    selectedCommunity: "",
    selectedYogaFans: "",
    selectedCourses: "",
    minimumAmount:'',
    selectedCategory:"",
    selectedItem:"",
    expiresAt:"",
    activateNow: '',
    paymentPlan:"",
    notes:''
  });
    
    const [coupon, setCoupon] = useState({
        selectedOffer:"",
        coupon_code: '',
        discountType: '',
        discountAmount: '',
        activate: dayjs(),
        expiresAt: dayjs(),
        communities: [],
        fanspages: [],
        amountInYear:0,
        amountInMonth:0,
        minimum_amount:0,
        paymentType: '',
        courses: [],
        notes: '',
    });

    useEffect(() => {
        if (couponData) {
            setCoupon({
                selectedOffer:couponData.offerType,
                coupon_code: couponData.coupon_code,
                discountType: couponData.discountType,
                discountAmount: couponData.discountAmount,
                communities: couponData.communities,
                fanspages: couponData.fanspages,
                amountInYear: couponData?.amountInYear ,
                amountInMonth: couponData?.amountInMonth ,
                courses: couponData.courses,
                minimum_amount:couponData?.minimum_amount,
                paymentType: couponData.paymentType,
                notes: couponData?.notes,
            });
        }
    }, [couponData]);

    useEffect(() => {
        const initializeValues = () => {
            if (couponData) {
                // Set selected values from coupon data received from location.state
                setSelectedCommunity(couponData.communities);
                setSelectedYogaFans(couponData.fanspages);
                setSelectedCourses(couponData.courses);
            }
        };

        initializeValues();
        setLoading(false);
    }, [couponData]);


    console.log(selectedItemObject )
    console.log(selectedCategory)

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch communities, fans pages, and courses
                await Promise.all([
                    getCommunity(),
                    getFans(),
                    getCourses()
                ]);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [params]);

    const getCommunity = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL +
          `Community/GetCommunitybasedonteacherid?createdby=${params && params.id
          }`)
          
        const discountOptions = res.data.communities.filter(community => community.community_type !== 'public' && community.festiveOffer !== false);
        setCommunities(discountOptions);
        // const discountOptions = res.data.community.filter(community => community.community_type !== 'public');
        // setCommunities(discountOptions)
      };
      
    
      const getFans = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + `FanPage/GetFansbyTeacherId/${params.id}`);
        

        const paidFanspage = res.data.fans.filter(fan => fan.fanspage_paymentype !== 'free' && fan.festiveOffer !== false);
        setYogaFans(paidFanspage);
      //   const paidFanspage = res.data.fansPages.filter(fan => fan.fanspage_paymentype !== 'free');
      // setYogaFans(paidFanspage)
      };
    
      const getCourses = async () => {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCourseallpublic`);
        

        const paidCourses = res.data.course.filter(course => course.course_paymenttype !== 'free' && (course.festiveOffer !== false && course.festiveOffer !== undefined ));
        setCourses(paidCourses);
      };

      // const handleChangeCommunity = (event) => {
      //   const {
      //     target: { value },
      //   } = event;
      //   setSelectedCommunity(typeof value === 'string' ? value.split(',') : value);
      // };
    
      // const handleChangeYogaFans = (event) => {
      //   const {
      //     target: { value },
      //   } = event;
      //   setSelectedYogaFans(typeof value === 'string' ? value.split(',') : value);
      // };
    
      // const handleChangeCourses = (event) => {
      //   const {
      //     target: { value },
      //   } = event;
      //   setSelectedCourses(typeof value === 'string' ? value.split(',') : value);
      // };

      const handleTogglePrivate = () => {
        setIsPrivate(!isPrivate)
      }

      const handleChangeMinimumAmount = (e) =>{
        setMinimumAmount(e.target.value);
      }

      const handleInputChange = (field) => (e) => {
        setCoupon({ ...coupon, [field]: e.target.value });
        setErrors((prev) => ({ ...prev, [field]: "" })); // Clear error for this field
      };

    // const handleDateChange = (field) => (newValue) => {
    //     setCoupon({ ...coupon, [field]: newValue });
    //     setErrors((prev) => ({ ...prev, [field]: "" })); // Clear error for this field
    // };

  const modifyString = str => str.split(" ").map((word, index) => index === 0 ? word.slice(0, -1) : word).join(" ");



  const handleUpdateCommunity = (newSelectedCommunity) => {
    setSelectedCommunity(newSelectedCommunity);
  };

  

  const handleUpdateYogaFans = (newSelectedYogaFans) => {
    setSelectedYogaFans(newSelectedYogaFans);
  };

  


  const handleUpdateCourses = (newSelectedCourses) => {
    setSelectedCourses(newSelectedCourses);
  };


    const validateFields = () => {
        const newErrors = { ...errors }; // Start with current errors
        console.log(newErrors)
    
        // Reset errors
        Object.keys(newErrors).forEach(key => {
          newErrors[key] = "";
        });
    
        // Check required fields
        if (!coupon.selectedOffer || coupon.selectedOffer === "sel") {
          newErrors.selectedOffer = "Offer type is required.";
        }

        
    
        if (coupon.selectedOffer === "item_Level") {
          if (!coupon.coupon_code) {
            newErrors.couponCode = "Coupon code is required.";
          }
    
          if (!coupon.discountType || coupon.discountType === "sel") {
            newErrors.selectedDiscount = "Discount type is required.";
          }
    
          if (!coupon.discountAmount) {
            newErrors.discountValue = "Discount value is required.";
          }
    
          if (!coupon.notes) {
            newErrors.notes = "Description is required.";
          }

          if(!selectedCategory){
            newErrors.selectedCategory = "Category is required.";
          }
    
          if(selectedCategory){
            if(!selectedItem){
              newErrors.selectedItem = `${modifyString(selectedCategory)} is required`;
            }
          }
    
          if(selectedCategory === "Yoga-Fans"){
            if(selectedItem){
              if(!selectedPayment){
    
                newErrors.paymentPlan = `Payment Plan is required`;
              }
            }
          }
    
          // // Validate "Expires At"
          // if (!coupon.expiresAt) {
          //   newErrors.expiresAt = "Expiration date is required.";
          // } 
          // // Validate "Activate Now"
          // if (!coupon.activate) {
          //   newErrors.activateNow = "Activation time is required.";
          // }
        }else{
          if (!coupon.coupon_code) {
            newErrors.couponCode = "Coupon code is required.";
          }
    
          if (!coupon.discountType || coupon.discountType === "sel") {
            newErrors.selectedDiscount = "Discount type is required.";
          }
    
          if (!coupon.discountAmount) {
            newErrors.discountValue = "Discount value is required.";
          }
    
          if (!coupon.notes) {
            newErrors.notes = "Description is required.";
          }
          //  // Validate "Expires At"
          //  if (!coupon.expiresAt) {
          //   newErrors.expiresAt = "Expiration date is required.";
          // } 
          // // Validate "Activate Now"
          // if (!coupon.activate) {
          //   newErrors.activateNow = "Activation time is required.";
          // }
        }
    
    
    
        setErrors(newErrors);
        return newErrors;
      };

      const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setSelectedItem(''); // Reset selected item when category changes
        setSelectedItemObject(null); // Reset the object of selected item
      };
    
      // Handle item change (community, yoga-fan or course)
      const handleItemChange = (event) => {
        const value = event.target.value;
        setSelectedItem([{_id:value}]);
        console.log(value)
    
        // Find the selected item object based on the category and value
        let selectedObject = null;
        if (selectedCategory === 'Communities') {
          selectedObject = communities.find(option => option._id === value);
        } else if (selectedCategory === 'Yoga-Fans') {
          selectedObject = yogaFans.find(option => option._id === value);
        } else if (selectedCategory === 'Courses') {
          selectedObject = courses.find(option => option._id === value);
        }
        setSelectedItemObject(selectedObject);
        setCoupon(prevCoupon => {
          const updatedCoupon = {
            ...prevCoupon,
            // [name]: value, // Update the property that is changed
      
            // If the selectedItemObject has `amountInYear` and `amountInMonth`, update those too
            ...(selectedItemObject?.amountInYear && { amountInYear: selectedItemObject.amountInYear }),
            ...(selectedItemObject?.amountInMonth && { amountInMonth: selectedItemObject.amountInMonth }),
          };
      
          return updatedCoupon;
        });

        if(value) setErrors((prev)=>({...prev,selectedItem:''}))

      };

      
      


      
    

   

 // Render options based on selected category
 const renderOptions = () => {
  let options = [];
  if (selectedCategory === 'Communities') {
    options = communities;
  } else if (selectedCategory === 'Yoga-Fans') {
    options = yogaFans;
  } else if (selectedCategory === 'Courses') {
    options = courses;
  }

  return options.map((option) => (
    <MenuItem key={option._id} value={option._id}>
      <Checkbox checked={selectedItem && selectedItem[0]._id === option._id} />
      <ListItemText primary={option.coummunity_title || option.name || option.course_title} />
    </MenuItem>
  ));
};

const getSelectedItemName = () => {
  const selectedOption = (selectedCategory === 'Communities' ? communities :
    selectedCategory === 'Yoga-Fans' ? yogaFans : courses
  ).find(option => option._id === selectedItem[0]._id);

  return selectedOption ? selectedOption.coummunity_title || selectedOption.name || selectedOption.course_title : '';
};


// Handle payment selection (Radio button change)
  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
    setAmountInYearAmount(coupon.amountInYear);
    setAmountInMonthAmount(coupon.amountInMonth);
  };


  function calculateTotalPrice(communities,courses,fanspages,discountType,discountAmount) {
   // Initialize sums for each category
   let communitySum = 0;
   let courseSum = 0;
   let fanspageSum = 0;

   // 1. Calculate the sum for communities
   communities.forEach(community => {
       if (community.paymentPlan === 'month') communitySum += community.amountInMonth * 12;
       if (community.paymentPlan === 'year') communitySum += community.amountInYear;
   });

   // 2. Calculate the sum for courses
   courses.forEach(course => {
       if (course.amountInMonth) courseSum += course.amountInMonth * 12;
       if (course.amountInWeek) courseSum += course.amountInWeek * 52;
       if (course.amountInBiWeek) courseSum += course.amountInBiWeek * 26;
       if (course.amountInOneTime) courseSum += course.amountInOneTime;
   });

   // 3. Calculate the sum for fan pages
   fanspages.forEach(fanspage => {
       if (fanspage.paymentPlan === 'year') fanspageSum += fanspage.amountInYear;
       if (fanspage.paymentPlan === 'month') fanspageSum += fanspage.amountInMonth * 12;
   });

   // console.log(fanspageSum)
   // console.log(communitySum)
   // console.log(courseSum)

   // Calculate total sum across all categories
   let totalSum = communitySum + courseSum + fanspageSum;
   
   // Calculate the total number of items across all arrays
   let totalItems = communities.length + courses.length + fanspages.length;

   // 4. Apply discount based on discount type
   let discountPerItem = 0;
   let finalDiscountedPrice = 0;

   if (discountType === "flatDiscount") {
       // Calculate the flat discount per item
       discountPerItem = (parseFloat(discountAmount) || 0) / totalItems;

       // Apply the discount to each item in the arrays
       communities.forEach(community => {
       
         if (community.paymentPlan === 'month') community.AfterDiscount =  community.amountInMonth * 12 - discountPerItem;
         if (community.paymentPlan === 'year') community.AfterDiscount =  community.amountInYear - discountPerItem;
       });
       courses.forEach(course => {
           if (course.amountInMonth) course.AfterDiscount = course.amountInMonth * Number(course.installment) - discountPerItem;
           if (course.amountInWeek) course.AfterDiscount = course.amountInWeek * Number(course.installment) - discountPerItem;
           if (course.amountInBiWeek) course.AfterDiscount = course.amountInBiWeek * Number(course.installment) - discountPerItem;
           if (course.amountInOneTime) course.AfterDiscount = course.amountInOneTime - discountPerItem;
       });
       fanspages.forEach(fanspage => {
           if (fanspage.paymentPlan === 'year') fanspage.AfterDiscount = fanspage.amountInYear - discountPerItem;
           if (fanspage.paymentPlan === 'month') fanspage.AfterDiscount = fanspage.amountInMonth * 12 - discountPerItem;
       });

       // 5. Calculate the final discounted price (after applying discount)
   finalDiscountedPrice = totalSum - discountPerItem * totalItems;
   } else if (discountType === "Percentage") {
       // Apply percentage discount to each item in the arrays
       communities.forEach(community => {

        
         if (community.paymentPlan === 'month') community.AfterDiscount = community.amountInMonth * 12 - (community.amountInMonth * 12 * (parseFloat(discountAmount) / 100));
         if (community.paymentPlan === 'year') community.AfterDiscount = community.amountInYear - (community.amountInYear *  (parseFloat(discountAmount) / 100))
           
       });
       courses.forEach(course => {
           if (course.amountInMonth) course.AfterDiscount = course.amountInMonth * 12 - (course.amountInMonth * 12 * (parseFloat(discountAmount) / 100));
           if (course.amountInWeek) course.AfterDiscount = course.amountInWeek * 52 - (course.amountInWeek * 52 * (parseFloat(discountAmount) / 100));
           if (course.amountInBiWeek) course.AfterDiscount = course.amountInBiWeek * 26 - (course.amountInBiWeek * 26 * (parseFloat(discountAmount) / 100));
           if (course.amountInOneTime) course.AfterDiscount = course.amountInOneTime - (course.amountInOneTime * (parseFloat(discountAmount) / 100));
       });
       fanspages.forEach(fanspage => {
           if (fanspage.paymentPlan === 'year') fanspage.AfterDiscount = fanspage.amountInYear - (fanspage.amountInYear * (parseFloat(discountAmount) / 100));
           if (fanspage.paymentPlan === 'month') fanspage.AfterDiscount = fanspage.amountInMonth * 12 - (fanspage.amountInMonth * 12 * (parseFloat(discountAmount) / 100));
       });
       // 5. Calculate the final discounted price (after applying percentage discount)
       finalDiscountedPrice = totalSum - (totalSum * (ceil(discountAmount) / 100));
   }



   // The arrays have been directly updated with the price field
   console.log({
       originalSum: totalSum,
       discountedPrice: finalDiscountedPrice,
       discountType: discountType,
       discountAmount: discountAmount,
   });

   return [
         {
           originalSum:totalSum,
           discountedPrice:finalDiscountedPrice,
           discountType:discountType,
           discountAmount:discountAmount,
         }
       ];
  }

    const handleUpdate = async (event) => {
        event.preventDefault();
        console.log("called")
      
        const validationErrors = validateFields();
      
        if (Object.keys(validationErrors).some(key => validationErrors[key])) {
          return; // Exit if there are validation errors
        }

        const result = calculateTotalPrice(selectedCommunity, selectedCourses, selectedYogaFans, coupon.discountType, coupon.discountAmount);
      
       
        // Prepare data for update
        // const data = {
        // //   offerType: coupon.selectedOffer,
        //   aprrove:true,
        //   coupon_code: coupon.coupon_code,
        //   discountType: coupon.discountType,
        //   discountAmount: coupon.discountAmount,
        //   communities: selectedCommunity,
        //   fanspages: selectedYogaFans,
        //   courses: selectedCourses,
          
        //   paymentType:selectedPayment,
        //   amountInMonth:amountInMonthAmount || coupon.amountInMonth,
        //   minimum_amount:minimumAmount,
        //   amountInYear:amountInYearAmount || coupon.amountInYear,
        //   // expiresAt: coupon.expiresAt,
        //   // activate: coupon.activate,
        //   notes: coupon.notes,
        //   createdby: params.id
        // };
        let data;

    if(coupon.selectedOffer === "bundle_Level"){
      data = {
        aprrove:true,
        coupon_code:coupon.coupon_code,
        discountType:coupon.discountType,
        discountAmount:coupon.discountAmount,
        // communities:selectedCommunity,
        // fanspages:selectedYogaFans,
        // courses:selectedCourses,
        communities: selectedCommunity,
        fanspages:selectedYogaFans,
        courses: selectedCourses,
        // expiresAt:expiresAt,
        // activate:activateNow,
        notes:coupon.notes,
        paymentType:selectedPayment ? selectedPayment : "none",
        amountInMonth:amountInMonthAmount || coupon.amountInMonth,
        minimum_amount:minimumAmount,
        amountInYear:amountInYearAmount || coupon.amountInYear,
        bundle_price:result,
        createdby:params.id
      }
    }
    else{
      if (selectedCategory === 'Communities') {
        setSelectedCommunity(selectedItem)
      } else if (selectedCategory === 'Yoga-Fans') {
       
        setSelectedYogaFans((prevState) => [...prevState, selectedItem])
      } else if (selectedCategory === 'Courses') {
        setSelectedCourses(selectedItem)
      }
// // Prepare data for submission
 data = {
    aprrove:true,
    coupon_code: coupon.coupon_code,
    discountType: coupon.discountType,
    discountAmount: coupon.discountAmount,
    communities: selectedCommunity,
    fanspages: selectedYogaFans,
    courses: selectedCourses,
    isPrivate:isPrivate,
    paymentType:selectedPayment,
    amountInMonth:amountInMonthAmount || coupon.amountInMonth,
    minimum_amount:minimumAmount,
    amountInYear:amountInYearAmount || coupon.amountInYear,
    // expiresAt: coupon.expiresAt,
    // activate: coupon.activate,
    bundle_price:result,
    notes: coupon.notes,
    createdby: params.id
};
    }
      
        console.log(data);
        // try {
        //   // Use the appropriate API endpoint for updating a coupon
        //   const response = await axios.put(process.env.REACT_APP_BASE_URL + `Coupon/UpdateCoupons/${couponData._id}`, data);
        //   console.log('Update Success:', response.data);
        //   navigate(`/teachers/details/settings/coupons/${params.id}`);
        //   // Handle success (e.g., show a success message, etc.)
      
        // } catch (error) {
        //   console.error('Update Error:', error);
        //   // Handle error (e.g., show an error message)
        // }
      };



  return (
    <>
      <Box className='companies'>

        <MiniDrawer menuOrder={12} submenuOrder={2} />
        <Box component="section" className="contentWraper">
          <Box className='headingBox'>
            <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Settings</Typography>
          </Box>
          <Box className='whiteContainer' sx={{ minHeight: "80vh" }}>
            <Grid item xs={12} sm={6} md={6} lg={12}>
              <Box className="">
                <Box className="mb-10  p-10">
                  <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Edit Promos and Offers</Typography>
                </Box>

                <Box className="mb-20 p-10">
                  <Box className="mb-10 p-20 whiteContainer ">
                    <form onSubmit={handleUpdate}>
                      <FormGroup>
                        <Box className="mb-20 ">
                          <Typography component='div' className='label'>
                            <span><span>{coupon.selectedOffer === "bundle_Level" ? "Bundle name" : "Coupon Code"}</span></span>
                            <Typography component='span' className='star'>*</Typography>
                            <Typography component='span' style={{ fontSize: 'smaller' }}>
                              {/* This name will be used to create a unique URL for your community */}
                            </Typography>
                          </Typography>
                          <FormControl variant="standard" className='mb-10'>
                            <TextField
                              id="coupon-code"
                              variant="outlined"
                              size="small"
                              name='coupon_code'
                              className='textfield'
                              style={{ width: '500px' }}
                              value={coupon.coupon_code}
                              onChange={(e) => {

                                setCoupon({ ...coupon, coupon_code: e.target.value });
                                setErrors(prev => ({ ...prev, couponCode: "" }));
                              }
                              }
                            />
                            {errors.couponCode && <p style={{ color: 'red' }} className="fz-13 m-0">{errors.couponCode}</p>}
                          </FormControl>

                          <Typography component='div' className='label'>
                            Choose Discount Type<Typography component='span' className='star'>*</Typography>
                          </Typography>
                          <FormControl className='mb-20' >
                            {/* <Select
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  value={coupon.discountType}

                                                  value={selectedDiscount ? selectedDiscount : "sel"}
                                                  placeholder="Select Discount Type"
                                                  className="select"
                                                  sx={{
                                                      minWidth: "500px"
                                                  }}
                                                  onChange={handleChangeDiscountType}
                                              >
                                                  <MenuItem disabled value={'sel'}>Select Discount Type</MenuItem>
                                                  <MenuItem value={"flatDiscount"}>Flat Discount</MenuItem>
                                                  <MenuItem value={"Percentage"}>Percentage</MenuItem>
                                              </Select> */}
                            <Select
                              labelId="discount-type-label"
                              id="discount-type"
                              value={coupon.discountType}
                              onChange={(e) => setCoupon({ ...coupon, discountType: e.target.value })}
                              sx={{ minWidth: "500px" }}
                            >
                              <MenuItem disabled value=''>Select Discount Type</MenuItem>
                              <MenuItem value="flatDiscount">Flat Discount</MenuItem>
                              <MenuItem value="Percentage">Percentage</MenuItem>
                            </Select>
                            {errors.selectedDiscount && (
                              <p style={{ color: 'red' }} className="fz-13 m-0">
                                {errors.selectedDiscount}
                              </p>
                            )}
                          </FormControl>

                          <Typography component='div' className='label'>
                            <span>Discount Value</span>
                            <Typography component='span' className='star'>*</Typography>
                            <Typography component='span' style={{ fontSize: 'smaller' }}>
                              {/* This name will be used to create a unique URL for your community */}
                            </Typography>
                          </Typography>
                          <FormControl variant="standard" className='mb-10'>
                            <TextField
                              id="discount-value"
                              variant="outlined"
                              size="small"
                              name='discount_amount'
                              className='textfield'
                              style={{ width: '500px' }}
                              value={coupon.discountAmount}
                              onChange={handleInputChange('discountAmount')}
                              InputProps={{
                                startAdornment: coupon.discountType === "Percentage" ? (
                                  <InputAdornment position="start">%</InputAdornment>
                                ) : <InputAdornment position="start">₹</InputAdornment>,
                              }}
                            />
                            {errors.discountValue && (
                              <p style={{ color: 'red' }} className="fz-13 m-0">
                                {errors.discountValue}
                              </p>
                            )}
                          </FormControl>


                          {
                            coupon.selectedOffer === 'cart_Level' &&
                            <>
                              <Typography component='div' className='label'>
                                <span>Minimum Amount</span>
                                <Typography component='span' className='star'>*</Typography>
                                <Typography component='span' style={{ fontSize: 'smaller' }}>
                                  {/* This name will be used to create a unique URL for your community */}
                                </Typography>
                              </Typography>
                              <FormControl variant="standard" className='mb-10'>
                                <TextField
                                  id="filled-size-small"
                                  variant="outlined"
                                  size="small"
                                  name='name'
                                  className='textfield'
                                  style={{ width: '500px' }}
                                  value={minimumAmount}
                                  onChange={handleChangeMinimumAmount}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                  }}
                                />
                                {errors.minimumAmount && (
                                  <p style={{ color: 'red' }} className="fz-13 m-0">
                                    {errors.minimumAmount}
                                  </p>
                                )}
                              </FormControl>
                            </>
                          }

                          {coupon.selectedOffer === 'item_Level' &&
                            <>
                              <Typography component="div" className="label">
                                <span>Select Category</span>
                                <Typography component="span" className="star">*</Typography>
                              </Typography>
                              <FormControl className="mb-20 mt-5">
                                <InputLabel id="category-select-label">Select Category</InputLabel>
                                <Select
                                  labelId="category-select-label"
                                  id="category-select"
                                  value={selectedCategory}
                                  onChange={handleCategoryChange}
                                  sx={{ minWidth: '500px' }}
                                >
                                  <MenuItem value="Communities">Communities</MenuItem>
                                  <MenuItem value="Yoga-Fans">Yoga-Fans Pages</MenuItem>
                                  <MenuItem value="Courses">Courses</MenuItem>
                                </Select>
                              </FormControl>

                              {/* Conditionally Render Based on Selected Category */}
                          {(selectedCategory && coupon.selectedOffer === 'item_Level') && (
                            <>
                              <Typography component="div" className="label">
                                <span>{`Select ${selectedCategory}`}</span>
                                <Typography component="span" className="star">*</Typography>
                              </Typography>
                              <FormControl className="mb-20 mt-5">
                                <InputLabel id="item-select-label">Select {selectedCategory}</InputLabel>
                                <Select
                                  labelId="item-select-label"
                                  id="item-select"
                                  value={selectedItem && selectedItem[0]._id}
                                  onChange={handleItemChange}
                                  renderValue={getSelectedItemName} // Display the selected item's name
                                  sx={{ minWidth: '500px' }}
                                >
                                  {renderOptions()}
                                </Select>
                                {errors.selectedItem && (
                                  <p style={{ color: 'red' }} className="fz-13 m-0">
                                    {errors.selectedItem}
                                  </p>
                                )}
                              </FormControl>
                            </>
                          )}

                          {/* Payment Amount Section */}
                          {(selectedCategory === 'Yoga-Fans' && selectedItem) && (
                            <>
                              <Typography component="div" className="label">
                                <span>Select Payment Amount</span>
                              </Typography>
                              <FormControl className="mb-20 mt-5">
                                <FormLabel component="legend" className="label">
                                  Choose Payment Plan:
                                </FormLabel>
                                <RadioGroup
                                  value={selectedPayment}
                                  onChange={handlePaymentChange}
                                >
                                  {coupon.amountInYear ? (
                                    <FormControlLabel
                                      value="year"
                                      control={<Radio />}
                                      label={`Amount per Year: ${coupon.amountInYear} `}
                                    />
                                  ) : (
                                    <Typography component="div" className="label">
                                      <span>Amount per Year: No Amount Available</span>
                                    </Typography>
                                  )}
                                  {coupon.amountInMonth ? (
                                    <FormControlLabel
                                      value="month"
                                      control={<Radio />}
                                      label={`Amount per Month: ${coupon.amountInMonth} `}
                                    />
                                  ) : (

                                    <Typography component="div" className="label">
                                      <span>Amount per Month: No Amount Available</span>
                                    </Typography>
                                  )}
                                </RadioGroup>
                                {errors.selectedItem && (
                                  <p style={{ color: 'red' }} className="fz-13 m-0">
                                    {errors.selectedItem}
                                  </p>
                                )}
                              </FormControl>
                            </>
                          )}


                          {/* Payment Amount Section */}
                          {(selectedCategory === 'Communities' && selectedItem) && (
                            <>
                              <Typography component="div" className="label">
                                <span>Select Payment Amount</span>
                              </Typography>
                              <FormControl className="mb-20 mt-5">
                                <FormLabel component="legend" className="label">
                                  Choose Payment Plan:
                                </FormLabel>
                                <RadioGroup
                                  value={selectedPayment}
                                  onChange={handlePaymentChange}
                                >
                                  {coupon.amountInYear ? (
                                    <FormControlLabel
                                      value="year"
                                      control={<Radio />}
                                      label={`Amount per Year: ${coupon.amountInYear} `}
                                    />
                                  ) : (
                                    <Typography component="div" className="label">
                                      <span>Amount per Year: No Amount Available</span>
                                    </Typography>
                                  )}
                                  {coupon.amountInMonth ? (
                                    <FormControlLabel
                                      value="month"
                                      control={<Radio />}
                                      label={`Amount per Month: ${coupon.amountInMonth} `}
                                    />
                                  ) : (

                                    <Typography component="div" className="label">
                                      <span>Amount per Month: No Amount Available</span>
                                    </Typography>
                                  )}
                                </RadioGroup>
                                {errors.selectedItem && (
                                  <p style={{ color: 'red' }} className="fz-13 m-0">
                                    {errors.selectedItem}
                                  </p>
                                )}
                              </FormControl>
                            </>
                          )}
                            </>
                          }

                          





                          {
                            coupon.selectedOffer === 'bundle_Level' && <EditBundle
                              selectedCommunity={selectedCommunity}
                              selectedYogaFans={selectedYogaFans}
                              selectedCourses={selectedCourses}
                              onUpdateCommunity={handleUpdateCommunity}
                              onUpdateYogaFans={handleUpdateYogaFans}
                              onUpdateCourses={handleUpdateCourses}
                              communities={communities}
                              yogaFans={yogaFans}
                              courses={courses}
                            />
                          }

                          {
                            (coupon.selectedOffer === 'item_Level' || coupon.selectedOffer === 'cart_Level') && (
                              <>
                                <Box className="mb-20">
                                  <FormControlLabel
                                    className="checkbox_list"
                                    control={
                                      <Checkbox
                                        checked={isPrivate}
                                        onChange={handleTogglePrivate}
                                        name="checkbox-value"
                                        color="primary"
                                      />
                                    }
                                    label={
                                      <Typography component="div" className="label mb-0">
                                        Do you want to make this  as Private Coupon ? {" "}
                                      </Typography>
                                    }
                                  />
                                </Box>
                              </>
                            )
                          }



                          <Typography component='div' className='label'>
                            <span>Description </span>
                            <Typography component='span' className='star'>*</Typography>
                            <Typography component='span' style={{ fontSize: 'smaller' }}>
                              {/* This name will be used to create a unique URL for your community */}
                            </Typography>
                          </Typography>
                          <FormControl variant="standard" className='mb-10'>
                            <TextField
                              id="filled-size-small-textarea"
                              variant="outlined"
                              size="small"
                              name='notes'
                              className='textfield'
                              style={{ width: '500px' }}
                              multiline
                              rows={3} // Adjust the number of rows as needed
                              value={coupon.notes}
                              //   onChange={(e) => {
                              //       setNotes(e.target.value);
                              //       setErrors(prev => ({ ...prev, notes: "" }));
                              //   }}
                              onChange={(e) => setCoupon({ ...coupon, notes: e.target.value })}
                            />
                            {errors.notes && <p style={{ color: 'red' }} className="fz-13 m-0">{errors.notes}</p>}
                          </FormControl>


                        </Box>
                      </FormGroup>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          mt: 4,
                          pt: 3
                        }}
                      >
                        <Button type="submit" variant="contained" className='addbtn mr-10'>
                          Update
                        </Button>
                      </Box>
                    </form>

                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Editcoupon