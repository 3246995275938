import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Stack, TextField, Typography, styled } from '@mui/material'
import Spacing from '../../Home/Spacing'
import 'swiper/css';
import 'swiper/css/pagination';
import { Link, useLocation } from 'react-router-dom'
import prof1 from '../../../assets/images/profile.png'
import axios from 'axios';
import moment from 'moment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function Comments() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const location = useLocation()
    const auth = JSON.parse(localStorage.getItem('userData'))
    const [blog_id, setBlog_id] = useState(location && location.state)
    const [comments, setComments] = useState([])
    const [replyModal, setReplyModal] = useState(false)
    const [content, setContent] = useState('')
    const [replydata, setReplydata] = React.useState('')

    React.useEffect(() => {
        getComments()
    }, [location])

    const getComments = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `BlogComments/getcommentsbyid/${blog_id}`, { headers: headerlocal }).then((res) => {
            setComments(res.data)
            console.log(res,"hhhh");
        }).catch((error) => {
            console.log(error.response, "error");
        })
    }

    const handleReply = (data) => {
        setReplydata(data);
        setReplyModal(true);
    }

    const commentPost = async (e, parent_id) => {
        try {
            let body = {
                "parentId": parent_id,
                "blogId": blog_id,
                "user_id": auth && auth._id,
                "content": content
            }
            await axios.post(process.env.REACT_APP_BASE_URL + `BlogComments/AddBlogComments`, body, { headers: headerlocal }).then((res) => {
               console.log(res,"comments");
                setContent('')
                getComments()
            }).catch((error) => {
                console.log(error.response, "error");
            })
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const handleDeletecomment = (id) => {
        let body = {
            commentId: id,

        }
        axios.put(process.env.REACT_APP_BASE_URL + `BlogComments/replydelete`, body)
            .then((res) => {
                console.log(res,"deletecomment");
                getComments()
                // enqueueSnackbar("comment deleted successfully", { variant: 'success' })

            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <Box className="comments_section my-30 mw100">
                        <Typography component='h5' variant='h5' className='text_black semibold '>Comments</Typography><br></br>
            <Grid container columnSpacing={2} rowSpacing={2}>
                {comments && comments.map((data, i) => (
                    <Grid item xs={12} sm={12} md={12} key={data._id} >
                        <Box className='receiver' >
                            <Box className="comment_each ">
                                <Box className="flex-center ">
                                    <Box className="flex-center">

                                        {data.prof1?.length > 0 ? (
                                            <img
                                                src={process.env.REACT_APP_BASE_URL + `${data.prof1}`}
                                                height={50}
                                                width={50}
                                                style={{ borderRadius: '50%' }}
                                                className='mr-10'
                                            />
                                        ) : (
                                            < AccountCircleIcon className="mr-10" fontSize="large" />
                                        )}
                                        <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>{data.user._id && data.user.username}</Typography>
                                        <Typography component='div' variant='body1' className='text_grey letter_sp mr-20 fz-12 semibold '>{moment(data.createdAt).fromNow()}</Typography>

                                    </Box>
                                    {/* <Box className="ml-auto">

                                    </Box> */}

                                </Box>
                            </Box>
                            <Box>
                                <Typography component='div' variant='body1' className='py-20 letter_sp fz-14 fw-400'>{data.content}</Typography>
                                <Typography
                                    component='div'
                                    variant='body1'
                                    className='text_grey letter_sp fz-14 fw-400 reply-button'
                                    onClick={() => handleReply(data)}
                                >
                                    <i class="fa-solid fa-reply"></i>  Replies
                                </Typography>
                            </Box>
                            {/* Render replies only if replyModal is true and it matches the current comment's _id */}
                            {replyModal && replydata && replydata._id === data._id && data.replies.length > 0 && (
                                data.replies.map((item) => (
                                    <Grid item xs={12} sm={12} md={12} key={item._id}>
                                        <Box className={item.user_id === auth._id ? 'sender' : 'receiver'}>
                                            <Box className="comment_each ">
                                                <Box className="flex-center ">
                                                    <Box className="">
                                                        {data.prof1?.length > 0 ? (
                                                            <img
                                                                src={process.env.REACT_APP_BASE_URL + `${data.prof1}`}
                                                                height={50}
                                                                width={50}
                                                                style={{ borderRadius: '50%' }}
                                                                className='mr-10'
                                                            />
                                                        ) : (
                                                            < AccountCircleIcon className="mr-10" fontSize="large" />
                                                        )}
                                                        {/* <img src={prof1} width="30" height="30" alt="Profile" /> */}
                                                    </Box>
                                                    <Box>
                                                        <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>Admin</Typography>
                                                        {/* <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>{data.user_id && data.user_id.username}</Typography> */}

                                                        <Typography component='span' variant='body1' className='text_grey letter_sp mr-20 fz-12 semibold '>{moment(item.createdAt).fromNow()}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Typography component='div' variant='body1' className='text_grey letter_sp fz-12 fw-400'>{item.content}</Typography>
                                                </Box>
                                                <Box className='flex-center '>
                                                    <Button className='btn mr-10'>
                                                        <Button className='btn mr-10' onClick={() => handleDeletecomment(item._id)}>
                                                            <Typography component='span' variant='span' className='text_primary fz-14 semibold '><i class="fa-solid fa-trash"></i> Delete</Typography>
                                                        </Button>
                                                    </Button>
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Grid>
                                ))
                            )}
                            {/* Render comment box only if replyModal is true and it matches the current comment's _id */}
                            {replyModal && replydata && replydata._id === data._id && (
                                <Box className="comment_box d-flex mt-20">
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                        placeholder='Add a comment'
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        className='textfield text_white'
                                        sx={{ width: 'calc(100% - 50px)' }}
                                    />
                                    {data.prof1?.length > 0 ? (
                                        <img
                                            src={process.env.REACT_APP_BASE_URL + `${data.prof1}`}
                                            height={50}
                                            width={50}
                                            style={{ borderRadius: '50%' }}
                                            className='mr-10'
                                        />
                                    ) : (
                                        < AccountCircleIcon className="ml-10" fontSize="large" />
                                    )}
                                    {/* <img src={prof1} width="30" height="30" alt="Profile" /> */}

                                </Box>
                            )}
                            <Box className="text-right" style={{ paddingRight: 40 }}>
                                {replyModal && replydata && replydata._id === data._id && <Button className='fz-16 theme-btn mt-20' onClick={(e) => commentPost(e, data._id)}>Send</Button>}
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
