import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography, TableBody, IconButton, Grid, TextField, InputAdornment, Radio, Box, Button, FormControl, FormGroup } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack'
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { ExpandMore, SearchOutlined, DeleteOutline, UnfoldMore, CloseOutlined, HighlightOffRounded, SettingsInputComponent } from '@mui/icons-material';
import moment from 'moment'
import { CiLink } from "react-icons/ci";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { header, headerformdata } from "../../../components/axiousheader"
import VisibilityIcon from '@mui/icons-material/Visibility';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#f3714d',
    },
});

export default function DataTable() {
    const headerformdata = {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem("token")
    }
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [deletearry, setDeleteArray] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [teacherDetails, setTeacherDetails] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('')
    const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [subscription, setSubscription] = React.useState()
    console.log(subscription, "teacherrrr")

    React.useEffect(() => {
        getTeacherDetails()
    }, [page, rowsPerPage, searchTerm])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getTeacherDetails = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `Admin/GetAllAdminusersdetails?size=${rowsPerPage}&page=${page}&search=${searchTerm}`, { headers: headerformdata }).then((res) => {
            console.log(res.data, 'userdetails');
            setTeacherDetails(res.data.getuserdata)
            setCount(res.data.totalcount)
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleDeleteData = () => {
        setDeletemodel(true)
    }

    const handleDelete = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `Admin/deleteUser/${deleteid}`, {}, { headers: headerformdata },).then((res) => {
            handleClose()
            getTeacherDetails()
            enqueueSnackbar("User deleted successfully", { variant: 'success' })

        }).catch((err) => {
            console.log(err);
        })
    }

    const EditData = (row) => {
        console.log(row, 'rowrow');
        let obj = {
            data: row
        }
        navigate("/editusers", { state: obj })
    }

    const handleOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleClose = () => {
        setDeleteId('')
        setOpen(false)
    }

    const handleRemove = (e) => {
        setSearchTerm('');
        setPage(0)
    }

    const handleCopy = (e, id) => {
        e.preventDefault();
        const url = generateUrl(id);
        copyToClipboard(url);
    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            enqueueSnackbar('Link copied to clipboard.', { variant: 'success' })
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const generateUrl = (id) => {
        return `${id}`;
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Toolbar className='toolbar comptoolbar'>

                    {deletearry && deletearry.length > 0 && <Button className='del-btn' onClick={handleDeleteData}>Delete</Button>}
                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={searchTerm}
                                className='textfield search'
                                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchTerm !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>
                <TableContainer id='table'>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className='tble'>
                        <TableHead className='DT-head'>
                            <TableRow className=''>
                                <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'><strong>Profile Image</strong></TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Name</strong> </TableCell>
                                {/* <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Username</strong> </TableCell> */}
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Email</strong> </TableCell>
                                {/* <TableCell style={{ minWidth: 180 }} align="left" className='tbl_head'> <strong>Address</strong>  </TableCell> */}
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Phone</strong>  </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'> <strong>Action</strong>  </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teacherDetails && teacherDetails.length > 0 ? teacherDetails.map((row, i) => {
                                return (
                                    <TableRow className='' key={row._id}>
                                        <TableCell align="center" className='DT-row'>
                                            {row.image
                                                ? <img className="cat_image" src={process.env.REACT_APP_BASE_URL + row.image} alt='' height={50} width={50} />
                                                : <PersonIcon style={{ height: 50, width: 50 }} /> // Replace PersonIcon with your actual icon component or element
                                            }
                                        </TableCell>
                                        <TableCell className='DT-row'  >{row.name ? row.name : '-'}</TableCell>
                                        {/* <TableCell className='DT-row'  >{row.username ? row.username : '-'}</TableCell> */}
                                        <TableCell align="left" className='DT-row'  >{row.email ? row.email : '-'}</TableCell>
                                        {/* <TableCell align="left" className='DT-row' >{row.address ? row.address : '-'}</TableCell> */}
                                        <TableCell align="left" className='DT-row' >{row.contact_number ? row.contact_number : '-'}</TableCell>
                                        <TableCell align="center" className='DT-row' style={{ cursor: 'pointer' }} >
                                            <Box className="flex-center justify-content-center actionbtns">
                                                <Tooltip title="Edit"><Box className='a_tag' onClick={() => EditData(row)}><EditOutlinedIcon className='icons text-grey' /></Box></Tooltip>
                                                <Tooltip title="Delete"><Box ><DeleteOutline className='icons text-grey' onClick={() => handleOpen(row._id)} /></Box></Tooltip>

                                                {/* <Tooltip title="View"> <Box  ><VisibilityIcon className='icons text-grey' onClick={(e) => handleNavigate(e, row._id)} /></Box></Tooltip> */}
                                                {/* <Tooltip title="Copy URL"> <Box  ><CiLink className='icons text-grey' onClick={(e) => handleCopy(e, row.unique_link)} /></Box></Tooltip> */}

                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableCell colSpan={7} className='nodata' > No data found </TableCell>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Paper>
            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete User?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}