import * as React from 'react';
import Box from '@mui/material/Box';
import Check from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Button, Tooltip, Radio, FormControl, Grid, TextareaAutosize, InputAdornment, MenuItem, RadioGroup, MenuList, Popper, TextField, FormControlLabel, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useCommunity } from "../Context/CommunityContext";
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close';
import Switch, { SwitchProps } from '@mui/material/Switch';
import DialogTitle from '@mui/material/DialogTitle';
import './Communityusers.css'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#f3714d',
    },
});


export default function DataTable({ params }) {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    let tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const { enqueueSnackbar } = useSnackbar()
    const { communityUser, addCommunityUser, fetchCommunity } = useCommunity();
    const { communitydetails, addCommunitydetails } = useCommunity();
    // console.log(communitydetails.community_type,"communitydetails");
    const [isActive, setisActive] = React.useState(false)
    const [buttondisable, setButtondisable] = React.useState(false)
    const [addDisable, setAddDisable] = React.useState(false)
    const [error, setError] = React.useState({ firstname: "", email: "" })
    const [user, setUser] = React.useState([])
    const [addEventFrom, setaddEventFrom] = React.useState('Community')
    // const params = useParams()
    const [staffdata, setStaffdata] = React.useState([])
    const [selecttedUsers, setSelecttedUsers] = React.useState([])
    const [selectNewUsers, setSelectNewUsers] = React.useState([])
    const [newUser, setNewUser] = React.useState({ firstname: '', email: '', gender: 'Male', phone: '', address: '' })

    const [communityData, setCommunityData] = React.useState(null);
    const navigate = useNavigate()


	React.useEffect(() => {
        // Retrieve and parse community data from local storage when the component mounts
        const communityDataString = localStorage.getItem('communityData');
        if (communityDataString) {
            setCommunityData(JSON.parse(communityDataString));
        }
    }, []);

	console.log(params, 'paramschild1');
	console.log(communityData)
    const validation = () => {
        if (!newUser.firstname) {
            setError({ firstname: "First name can not be empty" })
            return false
        }
        // else if (!user.specialization) {
        //     setError({ specialization: "Specialization can not be empty" })
        //     return false
        // }
        else if (!newUser.email) {
            setError({ email: "Email cannot be empty" })
            return false
        }
        // else if (newUser.email) {
        //     const pattern = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
        //     var valid = pattern.test(user.email)
        //     if (!valid) {
        //         setError({ email: "Please enter valid Email" })
        //         return false
        //     }
        // }
        return true
    }

    

    React.useEffect(() => {
        fetchCommunity(params.id);
    }, [params.id]);

    let username, uservalue;
    const handleChange = (e) => {
        username = e.target.name;
        uservalue = e.target.value;
        setNewUser({ ...newUser, [username]: uservalue })
        setError({ ...error, [username]: '' })
    };
    console.log(communityUser, 'communityUsercommunityUser');

    React.useEffect(() => {
        if(communityData){

            getUser()
        }
    }, [communityData])
console.log(communityData)
    const getUser = async () => {
        try {
            await axios.get(process.env.REACT_APP_BASE_URL + `Admin/usersdetails`, { headers: headerlocal }).then((res) => {
                console.log(res.data.getuserdata, 'userdataa');
                res.data.getuserdata && res.data.getuserdata.forEach((item, i) => {
                    const isSelected = communityData && communityData.user_id ? 
                    communityData.user_id.some(user => user.user_id === item._id): false;

                    if (isSelected) {
                        // let ind = communityData.user_id.findIndex(dex => dex.user_id === item._id)
                        setSelecttedUsers(prevState => [...prevState, 
                            { 
                                email: item.email, 
                                user_id: item._id, 
                                username: item.username, 
                                // isActive: communityData[ind].isActive 

                            }]);
                    }
                    var value = { selectuser: isSelected };
                    Object.assign(item, value);
                    
                })
                setUser(res.data.getuserdata)
            }).catch((err) => console.log(err, "error1"))
        } catch (error) {
            console.log(error.response, "error2");
        }
    }

    const habdleSelectstaff = async (e, item, i) => {
        console.log(item.selectuser)
        console.log(i)
        var userss = [...user]
        var value = item.selectuser
        console.log(value)
        if (value === false) {
            userss[i].selectuser = true;
            setUser(userss)
            // const isActive =communitydetails ?  communitydetails.community_type === 'public': false                             ;
            // setSelecttedUsers(prev => prev.concat({ username: item.username, user_id: item._id, email: item.email, isActive }))
            // setSelectNewUsers(prev => prev.concat({ username: item.username, user_id: item._id, email: item.email, isActive }))
            // const isActive =communitydetails ?  communitydetails.community_type === 'public': false                             ;
            setSelecttedUsers(prev => prev.concat({ username: item.username, user_id: item._id, email: item.email }))
            setSelectNewUsers(prev => prev.concat({ username: item.username, user_id: item._id, email: item.email }))
        }
        if (value === true) {
            var data1 = selecttedUsers.filter((val) => val.user_id !== item._id)
            var data2 = selectNewUsers.filter((val) => val.user_id !== item._id)
            setSelecttedUsers(data1)
            setSelectNewUsers(data2)
            userss[i].selectuser = false;
            setStaffdata(userss)
        }
    }
    console.log(selectNewUsers, "selectednewusers")
    const handleUpdateCommunity = async () => {
        // var valid = validate();
        // if (valid == true) {
        // setTruebutton(true);
        const filteredData = [];
        const seenIds = {};
        var newUsers = [];
        selecttedUsers.forEach(item => {
            if (!seenIds[item.user_id]) {
                filteredData.push(item);
                seenIds[item.user_id] = true;
                // newUsers.push({...item,isActive: communitydetails.community_type === 'public' ? true : false})
            }
        });
        // console.log(newUsers,'newUsersnewUsersnewUsers')
        // console.log(seenIds, 'filteredData');
        // console.log(filteredData, 'filteredData');
        const formData = new FormData();
        // formData.append('isActive', communitydetails.community_type === 'public' ? 'true' : 'false');
        formData.append('user_id', JSON.stringify(filteredData.length > 0 ? filteredData : []))
        formData.append('new_Users', JSON.stringify(selectNewUsers.length > 0 ? selectNewUsers : []))

        console.log([...formData], 'formdataaa');
        let body = {
            user_id: JSON.stringify(filteredData.length > 0 ? filteredData : []),
            new_Users: JSON.stringify(selectNewUsers.length > 0 ? selectNewUsers : [])
        }
        axios.post(process.env.REACT_APP_BASE_URL + `community/UpdateUserCommunity/${communityData?._id}`, body, { headers: headerlocal }).then((res) => {
            console.log(res.data, "response");
            setSelecttedUsers([]);
            fetchCommunity(params.id)
            enqueueSnackbar("Community updated successfully", { variant: 'success' });
            localStorage.setItem('communityData', JSON.stringify(res.data.updateusercommunity));

			// navigate(`/teachers/details/community/${params.id}`)
        }).catch((err) => {
            enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
            console.log(err, "error");
        });
        // }
    }

    const addUserDetails = () => {
        const validator = validation()
        if (validator === true) {
            const formData = new FormData();
            formData.append("username", newUser.firstname);
            // formData.append('addEventFrom',addEventFrom )
            formData.append("usertype", 'member')
            formData.append('community_id', communityData?._id)
            formData.append("email", newUser.email);
            formData.append("address", newUser.address);
            formData.append("contact_number", newUser.phone);
            formData.append("gender", newUser.gender ? newUser.gender : '');
            formData.append("role_id", 3);
            console.log([...formData], 'formData');
            axios.post(process.env.REACT_APP_BASE_URL + `Admin/addMember`, formData, { headers: headerformdata }).then((res) => {
                console.log(res, "response user");
                const resp = res.data.addUser;
                if (resp) {
                    const obj = {
                        "username": resp.username || "",
                        "user_id": resp._id || "",
                        "email": resp.email || "",
                    };
                    addCommunityUser(obj);
                    fetchCommunity();
                    setNewUser({ firstname: '', email: '', gender: 'Male', phone: '' });
                    enqueueSnackbar("Member added successfully", { variant: 'success' });
                }
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err && err.response && err.response.data.message ? err.response.data.message : "Somthing went wront", { variant: 'error' })
            })
        }
    }

    console.log(selecttedUsers, 'selecttedUsers');


    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    return (
        <Box sx={{ width: '100%' }}>
            <Box className='whiteContainer p-20 mb-10'>
                <Typography component='h6' className='formTitle'>Select Members</Typography>
                <Grid container className='newmerchant  mb-20'>
                    <Grid item sm={12} md={12} className="w-100">
                        <Box className="form-box staff_list" >
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                {user && user.length > 0 ? user.map((item, index) => (
                                    <Grid item sm={12} md={12} lg={6} xl={4} key={index} className="w-100 d-flex">

                                        <Box onClick={(e) => habdleSelectstaff(e, item, index)} className={item.selectuser === true ? "selected_staff staff_card" : "staff_card"} >
                                            <Check className='checksvg' />
                                            <Avatar className='mr-10' >{item && item.username && item.username.split("", 1)}</Avatar>
                                            <Box>
                                                <Box className='fz-16 semibold'> {item.username && item.username}</Box>
                                                <Box className='fz-14'>{item.email}</Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )) : ""}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Box className="text-right">
                    {buttondisable === false ? <Button variant="contained" onClick={handleUpdateCommunity} sx={{ mt: 1, mr: 1, width: 100 }}
                        className='theme-btn'>Submit</Button> : <Button variant="contained" sx={{ mt: 1, mr: 1 }} className='theme-btn'>Please wait</Button>}
                </Box>
            </Box>
            <Box className='whiteContainer  mb-10'>
                <Grid container className='newmerchant '>
                    <Typography component='h6' className='formTitle'>Add Members</Typography>
                    <Grid item sm={12} md={12} className="w-100">
                        <Box className="form-box staff_list" >
                            <Grid item xs={12} sm={5} md={5} lg={5}>
                                <FormControl variant="standard" fullWidth>
                                    <Typography component='div' className='label'>Name: <Typography component='span' className='star'>*</Typography></Typography>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        name='firstname'
                                        className='textfield'
                                        placeholder='First Name'
                                        value={newUser.firstname}
                                        onChange={handleChange}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // }}
                                    />
                                </FormControl>
                                <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                            </Grid>

                            <Grid item xs={12} sm={5} md={5} lg={5}>
                                <FormControl variant="standard" fullWidth>
                                    <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        name='email'
                                        className='textfield'
                                        value={newUser.email}
                                        placeholder='Email'
                                        onChange={handleChange}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // }}
                                    />
                                </FormControl>
                                <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                            </Grid>

                            <Grid item xs={12} sm={5} md={5} lg={5}>
                                <FormControl variant="standard" fullWidth>
                                    <Typography component='div' className='label'>Phone:</Typography>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        name='phone'
                                        className='textfield'
                                        value={newUser.phone}
                                        placeholder='Phone'
                                        onChange={handleChange}
                                        onInput={(e) => onlyNumbers(e)}
                                        inputProps={{ maxLength: 14 }}
                                    // InputProps={{
                                    //     startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} lg={5} className='radiobtnGrid'>
                                <Typography component='div' className='label mr-10'>Gender:</Typography>
                                <Box className=" ">
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-customized-radios"
                                        name="gender"
                                        onChange={handleChange}
                                        value={newUser.gender}
                                    >
                                        <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                        <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                    </RadioGroup>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5} lg={5}>
                                <FormControl variant="standard" fullWidth>
                                    <Typography component='div' className='label'>Address:</Typography>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Address"
                                        className='textfield textarea'
                                        name='address'
                                        value={newUser.address}
                                        onChange={handleChange}
                                        minRows={2}
                                    />
                                </FormControl>
                            </Grid>
                        
                        </Box>
                    </Grid>

                </Grid>
                <Box className="text-right mb-20">
                    {addDisable == false ? <Button variant="contained" onClick={addUserDetails} sx={{ mt: 1, mr: 1, width: 100, marginLeft: 'auto' }}
                        className='theme-btn'>Submit</Button> : <Button variant="contained" sx={{ mt: 1, mr: 1 }} className='theme-btn'>Please wait</Button>}
                </Box>
            </Box>
        </Box>
    );
}