import React, { useEffect, useState } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import { FormControl, Select, MenuItem, Box, Typography } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const JobChart = () => {
    const [LinechartData, setLineChartData] = useState([]);
    const [fullData, setFullData] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(3); // Default to Last 12 months
    const { id } = useParams();

    useEffect(() => {
        fetchdata()


    }, [id, selectedPeriod]);

    // Handle selection change
    const handleSelectChange = (event) => {
        setSelectedPeriod(event.target.value);
        fetchdata(event.target.value)
        // setLineChartData(fullData.slice(0, event.target.value));
    };

    const fetchdata = (selectedPeriod) => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}Admin/TeacherCourseCharts1/${id}?months=${selectedPeriod}`)
            .then((res) => {





                setLineChartData(res.data);
            })
            .catch(() => {
                console.log('Error fetching data');
            });
    }

    return (
        <Box>
            <Box className="flex-center flex-between flex-wrap mb-20">
                <Typography
                    variant="h6"
                    component="h6"
                    className="fz-18 fw-600 "
                >
                    Jobs
                </Typography>
                <FormControl sx={{ width: "200px" }}>
                    <Select
                        value={selectedPeriod}
                        onChange={handleSelectChange}
                        className="select"

                    >
                        <MenuItem value={3} sx={{ fontSize: "14px", color: "black" }}>
                            Last 3 Months
                        </MenuItem>
                        <MenuItem value={6} sx={{ fontSize: "14px", color: "black" }}>
                            Last 6 Months
                        </MenuItem>
                        <MenuItem value={9} sx={{ fontSize: "14px", color: "black" }}>
                            Last 9 Months
                        </MenuItem>
                        <MenuItem value={12} sx={{ fontSize: "14px", color: "black" }}>
                            Last 12 Months
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box className="position_rel flex-center justify-content-center mt-20 p-20 border borderR_4">

                <LineChart width={600} height={350} data={LinechartData}>
                    {/* XAxis with solid line */}
                    <XAxis
                        dataKey="category"
                        tickLine={false}
                        margin={{ left: 0, right: 0, top: 10, bottom: 20 }}
                        padding={{ left: 20, right: 20 }}
                    />

                    <YAxis
                        tickLine={false}
                        padding={{ top: 20, bottom: 20 }}
                        tickFormatter={(value) => `${value}`}
                    />

                    <CartesianGrid strokeDasharray="3" vertical={false} />

                    <Tooltip />

                    {/* Line chart with custom stroke */}
                    <Line
                        type="monotone"
                        dataKey="total"
                        stroke="#00C853"
                        strokeWidth={4}
                        dot={{ r: 6 }}
                    />

                </LineChart>
                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        position: "absolute",
                        left: "20px",
                        top: "30%",
                        transform: "translateY(100%)",
                        writingMode: "vertical-rl",
                        color: "#000",
                        zIndex: 8,
                        letterSpacing: "0.5px",
                    }}
                    className="fz-14 fw-600"
                >
                    Jobs
                </Typography>
                <Typography
                    className="fz-14 fw-600"
                    variant="h6"
                    component="h6"
                    sx={{
                        position: "absolute",
                        left: "50%",
                        bottom: "10px",
                        color: "#000",
                        zIndex: 8,
                        letterSpacing: "0.5px",
                    }}
                >
                    Timeline
                </Typography>
            </Box>

        </Box>
    );
};

export default JobChart;
