

import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography, TextField } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './DataTable'
import { usePlacesWidget } from "react-google-autocomplete";
// import { Button, Card, Box,  , Typography, TextareaAutosize, CardActions, Menu, RadioGroup, Radio, IconButton, Tooltip } from '@mui/material';

export default function AutoComplete_Edit({cityName,setError, setCityName, lat, setLat,lng, setLng, formattedadrress, setFormattedAddress}) {
    
    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            console.log(place,"place");
            // setCityName(place.location_coord)
            setCityName(place.formatted_address)
            setFormattedAddress(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });

    const handleChange = (event) => {
        const value = event.target.value;
        setCityName(value);
        setError('')
    };
    // React.useEffect(() => {
    //     if (cityName) {
    //         ref.current.value = cityName;
    //     }
    // }, [cityName])

    return (
        <TextField
            fullWidth
            label="Address"
            variant="outlined"
            value={cityName}
            onChange={handleChange}
            inputRef={ref}
            defaultValue = {formattedadrress}

        />
    );
}