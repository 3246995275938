import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from '@mui/material';
import { getToken } from 'firebase/messaging';
import axios from 'axios';
// import { messaging } from "../../firebase";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { messaging, setupNotifications } from "../../firebase";
import { useSnackbar } from "notistack";

export default function RedirectLogin() {
   
    const [loading, setLoading] = useState(true);
    // const [email, setEmail] = useState(""); 
    const [fcmkey, setFcmKey] = useState('');
    const navigate = useNavigate(); 
    const params = useParams() ;
    const {email , token ,redirect}  = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const [permission, setPermission] = useState(Notification.permission);

    useEffect(() => {
      if (permission === 'default') {
        requestNotificationPermission();
      } else if (permission === 'denied') {
        enqueueSnackbar(
          'You have denied notification permission. Please enable it in your browser settings to receive notifications.'
        ,{variant:'error'})
      }
    }, [permission]);
  
    const requestNotificationPermission = async () => {
      const result = await Notification.requestPermission();
      setPermission(result);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentToken = await getToken(messaging, { vapidKey: "BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo" }).then((res) => {
                    console.log(res,"res console")
                })
                .catch((err) => {
                    console.log(err,"error console")
                })
                if (currentToken) {
                    setFcmKey(currentToken);
                } else {
                    console.log('No FCM token available.');
                }
                const headerlocal = {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    Authorization: token
                }
                
                    const body = {
                        email: email,
                        FCM_token: fcmkey,
                    };
                    console.log(redirect)
                    console.log(body,"bodyyyy");
                    const response = await axios.post(process.env.REACT_APP_BASE_URL + `Admin/RedirectLogin`, body,{ headers: headerlocal });
                    localStorage.setItem("userData", JSON.stringify(response.data.teacher));
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("redirect", redirect);
                    console.log(response.data, 'Teacher login details');
                    // enqueueSnackbar("Teacher Login Successfully", { variant: 'success' });
                    navigate(`/dashboard/${response.data.teacher._id}`);
                    
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
  
}, [email, fcmkey,token]);


const req = async () => {
    const permission = await Notification.requestPermission();
    console.log(permission,'permission')
}

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            {loading ? (
                <CircularProgress /> 
            ) : (
                <Typography variant="h6">Please wait...</Typography> 
            )}
        </Box>
    );
}
