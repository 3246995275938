import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Sidebar from '../../Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import MiniDrawer from '../../../../../components/drawer'
import DataTable from './Edit'

export default function Image() {
    const params = useParams()
    let navigate = useNavigate()
    const addImage = () => {
        navigate('/teachers/details/image/add');
    }
    return (
        <Box className='companies withSubmenu'>
           <MiniDrawer menuOrder={2} submenuOrder={5} />
            <Sidebar subtype={5} />
            {/* <Box component="section" className="contentWraper"> */}
                {/* <Box className='headingBox'> */}
                    {/* <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Image</Typography> */}
                    {/* <Button variant="contained" className='addbtn' onClick={() => addImage()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Image</Button> */}
                {/* </Box> */}
                <Box className='whiteContainer'>
                <DataTable />
                </Box>
            {/* </Box> */}
        </Box>
    )
}


