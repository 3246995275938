import React from 'react'
import MiniDrawer from '../../../../../components/drawer'
import { Box, Button, IconButton, FormControlLabel, Tooltip, Card, Checkbox, Link, List, ListItemText, OutlinedInput, ListItemButton, ListItemAvatar, Autocomplete, Input, Breadcrumbs, TableContainer, Select, TablePagination, MenuItem, Dialog, Typography, Grid, FormControl, TextField, DialogContent, DialogActions, DialogTitle, styled, CardContent, AccordionDetails, AccordionSummary, Accordion, Divider, RadioGroup, Radio, InputAdornment, Tabs, Tab } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import AudioPlayer from 'react-h5-audio-player';
import Sidebar from './../../Sidebar'


export default function EditPodcast() {
    let location = useLocation()
    const tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const tokendataOne = {
        'headers': {
            'Content-Type': 'multipart/form-data',
            Authorization: localStorage.getItem("token")
        }
    }
    function BpRadio(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 40,
        height: 20,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 0,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(20px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 18.5,
            height: 18.5,
        },
        '& .MuiSwitch-track': {
            borderRadius: 18.5 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));
    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#f3714d',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#f3714d',
        },
    });
    let navigate = useNavigate()
    const params = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    var editId = location.state.data._id
    const [user, setUser] = React.useState([])
    const [truebutton, setTruebutton] = React.useState(false)
    const [community, setCommunity] = React.useState([])
    const [podcast, setPodcast] = React.useState({ title: location.state.data.name, description: location.state.data.description })
    const [error, setError] = React.useState({ title: "", audio: "" })
    const [audio, setAudio] = React.useState(location.state.data.audio)
    const [audioPreview, setaudioPreview] = React.useState(location.state.data.audio)
    const [imageError, setImageError] = React.useState('')
    const [isPublic, setPublic] = React.useState(location.state.data.public)
    const [isPrimary, setPrimary] = React.useState(location.state.data.primary)
    const [selectGallert, setSelectGallery] = React.useState(location.state.data.gallery)
    const [selectedVideo, setSelectedVideo] = React.useState([...[], location.state.data.Video]);
    const [isToggled, setToggled] = React.useState(false)
    const [audioSize, setAudioSize] = React.useState(location.state.data.audio_size)
    const [statesingle, setStatesingle] = React.useState([...[], location.state.data.thumbnail])
    const [statesingle2, setStatesingle2] = React.useState([...[], location.state.data.thumbnail])
    const [videoPreviewUrl, setVideoPreviewUrl] = React.useState([...[], location.state.data.Video]);
    const [videoSize, setVideoSize] = React.useState(location.state.data.audio_size)

    let name, value;
    const handlePodcast = (e) => {
        name = e.target.name;
        value = e.target.value
        setPodcast({ ...podcast, [name]: value })
    }

    const handleAudio = (e, index) => {
        console.log(e.target.files);
        const file = e.target.files[0];
        if (file) {
            setAudio(file);
            setaudioPreview(URL.createObjectURL(file))
        }

        if (!file) {
            // Handle the case where no file is selected
            setError({ ...error, audio: "Please select an audio file." });
            return;
        }
        const fileSizeInBytes = file.size;
        const fileSizeInKB = fileSizeInBytes / 1024;
        const fileSizeInMB = fileSizeInKB / 1024;
        const roundedFileSizeInMB = fileSizeInMB.toFixed(1);
        setAudioSize(String(roundedFileSizeInMB));
        console.log(roundedFileSizeInMB, "roundedFileSizeInMBroundedFileSizeInMB");
        console.log("File size:", fileSizeInBytes, "bytes", fileSizeInKB, "KB", fileSizeInMB, "MB");
    }

    React.useEffect(() => {
        console.log("Audio size:", audioSize, "MB");
    }, [audioSize]);

    // const handleAudio = (e, index) => {
    //     const file = e.target.files[0];
    //     setAudio(file);
    //     setAudio(file)
    //     if (!file) {
    //         // Handle the case where no file is selected
    //         setError({ ...error, audio: "Please select an audio file." });
    //         return;
    //     }
    //     const fileSizeInBytes = file.size;
    //     const fileSizeInKB = fileSizeInBytes / 1024;
    //     const fileSizeInMB = fileSizeInKB / 1024;
    //     const roundedFileSizeInMB = fileSizeInMB.toFixed(1);
    //     setAudioSize(String(roundedFileSizeInMB));
    //     console.log(roundedFileSizeInMB,"roundedFileSizeInMBroundedFileSizeInMB");
    //     console.log("File size:", fileSizeInBytes, "bytes", fileSizeInKB, "KB", fileSizeInMB, "MB");
    // }

    const handleTogglePublic = () => {
        setPublic(!isPublic)
    }

    const handleToggle = () => {
        setToggled(!isToggled)
    }

    const handleVideoChange = (e) => {
        setError({ video: '' });

        const fileObj = e.target.files;
        const fileArray = [];
        const filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            const videofileSize = fileObj[i].size;
            const VideofileSizeInMB = videofileSize / (1024 * 1024);
            const roundedVideoFileSizeInMB = VideofileSizeInMB.toFixed(1);
            console.log(roundedVideoFileSizeInMB, "video sizeee");
            setVideoSize(String(roundedVideoFileSizeInMB));
            if (videofileSize > 25 * 1024 * 1024) {
                setError({ video: 'Video should be less than 25 MB' });
                return;
            }

            fileArray.push(URL.createObjectURL(fileObj[i]));
            filepath.push(fileObj[i]);

            console.log(`File ${i + 1}: ${fileObj[i].name}, Size: ${VideofileSizeInMB.toFixed(2)} MB`);
        }

        setVideoPreviewUrl(fileArray);
        setSelectedVideo(filepath);
    };


    const clearVideo = (e, index) => {
        setSelectedVideo([]);
        setVideoPreviewUrl([]);
    };


    const SingleImage = (e, index) => {
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        var data = statesingle.filter(x => x != null)
        var data1 = statesingle2.filter(x => x != null)
        setStatesingle(data)
        setStatesingle2(data1)
        setStatesingle(prevValue => prevValue.concat(fileArray))
        setStatesingle2(prevValue => prevValue.concat(filepath))
    }

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const handleEditGallery = (e) => {
        setSelectGallery(selectGallert === 'Podcast' ? 'Video' : 'Podcast')
    }

    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }

    React.useEffect(() => {
        getCommunity()
    }, [])

    const getCommunity = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunityList`, tokendataOne);
            setCommunity(response.data.community);
            setCommunityDataUpdated(response.data.community)
        } catch (error) {
            console.log(error.response, "error");
        }
    }


    React.useEffect(() => {
        getUser()
    }, [])


    const getUser = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getUserdata`, tokendataOne);
            setUser(response.data.getuserdata);
            //console.log(response,"fvdfjvdfjvdfjv");
            setUserDataUpdated(response.data.getuserdata)
        } catch (error) {
            console.log(error.response, "error");
        }
    }


    const [userDataUpdated, setUserDataUpdated] = React.useState([])
    const [userUpdated, setUserUpdated] = React.useState([])

    const [selectedUsers, setSelectedUsers] = React.useState(() => {
        const arrVal = []
        const userArr = location.state.data.user_id
        //console.log(location.state,"location.statelocation.stateuser");
        if (userArr.length > 0) {
            userArr.map((item) => {
                arrVal.push(item.username)
            })
        }
        return arrVal
    });

    React.useEffect(() => {
        getUserSelected();
    }, [selectedUsers, userDataUpdated])

    function getUserSelected() {
        var array = []
        const selectedUser = userDataUpdated.filter(content => selectedUsers.includes(content.username));
        selectedUser.map((item, i) => {
            var val = { username: item.username, user_id: item._id, email: item.email, image: item.image ? item.image : '' }
            Object.assign(item, val)
            array.push(val)
        })
        setUserUpdated(array)
    }

    const handlechangeUserSelected = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setSelectedUsers(
            typeof value === 'string' ? value.split(',') : value,
        );
        getUserSelected()
    }

    const [communityDataUpdated, setCommunityDataUpdated] = React.useState([])
    const [communityUpdated, setCommunityUpdated] = React.useState([])
    const [selectedCommunity, setSelectedCommunity] = React.useState(() => {
        const arrVal = []
        const communityArr = location.state.data.community_id
        console.log(communityArr, "communityArrcommunityArr");
        if (communityArr.length > 0) {
            communityArr.map((item) => {
                arrVal.push(item.coummunity_name)
            })
        }
        return arrVal
    });

    React.useEffect(() => {
        getCommunitySelected();
    }, [selectedCommunity, communityDataUpdated])

    function getCommunitySelected() {
        var array = []
        const selectedCommunitys = communityDataUpdated.filter(content => selectedCommunity.includes(content.coummunity_name));
        selectedCommunitys.map((item, i) => {
            var val = { coummunity_name: item.coummunity_name, coummunity_title: item.coummunity_title, category_id: item.category_id, _id: item._id, image: item.image ? item.image : '' }
            Object.assign(item, val)
            array.push(val)
            // console.log(selectedCommunitys,"updatedCommunitiesupdatedCommunities");
        })
        setCommunityUpdated(array)
    }

    const handlechangeCommunitySelected = (event, item) => {
        const {
            target: { value },
        } = event;
        setSelectedCommunity(
            typeof value === 'string' ? value.split(',') : value,
        );
        getCommunitySelected()
    }


    const Validator = () => {
        if (!podcast.title && !podcast.description && statesingle2?.length == 0 && audio?.length === 0) {
            setError({ title: "This field is required.", description: "This field is required.", audio: "This field is required." })
            setImageError("This field is required.")
            return false
        }
        if (!podcast.title) {
            setError({ title: "This field is required." })
            return false
        }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        // if (selectGallert === 'Podcast') {
        //     if (audio.length === 0) {
        //         setError({audio : "Audio upload is required."})
        //         return false
        //     }
        // } else if (selectGallert === 'Video') {
        //     if (selectedVideo.length === 0) {
        //         setError({ video: "Video upload is required." })
        //         return false
        //     }
        // }
        return true
    }

    const handleUpdate = async () => {
        var Valid = Validator()
        if (Valid) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("createdby[]", params && params.id)
            formData.append('name', podcast.title);
            // formData.append('addPodcastFrom','school' )
            formData.append('addPodcastFrom', 'YogaSchool')
            formData.append('description', podcast.description);
            // formData.append('audio', audio)
            formData.append("public", isPublic)
            formData.append("primary", isPrimary)
            // formData.append('audio_size', audioSize);
            // audio.forEach((item) => formData.append("audio", item));
            // { selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '') }
            statesingle2.forEach((item) => formData.append("thumbnail", item));
            formData.append('user_id', JSON.stringify(userUpdated.length > 0 ? userUpdated : []))
            formData.append('community_id', JSON.stringify(communityUpdated.length > 0 ? communityUpdated : []))
            {
                selectGallert === 'Podcast' ? <>
                    {formData.append('audio', audio)}
                    {formData.append('audio_size', audioSize)}
                    {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
                </>
                    :
                    <>
                        {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}
                        {formData.append('audio_size', videoSize)}
                        {formData.append('audio', "")}
                        {/* {audioSize ? audioSize.forEach((item) => { formData.append("audio_size", '') }) : formData.append("Video", '')} */}
                    </>
            }
            console.log([...formData], 'ddddddddd');
            await axios.put(process.env.REACT_APP_BASE_URL + `podcast/updatePodcast/${editId}`, formData, tokendataOne)
                .then((res) => {
                    setTruebutton(false)
                    setSelectedVideo([]);
                    setVideoPreviewUrl([]);
                    navigate(`/yogaschool/details/broadcast/${params.id}`)
                    enqueueSnackbar("Podcast updated successfully", { variant: 'success' })
                })
                .catch((error) => {
                    setTruebutton(false)
                    enqueueSnackbar(error.response.data.error ? error.response.data.error : "Something went wrong.", { variant: 'error' })
                    console.log(error.response, "error");
                })
        }
    }

    return (
        <Box className='withSubmenu'>
            <MiniDrawer menuOrder={2} submenuOrder={4} />
            <Sidebar subtype={1} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className='whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Edit Podcast</Typography><br></br>
                                <Grid container rowSpacing={2} columnSpacing={2}>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Title'
                                                name="title"
                                                value={podcast.title}
                                                onChange={(e) => { handlePodcast(e); setError({ title: "" }) }}
                                            />
                                            {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className='mb-10 d-flex align-items-start justify-content-between' >
                                            <Box className='flex-center flex-between' >
                                                <Typography component='div' className='label mb-0'>Primary </Typography>
                                                <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<Switch size='small' onClick={handleTogglePrimary} checked={isPrimary} />} />
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}  >
                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                            <Typography component='div' className='label mb-0'>Primary Image <Typography component='span' className='star'> * </Typography></Typography>

                                            {statesingle.length < 1 &&
                                                <Button className="upload_btn" variant="contained" component="label">
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                    <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                                </Button>
                                            }
                                        </Box>
                                    </Grid>
                                    {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                                    {statesingle.length > 0 &&
                                        <Grid item xs={12} sm={12} md={12}  >
                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                {statesingle.length > 0 ? statesingle.map((x, i) => (
                                                    <Grid item xs={6} sm={4} md={4} lg={4} >
                                                        <Box className='uploaded_img' >
                                                            <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                            <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' width='100%' height='100%' />
                                                        </Box>
                                                    </Grid>
                                                )) : ""}
                                            </Grid>

                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className=' fz-12 mt-10 mb-20' component='p' variant='p'><strong>Note:</strong> Image size should be more than 220px * 250px for better quality picture.</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description <Typography component='span' >  </Typography></Typography>
                                            <textarea
                                                className='textarea'
                                                placeholder='Description'
                                                name="description"
                                                value={podcast.description}
                                                onChange={(e) => handlePodcast(e)}
                                                rows={5}
                                                cols={40} />
                                            {/* {error.description && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.description}</p>} */}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Typography component='div' className='label'>Audio/Video <Typography component='span' className='star'> * </Typography> </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} lg={8}>
                                        <Box className=" ">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="payment_type"
                                                onChange={handleEditGallery}
                                                value={selectGallert}
                                            >
                                                <FormControlLabel value="Podcast" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Audio</Typography>} />
                                                <FormControlLabel value="Video" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Video</Typography>} />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>

                                    {selectGallert === 'Podcast' ? <>

                                        <Grid item xs={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Box className='flex-center flex-between flex-wrap w100 mb-20' >
                                                    <Typography component='div' className='label mb-0'>Upload Audio</Typography>
                                                    <Button className="upload_btn" variant="contained" component="label">
                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                        <input name="audio" accept="audio/*" onChange={handleAudio} hidden type="file" />
                                                    </Button>
                                                </Box>
                                            </FormControl>
                                            {error.audio && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.audio}</p>}
                                        </Grid>


                                        <Box className='audioPlayer_container mb-20'>
                                            <AudioPlayer
                                                autoPlay
                                                src={audioPreview && audioPreview.slice(0, 4) === "blob" ? audioPreview : process.env.REACT_APP_BASE_URL + `${audioPreview}`}
                                                onPlay={e => console.log("onPlay")}
                                                className='audio_player'
                                            />
                                        </Box>
                                    </> : <>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography component='label' className='label ' htmlFor="video-upload">Video</Typography>


                                            {/* <label className='form-labels' htmlFor="video-upload">Video</label> */}
                                            <Box className="mt-20 ">
                                                {videoPreviewUrl && videoPreviewUrl?.length === 0 &&
                                                    <Button className="multiple_upload_btn" variant="contained" component="label">
                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                        </Box>
                                                        <input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
                                                    </Button>
                                                }
                                                {videoPreviewUrl && videoPreviewUrl?.length > 0 &&
                                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                        {videoPreviewUrl.length > 0 ? videoPreviewUrl.map((x, i) => (
                                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                <Box className="uploaded_vdo" >
                                                                    <Button className='close_icon' onClick={(e) => clearVideo(e, i)}><Close /></Button>
                                                                    <video className='w100' controls>
                                                                        <source src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} height={155} width={195} />
                                                                    </video>
                                                                </Box>
                                                            </Grid>
                                                        )) : ""}
                                                    </Grid>
                                                }
                                            </Box>
                                            {error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}
                                        </Grid>
                                    </>}
                                </Grid>

                                <Box className="mb-20">
                                    <Box className='mb-10 d-flex align-items-start justify-content-between' >
                                        <Box className=' text-right' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography component='div' variant='body1' className='fz-14 label mb-0 mr-10'>Public:   </Typography>
                                            <FormControlLabel className='m-0' control={<Switch size='small' onClick={handleTogglePublic} checked={isPublic} />} />
                                        </Box>
                                    </Box>
                                </Box>

                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button onClick={handleUpdate} variant="contained" className='theme-btn mr-10'>Update</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button onClick={handleUpdate} variant="contained" className='theme-btn mr-10'>Update</Button>
                                    </Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}

