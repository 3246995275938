import { Box, Button, Divider, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Sheet } from "@mui/joy";
import Table from "@mui/joy/Table";
import { Info } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

export default function CommunityTable() {
  //UseState's:
  const [communitydashboard, setCommunitydashboard] = React.useState([]);
  const [totalcommunity, setTotalCommunity] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const params = useParams();

  //Hook's state:
  React.useEffect(() => {
    // Simulate 1-second loading time before fetching data
    setTimeout(() => {
      CommunityDash();
    }, 1000);
  }, []);

  const CommunityDash = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}Community/GetCommunitybasedonteacherid?createdby=${params?.id}`
      )
      .then((res) => {
        console.log(res.data)
        setCommunitydashboard(res.data.communities);
        setTotalCommunity(res.data.totalcount);
        setLoading(false); // Disable loader after data is fetched
      })
      .catch((error) => {
        console.log(error, "error in CommunityDash");
        setLoading(false); // Disable loader if there's an error
      });
  };

  // React.useEffect(()=>{
  //     CommunityDash()
  // },[])

  // const CommunityDash = () => {
  //     axios.get(process.env.REACT_APP_BASE_URL + `Community/GetCommunitybasedonteacherid?createdby=${params && params.id}&size=${0}&page=${10}&search=${""}&category_id=${"all"}`)
  //     .then((res)=>{
  //         setCommunitydashboard(res.data.getCommunity);
  //         setTotalCommunity(res.data.totalcount)
  //     })
  //     .catch((error)=>{
  //         console.log(error,"error in CommunityDash");
  //     })
  // }

  return (
    <Box className="individual_table">
      <Box className="p-10 flex-between flex-center">
        <Box className="flex-center flex-wrap">
          <Typography
            component="h6"
            variant="h6"
            className="semibold letter_sp fz-18 mr-10"
          >
            Communities
          </Typography>
          {/* <Typography component='h6' variant='h6' className='fw-bold letter_sp text_primary fz-16 mr-10'>Subscribed Communities</Typography> */}
          <Tooltip title="watch video" className="flex-center text-right">
            <Info className="fz-18" />
          </Tooltip>
        </Box>
        <Typography component="div" variant="body" className="countBox fz-24">
          {totalcommunity}
        </Typography>
      </Box>
      <Divider />
      <Box className="p-10">
      <Box className="d-flex justify-content-right">
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Button className='theme-btn btn-gradient btn-xs'>More</Button>
                    </Tooltip>
                </Box>
        <Box className="table_content">
          <Sheet sx={{ height: 200, overflow: "auto" }}>
            <Table
              aria-label="table with sticky header"
              stickyHeader
              stickyFooter
              stripe="odd"
              hoverRow
            >
              <thead>
                <tr>
                  <th className="minw100">Comm Name</th>
                  <th className="minw100 text-right">No. of Members</th>
                  <th>Recent Activity</th>
                </tr>
              </thead>
              {/* <tbody>
                                {communitydashboard && communitydashboard?.length > 0 ?
                                (communitydashboard && communitydashboard?.map((item,i) => (
                                    <tr key={i}>
                                        <td className='semibold fz-12' >{item.coummunity_name}</td>
                                        <td className='semibold fz-12 text-right' >{item.user_id && item.user_id?.length}</td>
                                        <td ><div className='ellipse2 fz-12 text_blue letter_sp'>{item.short_description ? item.short_description : '-'}</div></td>
                                    </tr>
                                ))) : (
                                    <p>No data found</p>
                                )}
                            </tbody> */}

              <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan="3"
                     
                      style={{ background:"#fbfcfe", textAlign: "center", padding: "20px" }}
                  >
                    {/* Circular progress loader */}
                    <CircularProgress sx={{ color: "#f3714d" }} />
                  </td>
                </tr>
              ) : communitydashboard && communitydashboard?.length > 0 ? (
                communitydashboard &&
                communitydashboard?.map((item, i) => (
                  <tr key={i}>
                    <td className="semibold fz-12">{item.coummunity_name}</td>
                    <td className="semibold fz-12 text-right">
                      {item.user_id && item.user_id?.length}
                    </td>
                    <td>
                      <div className="ellipse2 fz-12 text_blue letter_sp">
                        {item.short_description ? item.short_description : "-"}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="3"
                    style={{
                      background:"#fbfcfe",
                      textAlign: "center",
                      fontWeight: "bold",
                      padding: "20px",
                    }}
                  >
                    <Typography component="p" variant="body1">
                      No data found
                    </Typography>
                  </td>
                </tr>
              )}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      </Box>
    </Box>
  );
}
