import React, { useState } from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Tooltip, Grid, Avatar, Input, Checkbox, TextField, FormControlLabel, Typography, IconButton, TextareaAutosize } from '@mui/material'
// import { Box, Button, FormControl, Tooltip, Grid, InputAdornment, Avatar, Input, Checkbox, TextField, FormControlLabel, RadioGroup, Radio, Typography, IconButton, TextareaAutosize } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { SearchOutlined, AddAPhoto } from '@mui/icons-material';
import { AddAPhoto } from '@mui/icons-material';
import axios from 'axios';
// import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'
import Avatarimage from "../../../assets/images/Avatar.png"
// import { locale } from 'moment';
import Switch from '@mui/material/Switch';
import DialogTitle from '@mui/material/DialogTitle';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
// import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import PropTypes from 'prop-types';
// import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { usePlacesWidget } from "react-google-autocomplete";
import AutoComplete from "./Autocomplete";
// import Sample from "./sample.json";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';


// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//     '& .MuiDialogContent-root': {
//         padding: theme.spacing(2),
//     },
//     '& .MuiDialogActions-root': {
//         padding: '15px',
//     },
// }));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

// function BpRadio(props) {
//     return (
//         <Radio
//             sx={{
//                 '&:hover': {
//                     bgcolor: 'transparent',
//                 },
//             }}
//             disableRipple
//             color="default"
//             checkedIcon={<BpCheckedIcon />}
//             icon={<BpIcon />}
//             {...props}
//         />
//     );
// }

// const IOSSwitch = styled((props) => (
//     <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//     width: 40,
//     height: 20,
//     padding: 0,
//     '& .MuiSwitch-switchBase': {
//         padding: 0,
//         margin: 0,
//         transitionDuration: '300ms',
//         '&.Mui-checked': {
//             transform: 'translateX(20px)',
//             color: '#fff',
//             '& + .MuiSwitch-track': {
//                 backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//                 opacity: 1,
//                 border: 0,
//             },
//             '&.Mui-disabled + .MuiSwitch-track': {
//                 opacity: 0.5,
//             },
//         },
//         '&.Mui-focusVisible .MuiSwitch-thumb': {
//             color: '#33cf4d',
//             border: '6px solid #fff',
//         },
//         '&.Mui-disabled .MuiSwitch-thumb': {
//             color:
//                 theme.palette.mode === 'light'
//                     ? theme.palette.grey[100]
//                     : theme.palette.grey[600],
//         },
//         '&.Mui-disabled + .MuiSwitch-track': {
//             opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxSizing: 'border-box',
//         width: 18.5,
//         height: 18.5,
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: 18.5 / 2,
//         backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
//         opacity: 1,
//         transition: theme.transitions.create(['background-color'], {
//             duration: 500,
//         }),
//     },
// }));


BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

// const BpIcon = styled('span')(({ theme }) => ({
//     borderRadius: '50%',
//     width: 16,
//     height: 16,
//     boxShadow:
//         theme.palette.mode === 'dark'
//             ? '0 0 0 1px rgb(16 22 26 / 40%)'
//             : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
//     backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
//     backgroundImage:
//         theme.palette.mode === 'dark'
//             ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
//             : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
//     '.Mui-focusVisible &': {
//         outline: '2px auto rgba(19,124,189,.6)',
//         outlineOffset: 2,
//     },
//     'input:hover ~ &': {
//         backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
//     },
//     'input:disabled ~ &': {
//         boxShadow: 'none',
//         background:
//             theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
//     },
// }));

// const BpCheckedIcon = styled(BpIcon)({
//     backgroundColor: '#f3714d',
//     backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
//     '&:before': {
//         display: 'block',
//         width: 16,
//         height: 16,
//         backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
//         content: '""',
//     },
//     'input:hover ~ &': {
//         backgroundColor: '#f3714d',
//     },
// });


export default function AddStudios() {
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let location = useLocation()
    var data = location.state ? location.state.result : null;
    const [user, setUser] = React.useState({plans: '', firstname: '', username: '', email: '', gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: [''], description: "", plan: '', userid: '' });
    const [error, setError] = React.useState({ plans:"" ,firstname: "", uniquename: "", email: "", image: "", subscription: "",phone:'',location:'' })
    const [isToggled, setToggled] = React.useState(false)
    const [plans, setPlans] = React.useState([])
    const [truebutton, setTruebutton] = React.useState(false)
    const [cityName, setCityName] = React.useState('')
    const [selectedPlan, setSelectedPlan] = useState('')
    // console.log(selectedPlan.product, "selectedolan");
    // const [isChecked, setChecked] = React.useState(false)
    const [isPrimary, setPrimary] = React.useState(false)
    // const [customerid, setCustomerId] = useState('')
    // console.log(customerid, "customeridcustomeridcustomerid");
    const [lat, setLat] = React.useState('')
    const [lng, setLng] = React.useState('')

    // const [plan, setPlan] = useState('')
    // const [userid, setUserId] = useState('')
    // console.log(userid, "userrrrrrrr");
    // console.log(plan, "plamnnnnn");



    // let username, uservalue;
    // const handleChange = (e) => {
    //     username = e.target.name;
    //     uservalue = e.target.value;
    //     setUser({ ...user, [username]: uservalue })
    //     setError({ ...error, [username]: '' })
    //     setError('')
    // };



    let username, uservalue;
    const handleChange = (e, index) => {
        // const { value } = e.target;
        username = e.target.name;
        uservalue = e.target.value;
        if (username === 'username') {
            uservalue = uservalue.trim();
            
            
            if (/^[^a-zA-Z0-9]+$/.test(uservalue)) {
                setError({ uniquename: "Name should not contain  spaces and special characters" });
                return false
            }
            if (/[^a-zA-Z]/.test(uservalue) ) {
                setError({ uniquename: "Name should not contain any special characters, spaces, and Number " });
                return false
                  
              } 
                setError(prev => ({ ...prev, uniquename: null })); // Clear error if valid

        }

        if(username === "firstname"){
            setError({firstname:""})
            if(/^[^a-zA-Z0-9]+$/.test(uservalue)){
                setError({ firstname: "Name should not contain special characters" });
                return false;
            }
            if(/.*[0-9].*/.test(uservalue)){
                setError({ firstname: "Name should not contain Number" });
                return false;
                
            }
        }
        const updatedSpecializations = [...user.specialization];
        updatedSpecializations[index] = uservalue;

        setUser({ ...user, [username]: uservalue, specialization: updatedSpecializations });
        setError('')
    };

    const handleAddSpecialization = () => {
        setUser({ ...user, specialization: [...user.specialization, ''] });
    };

    const handleRemoveSpecialization = (index) => {
        const updatedSpecializations = [...user.specialization];
        updatedSpecializations.splice(index, 1);
        setUser({ ...user, specialization: updatedSpecializations });
    };


    const handleaddphoto = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
            setError({ image: 'Image should be less than 1 MB' });
            setUser({
                ...user,
                Imageprew: '',
                Imageraw: null,
            });
        } else {
            setUser({
                ...user,
                Imageprew: URL.createObjectURL(file),
                Imageraw: file,
            });
            setError({ image: '' });
        }
    };
    <label htmlFor="icon-button-file">
        <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleaddphoto} />
        <IconButton color="#f3714d" aria-label="upload picture" component="span">
            {user.Imageprew ? <Avatar src={user.Imageprew} /> : <Avatar src={Avatarimage} />}
            <AddAPhoto />
        </IconButton>
    </label>


    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }

    const handleToggle = () => {
        setToggled(!isToggled)
    }

    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            setCityName(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setError(prevError => ({ ...prevError, location: '' }))
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });

    const handleSetCityName = (newCityName) => {
        
        setCityName(newCityName);

        // Clear location error when cityName is set
        if (newCityName.trim() !== '') {

            setError(prevErrors => {
                const newErrors = { ...prevErrors };
                delete newErrors.location;
                return newErrors;
            });
        }
    };

 
        
        

    // let urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    const validation = () => {
        let isValid = true;
        const errors = {};

        if (!selectedPlan) {
            errors.plans = "This field is required";
            isValid = false;
        }
        if (!user.firstname) {
            errors.firstname = "This field is required";
            isValid = false;
        }
        if (!user.username.trim() || /[^a-zA-Z0-9]/.test(user.username)) {
            errors.uniquename = "This field is required";
            isValid = false;
        }
        if (!user.email) {
            errors.email = "This field is required";
            isValid = false;
        } else {
            const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
            if (!emailPattern.test(user.email)) {
                errors.email = "Please enter a valid Email";
                isValid = false;
            }
        }
        if (!user.phone) {
            errors.phone = "This field is required";
            isValid = false;
        }
        if (!cityName) {
            errors.location = "This field is required";
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const subscriptionDetails = (q, cid, subscribeId) => {
        let body = {
            planId: selectedPlan.stripePlanId,
            userId: q,
            customerId: cid,
            subscriptionId: subscribeId

        };
        axios.post(process.env.REACT_APP_BASE_URL + `subscription/create-session`, body, headerlocal)
            .then((res) => {
                console.log(res.data, "SESSION");
            })
            .catch((err) => {

                console.log(err, "session error");
            });
    };




    const addStudioDetails = (details) => {
        const validator = validation()
        if (validator == true) {
            
            const formData = new FormData();
            formData.append("image", user.Imageraw ? user.Imageraw : "");
            formData.append("usertype", 'yoga studio')
            formData.append("name", user.firstname);
            formData.append("username", user.username);
            formData.append("premium_studio", isPrimary)
            formData.append("email", user.email);
            const transformedSpecializations = user.specialization.flatMap(spec => spec.split(',').map(s => s.trim())).filter(Boolean);
            transformedSpecializations.forEach((spec, index) => {
                formData.append(`specialization[${index}]`, spec);
            });
            formData.append("address", cityName);
            formData.append("SetVerifyTeacher", isToggled);
            formData.append("description", user.description);
            formData.append("contact_number", user.phone);
            formData.append("gender", user.gender ? user.gender : '');
            formData.append("role_id", 5);
            formData.append("planId", selectedPlan._id)
            // for (const value of formData.values()) {
            //     console.log(value);
            // }
            setTruebutton(true)
            axios.post(process.env.REACT_APP_BASE_URL + `Admin/addYogaStudio`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
                    // const { planId, _id } = res.data.AddYogaStudio;
                    const { _id } = res.data.AddYogaStudio;
                    let cid = res.data.AddYogaStudio.stripeCustomerId;
                    subscriptionDetails(_id, cid, selectedPlan._id);
                    if (details === 'addWithNavigate') {
                        setUser({ firstname: '', email: '', SetVerifyTeacher: "", gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: "", description: "" });
                        setSelectedPlan('');
                        navigate(`/yogastudios/details/events/${res.data.AddYogaStudio._id}`);
                    } else if (details === 'add') {
                        setUser({ firstname: '', email: '', SetVerifyTeacher: "", gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: "", description: "" });
                        setSelectedPlan('');
                        setCityName('')
                        enqueueSnackbar("Yoga Studio added successfully", { variant: 'success' });
                        navigate('/yogastudios')
                    }
                })

              

                .catch((err) => {
                    setTruebutton(false)
                    console.log(err.response, "------");
                    enqueueSnackbar(err.response.data.error ? err.response.data.error : err.response.data ? err.response.data : "Error occurred", { variant: 'error' });
                });

        }
    }

  

    


    React.useEffect(() => {
        const getPlans = async () => {
            await axios.get(process.env.REACT_APP_BASE_URL + `Subscription/getSubscriptionPlansYogaStudio`, { headers: headerlocal }).then((res) => {
                setPlans(res.data.getSubscriptiondata)
            }).catch((error) => {
                console.log(error, "Error ");
            })
        }
        getPlans()
    }, [])


    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={2} submenuOrder={5} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle mb-20'>Add Yoga Studio</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>

                                    <Grid item sm={12} md={12} lg={12} className='mb-20'>
                                        <Box className='personal-info-tab'>
                                            <Typography component='div' className='label' >
                                                <center>Profile Photo</center>
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleaddphoto} />
                                                    <IconButton color="#f3714d" aria-label="upload picture" component="span">
                                                        {user.Imageprew ? <Avatar src={user.Imageprew}>
                                                        </Avatar> : <Avatar src={Avatarimage} />
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed *.jpeg, *.jpg, *.png<br />max size of 1 MB
                                            </Typography>
                                            {/* <span style={{ color: "red", fontSize: 12 }} className='text-center'>{error.image}</span> */}

                                        </Box>

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={8} lg={12}>
                                        <FormControl fullWidth >
                                            <Typography component='div' className='label'>Select Subscription <Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedPlan ? selectedPlan : 'sel'}
                                                placeholder={selectedPlan && selectedPlan}
                                                className="select"
                                                name='plans'
                                                onChange={(e) => { setSelectedPlan(e.target.value); setError('') }}
                                            >
                                                <MenuItem disabled value={'sel'}>Select Subscription Plan</MenuItem>
                                                {plans && plans?.length > 0 ? plans.map((data) => (

                                                    <MenuItem value={data}>{data.name}</MenuItem>
                                                )) : <MenuItem disabled>{"No Plan's found"}</MenuItem>}
                                            </Select>
                                        </FormControl>
                                        {error.plans ? <span style={{ color: "red", fontSize: 12 }}>{error.plans}</span> : null}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='Name'
                                                value={user.firstname}
                                                onChange={handleChange}

                                            />
                                        </FormControl>
                                        {error.firstname && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.firstname}</p>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Yoga Studio Name: <Typography component='span' className='star'>*</Typography>

                                                <Tooltip title="This name will be used to create a unique URL for your yoga studio">
                                                    <IconButton aria-label="information">
                                                        <AiOutlineInfoCircle size={20} />
                                                    </IconButton>
                                                </Tooltip></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='username'
                                                className='textfield'
                                                placeholder='Yoga Studio Name'
                                                value={user.username}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.uniquename}</span>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={user.email}
                                                placeholder='Email'
                                                onChange={handleChange}

                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className='mb-10 flex-center' >
                                            <Typography component='div' variant='body1' className='fz-14 label mb-0 '>Yoga Studio Approved?   </Typography>
                                            <FormControlLabel className='m-0' control={<Switch checked={isToggled} size='small' onClick={handleToggle} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isPrimary}
                                                    onChange={handleTogglePrimary}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Do you want to make this yoga studio as Premium? </Typography>}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>
                                                Specialization:
                                            </Typography>
                                            {user.specialization.map((value, index) => (
                                                <Grid container spacing={1} alignItems="center" key={index}>
                                                    <Grid item xs={index > 0 ? 10 : 12}> {/* Adjust width for cancel icon */}
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            className='textfield'
                                                            placeholder={`Specialization`}
                                                            value={value}
                                                            onChange={(e) => handleChange(e, index)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {index > 0 && (
                                                        <Grid item xs={2}>
                                                            <IconButton onClick={() => handleRemoveSpecialization(index)}>
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            ))}
                                            <IconButton onClick={handleAddSpecialization}>
                                                <AddIcon />
                                            </IconButton>
                                        </FormControl>
                                    </Grid>

                                    {/* <Grid item lg={12} className='pt-0'>
                                        <Typography component='div' className='label '>Gender:</Typography>
                                        <Box className=" ">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="gender"
                                                onChange={handleChange}
                                                value={user.gender}
                                            >
                                                <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                            </RadioGroup>
                                        </Box>
                                    </Grid> */}


                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                className='textfield'
                                                value={user.phone}
                                                placeholder='Phone'
                                                onChange={handleChange}
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 14 }}
                                            />
                                        </FormControl>
                                        {error.phone && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.phone}</p>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Location<Typography component='span' className='star'>*</Typography></Typography>
                                            <AutoComplete cityName={cityName} setCityName={handleSetCityName} onChange={handleSetCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} className="textfield" />
                                            {error.location && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.location}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Details"
                                                className='textfield textarea'
                                                name='description'
                                                value={user.description}
                                                onChange={handleChange}
                                                minRows={3}
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10' onClick={() => addStudioDetails('add')}>Save</Button>
                                        <Button variant="contained" className='addbtn mr-10' onClick={() => addStudioDetails('addWithNavigate')}>Save + Continue</Button>
                                    </Box>
                                    : truebutton === true &&

                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait</Button>
                                        {/* <Button variant="contained" className='addbtn mr-10'>Save + Continue</Button> */}
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}