import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MiniDrawer from '../../../../../components/drawer'
import Sidebar from './../../Sidebar'
import DataTable from './Event'

export default function Events() {
    let navigate = useNavigate()
	const params = useParams()

    const auth = JSON.parse(localStorage.getItem("userData"))
    const addEvents = () => {
        navigate(`/yogaschool/details/events/add/${params.id}`);
    }
    return (
        <Box className='companies withSubmenu'>
            <MiniDrawer menuOrder={2} submenuOrder={4} />
            <Sidebar subtype={0} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Events</Typography>
                   {auth.role_id === 2 && auth.planId.Event && auth.planId.Event.addEvent === true &&  <Button variant="contained" className='addbtn' onClick={() => addEvents()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Events</Button>}
                   {auth.role_id === 1 && <Button variant="contained" className='addbtn' onClick={() => addEvents()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Events</Button>}
                </Box>           
                    <DataTable />               
            </Box>
        </Box>
    )
}

