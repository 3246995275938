import React, { useState } from "react";

// import { styled } from '@mui/material/styles';

// import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import Sidebar from './../../Sidebar'

import MiniDrawer from '../../../components/drawer'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'

// import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
// import { Box, Button, FormControl, Grid, MenuItem, Checkbox, ListItemText, TextField, Select, OutlinedInput, Typography, Input } from '@mui/material';
import { Box, Button, FormControl, Grid, MenuItem, Checkbox, ListItemText, Select, OutlinedInput, Typography } from '@mui/material';
// import { Close } from '@mui/icons-material';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import Edit_Video from './EditVideo'

import AddVideo from "./AddVideo";


// const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
// 	[`&.${stepConnectorClasses.alternativeLabel}`]: {
// 		top: 22,
// 	},
// 	[`&.${stepConnectorClasses.active}`]: {
// 		[`& .${stepConnectorClasses.line}`]: {
// 			backgroundColor:
// 				'#E80A89',
// 		},
// 	},
// 	[`&.${stepConnectorClasses.completed}`]: {
// 		[`& .${stepConnectorClasses.line}`]: {
// 			backgroundColor:
// 				'#E80A89',
// 		},
// 	},
// 	[`& .${stepConnectorClasses.line}`]: {
// 		height: 3,
// 		border: 0,
// 		backgroundColor:
// 			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
// 		borderRadius: 1,
// 	},
// }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function Service_Steppyform() {
	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}



	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [videos, setVideos] = useState([])
	const [school, setSchool] = React.useState([])
	const [studio, setStudio] = React.useState([])
	const [community, setCommunity] = React.useState([])
	const [user, setUser] = React.useState([])
	const auth = JSON.parse(localStorage.getItem('userData'))
	const [imageAddError, setImageAddError] = useState('');
	const [videoAddError, setVideoAddError] = useState('');
	const [truebutton, setTruebutton] = React.useState(false)
	const [selecttedUsers, setSelecttedUsers] = React.useState([])
	const [userName, setUserName] = React.useState([])
	const [selecttedCommunity, setSelecttedCommunity] = React.useState([])
	const [coummunityName, setCommunityName] = React.useState([])
	const [selecttedStudio, setSelecttedStudio] = React.useState([])
	const [studioName, setStudioName] = React.useState([])
	const [selecttedSchool, setSelecttedSchool] = React.useState([])
	const [schoolName, setSchoolName] = React.useState([])




	

	// Fetching Teacher** list for drop-down


	React.useEffect(() => {
		getTeacher()
	}, [])

	const getTeacher = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachersListForVideos`, { headers: headerformdata });
			setUser(response.data.teachers);
			console.log(response.data, 'response.data.user');
		} catch (error) {
			console.log(error.response, "error");
		}
	}


	const handleSelectedTeacher = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setUserName(
			typeof value === 'string' ? value: value.split(',') ,
		);
		getArrayval()
	}

	React.useEffect(() => {
		getArrayval()
	}, [userName])

	function getArrayval() {
		var array = []
		const selectedUser = user.filter(content => userName.includes(content.username));
		//console.log(selectedUser, 'selectedUser');
		selectedUser.map((item, i) => {
			var val = { user_id: item._id }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedUsers(array)
	}


		// Fetching Community** list for drop-down


		React.useEffect(() => {
			getCommunity()
		}, [])
	
		const getCommunity = async () => {
			try {
				const response = await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunityList`, { headers: headerformdata });
				setCommunity(response.data.community);
				console.log(response.data, 'response.data.community');
			} catch (error) {
				console.log(error.response, "error");
			}
		}


		const handlechangeCommunity = (event, item) => {
			// setError({ ...error, ["userselect"]: '' })
			const {
				target: { value },
			} = event;
			setCommunityName(
				typeof value === 'string' ? value.split(',') : value,
			);
			getCommunityval()
		}
	
		
		React.useEffect(() => {
			getCommunityval()
		}, [coummunityName])
	
		function getCommunityval() {
			var array = []
			const selectedCommunity = community.filter(content => coummunityName.includes(content.coummunity_name));
			// console.log(selectedCourse,'selectedCourseselectedCourse');
			selectedCommunity.map((item, i) => {
				var val = item._id
				Object.assign(item, val)
				array.push(val)
			})
			setSelecttedCommunity(array)
		}


	// Fetching Studio** list for drop-down



	React.useEffect(() => {
		getStudio()
	}, [])

	const getStudio = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaStudioListvideo`, { headers: headerlocal });
			setStudio(response.data.getYogaStudioLists);
			console.log(response, "hhhhhh");
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	
	const handlechangeStudio = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setStudioName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getStudioval()
	}


	React.useEffect(() => {
		getStudioval()
	}, [studioName])

	function getStudioval() {
		var array = []
		const selecttedStudio = studio.filter(content => studioName.includes(content.username));
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedStudio.map((item, i) => {
			var val = { user_id: item._id, username: item.username, email: item.email }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedStudio(array)
	}

	// Fetching School** list for drop-down

	React.useEffect(() => {
		getSchool()
	}, [])

	const getSchool = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaSchoolListVideo`, { headers: headerlocal });
			setSchool(response.data.getYogaSchoolLists);
			console.log(response, "kkkk");
		} catch (error) {
			console.log(error.response, "error");
		}
	}
	console.log(school)


	const handlechangeSchool = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setSchoolName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getSchoolval()
	}



	React.useEffect(() => {
		getSchoolval()
	}, [schoolName])



	function getSchoolval() {
		var array = []
		const selecttedSchool = school?.filter(content => schoolName.includes(content.username));
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedSchool.map((item, i) => {
			var val = { user_id: item._id, username: item.username, email: item.email }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedSchool(array)
	}





	//SubmitVideoHandler

	const handleAddVideo = () => {
		const imgrawArray = videos.map(video => video.imgraw);
		const vdorawArray = videos.map(video => video.vdoraw);
		var valid = validatenew();

		if (valid === true) {
			setTruebutton(true)
			const formData = new FormData();
			// const idArray = selecttedUsers.map(id => id.user_id);
			// idArray.forEach(id => {
			//   formData.append('createdby[]', id);
			// });
			if (selecttedUsers.length > 0) {
				const idArray = selecttedUsers.map(id => id.user_id);
				idArray.forEach(id => {
					formData.append('createdby[]', id);
				});
				formData.append('createdby[]', auth._id);
			} else {
				formData.append('createdby[]', auth._id);
			}
			// if (auth.role_id == 1) {
			//   const idArray = selecttedUsers.map(id => id.user_id);
			//   idArray.forEach(id => {
			//     formData.append('createdby[]', id);
			//   });
			// } else {
			//   formData.append('createdby[]', auth._id);
			// }
			const idArrayCommunities = selecttedCommunity.map(id => id);
			idArrayCommunities.forEach(id => {
				formData.append('community_id[]', id); // Use 'community_id[]' to send as array
			});
			// formData.append('user_id', JSON.stringify(selecttedUsers.length > 0 ? selecttedUsers : []))
			// Assuming selecttedUsers is an array of user IDs
			// let usersData = selecttedUsers.map(userId => ({ user_id: userId }));
			
			formData.append('yogastudio', JSON.stringify(selecttedStudio.length > 0 ? selecttedStudio : []))
			formData.append('yogaschool', JSON.stringify(selecttedSchool.length > 0 ? selecttedSchool : []))

			imgrawArray ? imgrawArray.forEach((item) => { formData.append("thumbnail", item) }) : formData.append("thumbnail", '') 
			vdorawArray ? vdorawArray.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '') 


			axios.post(process.env.REACT_APP_BASE_URL + `video/addVideo`, formData, { headers: headerformdata })
				.then((res) => {
					setTruebutton(false)
					navigate('/video');
					enqueueSnackbar("Videos added successfully", { variant: 'success' })
				})
				.catch((err) => {
					setTruebutton(false)
					enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
					console.log(err, "error");
				})
				.finally(()=>{
					setTruebutton(false)
				})
		}
	}

	//Validation

	const validatenew = () => {
		// Validate if either thumbnail or video is missing
		if (videos.length === 0) {
			setVideoAddError("Thumbnail and Video fields are required.");
			return false;
		}
		if (videos.some(video => !video.imgraw)) {
			setVideoAddError("Thumbnail is required.");
			return false;
		}
		if (videos.some(video => !video.vdoraw)) {
			setVideoAddError("Video is required.");
			return false;
		}
		return true;
	};

	//collecting the data(Thumnails and Videos) from parent 

	const handleparent = (value) => {
		setImageAddError('');
		setVideoAddError('')
		const newData = value.map(obj => ({
			"imgraw": obj.imgraw,
			"vdoraw": obj.vdoraw
		}));
		setVideos(newData)
		
	}

	return (
    <Box className="">
      <MiniDrawer menuOrder={7} />
      {/* <Sidebar subtype={4} /> */}
      <Box component="section" className="contentWraper">
        <Box className="whiteContainer p-20">
          <Typography component="h6" className="formTitle mb-20">
            Add Video
          </Typography>
          <Grid container columnSpacing={2} rowSpacing={4}>
            <Grid item xs={12} sm={12} md={10} lg={6}>

				{/* Teacher__ */}
              {auth.role_id === 1 ? (
                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Select Teacher
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      className="select"
                      value={userName}
                      onChange={(e) => handleSelectedTeacher(e)}
                      input={<OutlinedInput />}
					  renderValue={(selected) => {
						const selectedUser = user.find(
						  (content) => content.username === selected
						);
						return selectedUser
						  ? selectedUser.username
						  : "Any Teacher";
					  }}
					  MenuProps={MenuProps}

                    //   renderValue={(selected) => {
                    //     const selectedUser = user.filter((content) =>
                    //       selected.includes(content.username)
                    //     );
                    //     if (selectedUser.length === 0) {
                    //       return "Any User";
                    //     } else if (selectedUser.length === 1) {
                    //       return selectedUser[0].username;
                    //     } else {
                    //       return `${selectedUser.length} Users selected`;
                    //     }
                    //   }}
                    //   MenuProps={MenuProps}
                    >
						{user && user.length > 0 ? (
                          user.map((name) => (
                            <MenuItem key={name._id} value={name.username} disabled={name.disabled}>
                              <Checkbox
                                checked={userName === name.username}
                              />
                              <ListItemText primary={name.username} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No Teachers Found</MenuItem>
                        )}
                      {/* {user && user.length > 0 ? (
                        user.map((name) => (
                          <MenuItem key={name._id} value={name.username}>
                            <Checkbox
                              checked={userName.indexOf(name.username) > -1}
                            />
                            <ListItemText primary={name.username} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No user found</MenuItem>
                      )} */}
                    </Select>
                    {/* <p className='error-field'>{error.userselect}</p> */}
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
				{/* Community__ */}
              {auth.role_id === 1 ? (
                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Select Community
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      className="select"
                      value={coummunityName}
                      onChange={(e) => handlechangeCommunity(e)}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        const selectedCommunity = community.filter((content) =>
                          selected.includes(content.coummunity_name)
                        );
                        if (selectedCommunity.length === 0) {
                          return "Any Community";
                        } else if (selectedCommunity.length === 1) {
                          return selectedCommunity[0].coummunity_name;
                        } else {
                          return `${selectedCommunity.length} Community selected`;
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      {community && community.length > 0 ? (
                        community.map((name) => (
                          <MenuItem key={name._id} value={name.coummunity_name}>
                            <Checkbox
                              checked={
                                coummunityName.indexOf(name.coummunity_name) >
                                -1
                              }
                            />
                            <ListItemText primary={name.coummunity_name} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No user found</MenuItem>
                      )}
                    </Select>
                    {/* <p className='error-field'>{error.userselect}</p> */}
                  </FormControl>
                </Box>
              ) : (
                ""
              )}

			  	{/* School__ */}
              {auth.role_id === 1 ? (
                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Select School
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      className="select"
                      value={schoolName}
                      onChange={(e) => handlechangeSchool(e)}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        const selecttedSchool = school.filter((content) =>
                          selected.includes(content.username)
                        );
                        if (selecttedSchool.length === 0) {
                          return "Any School";
                        } else if (selecttedSchool.length === 1) {
                          return selecttedSchool[0].username;
                        } else {
                          return `${selecttedSchool.length} School selected`;
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      {school && school.length > 0 ? (
                        school.map((name) => (
                          <MenuItem key={name._id} value={name.username}>
                            <Checkbox
                              checked={schoolName.indexOf(name.username) > -1}
                            />
                            <ListItemText primary={name.username} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No school found</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
				{/* Studio__ */}
              {auth.role_id === 1 ? (
                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Select Studio
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      className="select"
                      value={studioName}
                      onChange={(e) => handlechangeStudio(e)}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        const selecttedStudio = studio.filter((content) =>
                          selected.includes(content.username)
                        );
                        if (selecttedStudio.length === 0) {
                          return "Any studio";
                        } else if (selecttedStudio.length === 1) {
                          return selecttedStudio[0].username;
                        } else {
                          return `${selecttedStudio.length} studio selected`;
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      {studio && studio.length > 0 ? (
                        studio.map((name) => (
                          <MenuItem key={name._id} value={name.username}>
                            <Checkbox
                              checked={studioName.indexOf(name.username) > -1}
                            />
                            <ListItemText primary={name.username} />
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>No studio found</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={10} lg={8}>
              <label className="form-labels " for="my-input">
                Videos{" "}
                <Typography component="span" className="star">
                  {" "}
                  *{" "}
                </Typography>
              </label>
              <Box className="mt-10">
                <AddVideo
                  parentFunc={handleparent}
                  setVideoAddError={setVideoAddError}
                />
                {/* {imageAddError && <span className="error-message">{imageAddError}</span>} */}
                {videoAddError && (
                  <span style={{ color: "red" }} className="error-message">
                    {videoAddError}
                  </span>
                )}{" "}
              </Box>
            </Grid>
          </Grid>

          {truebutton ? (
            <Box className="heading-btns mt-20" style={{ textAlign: "end" }}>
              <Button variant="contained" className="addbtn mr-10" disabled>
                Please Wait...
              </Button>
            </Box>
          ) : (
            <Box className="heading-btns mt-20" style={{ textAlign: "end" }}>
              <Button
                onClick={handleAddVideo}
                variant="contained"
                className="theme-btn"
              >
                Submit
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Service_Steppyform;
