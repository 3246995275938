// import { Box, Button, Divider, Grid, Tooltip, Typography } from '@mui/material'
// import React from 'react'
// import { Sheet } from '@mui/joy'
// import Table from '@mui/joy/Table';
// import { Info } from '@mui/icons-material';
// import axios from 'axios';
// import moment from 'moment';
// import { useParams } from 'react-router-dom';

// export default function Blog() {
// //UseState's:
//     const [blogdashboard,setBlogdashboard] = React.useState([])
//     const [totalblog,setTotalBlogs] = React.useState(0)
//     const params = useParams()



// //Hook's state:
//     React.useEffect(()=>{
//         BlogDashboard()
//     },[])


//     const BlogDashboard = () => {
//         axios.get(process.env.REACT_APP_BASE_URL + `Blog/GetBlog?createdby=${params && params.id}&size=${10}&page=${10}&search=${""}&category_id=${"all"}`)
//         .then((res)=>{
//             setBlogdashboard(res.data.getBlog);
//             setTotalBlogs(res.data.totalcount)
//         })
//         .catch((error)=>{
//             console.log(error,"error in BlogDashboard");
//         })
//     }




//     return (
//         <Box className="individual_table">
//             <Box className="p-10 flex-between flex-center">
//                 <Box className="flex-center">
//                     <Typography component='h6' variant='h6' className='semibold letter_sp fz-16 mr-10'>Blogs</Typography>
//                     <Tooltip title="watch video" className='flex-center text-right'>
//                         <Info className='fz-18' />
//                     </Tooltip>
//                 </Box>
//                 <Typography component='div' variant='body' className='countBox fz-24'>{totalblog}</Typography>
//             </Box>
//             <Divider />
//             <Box className="p-10">
//                 {/* <Box className="d-flex justify-content-right">
//                     <Tooltip title="watch video" className='flex-center text-right'>
//                         <Button className='theme_btn btn-xs'>Add or Edit Blog</Button>
//                     </Tooltip>
//                 </Box> */}
//                 <Box className="table_content">
//                     <Sheet sx={{ height: 200, overflow: 'auto' }}>
//                         <Table
//                             aria-label="table with sticky header"
//                             stickyHeader
//                             stickyFooter
//                             stripe="odd"
//                             hoverRow
//                         >
//                             <thead>
//                                 <tr>
//                                     <th className='minw100'>Blog Name</th>
//                                     <th className=''>Description</th>
//                                     <th className='text-center minw100'>Added on</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {blogdashboard && blogdashboard?.length > 0 ?
//                                 (blogdashboard && blogdashboard?.map((item,i) => (
//                                     <tr key={i}>
//                                         <td className='semibold fz-12' >{item.Blog_title}</td>
//                                         <td  ><Box className='ellipse2 fz-12 text_blue letter_sp'>{item.shortDescription ? item.shortDescription : '-'}</Box></td>
//                                         <td ><div className='semibold fz-12 text-center'>{moment(item.createdAt).format('MMM Do YY')}</div></td>
//                                     </tr>
//                                 ))) : (
//                                     <p>No data found</p>
//                                 )}
//                             </tbody>
//                         </Table>
//                     </Sheet>
//                 </Box>
//             </Box>
//         </Box>
//     )
// }



import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Sheet } from '@mui/joy';
import Table from '@mui/joy/Table';
import { Info } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export default function Blog() {
  // UseState's:
  const [blogdashboard, setBlogdashboard] = React.useState([]);
  const [totalblog, setTotalBlogs] = React.useState(0);
  const [loading, setLoading] = React.useState(true); // Loading state
  const params = useParams();

  // Hook's state:
  React.useEffect(() => {
    // Simulate 1-second loading time before fetching data
    setTimeout(() => {
      BlogDashboard();
    }, 1000);
  }, []);

  const BlogDashboard = () => {
    axios.get(process.env.REACT_APP_BASE_URL + `Blog/GetBlog?createdby=${params?.id}`)
      .then((res) => {
        console.log(res.data)
        setBlogdashboard(res.data.blog);
        setTotalBlogs(res.data.totalcount);
        setLoading(false); // Disable loader after data is fetched
      })
      .catch((error) => {
        console.log(error, "error in BlogDashboard");
        setLoading(false); // Disable loader if there's an error
      });
  };

  return (
    <Box className="individual_table">
      <Box className="p-10 flex-between flex-center">
        <Box className="flex-center">
          <Typography component='h6' variant='h6' className='semibold letter_sp fz-18 mr-10'>Blogs</Typography>
          <Tooltip title="watch video" className='flex-center text-right'>
            <Info className='fz-18' />
          </Tooltip>
        </Box>
        <Typography component="div" variant="body" className='countBox fz-24'>{totalblog}</Typography>
      </Box>
      <Divider />
      <Box className="p-10">
      <Box className="d-flex justify-content-right">
                    <Tooltip title="watch video" className='flex-center text-right'>
                        <Button className='theme-btn btn-gradient btn-xs'>More</Button>
                    </Tooltip>
                </Box>
        <Box className="table_content">
          <Sheet sx={{ height: 200, overflow: 'auto' }}>
            <Table
              aria-label="table with sticky header"
              stickyHeader
              stickyFooter
              stripe="odd"
              hoverRow
            >
              <thead>
                <tr>
                  <th className='minw100'>Blog Name</th>
                  <th className=''>Description</th>
                  <th className='text-center minw100'>Added on</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="3" style={{background:"#fbfcfe", textAlign: "center", padding: "20px" }}>
                      <CircularProgress sx={{ color: "#f3714d" }} />
                    </td>
                  </tr>
                ) : blogdashboard && blogdashboard.length > 0 ? (
                  blogdashboard.map((item, i) => (
                    <tr key={i}>
                      <td className='semibold fz-12'>{item.Blog_title}</td>
                      <td>
                        <Box className='ellipse2 fz-12 text_blue letter_sp'>{item.shortdescription ? item.shortdescription : '-'}</Box>
                      </td>
                      <td>
                        <div className='semibold fz-12 text-center'>{moment(item.createdAt).format('MMM Do YY')}</div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" style={{background:"#fbfcfe", textAlign: "center", fontWeight: "bold", padding: "20px" }}>
                      <Typography component="p" variant="body1">No data found</Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Sheet>
        </Box>
      </Box>
    </Box>
  );
}
