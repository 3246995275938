import { Box, Button, Card, Dialog, DialogActions, DialogTitle, FormControl, Grid, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import React from 'react';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from '@mui/icons-material/Close'; 
import { enqueueSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import AudioPlayer from 'react-h5-audio-player';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import LinearBuffer from './LinearBuffer';





const ContentUploader = ({ titleOfChapter,idOfCourse,idOfChapter,indexOfChapter, indexOfLesson}) => {

 
  const { id } = useParams();
  const [chapters, setChapters] = React.useState(null);
  const [parentLessonData, setParentLessonData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  
  // Get chapters when the component mounts or when id changes
 // Function to handle lesson selection
 const handleLessonClick = (chapIndex, lessonIndex) => {
  if (chapters && chapters[chapIndex]) {
    const lesson = chapters[chapIndex].lessons[lessonIndex];
    if (lesson) {
      setParentLessonData({
        ...lesson,
        chapterTitle: chapters[chapIndex].title,
        chapterId: chapters[chapIndex]._id,
        courseId: id,
      });
    } else {
      console.error("Lesson not found at specified indices.");
    }
  } else {
    console.error("Invalid chapter index or chapters not loaded.");
  }
};

// Fetch chapters from the API
const fetchChapters = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}Course1/GetCourse/${id}`);
    const fetchedChapters = response.data.course.chapters;
    console.log("Fetched Chapters:", fetchedChapters);


    if (fetchedChapters?.length > 0) {
      setChapters(fetchedChapters);
    } else {
      console.error("No chapters found.");
    }
  } catch (error) {
    console.error("Error fetching chapters:", error);
  }
};

// UseEffect to fetch chapters when the component mounts or id changes
React.useEffect(() => {
  fetchChapters();
}, [id]);

// UseEffect to handle lesson selection after chapters are fetched
React.useEffect(() => {
  if (chapters && indexOfChapter >= 0 && indexOfLesson >= 0) {
    handleLessonClick(indexOfChapter, indexOfLesson);
  }
}, [chapters, indexOfChapter, indexOfLesson]); // Watch for chapters and indices

// Log changes to parentLessonData for debugging
React.useEffect(() => {
  console.log("Updated Parent Lesson Data:", parentLessonData);
}, [parentLessonData]);

 
  const [inputRows, setInputRows] = React.useState([{ type: '', name: '', file: null }]);

  const handleAddRow = () => {
    setInputRows([...inputRows, { type: '', name: '', file: null }]);
  };
  

  // State
  const [openInputFields,setOpenInputFields] = React.useState(false)

  const openInputfield = ()=>{
    setOpenInputFields(true)
  }

  const dropDownArray = ["Audio", "Video", "File"];
  const [selectedFileType, setSelectedFileType] = React.useState('');


  const [openDel, setOpenDel] = React.useState(false);
  const handleClose = () => {
    setOpenDel(false);
  };



  // Funtion For selectType change
  const handleChange = (e) => {
    setSelectedFileType(e.target.value);
  };

  const [error,setError] = React.useState({typrerr:"",nameerr:'',fileerr:"",newerr:''})


 

  const handleFileChange = (index, e) => {
    const selectedFile = e.target.files[0];
    console.log(e.target.files[0])
  // Validate the selected file
  const updatedRows = [...inputRows];
  const row = updatedRows[index];

  if (!row.type && !row.name) {
    // enqueueSnackbar("Please select the File Type before uploading a file.", {
    //   variant: "error",
    // });
    // return;
    setError({typrerr:"This Field is required ",nameerr:"This Field is required "})
    
    // return;
    // setError({nameerr:"This Field is required name"})
    return;
  }

  if (!row.name) {
    setError({nameerr:"This Field is required "})
    return
  }

 

  if (selectedFile) {

   
    // Validate file type
    const extension = selectedFile.name.split('.').pop().toLowerCase();
    // const supportedFileTypes = ['mp3', 'mp4', 'jpg', 'png']; // Example supported file types
    const supportedFileTypesMedia = ['mp3', 'mp4','jpg', 'png']; // Example supported file types
    // const supportedFileTypesFile = ['pdf']; // Example supported file types

     if(inputRows[index].type === 'File' ){
      if(supportedFileTypesMedia.includes(extension)){
        enqueueSnackbar(`Change the File Type: ${selectedFile.name}`, { variant: 'error' });
        return;
      }
    }

    // Update file information in the inputRows
    updatedRows[index].file = selectedFile;
    updatedRows[index].filePreview = URL.createObjectURL(selectedFile); // Create a preview URL
    setInputRows(updatedRows);
    setError({newerr:""})
  }
  };


  const handleInputChange = (index, field, value) => {
    const updatedRows = [...inputRows];
    if (field === 'name' && !updatedRows[index].type) {
      setError({ typrerr: "This Field is required" });
      
      return; // Exit early to prevent setting the value if there's an error
  }
   
    updatedRows[index][field] = value;
    setInputRows(updatedRows);
    setError({nameerr:""})
    
  };

  // console.log(inputRows)

  const clearFile = (index) => {
    const updatedRows = [...inputRows];
    updatedRows[index].file = null;
    updatedRows[index].filePreview = null;
    setInputRows(updatedRows);
  };



  const deleteRow = (index) => {
    if(index === 0 && inputRows.length <= 1){
        
      setOpenInputFields(false)
      setInputRows([{ type: '', name: '', file: null }]);
      setError({})

      return;
    }
    
   
      const updatedRows = inputRows.filter((_, i) => i !== index);
      setInputRows(updatedRows);
     

  };




  const uploadFilesToBackend = async (filesArray) => {
    
    
    // Separate files by type
    const AudioFiles = filesArray.filter(file => file.type === 'Audio');
    const VideoFiles = filesArray.filter(file => file.type === 'Video');
    const Files = filesArray.filter(file => file.type === 'File');
    if(AudioFiles.length === 0 && VideoFiles.length === 0 && Files.length === 0) {

      
      setError(
        {typrerr:"this field is required",nameerr:"this field is required",fileerr:"this field is required"}
      )

      return;
    }

    const allFieldsFilled = inputRows.every(row => row.type && row.name && row.file);

    if (!allFieldsFilled) {
      setError(
        {typrerr:"this field is required",nameerr:"this field is required",fileerr:"this field is required"}
      )
      return
    }


    
    
    setLoading(true)
    // console.log(AudioFiles)
    // console.log(VideoFiles)
    // console.log(Files)
  
    // Function to handle audio file uploads
    const uploadAudioFiles = async () => {
      if (AudioFiles.length === 0) {
        console.log("No audio files to upload.");
        return; // Exit if no audio files
      }
      for (const audio of AudioFiles) {
        await uploadAudioFileToBackend(idOfCourse, idOfChapter, parentLessonData._id, audio.file, audio.name);
      }
    };
  
    // Function to handle video file uploads
    const uploadVideoFiles = async () => {
      if (VideoFiles.length === 0) {
        console.log("No Video files to upload.");
        return; // Exit if no audio files
      }
      for (const video of VideoFiles) {
        await uploadVideoFileToBackend(parentLessonData.courseId, parentLessonData.chapterId, parentLessonData._id, video.file, video.name);
      }
    };
  
    // Function to handle other file uploads (PDFs, etc.)
    const uploadOtherFiles = async () => {
      if (Files.length === 0) {
        console.log("No other files to upload.");
        return; // Exit if no other files
      }
      for (const file of Files) {
        await uploadOtherFileToBackend(idOfCourse, idOfChapter, parentLessonData._id, file.file, file.name);
      }
    };
    ;

    const uploadPromises = [];
  if (AudioFiles.length > 0) uploadPromises.push(uploadAudioFiles());
  if (VideoFiles.length > 0) uploadPromises.push(uploadVideoFiles());
  if (Files.length > 0) uploadPromises.push(uploadOtherFiles());

    // Execute uploads
    try {
      await Promise.all(uploadPromises);
      enqueueSnackbar("Files Uploaded Successfully", { variant: "success" });
      fetchChapters();
      setInputRows([{ type: '', name: '', file: null }]);
      setOpenInputFields(false)
      setError({})
      
   

      // Refresh the chapter list after uploads
       
    } catch (error) {
      console.error("Error during file uploads:", error);
      enqueueSnackbar("Failed to upload some files", { variant: "error" });
    }finally{
      setLoading(false)
    }
  };

  const uploadVideoFileToBackend = async (courseId, chapterId, lessonId, file, fileName) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/uploadVideoThumbnail/${courseId}/${chapterId}/${lessonId}`;
    const formData = new FormData();
    if (file) {
      formData.append("Video", file);
    }
    if (fileName) {
      formData.append("VideoName", fileName);
    }
  
    try {
      const response = await axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("Video upload successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error uploading video:", error);
      throw error;
    }
  };

  const uploadAudioFileToBackend = async (courseId, chapterId, lessonId, file, fileName) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/uploadAudio/${courseId}/${chapterId}/${lessonId}`;
    const formData = new FormData();
    if (file) {
      formData.append("audio", file);
    }
    if (fileName) {
      formData.append("audioName", fileName);
    }
  
    try {
      const response = await axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("Audio upload successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error uploading audio:", error);
      throw error;
    }
  };
  
  const uploadOtherFileToBackend = async (courseId, chapterId, lessonId, file, fileName) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/uploadPdf/${courseId}/${chapterId}/${lessonId}`;
    const formData = new FormData();
    if (file) {
      formData.append("pdf", file);
    }
    if (fileName) {
      formData.append("pdfName", fileName);
    }
  
    try {
      const response = await axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      console.log("File upload successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };



  const handleUploadAllFiles = async () => {
    await uploadFilesToBackend(inputRows);
    
  };




  // Delete 

  const [lessonToDelete, setLessonToDelete] = React.useState(null);

  const [audioToDelete,setAudioToDelete] = React.useState('');
  const [nameOfAudio,setNameOfAudio] = React.useState("");
  
  const [videoToDelete,setVideoToDelete] = React.useState("");
  const [nameOfVideo,setNameOfVideo] = React.useState("");


  const [fileToDelete,setFileToDelete] = React.useState('')




  
  const handleAudioDelete = (audio,audioName)=>{
      setAudioToDelete(audio);
      setNameOfAudio(audioName)
      setLessonToDelete(parentLessonData._id);
      setOpenDel(true);
  }

  const handleVideoDelete = (video,VideoName)=>{
    setNameOfVideo(VideoName)
    setVideoToDelete(video);
    setLessonToDelete(parentLessonData._id);
    setOpenDel(true);
  }

  const handleFileDelete = (file)=>{
    setFileToDelete(file);
    setLessonToDelete(parentLessonData._id);
    setOpenDel(true);
  }



  const deleteContentFromBackend = async (
    courseId,
    chapterId,
    lessonId,
    body
  ) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/deleteVideoFromLesson/${courseId}/${chapterId}/${lessonId}`;

    try {
      console.log(body);
      const response = await axios.put(
        url,
        body
      );
      console.log("Delete successful:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error deleting content:", error);
      throw error;
    }
  };



  const handleConfirmDelete = async () => {
    const body = {
   
      videoPath: videoToDelete,
      VideoName: nameOfVideo
    };
    console.log(body)
    if (lessonToDelete) {
      try {
        const response = await deleteContentFromBackend(
          id,
          idOfChapter,
          parentLessonData._id,
          body
        );
        console.log("Content deleted successfully:", response);
        enqueueSnackbar("Video Deleted Successfully", {
          variant: "success",
        });

        fetchChapters()
        setVideoToDelete('')
        setNameOfVideo('')
        setOpenDel(false);
      } catch (error) {
        console.error("Error during deletion:", error);
        enqueueSnackbar("Failed To Delete Thumbnail and Video", {
          variant: "error",
        });
      }
    }
    
  };


  const handleDeleteAudioContent = async (courseId, chapterId, lessonToDelete, audioToDelete,nameOfAudio) => {
    const url = `${process.env.REACT_APP_BASE_URL}Course1/deleteaudieo/${courseId}/${chapterId}/${lessonToDelete}`;
    console.log(audioToDelete)
  
    const body = {

      audioPath: audioToDelete,
      audioName: nameOfAudio
    };
  
    try {
      console.log(body);
      const response = await axios.put(url, body);
      console.log("Delete successful:", response.data);
      
      enqueueSnackbar("Audio Deleted Successfully", {
        variant: "success",
      });
  
     fetchChapters();
     setAudioToDelete("")
     setOpenDel(false);

      
      return response.data;
    } catch (error) {
      console.error("Error deleting content:", error);
      enqueueSnackbar("Failed To Delete Thumbnail and Video", {
        variant: "error",
      });
      throw error;
    } 
  };


  const handleDeleteFileContent = async (courseId, chapterId, lessonToDelete, fileTodelete) => {

    const url = `${process.env.REACT_APP_BASE_URL}Course1/deletepdf/${courseId}/${chapterId}/${lessonToDelete}`;
  
    const body = {
      pdfPath: fileTodelete
    };
  
    try {
      console.log(body);
      const response = await axios.put(url, body);
      console.log("Delete successful:", response.data);
      
      enqueueSnackbar("File Deleted Successfully", {
        variant: "success",
      });
  
      fetchChapters();
      setOpenDel(false);
      setFileToDelete('');

      
      return response.data;
    } catch (error) {
      console.error("Error deleting content:", error);
      enqueueSnackbar("Failed To Delete Thumbnail and Video", {
        variant: "error",
      });
      throw error;
    } 
  };


  

  const handleDelete=async () => {
    // if (audioToDelete) {
    //   console.log(audioToDelete)
    //   console.log("called")
    //   handleDeleteAudioContent(id, idOfChapter, parentLessonData._id, audioToDelete, nameOfAudio);
    //   return; 
    // }
    // if (videoToDelete) {
    //   console.log("called")
    //   handleConfirmDelete();
    //   return;
    // }
    // if(fileToDelete){
    //   console.log("called")
    //   handleDeleteFileContent(id, idOfChapter , parentLessonData._id, fileToDelete)
    //   return;
    // }

    setLoading(true); // Start loading
  try {
    if (audioToDelete) {
      await handleDeleteAudioContent(id, idOfChapter, parentLessonData._id, audioToDelete, nameOfAudio);
    } else if (videoToDelete) {
      await handleConfirmDelete();
    } else if (fileToDelete) {
      await handleDeleteFileContent(id, idOfChapter, parentLessonData._id, fileToDelete);
    }
  } catch (error) {
    console.error("Error during deletion:", error);
  } finally {
    setLoading(false); // End loading
  }
  };



  // view page

//   const renderFileEntry = (type, name, filePath) => (
//   <Paper
//   elevation={2}
//   sx={{
//     border: '1px solid #e0e0e0',
//     borderRadius: '8px',
//     overflow: 'hidden',
//     marginBottom: '16px',
//   }}
//   key={name}
// >
//   <Box
//     sx={{
//       display: 'flex',
//       flexDirection: 'column',
//       width: '100%',
//       backgroundColor: '#f5f5f5',
//     }}
//   >
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'flex-start',
//         width: '100%',
//         padding: '16px',
//         gap: '20px',
//       }}
//     >
//       <Box sx={{ flex: 1 }}>
//         <Typography variant="body2" color="text.secondary">
//           {type === 'Audio' ? 'Audio File' : type === 'Video' ? 'Video File' : 'PDF File Name'}
//         </Typography>
//         {type === 'Audio' ? (
//           <>
//           <AudioPlayer
//             src={process.env.REACT_APP_BASE_URL + filePath[0]}
//             onPlay={(e) => console.log("onPlay")}
//             className="audio_player"
//             style={{ marginTop: '8px', maxWidth: '90%' }}
//           />
//           </>
//         ) : type === 'Video' ? (
//           <>
//           <video 
//             controls 
//             src={process.env.REACT_APP_BASE_URL + filePath[0]} 
//             style={{ marginTop: '8px', maxWidth: '90%', height: 'auto' }}
//           />
          
//           </>
          
//         ) : (
//           <>
//           <Typography variant="body1">{filePath[0].split('-').pop()}</Typography>
          
//           </>
//         )}
//       </Box>

//       <Box sx={{ flex: 1 ,marginTop:"20px"}}>
//         <Typography variant="body2" color="text.secondary">
//           File Type
//         </Typography>
//         <Typography variant="body1">{type}</Typography>

//         <Typography variant="body2" color="text.secondary">
//           File Name
//         </Typography>
//         <Typography variant="body1">{name}</Typography>
//       </Box>

//       <Box sx={{ flex: 1 }}>

//       <Button
//         variant="contained"
//         className="theme-btn"
//         onClick={() => {
//           if (type === "Audio") {
//             handleAudioDelete(filePath[0], name);
//           } else if (type === "Video") {
//             handleVideoDelete(filePath[0], name);
//           } else if (type === "PDF") {
//             handleFileDelete(filePath[0], name);
//           }
//         }}
//         sx={{
//           position: 'absolute',
//           top: '16px',
//           right: '16px',
//           backgroundColor: '#ff7760',
//           '&:hover': {
//             backgroundColor: '#e66b56'
//           },
//           textTransform: 'none',
//           minWidth: 'unset',
//           padding: '6px 16px'
//         }}
//       >
//         Delete
//       </Button>

//      {/* {
//       type === "Audio" ?  <Button
//       variant="contained"
//       className="theme-btn"
//       onClick={() => {
//         handleAudioDelete(filePath[0],name)
     
//       }}
//       sx={{
//         marginTop:"10px",
        
//       }}
//     >
//       Delete
//     </Button> : type === "Video"  ?<Button
//       variant="contained"
//       className="theme-btn"
//       onClick={() =>{
//         handleVideoDelete(filePath[0],name)
     
//       }}
//       sx={{
//         marginTop:"10px"
//       }}
//     >
//       Delete
//     </Button>:type === "PDF"  ? <Button
//       variant="contained"
//       className="theme-btn"
//       onClick={() => {
//         handleFileDelete(filePath[0],name)
       
//       }}
//       sx={{
//         marginTop:"10px"
//       }}
//     >
//       Delete
//     </Button>:""
// } */}

     
          
      

//       </Box>

      
//     </Box>
//   </Box>
// </Paper>
//   );


const renderFileEntry = (type, name, filePath,ext) => (
 
  <Paper
    elevation={2}
    sx={{
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      overflow: 'hidden',
      marginBottom: '16px',
      position: 'relative',
    }}
    key={name}
  >
    
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#f5f5f5',
        padding: '16px',
        display: 'grid',
        gridTemplateColumns: '350px auto',
        gap: '20px',
      }}
    >
      {console.log(filePath)}
      {/* Left Column - Media Content */}
      <Box>
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ marginBottom: '8px' }}
        >
          {type === 'Audio' ? 'Audio File' : type === 'Video' ? 'Video File' : 'File Name'}
        </Typography>
        
        {type === 'Audio' ? (
          <AudioPlayer
            src={process.env.REACT_APP_BASE_URL + filePath[0]}
            onPlay={(e) => console.log("onPlay")}
            className="audio_player"
            style={{ width: '100%' }}
          />
        ) : type === 'Video' ? (
          <video 
            controls 
            src={process.env.REACT_APP_BASE_URL + filePath[0]} 
            style={{ width: '100%', height: 'auto' }}
          />
        ) : (
          <Typography variant="body1">
            {filePath[0].split('-').pop()}
          </Typography>
        )}
      </Box>

      {/* Right Column - File Info */}
      <Box sx={{ paddingTop: '24px' }}>
        <Box sx={{ marginBottom: '16px' }}>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ marginBottom: '4px' }}
          >
            File Type
          </Typography>
          <Typography variant="body1">{ext}</Typography>
        </Box>

        <Box>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ marginBottom: '4px' }}
          >
            File Name
          </Typography>
          <Typography variant="body1">{name}</Typography>
        </Box>
      </Box>

      {/* Delete Button - Absolutely Positioned */}
      <Button
        variant="contained"
        className="theme-btn"
        onClick={() => {
          if (type === "Audio") {
            handleAudioDelete(filePath[0], name);
          } else if (type === "Video") {
            handleVideoDelete(filePath[0], name);
          } else if (type === "PDF") {
            handleFileDelete(filePath[0], name);
          }
        }}
        sx={{
          position: 'absolute',
          bottom: '16px',
          right: '16px',
          backgroundColor: '#ff7760',
          '&:hover': {
            backgroundColor: '#e66b56'
          },
          textTransform: 'none',
          minWidth: 'unset',
          padding: '6px 16px'
        }}
      >
        Delete
      </Button>
    </Box>
  </Paper>
)



  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={12}>
        {/* {showContent ? */}
        <Box className=" ">
          <Box className="mb-20 whiteContainer p-20">
            <Typography className=" text_primary fw-bold mb-20" sx={{ fontSize: '22px' }}> {titleOfChapter} </Typography>
            <Box>
              <Typography className="fz-18 text_primary fw-bold mb-20">{parentLessonData.title}</Typography>
              {/* for view data from backend */}
              
    <Box>
      {/* Render Audio Files */}
      {parentLessonData?.audio?.length > 0 && <Typography variant="h6">Audio Files</Typography>}
      {parentLessonData?.audio?.map((audio) => 
        audio.file.map((filePath) => 
          renderFileEntry('Audio', audio.audioName, audio.file)
        )
      )}

      {/* Render Video Files */}
     {parentLessonData?.Video?.length > 0 &&  <Typography variant="h6">Video Files</Typography>}
      {parentLessonData?.Video?.map((video) => 
        video.file.map((filePath) => 
          renderFileEntry('Video', video.VideoName, video.file)
        )
      )}

      {/* Render PDF Files */}
    {parentLessonData?.pdf?.length > 0 && <Typography variant="h6">Files</Typography>}
      {parentLessonData?.pdf?.map((pdf) => 
        pdf.file.map((filePath) => 
          renderFileEntry('PDF', pdf.pdfName, pdf.file,pdf.file[0].split('.').pop())
        )
      )}
    </Box>




              {/* for input Add content */}
              {loading && <LinearBuffer />}
              <Grid
                container
                columnSpacing={2}
                className="custom-grid-container m-0"
                direction="row"
                sx={{
                  position:"relative"
                }}
              >
                  {
                    openInputFields && (
                      <Box>
                        {inputRows.map((row, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "flex-start",
                                  marginBottom: "20px",
                                  padding: "10px",
                                  gap: "20px"
                                }}
                              >
                              <Box sx={{ flex: 1 ,width:"250px"}}>
                                <Typography component='div' className='label'>
                                  Select File Type <Typography component='span' className='star'>*</Typography>
                                </Typography>
                                <FormControl fullWidth>
                                  <Select
                                    className="select"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={row.type || 'sel'}
                                    onChange={(e) => {
                                      handleInputChange(index, 'type', e.target.value);
                                      setError({typrerr:""})
                                    }}
                                  >
                                    <MenuItem disabled value={'sel'}>Select File Type</MenuItem>
                                    {dropDownArray.map((data, i) => (
                                      <MenuItem key={i} value={data}>{data}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                { error.typrerr && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.typrerr}</p> }
                                { error.newerr && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.newerr}</p> }

                              </Box>

                              <Box sx={{ flex: 1,width:"250px" }}>
                                <Typography component="div" className="label">
                                  {row.type === "Audio" ? `Enter Audio Title` :
                                    row.type === "Video" ? `Enter Video Title` :
                                    row.type === "File" ? `Enter File Title` :
                                    `Enter File Name`} 
                                  <Typography component="span" className="star">*</Typography>
                                </Typography>
                                <FormControl variant="standard" fullWidth>
                                  <TextField
                                    id={`name-${index}`}
                                    variant="outlined"
                                    placeholder={`Enter ${row.type} Title`}
                                    size="small"
                                    value={row.name}
                                    onChange={(e) => {
                                      handleInputChange(index, 'name', e.target.value);
                                    }}
                                    className="textfield"
                                  />
                                </FormControl>
                                { error.nameerr && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.nameerr}</p> }
                              </Box>

                              <Box sx={{ flex: 1 ,width:"250px"}}>
                                <Typography component="div" className="label">
                                  {row.type === "Audio" ? `Select Audio File` :
                                    row.type === "Video" ? `Select Video File` :
                                    row.type === "File" ? `Select File` :
                                    `Select File`}
                                  <Typography component="span" className="star">*</Typography>
                                </Typography>

                                {/* Input Box For all */}
                                    
                                    
                                       {
                                        (!row.filePreview && (
                                          <Card style={{ width: "200px" }}>
                                            <Button
                                                      className="upload_btn"
                                                      variant="contained"
                                                      component="label"
                                                      fullWidth
                                                    >
                                                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <FileUploadOutlinedIcon className="fz-18 mr-5" />
                                                        {row.type === "Audio" ? "Audio File" :
                                                          row.type === "Video" ? "Upload video" :
                                                          "Add Files"}
                                                      </Box>
                                                  
                                                          <input
                                                              accept={row.type === "Video" ? "video/*" :
                                                              row.type === "Audio" ? "audio/*" : "file/*"}
                                                              onChange={(e) => handleFileChange(index, e)}
                                                              single
                                                              hidden
                                                              type="file"
                                                          />
                                                          
                                            </Button>
                                          </Card>


                                        ))
                                       }
                                            { error.fileerr && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.fileerr}</p> }
                                            {/* { error.nameerr && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.nameerr}</p> } */}


                                {/* Preview for all files */}

                              {
                                row.filePreview && <>
                                  {
                                    row.type === 'Video' && (
                                      <div>
                                        {row.filePreview && (
                                          <video
                                            controls
                                            className=""
                                            src={row.filePreview}
                                            height={155}
                                            width={195}
                                          />
                                        )}
                                      </div>
                                    )
                                  }
                                  {
                                    row.type === 'Audio' && (
                                      <Card className='video-content' sx={{
                                        width:"300px"
                                      }}>
                                        {row.filePreview && (
                                          <AudioPlayer
                                          // autoPlay
                                          src={row.filePreview}
                                          onPlay={(e) =>
                                            console.log("onPlay")
                                          }
                                          className="audio_player"
                                        />
                                        )}
                                      </Card>
                                    )
                                  }
                                  {
                                    row.type === 'File' && (
                                      <Typography>
                                        {row.filePreview && (
                                          <ul className='p-0'>
                                              <li className='flex-center fz-16 text_black'>{row.file.name}</li>
                                          </ul>
                                          )
                                        }
                                        
                                        
                                      </Typography>
                                    )
                                  }
                                </>
                              }

                             
                                  
                              </Box>

                              {/* { row.filePreview && <Box sx={{ flex: 1 ,width:"250px"}}> */}
                                {/* for video preview */}
                                      

                              {/* </Box>} */}

                              
                                
                                      
                                     
                                      
                               
                              



                             
                              


                              {/* Remove the preview file */}
                              {row.filePreview && (
                                      <CloseIcon
                                        onClick={() => clearFile(index)}
                                        sx={{
                                          cursor: 'pointer',
                                          color: 'red',
                                        }}
                                      />
                                  )}



                              {/* Delete Row button */}
                              {
                                row && (
                                  <Box sx={{ flex: 1 }}>
                                    <Button
                                      variant="contained"
                                      color="error"
                                      onClick={() => deleteRow(index)}
                                      sx={{
                                        marginTop: "35px"
                                      }}
                                    >
                                      Delete Row
                                    </Button>
                                </Box>
                                )
                              }
          

                              {/* Add Row button */}
                              <Box sx={{ flex: 1 }}>
                                <Button
                                  variant="contained"
                                  className="theme-btn"
                                  onClick={handleAddRow}
                                  sx={{ 
                                    position:"absolute",
                                    bottom:"10px",
                                    right:"125px"
                                  }}
                                >
                                  Add More
                                </Button>
                              </Box>
                            </Box>
                        ))}
                    </Box> 
                    )
                  }


{/* Add Content and submit Button */}


                  {
                    openInputFields ? (
                      <Box sx={{flex: 1,
                      position: "absolute",
                      bottom:"10px",
                      right:"20px"
                      }}>
                        <Button
                          variant="contained"
                          className="theme-btn"
                          // disabled={!dropDownArray?.length > 0}
                          onClick={handleUploadAllFiles}
                          sx={{
                            marginTop:"45px"
                          }}
                          disabled = {loading}
                        >
                          Submit
                        </Button>
                      </Box>
                    ):(
                      <Box sx={{flex: 1}}>
                        <Button
                          variant="contained"
                          className="theme-btn"
                          // disabled={!dropDownArray?.length > 0}
                          onClick={openInputfield}
                          sx={{
                            marginTop:"45px"
                          }}
                        >
                          Add Content
                        </Button>
                      </Box>
                    )
                  }
                


              </Grid>

              
            </Box>
          </Box>
        </Box>
      </Grid>
      <Dialog
        open={openDel}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="custom_modal"
      >
      
        <DialogTitle>
          {videoToDelete? `Would you like to delete this video?`: audioToDelete ? `Would you like to delete this  Audio?`:fileToDelete ? `Would you like to delete this PDF?`:''}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} className="cancel_btn">
            No
          </Button>
          <Button 
          onClick={handleDelete} 
          className="theme-btn"
          disabled ={loading}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ContentUploader