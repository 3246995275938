import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography, Grid, Button, TextField, FormControlLabel, Select, InputAdornment, FormControl, Card, Menu } from '@mui/material';
import { SearchOutlined,CloseOutlined, Verified } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DOMPurify from 'dompurify';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import Switch, { SwitchProps } from '@mui/material/Switch';
import CardContent from '@mui/material/CardContent';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function FormPropsTextFields() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [openview, setOpenview] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);

    const menuopen = Boolean(anchorEl);
    const [alldata, setAlldata] = React.useState('');
    const [cat, setCat] = React.useState([]);
    const [view, setView] = React.useState(false);
    const [deleteid, setDeleteId] = React.useState('');
    const [catid, setCatId] = React.useState('all');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        getCategory()
    }, [])

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `category/GetCategory?size=100&page=0&order=asc`, { headers: headerlocal })
            .then((res) => {
                console.log(res.data)
                setCat(res.data.category)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }

    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    const handleClose_menu = () => {
        setAnchorEl(null);
    };

    const handleChangeStatus = async (e, data) => {
        try {
            const updatedData = { ...data, verifyNews: e.target.checked };
            await axios.put(process.env.REACT_APP_BASE_URL + `News/updateNews/${data._id}`, updatedData, { headers: headerlocal });
            // Update the state to reflect the new toggle state
            const newData = newdata.map(item => item._id === data._id ? updatedData : item);
            setNewdata(newData);
        } catch (error) {
            console.error("Error updating news status:", error);
            // Handle error, show error message to the user
            enqueueSnackbar(error.response && error.response.data.error ? error.response.data.error : "Something went wrong.", { variant: 'error' })

            // enqueueSnackbar("Error updating news status", { variant: 'error' });
        }
    };

    const handleChangeStatus1 = async (e, data, type) => {
        console.log(type, 'typetypetypetype');
        // if (type === 'Public') {
        //     const body = {
        //         public: e.target.checked,
        //     };
        //     try {
        //         const response = await axios.put(process.env.REACT_APP_BASE_URL + `course/updateCourse/${data._id}`, body, { headers: headerlocal });
        //         getCourse();
        //     } catch (error) {
        //         enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
        //         console.error("Error while updating public", error);
        //     }
        // }
        if (type === 'Primary') {
            const body = {
                Primary: e.target.checked,
            };
            console.log(body, 'bodybodybodyin newssss');
            try {
                const response = await axios.put(process.env.REACT_APP_BASE_URL + `News/updateNews/${data._id}`, body, { headers: headerlocal });
                getNews()
            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating primary news", error);
            }
        }
    };




    React.useEffect(() => {
        getNews()
    }, [rowsPerPage, page, search, catid])

    const getNews = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `News/GetNews?size=${rowsPerPage}&page=${page}&search=${search}&Category_id=${catid}`, { headers: headerlocal })
            .then((res) => {
                console.log(res.data.news, "getnews");
                setNewdata(res.data.news)
                setTotal(res.data.totalcount)
            }).catch((error) => {
                console.log(error, "error in news get");
            })
    }


    const handleDeleteService = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `News/deleteNews/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                enqueueSnackbar("News deleted successfully", { variant: 'success' })
                getNews()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };


    const handleEdit = () => {
        let val = {
            data: alldata
        }
        navigate("/editnews", { state: val })
    }

    const handleView = () => {
        setAnchorEl(null);
        setOpenview(true)

    }

    const handleRemove = () => {
        setCatId('all')
        setSearch('')
        setPage(0)
    }


    const sanitizedContent = DOMPurify.sanitize(alldata.description, { ALLOWED_TAGS: ['img'] });

    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }

    // const handleChangeStatus = async (e, data) => {
    //     if (data.setService == false) {
    //         var body = {
    //             isActive: e.target.checked,
    //         }
    //         await axios.put(process.env.REACT_APP_BASE_URL + `course/updateCourse/${data._id}`, body, { headers: headerlocal })
    //             .then((res) => {
    //                 getNews();
    //             }).catch((err => {
    //                 console.log("error while shop enable");
    //             }))
    //     }
    //     else {
    //         enqueueSnackbar(`The service you are attempting to disable is set as "Primary Service".You can disable the service after removing it from the list of "Primary Services".`, { variant: 'error' })
    //     }
    // }

    return (
        <>
            <Box className='whiteContainer p-20'>
                <Box className='flex-center flex-wrap mb-20 '>
                <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={search}
                                className='textfield search'
                                onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {search !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box className='flex-center'>
                        <Typography component="div" className="label mb-0 mr-10">Category: </Typography>
                        <FormControl fullWidth className='minw200' >
                            <Select className="select"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => setCatId(e.target.value)}
                                value={catid ? catid : 'all'}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                {cat && cat.length > 0 && cat.map((data) => (
                                    <MenuItem value={data._id}>{data.category_title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
               
                    {search != '' ?
                        <Button variant="contained" className='addbtn theme-btn ml-10' onClick={handleRemove} >Reset</Button>
                        : ""}
                </Box>
                <Box>
                    <Grid container columnSpacing={2} rowSpacing={2} className="servicesList otherList" >
                        {newdata && newdata.length > 0 ? newdata.map((data, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={3} sx={{ marginTop: 2 }}>
                                    <Card className="cardGrid" >
                                        <IconButton aria-label="more" id="long-button"
                                            aria-controls={menuopen ? 'long-menu' : undefined}
                                            aria-expanded={menuopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, data)}
                                            className='vertical_menu'>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={menuopen}
                                            onClose={handleClose_menu}
                                            PaperProps={{
                                                style: {
                                                    width: '15ch',
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            <MenuItem className='menuitems' onClick={() => handleView()}><VisibilityIcon className='fz-16 mr-5' />View</MenuItem>
                                            <MenuItem className='menuitems' onClick={() => handleEdit()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>
                                            <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, data._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>
                                        </Menu>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <div className="carosuel">
                                                <Swiper
                                                    modules={[Navigation, Pagination]}
                                                    className="mySwiper "
                                                    slidesPerView={1}
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                >
                                                    {data && data.allimages && data.allimages?.length > 0 && data.allimages.map((tdata, i) => (
                                                        <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                                    )
                                                    )}
                                                </Swiper>
                                            </div>
                                        </Grid>

                                        <CardContent>
                                            <Box className='d-flex align-items-center justify-content-between' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography noWrap className='semibold title fz-18' variant="body1" component="div">{data.news_title}</Typography>
                                                {
                                                    data.Primary && <Typography component='div' variant='body1' className='fz-14' style={{ display: 'flex', alignItems: 'center' }}><Verified className='fz-18' style={{ color: '#1492fd' }} /></Typography>
                                                }
                                            </Box>

                                            <Box className=' d-flex align-items-start justify-content-between my-10' >
                                                <Box className=''>
                                                    <Typography component='div' variant='body1' className='fz-14 semibold'>News Verified:   </Typography>
                                                    <FormControlLabel className='m-0'  control={<Switch size='small' onChange={(e) => handleChangeStatus(e, data)} checked={data.verifyNews}  />} />
                                                </Box>
                                                <Box className=' ' >
                                                        <Typography component='div' variant='body1' className='fz-14 semibold'>Primary:   </Typography>
                                                        <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<Switch size='small' onChange={(e) => handleChangeStatus1(e, data, 'Primary')} checked={data.Primary} />} />
                                                    </Box>
                                            </Box>

                                            {data && data.description ?
                                                <Typography component='div' variant='body1' className='fz-14 ' >{data && data.description.slice(0, 50) + "..."}</Typography> :
                                                <Typography component='div' variant='body1' className='fz-14 '>There is no description</Typography>}

                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                            <p className='text-center semibold'>No data found</p></Grid>}
                    </Grid>
                    <Dialog open={open} keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                        className='custom_modal'>
                        <DialogTitle>Would you like to delete News?</DialogTitle>
                        <DialogActions>
                            <Button onClick={handleClose} className='cancel_btn'>No</Button>
                            <Button onClick={handleDeleteService} className='theme-btn'>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <TablePagination
                    rowsPerPageOptions={[8, 12, 16, 20, 24]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Box>

            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className='custom_modal'
                maxWidth='md'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    News Details
                </DialogTitle>

                <DialogContent dividers>
                                <Swiper
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper "
                                    slidesPerView={1}
                                    pagination={{
                                        clickable: true,
                                    }}
                                >
                                    {alldata && alldata.allimages && alldata.allimages?.length > 0 && alldata.allimages.map((tdata, i) => (
                                        <SwiperSlide> <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${tdata}`} /></SwiperSlide>
                                    )
                                    )}
                                </Swiper>



                            {/* <Box className=''>
                                <Typography component='span' variant='body1' className='fz-15 semibold'>Specialization: </Typography>
                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.course_name}</Typography>
                            </Box> */}

                            {/* <Box className=''>
                                <Typography component='span' variant='body1' className='fz-15 semibold'>Category: </Typography>
                                <Typography component='span' variant='body1' className='fz-15 '>{alldata.category && alldata.category?.length > 0 && alldata.category[0].category_name}</Typography>
                            </Box> */}

                            {/* {alldata.subcategory && alldata.subcategory?.length > 0 ?
                                <Box className=''>
                                    <Typography component='span' variant='body1' className='fz-15 semibold'>Sub-Category: </Typography>
                                    <Typography component='span' variant='body1' className='fz-15 '>{alldata.subcategory && alldata.subcategory?.length > 0 ? alldata.subcategory[0].subcategory_name : '-'}</Typography>
                                </Box> : ""} */}


                    <Typography component='div' variant='body1' className='fz-14 semibold my-10'>Description: </Typography>
                    {sanitizedContent ? <div className='w-full editor_view' dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                        : <Typography className='p18_tag' >There is no description</Typography>}

                </DialogContent>
                <DialogActions>
                    <Button className='cancel_btn' onClick={handleClickClose}>Close</Button>
                </DialogActions>
            </BootstrapDialog>
        </>
    );
}
