import React from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  styled,
  Grid,
  FormControl,
  TextField,
  Switch,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import dayjs from "dayjs";
import axios from "axios";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import Confetti from 'react-confetti';
import { useCallback } from 'react';
import debounce from 'lodash.debounce';

function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#f3714d",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#f3714d",
  },
});

const StyledSwitch = styled(Switch)(({ theme }) => ({
    // Custom styles for the switch can be added here
  }));

export default function Publish() {
  const auth = JSON.parse(localStorage.getItem("userData"))
  const [chapters, setChapters] = React.useState([]);
  const [course, setCourse] = React.useState([]);
  const [teachername,setTeacherName] = React.useState('')
  const [showConfetti, setShowConfetti] = React.useState(false);

  // Initialize state for lessons' date and time
  const [lessonTimes, setLessonTimes] = React.useState({});
  const [isPublished, setIsPublished] = React.useState(false);

  const [openWarn, setOpenWarn] = React.useState(false);

  const [openModal, setOpenModal] = React.useState(false); 

  const [festiveOffer, setFestiveOffer] = React.useState(false)

  const { id } = useParams();

  React.useEffect(() => {
    getChapters();
  }, [id]);

  const getChapters = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `Course1/GetCourse/${id}`
      );
      setChapters(response.data.course.chapters);
      setCourse(response.data.course);
      setIsPublished(response.data.course.publishCourse);
      setFestiveOffer(response.data.course.festiveOffer)


      const initialTimes = {};
      response.data.course.chapters.forEach((chapter) => {
        chapter.lessons.forEach((lesson, i) => {
          if (lesson.DripSchedule.length > 0) {
            const dateStr = lesson.DripSchedule[0]?.releaseDate; // Get the first release date
            if (dateStr) {
              initialTimes[lesson._id] = {
                releaseDate: dayjs(dateStr),
              };
            }
          } else {
            initialTimes[lesson._id] = {
              releaseDate: dayjs(new Date()), // Set to current date if empty
            };
          }
        });
        setLessonTimes(initialTimes);
        
      });

      setOpen(new Array(chapters.length).fill(false));
    } catch (error) {
      console.error(error);
    }
  };


  

//Teacher details:
  React.useEffect(()=>{
    GetTeacher()
  },[])

  const GetTeacher = () => {
    axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachersbyid/${auth?._id}`)
    .then((res)=>{
      setTeacherName(res.data.teachers[0].username);
    })
    .catch((err)=>{
      console.log(err,"error in GetTeacher");
    })
  }

  const handleValidate = () => {
    if (chapters.length <= 0) {
        enqueueSnackbar('Please add chapters first', { variant: 'error' });
        return false;
    }
    for (const chapter of chapters) {
        if (chapter.lessons.length <= 0) {
            enqueueSnackbar('Please add lessons for chapters', { variant: 'error' });
            return false;
        }
        // for(const lesson of chapter.lessons) {
        //     if(lesson.DripSchedule <= 0){
        //         setOpenWarn(true)
        //         return false;
        //     }
        // }
    }
    if(course.course_paymenttype === undefined && (course.amountInMonth === undefined || course.amountInWeek === undefined) && course.amountOneTime === undefined ){
        enqueueSnackbar('Please Set Payment Option', { variant: 'error' });
        return false;
    }
    return true;
};



  // handleUpdate

  const [open, setOpen] = React.useState(chapters.map(() => true));


// const handlePublishToggle = async (event) => {
//   if (handleValidate()) {
//     const newPublishStatus = true;
//       try {
//           const response = await axios.put(
//               process.env.REACT_APP_BASE_URL + `Course1/PublishCourse/${id}`,
//               { publishCourse: true, publishername: teachername }
//           );
//           setIsPublished(newPublishStatus);

//           if (newPublishStatus) {
//               enqueueSnackbar('Course Published Successfully', { variant: 'success' });
//               setShowConfetti(true); // Trigger confetti
              
//           } else {
//               enqueueSnackbar('Course Unpublished Successfully', { variant: 'success' });
//           }

//           // Optionally, you can refresh course details after updating
//       } catch (error) {
//           console.error("Error updating publish status:", error);
//       }
//     }
// };

// const handleUnPublishToggle = async (event) => {
//   if (handleValidate()) {
//       const newPublishStatus = false;
//       try {
//           const response = await axios.put(
//               process.env.REACT_APP_BASE_URL + `Course1/PublishCourse/${id}`, 
//               { publishCourse: false,publishername:teachername }
//           );
//           setIsPublished(newPublishStatus);

//           // Use newPublishStatus for the notification
//           if (newPublishStatus) {
//               enqueueSnackbar('Course Published Successfully', { variant: 'success' });
//           } else {
//            setShowConfetti(false)
//               enqueueSnackbar('Course Unpublished Successfully', { variant: 'success' });
//           }

//           // Optionally, you can refresh course details after updating
//       } catch (error) {
//           console.error("Error updating publish status:", error);
//       }
//   }
// };


const handleFestiveOffer = async (e) => {
		
  setFestiveOffer(e.target.value);
  try {
    const response = await axios.put(
      process.env.REACT_APP_BASE_URL + `Course1/festiveOfferupdate/${course._id}`,
      { festiveOffer:e.target.value}
    ); 
    if (response.data.update.festiveOffer) {
      enqueueSnackbar('Course is added in Festive Offer', { variant: 'success' });
      
      } else {
      
      enqueueSnackbar(' Course is excluded from Festive Offer', { variant: 'success' });
      }
        // Optionally refresh course details after updating
  } catch (error) {
    console.error("Error while adding offer:", error);
  }

}



const handleTogglePublish = useCallback(debounce(async (status) => {
  if (handleValidate()) {
    try {
      const response = await axios.put(
        process.env.REACT_APP_BASE_URL + `Course1/PublishCourse/${id}`,
        { publishCourse: status, publishername: teachername}
      );
      
      setIsPublished(status);
      setOpenModal(true);

      if (status) {
        enqueueSnackbar('Course Published Successfully', { variant: 'success' });
        setShowConfetti(true); // Trigger confetti
      } else {
        setShowConfetti(false);
        enqueueSnackbar('Course Unpublished Successfully', { variant: 'success' });
      }

      // Optionally refresh course details after updating
    } catch (error) {
      console.error("Error updating publish status:", error);
    }
  }
}, 300), [id, teachername]);

const handleCloseModal = () => {
  setOpenModal(false); // Close the modal
};


const handleClose =()=>{
    setOpenWarn(false)
}

const handleScheduleLessons =async ()=>{
   try {
    await axios.put(
        process.env.REACT_APP_BASE_URL + `Course1/updateReleasedate/${id}`,  
    )
    enqueueSnackbar('Lessons are  Successfully scheduled', { variant: 'success' })  
    setOpenWarn(false)
    getChapters()
    
   } catch (error) {
    console.log("failed to schedule the lessons",error)
   }
}


 
  const trimmedDescription = course.shortDescription?.split(' ').slice(0, 20).join(' ') + (course.shortDescription?.split(' ').length > 20 ? '...' : '');

  return (
    <Box className="whiteContainer pricingPage p-20">
      <Box className="card p-20">
        <Box className="text-center ">
          <div
            style={{
              display: "flex",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Typography className="fz-16 text_black mb-10 semibold">
              Publisher Name:
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              className="fz-16 text_black "
            >
              {teachername}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Typography className="fz-16 text_black mb-10 semibold">
              CourseName:
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              className="fz-16 text_black "
            >
              {course.course_title}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Typography className="fz-16 text_black mb-10 semibold">
              Mode Of Course:
            </Typography>
            <Typography className="fz-16 text_black mb-10 ">
              {course.mode_of_course}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Typography className="fz-16 text_black mb-10 semibold">
              Description:
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              className="fz-16 text_black "
            >
              {(course.shortDescription && trimmedDescription) || `NA`}
            </Typography>
          </div>

          {course.course_paymenttype && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Typography className="fz-16 text_black mb-10 semibold">
                  Frequency:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="fz-16 text_black "
                >
                  {course.course_paymenttype}
                </Typography>
              </div>
            </>
          )}

          {(course.amountInMonth ||
            course.amountInWeek ||
            course.amountInByWeekly) && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Typography className="fz-16 text_black mb-10 semibold">
                  Paymenttype:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="fz-16 text_black "
                >
                  {course.amountInMonth
                    ? `Monthly`
                    : course.amountInWeek
                    ? "Weekly"
                    : "Biweekly"}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Typography className="fz-16 text_black mb-10 semibold">
                  Amount:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="fz-16 text_black "
                >
                  ${" "}
                  {course.amountInMonth
                    ? course.amountInMonth
                    : course.amountInWeek
                    ? course.amountInWeek
                    : course.amountInByWeekly}
                </Typography>
              </div>

              {/* <div
            style={{
              display: "flex",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <Typography className="fz-16 text_black mb-10 semibold">
            amountInWeek:
            </Typography>
            <Typography
              component="h6"
              variant="h6"
              className="fz-16 text_black "
            >
              { "$ " +  course.amountInWeek }
            </Typography>
          </div> */}
            </>
          )}

          {course.amountOneTime && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Typography className="fz-16 text_black mb-10 semibold">
                  Paymenttype:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="fz-16 text_black "
                >
                  {`Onetime Payment`}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <Typography className="fz-16 text_black mb-10 semibold">
                  OneTimeAmount:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  className="fz-16 text_black "
                >
                  {"$ " + course.amountOneTime}
                </Typography>
              </div>
            </>
          )}

        
            <>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "5px",
                }}
              >
                
                <Typography component="div" className="label fz-16 text_black mb-10 semibold">
                      Would you like to include this FansPage in Festive Offer?
                    </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  marginBottom: "20px",
                }}
              >
                <RadioGroup
                      row
                      aria-labelledby="demo-customized-radios"
                      name="payment_type"
                      onChange={handleFestiveOffer}
                      value={festiveOffer}
                    >
                      <FormControlLabel
                        value={true}
                        className="radio-btns"
                        control={<BpRadio />}
                        label={<Typography className="fz-14">Yes</Typography>}
                      />
                      <FormControlLabel
                        value={false}
                        className="radio-btns"
                        control={<BpRadio />}
                        label={<Typography className="fz-14">No</Typography>}
                      />
                    </RadioGroup>
              </div>
            </>
       

          {/* <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
            <Typography className="fz-16 text_black mb-10 semibold">
             {(!isPublished) ? " Publish Course:" : "Unpublish Course:"}
            </Typography>
            <StyledSwitch
            sx={{
                marginBottom:"7px"
            }}
              checked={isPublished}
              onChange={handlePublishToggle}
              color="primary"
            />
          </div> */}
          {/* {!isPublished ? 
          <Button  variant="contained" className="theme-btn" onClick={(e)=>handlePublishToggle(e)} >Publish Course</Button> 
          :
          <Button  variant="contained" className="theme-btn" onClick={(e)=>handleUnPublishToggle(e)} >UnPublish Course</Button> }
          {showConfetti == true && 
           <>
            <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={600}
          />
         
          
           </>
          }*/}

          <Button
            variant="contained"
            className="theme-btn"
            onClick={() => handleTogglePublish(!isPublished)}
          >
            {isPublished ? "UnPublish Course" : "Publish Course"}
          </Button>
          {showConfetti && (
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              recycle={false}
              numberOfPieces={600}
            />
          )}
        </Box>
      </Box>

      <Dialog
        open={openWarn}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        className="custom_modal"
      >
        <DialogTitle>
          Your haven't Schedule a few lessons , Do you want lessons's being
          scheduled now.
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} className="cancel_btn">
            No
          </Button>
          <Button onClick={handleScheduleLessons} className="theme-btn">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Congratulations!</DialogTitle>
        <DialogContent>
          <p>
            Your course has been {isPublished ? "published" : "unpublished"}{" "}
            successfully!
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
