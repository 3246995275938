import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSnackbar } from 'notistack';

const CommunityContext = createContext();

function CommunityProvider({ children }) {
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const [communitydetails, setCommunitydetails] = useState([])
    const [communityUser, setCommunityUser] = useState([])
    const [editorJSON, seteditorJSON] = useState('')
    const [communityVideo, setCommunityVideo] = useState([])
    const [communityPodcast, setCommunityPodcast] = useState([])
    const [communityThumbnail, setCommunityThumbnail] = useState([])
    const [communityAdImages, setCommunityAdImages] = useState([])
    const [communityAttachments, setCommunityAttachments] = useState([])
    const [communityCourse, setCommunityCourse] = useState([])
    const [communityEvent, setCommunityEvent] = useState([])
    // const [newdata, setNewdata] = React.useState([]);
    // const { enqueueSnackbar } = useSnackbar();


    const addCommunityUser = (user) => {
        setCommunityUser(prevUsers => {
            const validPrevUsers = Array.isArray(prevUsers) ? prevUsers : [];
            return [...validPrevUsers, user];
        });
        
    };

    const addCommunityCourse = (course) => {
       
        
        
        setCommunityCourse(prevCourse => {
            const validPrevCourse = Array.isArray(prevCourse) ? prevCourse : [];
            return [...validPrevCourse, course];
        });
    }

    const addCommunityEvent = (event) => {
        setCommunityEvent(prevEvent => {
            const validPrevEvent = Array.isArray(prevEvent) ? prevEvent : [];
            return [...validPrevEvent, event];
        });
        
    }

    const addCommunityPodcast = (podcast) => {

        setCommunityPodcast(prevPodcast => {
            const validprevPodcast = Array.isArray(prevPodcast) ? prevPodcast : [];
            return [...validprevPodcast, podcast];
        });
        
    };

    const fetchCommunity = async (id) => {
        try {
            await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunityBasedOnId/${id}`, { headers: headerformdata }).then((res) => {
                console.log(res.data, 'res.data.communityin context');

                setCommunitydetails(res.data && res.data.community);
                setCommunityUser(res.data && res.data.community && res.data.community.user_id);
                setCommunityCourse(res.data && res.data.community && res.data.community.course_id);
                setCommunityEvent(res.data && res.data.community && res.data.community.event_id);
                seteditorJSON(res.data && res.data.community && res.data.community.DescriptionJSON);
                setCommunityPodcast(res.data && res.data.community && res.data.community.podcasts);
                // setCommunityVideo(res.data && res.data.community && res.data.community.Video)
                // setCommunityThumbnail(res.data && res.data.community && res.data.community.thumbnail)
                // setCommunityAdImages(res.data && res.data.community && res.data.community.additionalimages)
                setCommunityAttachments(res.data && res.data.community && res.data.community.files);
            }).catch((err) => {
                console.log(err, 'error in context')
            })
        } catch {
            console.log('In catch block')
        }
    }

    // React.useEffect(() => {
    //     getImage();
    // }, []);

    // const getImage = async (id) => {
    //     console.log(id,);
    //     try {
    //         const response = await axios.get(process.env.REACT_APP_BASE_URL + `image/GetImagescreatedby/${id}`, { headers: headerformdata });
    //         console.log(response, "Image Data data");
    //         // setNewdata(response.data.additionalimages);
    //         // setTotal(response.data.totalcount);
    //     } catch (error) {
    //         console.error("Error fetching Image:", error);
    //         // enqueueSnackbar("Error fetching news", { variant: 'error' });
    //     }
    // }


    // const fetchImages = async (id) => {
    //     try {
    //         await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunityBasedOnId/${id}`, { headers: headerformdata }).then((res) => {
    //             console.log(res, 'res.data.communityin context')
    //             setCommunityAdImages(res.data && res.data.community && res.data.community.allimages )
    //         }).catch((err) => {
    //             console.log(err, 'error in context')
    //         })
    //     } catch {
    //         console.log('In catch block')
    //     }
    // }

    return (

        <CommunityContext.Provider value={{ communitydetails, communityUser, communityPodcast,communityEvent, communityCourse,fetchCommunity, editorJSON, communityVideo, communityThumbnail, communityAdImages, addCommunityUser, addCommunityCourse, addCommunityEvent,communityAttachments,addCommunityPodcast }}>{children}</CommunityContext.Provider>
    );
}


const useCommunity = () => {
    const context = useContext(CommunityContext);
    if (context === undefined)
        throw new Error("Community Context was used outside the CommunityProvider");
    return context;
}


export { CommunityProvider, useCommunity };