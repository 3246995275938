import React, { useRef, useState } from "react";
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
// import Sidebar from './../../Sidebar'
// import MiniDrawer from '../../../components/drawer'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, MenuItem, Checkbox, ListItemText, TextField, Select, OutlinedInput, Typography, Input } from '@mui/material'
import { Close } from '@mui/icons-material';
import 'swiper/css';
import 'swiper/css/pagination';
import Sidebar from './../../Sidebar'
import MiniDrawer from '../../../../../components/drawer'
import 'swiper/css/navigation';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Add_Video from './EditVideo'
import Edit_Video from './EditVideo'


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#E80A89',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#E80A89',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Service_Steppyform() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [videos, setVideos] = useState([])
    const [community, setCommunity] = React.useState([])
    const [user, setUser] = React.useState([])
    const params = useParams()
    const [addVideoFrom, setaddVideoFrom] = useState('YogaStudio')


    const validatenew = () => {
        // if (state2?.length == 0 && videos?.length == 0) {
        // 	setError({ video: "This field is required." })
        // 	setImageAddError("This field is required.")
        // 	return false
        // }
        // if (state2?.length == 0) {
        // 	setImageAddError("This field is required.")
        // 	return false
        // }
        // if (videos?.length == 0) {
        // 	setError({ video: "This field is required." })
        // 	return false
        // }
        return true
    }

    React.useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getUserdata`, { headers: headerformdata });
            setUser(response.data.getuserdata);
            console.log(response.data, 'response.data.user');
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    React.useEffect(() => {
        getCommunity()
    }, [])

    const getCommunity = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunityList`, { headers: headerformdata });
            setCommunity(response.data.community);
            console.log(response.data, 'response.data.community');
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [selecttedUsers, setSelecttedUsers] = React.useState([])
    const [userName, setUserName] = React.useState([])
    const handleSelectUser = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setUserName(
            typeof value === 'string' ? value.split(',') : value,
        );
        getArrayval()
    }
    React.useEffect(() => {
        getArrayval()
    }, [userName])
    function getArrayval() {
        var array = []
        const selectedUser = user.filter(content => userName.includes(content.username));
        //console.log(selectedUser, 'selectedUser');
        selectedUser.map((item, i) => {
            var val = { user_id: item._id }
            Object.assign(item, val)
            array.push(val)
        })
        setSelecttedUsers(array)
    }
    console.log(selecttedUsers, "selecttedUsersselecttedUsersselecttedUsers");

    const [selecttedCommunity, setSelecttedCommunity] = React.useState([])
    const [coummunityName, setCommunityName] = React.useState([])
    const handlechangeCommunity = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setCommunityName(
            typeof value === 'string' ? value.split(',') : value,
        );
        getCommunityval()
    }
    React.useEffect(() => {
        getCommunityval()
    }, [coummunityName])

    function getCommunityval() {
        var array = []
        const selectedCommunity = community.filter(content => coummunityName.includes(content.coummunity_name));
        // console.log(selectedCourse,'selectedCourseselectedCourse');
        selectedCommunity.map((item, i) => {
            var val = item._id
            Object.assign(item, val)
            array.push(val)
        })
        setSelecttedCommunity(array)
    }
    console.log(selecttedCommunity, "selecttedCourseselecttedCourse");

    const handleAddVideo = () => {
        const imgrawArray = videos.map(video => video.imgraw);
        const vdorawArray = videos.map(video => video.vdoraw);
        var valid = validatenew();

        if (valid === true) {
            const formData = new FormData();
            const idArray = selecttedUsers.map(id => id.user_id);
            formData.append('createdby[]', params && params.id);
            formData.append('addVideoFrom',addVideoFrom )
            // const idArrayCommunities = selecttedCommunity.map(id => id);
            // idArrayCommunities.forEach(id => {
            //     formData.append('community_id[]', id); // Use 'community_id[]' to send as array
            // });
            // formData.append('user_id', JSON.stringify(selecttedUsers.length > 0 ? selecttedUsers : []))
            // Assuming selecttedUsers is an array of user IDs
            // let usersData = selecttedUsers.map(userId => ({ user_id: userId }));
            { imgrawArray ? imgrawArray.forEach((item) => { formData.append("thumbnail", item) }) : formData.append("thumbnail", '') }
            { vdorawArray ? vdorawArray.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '') }
            console.log(...formData, "formDataformData");
            axios.post(process.env.REACT_APP_BASE_URL + `video/addVideo`, formData, { headers: headerformdata })
                .then((res) => {
                    navigate(`/yogastudios/details/video/${params.id}`);
                    enqueueSnackbar("Video added successfully", { variant: 'success' })
                    console.log(res, "added");
                })
                .catch((err) => {
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
                    console.log(err, "error");
                })
        }
    }

    const handleparent = (value) => {
        const newData = value.map(obj => ({
            "imgraw": obj.imgraw,
            "vdoraw": obj.vdoraw
        }));
        setVideos(newData);

    }

    return (
        <Box className=''>
            <MiniDrawer menuOrder={2} submenuOrder={5} />
            <Sidebar subtype={4} />
            <Box component="section" className="contentWraper">
                <Box className='whiteContainer p-20'>
                    <Typography component='h6' className='formTitle'>Add Video</Typography>
                    <Box className="mb-20">
                        <Box className="steppy_content mb-10">
                            <Box className="steppy_content mb-10">
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <label className='form-labels ' for="my-input" >Videos <Typography component='span' className='star'> * </Typography></label>
                                    <Box className="">
                                        <Add_Video parentFunc={handleparent} />
                                        {/* {error.video && <span className="error-message">{error.video}</span>} */}
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>

                        <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                            <Button onClick={handleAddVideo} variant="contained" className='theme-btn'>Submit</Button>
                        </Box>
                    </Box>
                </Box >
            </Box >
        </Box>
    )
}

export default Service_Steppyform;
