import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Stack, TextField, Typography, styled, Divider } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import axios from 'axios';
import { Pagination } from 'swiper/modules';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import playBtn from '../../../../../assets/images/play.png'
import CloseIcon from '@mui/icons-material/Close';
import videoPoster from '../../../../../assets/images/TeacherBanner.png'
import yoga2 from '../../../../../assets/images/Jobsbanner.png'
// import Header from '../../../Home/Header';
// import Menu from '../../../Menu';
// import Footer from '../../../Home/Footer';
import Spacing from '../../../../../components/Spacing';
import Comment_section from './Comment_section';

import { useNavigate, useLocation, useParams } from 'react-router-dom';


export default function Fans_page() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const location = useLocation()
    const params = useParams()
    const [Open, setOpen] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [isActive2, setIsActive2] = React.useState(false);
    const [isActive3, setIsActive3] = React.useState(false);
    const [timeline, setTimeline] = React.useState([])
    const [newdata, setNewdata] = React.useState([])
    const [fansPage, setFansPage] = React.useState([])
    const [fans_id, setFans_id] = React.useState([])
    const currentURL = window.location.href;
    const hasMultipleImages = currentURL.includes("multiple-images");

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    React.useEffect(() => {
        getFans()
    }, [])

    const getFans = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `FanPage/GetFansbyTeacherId/${params.id}`, { headers: headerlocal });
            setNewdata(response.data.fans);

            // Extracting fan ids from response data
            const fanIds = response.data.fans.map(fan => fan._id);
            setFans_id(fanIds);
            console.log(fans_id, "iddd");

            console.log(response.data.fans, "stripePlanId");
        } catch (error) {
            console.log(error, "error in service get");
        }
    }


    React.useEffect(() => {
        getFansPage();
    }, [fans_id]);

    const getFansPage = () => {
        if (fans_id.length > 0) {
            axios.get(process.env.REACT_APP_BASE_URL + `Fanpagecomment/getCommentsByFanPageByIds/${fans_id}`)
                .then((res) => {
                    console.log(res.data.data,"postsss"); 
                    setFansPage(res.data.data);
                })
                .catch((error) => {
                    console.log(error, "error in Timeline");
                });
        }
    }

    const handleOpenModal = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    const like = () => {
        setIsActive(!isActive);
    };
    const comment = () => {
        setIsActive2(!isActive2);
    };
    const comment3 = () => {
        setIsActive3(!isActive3);
    };
    // const handleNavigate = () => {
    //     navigate('/podcast-details');
    // }


    return (
        <Box className='community_details'>
            {/* <Header /> */}
            {/* <Menu /> */}
            {newdata && newdata.length > 0 ? newdata.map((data) =>
            (
                <><Box className="container ">
                    <Box className="video_banner">
                        <Swiper
                            spaceBetween={30}
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 5000, disableOnInteraction: false }}
                            modules={[Pagination]}
                            className='mySwiper p-0'
                        >
                            <SwiperSlide>
                                <img style={{ width: '100%', height: '250px' }} src={process.env.REACT_APP_BASE_URL + data.image} />
                            </SwiperSlide>
                        </Swiper>
                    </Box>
                </Box><Box className='p-20'>
                        <Box className='d-flex mb-20 flex-wrap'>
                            <img style={{ width: '100%', height: '250px' }} src={process.env.REACT_APP_BASE_URL + data.profile} />
                            <Box>
                                <Typography component='h6' variant='body1' className='fz-14 text_black semibold'>
                                    {data.name}
                                </Typography>
                                <Typography component='span' variant='body1' className='fz-14 text_grey fw-400'>
                                    {moment(data.createdAt).fromNow()}<i className='fa-solid fa-earth-americas'></i>
                                </Typography>
                            </Box>
                        </Box>
                        <Typography component='span' variant='body1' className='fz-14 text_black letter_sp fw-400'>
                            {data.short_description}
                        </Typography>
                    </Box><Divider /><Box>
                        <Box className={isActive3 ? 'comment-collapse' : 'comment-collapse comment-close'} >
                            <Comment_section data={data} getTimeLine={getFansPage}/>
                        </Box>
                        <Button disableRipple className='likebtn btn  letter_sp pt-10 mr-20' onClick={() => like(data._id)}>
                            <i class="fa-solid fa-thumbs-up text_primary fz-18 mr-10"></i>
                            <Typography className='text_black fz-14 semibold'>{data.likes.length}</Typography>
                        </Button>
                        <Button disableRipple className='likebtn btn  letter_sp pt-10 mr-20' onClick={() => comment(data._id)}>
                            <i class="fa-regular fa-message text_primary fz-16 mr-10"></i>
                            <Typography className='text_black fz-14 semibold'>{data.comments.length}</Typography>
                        </Button>

                    </Box></>
            )) : <Grid item xs={12} sm={12} md={12} lg={12}>
                <p className='text-center semibold'>No data found</p></Grid>

            }
        </Box>

    )
}