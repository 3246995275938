import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, TextField, Typography, Select, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Add } from '@mui/icons-material';
import quizImg from './../../../../../../../assets/images/quiz.jpg';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));



export default function Quiz() {
    //useState:
    const { id } = useParams()
    const [showlessons, setShowLessons] = useState(true);
    const [showBatches, setShowBatches] = useState(false);
    const [showQuestion, setShowQuestion] = useState(false);
    const [checked, setChecked] = React.useState([0]);
    const [openview, setOpenview] = React.useState(false);
    const [chapters, setChapters] = React.useState([]);
    //Batch:
    const [batchname, setBatchName] = React.useState('');
    const [lessonid, setLessonId] = React.useState('');
    const [chapterid, setChapterId] = React.useState('');
    const [batchCid, setBatchCid] = React.useState('');
    const [batchLid, setBatchLid] = React.useState('');
    const [batcharr, setBatchArr] = React.useState('');
    const [batchquestionid, setBatchQuestionId] = React.useState('')

    //Quiz questions:
    // const [arrquestion, setArrQuestion] = React.useState([{ question: '', options: [{ ans: '', isChecked: false }, { ans: '', isChecked: false }, { ans: '', isChecked: false }, { ans: '', isChecked: false }] }]);
    const [arrquestion, setArrQuestion] = React.useState([]);
    const [isAddMode, setIsAddMode] = useState(false);
    const [isSubmit, setIsSubmit] = React.useState(false)



    React.useEffect(() => {
        getChapters();
    }, [id]);

    const getChapters = async () => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_BASE_URL + `Course1/GetCourse/${id}`
            );
            setChapters(response.data.course.chapters)


        } catch (error) {
            console.error(error);
        }
    };


    React.useEffect(() => {
        getBatches()
    }, [id, chapterid, lessonid])

    const getBatches = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `Course1/batches/${id}/${chapterid}/${lessonid}`)
            .then((res) => {
                setBatchArr(res.data.batches);
                setBatchCid(res.data.chapterId);
                setBatchLid(res.data.lessonId);
            })
            .catch((err) => {
                console.log(err, "error in getBatches")
            })
    }


    //Batch Validation and functionality:
    const [error, setError] = React.useState({ batchmsg: '' });

    const Batchvalidation = () => {
        if (!batchname) {
            setError({ batchmsg: 'This field is required' })
            return false
        }
        return true
    }

    const handleBatch = (Cid, Lid) => {
        let valid = Batchvalidation()
        if (valid == true) {
            let batchbody = {
                "batchName": batchname
            }

            axios.post(process.env.REACT_APP_BASE_URL + `Course1/addBatch/${id}/${Cid}/${Lid}`, batchbody)
                .then((res) => {
                    // setBatchCid(Cid)
                    // setBatchLid(Lid)
                    setChapterId(Cid)
                    setLessonId(Lid)
                    enqueueSnackbar('Batch name created successfully', { variant: 'success' });
                    getBatches()
                    setShowLessons(false)
                    setShowBatches(true)
                    setOpenview(false)
                    setShowQuestion(false)
                    setBatchName('')

                })
                .catch((err) => {
                    console.log(err, "error in handleBatch");
                })
        }


    };






    // const handleToggle = (value) => () => {
    //     const currentIndex = checked.indexOf(value);
    //     const newChecked = [...checked];

    //     if (currentIndex === -1) {
    //         newChecked.push(value);

    //     } else {
    //         newChecked.splice(currentIndex, 1);

    //     }
    //     setChecked(newChecked);
    // };


    const handleView = (Cid, Lid) => {
        setChapterId(Cid)
        setLessonId(Lid)
        setOpenview(true)
    }

    const handleClickClose = () => {
        setOpenview(false)
        // setChapterId('')
        // setLessonId('')
        setError({ batchmsg: '' })
        setBatchName('')
    }


    const handleNavigate = (Cid, Lid) => {
        setChapterId(Cid)
        setLessonId(Lid)
        setShowLessons(false)
        setShowBatches(true)
        setOpenview(false)
        setShowQuestion(false)
    }


    //handlePush:
    const handlePush = () => {
        setArrQuestion([...arrquestion, { question: '',correctanswer:'', options: [{ ans: '', isChecked: false }, { ans: '', isChecked: false }, { ans: '', isChecked: false }, { ans: '', isChecked: false }], correctanswer: '' }]);
    }

    //Delete Questions:
    const handleDelete = (indexToDelete) => {
        const updatedQuestions = arrquestion.filter((_, index) => index !== indexToDelete);
        setArrQuestion(updatedQuestions);
        enqueueSnackbar('Question deleted successfully', { variant: 'success' })
    };

    //OnChange:
    const handleQuestionChange = (index, value) => {
        const newQuestions = [...arrquestion];
        newQuestions[index].question = value;
        setArrQuestion(newQuestions);
    };

    const handleOptionChange = (questionIndex, optionIndex, value) => {
        const newQuestions = [...arrquestion];
        newQuestions[questionIndex].options[optionIndex].ans = value;
        setArrQuestion(newQuestions);
    };


    const handleCorrectAnswer = (index,value) => {
        const newQuestions = [...arrquestion];
        newQuestions[index].correctanswer = value;
        setArrQuestion(newQuestions);
    }

    const handleToggle = (questionIndex, optionIndex) => {
        const selectedOption = arrquestion[questionIndex].options[optionIndex];

        if (!selectedOption.isChecked) {
            const alreadyCheckedIndex = arrquestion[questionIndex].options.findIndex(option => option.isChecked);

            if (alreadyCheckedIndex !== -1) {
                enqueueSnackbar('You can choose only one answer. Please unselect the selected one to choose another.', { variant: 'error' });
            } else {
                arrquestion[questionIndex].options.forEach((option, i) => {
                    option.isChecked = i === optionIndex;
                });
            }
        } else {
            selectedOption.isChecked = false;
        }

        setArrQuestion([...arrquestion]);
    };


    //Questions:
    const handleQuestion = (item) => {
        setIsAddMode(true)
        setShowQuestion(true)
        setBatchQuestionId(item)
        setFromEdit(true)
    }


    const SubmitQuestion = (newdata) => {
        let questionbody = {
            "batchId": newdata._id,
            "questions": arrquestion,
        }
        console.log(questionbody,"questionnnnn");
        axios.post(process.env.REACT_APP_BASE_URL + `Course1/addQuestions/${id}/${chapterid}/${lessonid}`, questionbody)
            .then((res) => {
                enqueueSnackbar('Questions added successfully', { variant: 'success' })
                getBatches()
                setShowQuestion(false)
                setArrQuestion([{ question: '',correctanswer:'', options: [{ ans: '', isChecked: false }, { ans: '', isChecked: false }, { ans: '', isChecked: false }, { ans: '', isChecked: false }] }])
            })
            .catch((err) => {
                console.log(err, "error in SubmitQuestion");
            })
    }


    //Edit Select and functionality:
    const [fromedit, setFromEdit] = React.useState(false);
    const [editbatchid, setEditBatchId] = React.useState(false);


    const handleEditQuestion = (batch, batchid) => {
        setIsAddMode(batch.length <= 0)
        setArrQuestion(batch)
        setEditBatchId(batchid)
        setShowQuestion(true)
        setFromEdit(false)

    }



    const SubmitEditQuestion = (newdata) => {
        let questionbody = {
            "batchId": newdata._id,
            "updatedQuestion": arrquestion,
        }
        axios.put(process.env.REACT_APP_BASE_URL + `Course1/updateQuestion/${id}/${chapterid}/${lessonid}`, questionbody)
            .then((res) => {
                getBatches()
                setFromEdit(true)
                enqueueSnackbar('Questions Updated successfully', { variant: 'success' })
                setArrQuestion([{ question: '',correctanswer:'', options: [{ ans: '', isChecked: false }, { ans: '', isChecked: false }, { ans: '', isChecked: false }, { ans: '', isChecked: false }] }])
                setShowQuestion(false)
                setEditBatchId('')

            })
            .catch((err) => {
                console.log(err, "error in SubmitQuestion");
            })
    }


    return (
        <Box className="quizPage">
            {/* {showlessons &&
                <Box className="whiteContainer p-20">
                    <Typography className='fz-16 text_primary semibold'>Chapter</Typography>
                    <Divider className='my-10' />
                    <Grid container columnSpacing={2} rowSpacing={2} className='mt-10'>
                        {lessons.map((lesson) => (
                            <Grid item xs={6} sm={4} md={3} lg={2} key={lesson.id}>
                                <Button onClick={handleView} className="btnTransparent flex-col align-items-start capitalize w100 border p-10 boxShadow borderR_4">
                                    <Typography className='fz-14 text_black semibold'>{lesson.title}</Typography>
                                    <Typography className='fz-14 text_black'>{lesson.name}</Typography>
                                    <Typography className='fz-12 text-grey'>Created on: {lesson.createdOn}</Typography>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            } */}

            {showlessons && (
                <Box className="whiteContainer p-20">
                    {/* <Typography className='fz-16 text_primary semibold'>Chapters</Typography> */}

                    {chapters.map((chapter, index) => (
                        <Box key={index} className="mt-20">
                            <Typography className="fz-18 text_primary semibold">
                                {"Chapter" + (index + 1) + ': ' + chapter.title}
                            </Typography>
                            <Divider className="my-10" />

                            <Grid
                                container
                                columnSpacing={2}
                                rowSpacing={2}
                                className="mt-10"
                            >
                                {chapter.lessons.map((lesson, index) => {
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                                            {lesson.batch.length == '' ?
                                                <Button
                                                    onClick={() => handleView(chapter._id, lesson._id)}
                                                    className="btnTransparent flex-col align-items-start capitalize w100 border p-10 boxShadow borderR_4"
                                                >
                                                    <Typography className="fz-14 text_black semibold">
                                                        {lesson.title}
                                                    </Typography>
                                                    <Typography className="fz-14 text_black">
                                                        {lesson.name}
                                                    </Typography>
                                                    <Typography className="fz-12 text-grey">
                                                        {moment(lesson.end_date).format("DD MMM YYYY h:mm a")}
                                                    </Typography>
                                                </Button>
                                                :
                                                <Button
                                                    onClick={() => handleNavigate(chapter._id, lesson._id)}
                                                    className="btnTransparent flex-col align-items-start capitalize w100 border p-10 boxShadow borderR_4"
                                                >
                                                    <Typography className="fz-14 text_black semibold">
                                                        {lesson.title}
                                                    </Typography>
                                                    <Typography className="fz-14 text_black">
                                                        {lesson.name}
                                                    </Typography>
                                                    <Typography className="fz-12 text-grey">
                                                        {moment(lesson.end_date).format("DD MMM YYYY h:mm a")}
                                                    </Typography>
                                                </Button>}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    ))}
                </Box>
            )}

            {/* Batches */}
            {showBatches && (
                <Grid container columnSpacing={2} rowSpacing={2} className="">
                    <Grid item xs={4} sm={4} md={3} lg={3}>
                        <Box className="whiteContainer position_rel p-20">
                            <Typography className="fz-16 text_black semibold">
                                Batch
                            </Typography>

                            <Divider className="mt-10 mb-20" />
                            {/* Batch Array  */}
                            {batcharr.length > 0 && batcharr?.map((batch, index) => {
                                return (
                                    <Box
                                        key={index}
                                        onClick={() => handleEditQuestion(batch.questions, batch)}
                                        style={{ cursor: 'pointer' }}
                                        className="flex-between align-items-start border p-10 mb-10 borderR_4"
                                    >
                                        <Box>
                                            <Typography className="fz-14 text_black semibold">
                                                {batch.batchName}
                                            </Typography>
                                        </Box>
                                        {batch?.questions.length == 0 && <Button className="btnTransparent capitalize text_black p-0" onClick={() => handleQuestion(batch)}>
                                            <i class="fa-solid fa-plus mr-5 text-grey"></i>
                                            <Typography component="span" className="fz-14 text-grey ">
                                                Add Questions
                                            </Typography>
                                        </Button>}
                                    </Box>
                                )
                            })}
                            {/*  */}
                            {/* <Box className="add_btn">
                                <Button
                                    className="theme-btn"
                                    onClick={() => handleView(chapterid, lessonid)}
                                >
                                    <Add className="fz-16 mr-5" /> Add Batch
                                </Button>
                            </Box> */}
                        </Box>
                    </Grid>

                    <Grid item xs={8} sm={8} md={9} lg={9}>
                        <Box className="whiteContainer p-20">
                            <Typography className="fz-16 text_black semibold">
                                Add Questions
                            </Typography>
                            <Divider className="mt-10 mb-20" />
                            {showQuestion ? (
                                arrquestion.map((split, index) => {
                                    console.log(split,"dataa answer");
                                    return (
                                        <Box key={index} className="border borderR_4">
                                            <Typography className="fz-16 text_black semibold p-20">
                                                <i className="fa-solid fa-circle-question"></i>{" "}
                                                Question {index + 1}
                                                <Button
                                                    className="delete-button"
                                                    onClick={() => handleDelete(index)}
                                                    style={{ marginLeft: "700px", color: "gray" }}
                                                >
                                                    <i className="fa-solid fa-trash"></i>
                                                </Button>
                                            </Typography>
                                            <Divider />
                                            <Box className="p-20">
                                                <Box className="mb-30">
                                                    <Typography component="div" className="label">
                                                        Enter the question
                                                    </Typography>
                                                    <FormControl variant="standard" fullWidth>
                                                        <TextField
                                                            id={`question-${index}`}
                                                            variant="outlined"
                                                            placeholder="Enter question"
                                                            size="small"
                                                            name="publisher"
                                                            className="textfield"
                                                            style={{ width: "100%" }}
                                                            value={split.question}
                                                            onChange={(e) =>
                                                                handleQuestionChange(index, e.target.value)
                                                            }
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Typography component="div" className="label">
                                                    Enter the options here
                                                </Typography>
                                                {/* <Typography component="div" className="label">
                                                    Check mark the right answer for your question
                                                </Typography> */}

                                                <Grid container columnSpacing={4}>
                                                    {split.options?.map((value, optionIndex) => {
                                                        const labelId = `checkbox-list-label-${optionIndex}`;

                                                        return (
                                                            <><Grid
                                                                item
                                                                sm={12}
                                                                md={12}
                                                                lg={6}
                                                                key={optionIndex}
                                                            >
                                                                <ListItem disablePadding>
                                                                    <ListItemButton
                                                                        disableRipple
                                                                        className="btnTransparent"
                                                                        role={undefined}
                                                                        dense
                                                                    >
                                                                        <ListItemIcon sx={{ minWidth: "auto" }}>
                                                                            {/* <Checkbox
                                                                                edge="start"
                                                                                checked={value.isChecked}
                                                                                tabIndex={-1}
                                                                                disableRipple
                                                                                inputProps={{
                                                                                    "aria-labelledby": labelId,
                                                                                }}
                                                                                onClick={() => handleToggle(index, optionIndex)} 
                                                                            /> */}
                                                                            {optionIndex + 1}
                                                                        </ListItemIcon>
                                                                    </ListItemButton>
                                                                    <FormControl
                                                                        id={labelId}
                                                                        variant="standard"
                                                                        fullWidth
                                                                    >
                                                                        <TextField
                                                                            id={`option-${optionIndex}`}
                                                                            variant="outlined"
                                                                            placeholder=""
                                                                            size="small"
                                                                            name="publisher"
                                                                            className="textfield"
                                                                            style={{ width: "100%" }}
                                                                            value={value.ans}
                                                                            onChange={(e) => handleOptionChange(index, optionIndex, e.target.value
                                                                            )} />
                                                                    </FormControl>
                                                                </ListItem>
                                                            </Grid>




                                                            </>
                                                        );
                                                    })}
                                                </Grid>
                                            </Box>
                                            <Box className="mb-20">
                                                <FormControl fullWidth>
                                                    <Typography component='div' className='label'>Select Correct Answer<Typography component='span' className='star'>*</Typography></Typography>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={split.correctanswer ? split.correctanswer : "sel"}
                                                        placeholder="Select Answer"
                                                        className="select"
                                                        onChange={(e) => { handleCorrectAnswer(index,e.target.value)}}
                                                    >
                                                        <MenuItem disabled value={'sel'}>Select Answer</MenuItem>
                                                        {split.options?.length > 0 ? split.options?.map((data) => (
												            <MenuItem value={data.ans}>{data.ans}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>}
                                                        
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    );
                                })
                            ) : (
                                <Box className="flex-col align-items-center">
                                    <img src={quizImg} width={400} alt="Quiz" />
                                    {/* <Button
                                        className="text_black border btnTransparent capitalize mt-20 fz-16 semibold"
                                        onClick={() => setShowQuestion(true)}
                                    >
                                        <i className="fa-solid fa-plus mr-5"></i> Add Question
                                    </Button> */}
                                </Box>
                            )}

                            {showQuestion && arrquestion.length > 0 ? (
                                <Box className="text-right mt-20">
                                    <Button className="theme-btn" onClick={() => handlePush()}>
                                        Next Question{" "}
                                        <i className="fa-solid fa-angles-right ml-10"></i>
                                    </Button>
                                </Box>
                            )
                                :
                                (
                                    showQuestion && (
                                        <Box className="text-right mt-20">
                                            <Button
                                                className="theme-btn"
                                                onClick={() => handlePush()}
                                            >
                                                Add Question <i className="fa-solid fa-plus ml-10"></i>
                                            </Button>
                                        </Box>
                                    )
                                )
                            }
                        </Box>

                    </Grid>

                </Grid>
            )}
          
            {showQuestion && (
                <Button
                    style={{ marginLeft: 1050, marginTop: 20 }}
                    className="theme-btn"
                    onClick={isAddMode ? () => SubmitQuestion(batchquestionid) : () => SubmitEditQuestion(editbatchid)}
                >
                    {isAddMode ? "Submit" : "Update"}
                </Button>
            )}


            <BootstrapDialog
                onClose={handleClickClose}
                aria-labelledby="customized-dialog-title"
                open={openview}
                className="custom_modal"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Create Batch
                    <IconButton
                        aria-label="close"
                        onClick={handleClickClose}
                        sx={{
                            position: "absolute",
                            right: 16,
                            top: 15,
                        }}
                    >
                        <CloseIcon className="fz-18" />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <FormControl variant="standard" className="mt-10 mb-20">
                        <Typography component="div" className="label">
                            Enter Batch Name
                        </Typography>
                        <TextField
                            id="filled-size-small"
                            variant="outlined"
                            placeholder=""
                            size="small"
                            value={batchname}
                            onChange={(e) => {
                                setBatchName(e.target.value);
                                setError({ batchmsg: "" });
                            }}
                            name="publisher"
                            className="textfield"
                            style={{ width: "100%" }}
                        />
                        {error.batchmsg && (
                            <p style={{ color: "red" }} className="fz-13 m-0">
                                {error.batchmsg}
                            </p>
                        )}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button className="cancel_btn" onClick={handleClickClose}>
                        Close
                    </Button>
                    <Button
                        className="theme-btn fz-14"
                        onClick={() => handleBatch(chapterid, lessonid)}
                    >
                        Create
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </Box>
    );
}
