import React, { useEffect, useState } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as RechartsTooltip
} from 'recharts';
import { FormControl, Select, MenuItem, Box, Typography } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const SiteVisitorChart = () => {
    const { id } = useParams();
    const [timePeriod, setTimePeriod] = useState('3'); // Default to "Last 3 Months"
    const [chartData, setChartData] = useState([]);
    const [fullData, setFullData] = useState([]);

    // Fetch data based on the selected time period
    const fetchData = () => {
        axios
            .get(`${process.env.REACT_APP_BASE_URL}Admin/LMSChart/${id}?months=${timePeriod}`)
            .then((res) => {
                setFullData(res.data.chartData); // Store full data
                setChartData(res.data.chartData.slice(0, parseInt(timePeriod))); // Set chart data based on timePeriod
            })
            .catch((err) => {
                console.log("Error fetching data:", err);
                setFullData([]);
                setChartData([]);
            });
    };

    // Use effect to fetch data initially and when the timePeriod changes
    useEffect(() => {
        fetchData();
    }, [timePeriod, id]); // Re-fetch when timePeriod or id changes

    // Handle time period selection change
    const handleSelectChange = (event) => {
        setTimePeriod(event.target.value);
    };

    return (
        <Box>
            <Box className="flex-center flex-between flex-wrap mb-20">
                <Typography
                    variant="h6"
                    component="h6"
                    className="fz-18 fw-600 "
                >
                    Site Visitors
                </Typography>
                <FormControl sx={{ width: "200px" }}>
                    <Select
                        value={timePeriod} // Bind the selected value
                        onChange={handleSelectChange} // Handle the change event
                        className='select'
                    >
                        <MenuItem value="3" sx={{ fontSize: "14px", color: "black" }}>
                            Last 3 Months
                        </MenuItem>
                        <MenuItem value="6" sx={{ fontSize: "14px", color: "black" }}>
                            Last 6 Months
                        </MenuItem>
                        <MenuItem value="9" sx={{ fontSize: "14px", color: "black" }}>
                            Last 9 Months
                        </MenuItem>
                        <MenuItem value="12" sx={{ fontSize: "14px", color: "black" }}>
                            Last 12 Months
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box className="position_rel flex-center justify-content-center mt-20 p-20 border borderR_4">
                <BarChart width={600} height={350} data={chartData}>

                    <XAxis dataKey="category" tickLine={false} />
                    <YAxis tickLine={false} />
                    <CartesianGrid strokeDasharray="3" vertical={false} />
                    <RechartsTooltip />
                    <Bar dataKey="count" fill="#ff6c47" barSize={20} label={false} />
                </BarChart>

                <Typography
						variant="body1"
						component="p"
						sx={{
							position: "absolute",
							left: "20px",
							top: "20%",
							transform: "translateY(100%)",
							writingMode: "vertical-rl",
							color: "#000",
							zIndex: 8,
							letterSpacing: "0.5px",
						}}
						className="fz-14 fw-600"
					>
						Members
					</Typography>
					<Typography
						className="fz-14 fw-600"
						variant="h6"
						component="h6"
						sx={{
							position: "absolute",
							left: "50%",
							bottom: "10px",
							color: "#000",
							zIndex: 8,
							letterSpacing: "0.5px",
						}}
					>
						Timeline
					</Typography>
            </Box>
        </Box>
    );
};

export default SiteVisitorChart;
