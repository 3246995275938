import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Button, FormControl, InputAdornment, TextField, Grid, Select } from '@mui/material';
import { SearchOutlined, InfoOutlined, DeleteOutline, CloseOutlined } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack'
import DialogContent from '@mui/material/DialogContent';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import MenuItem from '@mui/material/MenuItem';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


export default function DataTable() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }

    const { enqueueSnackbar } = useSnackbar()
    const [selected, setSelected] = React.useState([]);
    const [newsLetterDetails, setNewsLetterDetails] = React.useState()
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [total, setTotal] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [addCaller, setAddCaller] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [category, setCategory] = React.useState({ subcategoryname: '', description: "" })
    const [editId, setEditId] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const [deleteid, setDeleteId] = React.useState('');
    const [count, setCount] = React.useState(0)
    const [error, setError] = React.useState({ subcategoryname: "" })
    const [search, setSearch] = React.useState('');
    const [categoryId, setCategoryId] = React.useState('')
    const [cat, setCat] = React.useState([]);
    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        setCategory({ ...category, [name]: value })
    }
    const CloseDialouge = () => {
        setCategory({ subcategoryname: '', description: "" })
        setError({ subcategoryname: "" })
        setAddCaller(false)
    }
    let navigate = useNavigate()

    // React.useEffect(() => {
    //     getCategory()
    // }, [])
    // const getCategory = async () => {
    //     await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal })
    //         .then((res) => {
    //             setCat(res.data.category)
    //             console.log(res.data.category, "category");
    //         }).catch((error) => {
    //             console.log(error, "Error ");
    //         })
    // }

    React.useEffect(() => {
        getNewsLetterDetails()
    }, [page, rowsPerPage, search])

    const getNewsLetterDetails = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `latestnewsemail/getnewsemail?size=${rowsPerPage}&page=${page}&search=${search}`, headerlocal).then((res) => {
            console.log(res, "newsletter response");
            setNewsLetterDetails(res.data.getnewsemail)
            setCount(res.data.totalcount || res.data.getnewsemail.length || 0)
        }).catch((err) => {
            console.log(err);
        })
    }


    const handleDeleteNewsletter = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `latestnewsemail/deletenews/${deleteid}`, {}, { headers: headerlocal })
            .then((res) => {
                handleClose()
                setNewsLetterDetails()
                enqueueSnackbar("Newsletter Subscription deleted successfully", { variant: 'success' })
            }).catch((error) => {
                console.log(error, "error");
            })
    }

    const handleClickOpen = (id) => {
        setDeleteId(id)
        setOpen(true);
    };
    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
    };

    const handleEdit = (row) => {
        // console.log(row,"rowrowrowrow");
        let obj = {
            data: row
        }
        navigate("/editsubcategory", { state: obj })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleRemove = (e) => {
        setSearch('');
        setPage(0)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box className='whiteContainer'>
                <Box className='flex-center flex-wrap  p-20'>
                    {/* <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={search}
                                className='textfield search'
                                onChange={(e) => { setSearch(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {search !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box> */}
                    {/* <Box className='flex-center'>
                        <Typography component="div" className="label mr-10 mb-0">
                            Category:
                        </Typography>
                        <FormControl fullWidth className='minw150'>
                            <Select
                                className="select"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => setCategoryId(e.target.value)}
                                value={categoryId ? categoryId : 'all'}
                                // style={{ minWidth: '150px' }} 
                                style={{ width: `${(categoryId && categoryId !== 'all') ? categoryId.length * 6 + 30 : 120}px` }}
                            >
                                <MenuItem value={'all'}>All</MenuItem>
                                {cat && cat.length > 0 && cat.map((data) => (
                                    <MenuItem key={data._id} value={data._id}>{data.category_title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box> */}

                    {/* {categoryId != 'all' || search != '' ?
                        <Grid item xs={12} sm={4} md={2} lg={2} className='d-flex align-items-end mb-5'>
                            <Button onClick={handleRemove} className='theme-btn'>Reset</Button>
                        </Grid> : ""} */}
                </Box>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 700 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <TableHead className='DT-head'>
                            <TableRow >
                                {/* <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px' }}>Drag row</TableCell> */}

                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 720 }} align="left" > <strong>Email</strong>  </TableCell>
                                {/* <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Category Name </strong>  </TableCell>
                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="left" > <strong>Description</strong>  </TableCell> */}
                                <TableCell className='DT-checkbox TRow' style={{ minWidth: 150 }} align="center" > <strong>Action</strong>  </TableCell>
                            </TableRow>
                        </TableHead>

                        <DragDropContext  >
                            <Droppable droppableId="characters">
                                {(provided) => (

                                    <TableBody className="characters "   {...provided.droppableProps} ref={provided.innerRef}>
                                        {
                                            newsLetterDetails && newsLetterDetails?.length > 0 ? newsLetterDetails.map((row, i) => {

                                                return (
                                                    <TableRow>
                                                        <TableCell align="left" className='DT-row'>{row && row.email}</TableCell>
                                                        {/* <TableCell align="left" className='DT-row'>{row && row.category_id.category_title ? row.category_id.category_title : "-"}</TableCell>
                                                        <TableCell align="left" className='DT-row' >{row.description ? row.description.slice(0, 50) + "..." : "-"}</TableCell> */}
                                                        <TableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }} >
                                                            <Box className="actionbtns">
                                                                {/* <Tooltip title="Edit"><Box className='a_tag' onClick={() => handleEdit(row)}><EditOutlinedIcon className='icons text-grey' /></Box></Tooltip> */}
                                                                <Tooltip title="Delete"><Box sx={{ color: "red" }} onClick={(e) => handleClickOpen(row._id)}><DeleteOutline className='icons text-grey' /></Box></Tooltip>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>


                                                );
                                            })
                                                :
                                                <TableRow >
                                                    <TableCell align="center" colSpan={6} className='DT-row'><p className='text-center semibold m-0 fz-16'>No data found</p></TableCell>
                                                </TableRow>}



                                    </TableBody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />

                <Dialog
                    open={open}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    className='custom_modal'
                >
                    <DialogTitle>{"Would you like to delete Sub Category?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose} className='cancel_btn'>No</Button>
                        <Button onClick={handleDeleteNewsletter} className='theme-btn'>Yes</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}